import React from 'react';

const ValueContainer = props => {
	return (
		<div
			data-testid={'ValueContainer'}
			className={props.selectProps.classes.valueContainer}
		>
			{props.children}
		</div>
	);
};

export default ValueContainer;
