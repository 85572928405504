import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import {CN} from '@gtb/utils';
import {makeStyles} from '@mui/styles';
import {darken} from '@mui/material/styles';
import Table from './Table';
import {
    Memo,
    notEmptyArray,
} from '@gtb/utils';
import {isNumber, isString,} from 'lodash'
import {CustomTheme} from "../Theme/interfaces";
import {GridProps} from "./interfaces";

const useStyles = makeStyles(({palette}: CustomTheme) => ({
    root: {
        position: 'relative',
        display: 'block',
        overflow: 'hidden',
    },
    dragger: {
        zIndex: 1000,
        display: 'block',
        position: 'absolute',
        top: 0,
        left: -3,
        bottom: 0,
        width: 1,
        background: darken(palette.background.light, 0.05),
    },
}));

const stv = (value: number | string | undefined | null, fr: string = '1fr'): string => {
    return value
            ? isNumber(value)
                    ? value + 'px'
                    : isString(value)
                            ? value
                            : fr
            : fr;
};

const generateTemplate = (columns: { width: number | string | undefined | null, [x: string]: any }[]) => {
    return columns.map(column => {
        return stv(column.width);
    });
};

const Grid: React.FC<GridProps> = (props) => {

    const stl = useStyles(props);
    const {
        theme,
        className,
        data = [],
        thead,
        tbody,
        tableClasses = {},
        onSort,
        onFocus,
        onMove,
        onFiltering,
        onAllResize,
        minColumnWidth = 50,
        filters = [],
        ...rest
    } = props;

    const H = thead && thead[0] ? thead[0]['fields'] : []; // Check if thead has default value;

    const [position, setPosition] = useState({x: 0, y: 0});
    const [dragging, setDragging] = useState(false);
    const [dragger, setDragger] = useState({x: 0, index: -3, diff: 0});

    const wrp: React.Ref<any> = useRef();

    const [template, setTemplate] = useState(generateTemplate(H));

    useEffect(() => {
        setTemplate(generateTemplate(H));
    }, [JSON.stringify(thead)]);

    const onDND = (dnd: { [x: string]: any }) => {
        console.log('%cRESULT', 'color:magenta', dnd, dnd.source.index, dnd.destination.index);
        if (
                typeof onMove == 'function' &&
                dnd &&
                dnd.draggableId &&
                dnd.source &&
                dnd.destination &&
                dnd.destination.index !== dnd.source.index
        ) {
            const prev = dnd.source.index;
            const next = dnd.destination.index;
            onMove(dnd.draggableId, next - prev);
        }
    };

    const updateTemplate = (index: number, diff: number) => {
        if (
                index < 0
                || !diff
                || !notEmptyArray(thead)
                || !notEmptyArray(H)
        ) {
            setDragger({index: -1, diff: 0, x: 0});
            setDragging(false);
        }

        const columns = [...H];
        let c = 0;
        let TW = 0;
        let FRW = 0;
        columns.forEach((e, i) => {
            TW = isNumber(e.width)
                    ? TW + e.width
                    : TW;
            if (!isNumber(e.width)) {
                c++;
            }
        });
        if (TW > 0 && c > 0 && wrp && wrp.current && wrp.current.clientWidth) {
            FRW = wrp.current.clientWidth - TW;
        }

        const tmpl: { slug: string, width?: number | string | undefined }[] = columns.map((column: { slug: string, width?: number | string | undefined, [x: string]: any }, i) => {
            if (isNumber(column.width)) {

                const prev = columns[i - 1];
                const next = columns[i + 1];

                if (index > 0 && i === index - 1) {
                    if (minColumnWidth >= column.width - diff) {
                        return {slug: column.slug, width: minColumnWidth};
                    } else if (!isNumber(next.width)) {
                        if (minColumnWidth > FRW + diff) {
                            return {slug: column.slug, width: FRW + column.width - minColumnWidth};
                        }
                        return {slug: column.slug, width: column.width - diff};
                    } else if (minColumnWidth > next.width + diff) {
                        return {slug: column.slug, width: next.width + column.width - minColumnWidth};
                    } else {
                        return {slug: column.slug, width: column.width - diff};
                    }
                }

                if (i === index) {
                    if (minColumnWidth >= column.width + diff) {
                        return {slug: column.slug, width: minColumnWidth};
                    } else if (!isNumber(prev.width)) {
                        if (minColumnWidth > FRW - diff) {
                            return {slug: column.slug, width: FRW + column.width - minColumnWidth};
                        }
                        return {slug: column.slug, width: column.width + diff};
                    } else if (minColumnWidth > prev.width - diff) {
                        return {slug: column.slug, width: prev.width + column.width - minColumnWidth};
                    } else {
                        return {slug: column.slug, width: column.width + diff};
                    }
                }

                return {slug: column.slug, width: column.width};
            } else {
                return {slug: column.slug};
            }
        });

        if (!onAllResize) {
            setTemplate(tmpl.map((c) => stv(c.width)));
        } else {
            let D: { [x: string]: any } = {};
            tmpl.filter(c => isNumber(c.width)).forEach((c: { slug: string, width?: number | string }) => {
                D[c.slug] = c.width;
            });

            if (process.env.NODE_ENV === `development`) {
                console.groupCollapsed('%cRESIZE', 'color:red');
                console.log('index', index, tmpl[index]['slug'], tmpl[index]['width'], 'diff', diff);
                console.log('template', generateTemplate(H));
                console.log('tmpl', tmpl);
                console.log('data', D);
                console.groupEnd();
            }

            onAllResize(D);
        }
        setDragger({index: -1, diff: 0, x: 0});
        setDragging(false);
    };

    const onStartDrag = () => { // params: slug, diff
        setDragging(true);
        setPosition(wrp.current.getBoundingClientRect());
    };

    const onResize = (index: number, diff: number, x: number) => {
        setDragger({
            index: index,
            diff: diff,
            // @ts-ignore
            x: x - window.screen.availLeft,
        });
    };

    const onEndDrag = (index: number, diff: number) => {
        updateTemplate(index, diff);
    };

    const thNormalize = useCallback((thead) => {
        const resizeActions = typeof onAllResize === 'function'
                ? {
                    onResize: onResize,
                    onStartDrag: onStartDrag,
                    onEndDrag: onEndDrag,
                }
                : {};

        return thead.map((e: { fields: { [x: string]: any }[], [x: string]: any, }) => {
            return {
                ...e,
                fields: e.fields ? e.fields.map((th, index) => {
                    const filtered = notEmptyArray(filters)
                            ? notEmptyArray(filters.filter((u: { [x: string]: any }) => u.enabled && u.field === th.slug))
                            : false;
                    return ({
                        filtered: filtered,
                        onSort: onSort
                                ? onSort
                                : undefined,
                        onFocus: onFocus
                                ? onFocus
                                : undefined,
                        onFiltering: onFiltering
                                ? onFiltering
                                : undefined,
                        ...resizeActions,
                        index: index,
                        thead: thead,
                        ...th,
                    });
                }) : [],
            };
        });
    }, [thead]);

    const tdNormalize = useCallback((thead, tbody) => {
        const thSlugs = thead && thead[0] ? thead[0].fields.map((e: { [x: string]: any }) => e.slug) : [];
        return tbody.map((row: { [x: string]: any }) => {
            return {
                ...row,
                fields: thSlugs
                        .map((slug: { [x: string]: any }) => {
                            const td = row.fields.filter((e: { [x: string]: any }) => e.slug === slug)[0];
                            return {
                                ...(td
                                        ? td
                                        : {}),
                            };
                        })
                        .filter((n: unknown) => n),
            };
        });
    }, [thead, tbody]);

    const [tHead, setHead] = useState([]);
    const [tBody, setBody] = useState([]);

    useEffect(() => {
        const H = thNormalize(thead);
        setHead(H);
    }, [JSON.stringify(thead)]);

    useEffect(() => {
        const H = thNormalize(thead);
        const B = tdNormalize(H, tbody(data));
        setHead(H);
        setBody(B);
    }, [thead, JSON.stringify(data)]);

    // const tHead = thNormalize(thead);
    // const tBody = tdNormalize(tHead, tbody(data));

    return (
            <div className={stl.root} ref={wrp}>

                <Table
                        // @ts-ignore
                        className={CN([className, 'setupGrid'])}
                        dragging={dragging}
                        classes={tableClasses}
                        template={template}
                        thead={tHead}
                        tbody={tBody}
                        onFocus={onFocus}
                        onDND={typeof onMove === 'function'
                                ? onDND
                                : null}
                        // minWidth={getMinWidth(template, minColumnWidth)}
                        {...rest}
                />
                <div className={stl.dragger} style={dragger.x - position.x
                        ? {left: dragger.x - position.x}
                        : {}}/>
            </div>
    );
}

// @ts-ignore
export default Memo(Grid, [
    'classes',
    'theme',
    'data',
    'thead',
    'tbody',
    'tableClasses',
    'minColumnWidth',
    'focused',
    'changed',
    'selected',
    'id',
    'maxHeight',
    'className',
    'enabled',
    'disabled',
    'isLoading',
    'hideOnLoading',
    'noDataMessage',
    'virtualizer',
    'filters',
], {
    debug: 'GtbSetupGrid',
    color: 'red',
});
