import { SHOW_MODAL, EDIT_MODAL, HIDE_MODAL, MODAL_SKIN_DEFAULT } from 'store/actions';

// @ts-ignore
const maxIndex = 20;

// @ts-ignore
const initialOptions = {
	width: '600px',
	height: '80vh',
	title: '',
	content: '',
	skin: MODAL_SKIN_DEFAULT,
};

// @ts-ignore
const initialState = {
	maxIndex: maxIndex,
	open: 0,
	options: Object.keys(Array.apply(0, Array(maxIndex))).map(e => initialOptions),
};

// @ts-ignore
const modal = function (state = { ...initialState }, action: any = {}) {
	switch (action.type) {
		case SHOW_MODAL: {
			const index = action.index || state.open + 1;
			const options = [...state.options];
			options[index - 1] = {
				...options[index - 1],
				...action.options,
				skin: action.options.skin ? action.options.skin : MODAL_SKIN_DEFAULT,
			};

			return {
				...state,
				open: index,
				options: options,
			};
		}
		case HIDE_MODAL: {
			if (state.open) {
				const index = action.index || state.open;
				const options = [...state.options];
				options[index - 1] = {
					...options[index - 1],
					...action.options,
					disableBackdropClick: true,
				};
				return {
					...state,
					open: index - 1,
					options: options,
				};
			} else {
				return { ...state };
			}
		}
		case EDIT_MODAL: {
			const index = action.index || state.open;
			const options = [...state.options];
			options[index - 1] = {
				...options[index - 1],
				...action.options,
			};

			return {
				...state,
				options: options,
			};
		}
		default: {
			return state;
		}
	}
};

// @ts-ignore
export default modal;
