import { makeStyles } from '@mui/styles';
import { CustomTheme } from '@gtb/interfaces';
import { lighten } from '@mui/material/styles';

const useStyles = makeStyles(({ spacing, palette, typography, shadows }: CustomTheme) => ({
	root: {
		background: 'transparent',
		padding: 0,
	},
	content: {
		padding: 0,
		overflowY: 'unset',
	},
	paper: {
		position: 'relative',
		// position: 'absolute',
		// left:'50%',
		// top: '12.5vh',
		minWidth: '200px',
		minHeight: '100px',
		margin: `0px auto 0px`,
		padding: spacing(3, 6),
		...typography.p,
		backgroundColor: palette.background.mainModal,
		// boxShadow: '0px 30px 60px 0px rgba(0,0,0,0.15)',
		boxShadow: shadows[5],
		borderRadius: '16px',
		// transform: 'translate(-50%, 0%)',
		'.white &': {
			backgroundColor: palette.common.white,
		},
	},
	scroll: {
		width: '100%',
		padding: 0,
	},
	title: {
		...typography.h1,
		margin: spacing(0, 0, 2),
		padding: 0,
	},
	close: {
		cursor: 'pointer',
		position: 'absolute',
		boxSizing: 'border-box',
		// top: spacing(6.5),
		right: spacing(6),
		width: '4rem',
		height: '4rem',
		padding: 0,
		margin: 0,
		color: lighten(palette.text.secondary, 0.2),
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: lighten(palette.text.secondary, 0.2),
		borderRadius: '50%',
		transition: 'all 0.25s ease',
		'&:hover': {
			opacity: 0.5,
		},
	},
	closeIco: {
		position: 'absolute',
		left: '50%',
		top: '50%',
		width: '2.5rem',
		height: '2.5rem',
		transition: 'all 0.25s ease',
		transform: 'translate(-50%, -50%)',
	},
	move: {
		cursor: 'pointer',
		display: 'inline-block',
		marginLeft: 24,
	},
}));

export default useStyles;
