import React, {
    useEffect,
    useRef,
    useState,
} from 'react';
import {CN} from '@gtb/utils';
import {CTooltip as Tooltip} from '@gtb';
import {Copy} from '@gtb/components/Setups/components';
import useStyles from './styles/SimpleText.style';


const SimpleText: React.FC<{ [x: string]: any }> = (props) => {
    const classes = useStyles(props);
    const {
        style,
        value,
        children,
        inOneLine,
        tooltip,
        tooltipOptions,
        noTooltip,
        copy,
    } = props;

    const [withTooltip, setWithTooltip] = useState(false);
    const wrp: React.Ref<any> = useRef(null);

    useEffect(() => {
        if (inOneLine && wrp.current && (wrp.current.scrollWidth > wrp.current.clientWidth)) {
            setWithTooltip(true);
        } else {
            setWithTooltip(false);
        }
    });

    const renderCell = (
            <Tooltip
                    title={tooltip
                            ? tooltip
                            : !noTooltip && withTooltip
                                    ? value
                                    : false
                    }
                    placement={'top'}
                    classes={{
                        tooltip: classes.tooltip,
                    }}
                    enterDelay={500}
                    leaveDelay={100}
                    {...tooltipOptions}
            >
                <div
                        className={CN(classes.root, {'inOneLine': inOneLine})}
                        style={{...style}}
                        ref={wrp}
                >
                    {value}{children}
                </div>
            </Tooltip>
    );

    return copy ? (
            <Copy classes={{root: classes.copy}}>
                {renderCell}
            </Copy>
    ) : renderCell;
}

SimpleText.defaultProps = {
    noTooltip: false,
    copy: false,
    tooltip: null,
    tooltipOptions: {},
};

export default SimpleText;
