import React, {
    useState,
    useEffect,
} from 'react';
import {makeStyles} from '@mui/styles';
import Checkbox from '../../Fields/Checkboxes/Checkbox'
import {CustomTheme} from "../../Theme/interfaces";

const useStyles = makeStyles(({spacing}: CustomTheme) => ({
    root: {
        display: 'block',
        width: 'auto',
        height: '100%',
        padding: spacing(1.84444, 3, 2.16666),
        '.minimizedView &': {
            minHeight: spacing(5),
            paddingTop: spacing(0.84444),
            paddingBottom: spacing(1.1666),
        },
    },
}));

const CustomCheckbox: React.FC<{ [x: string]: any }> = (props) => {

    const classes = useStyles(props);
    const {checked, onChange,} = props;

    const [chkd, setChecked] = useState(!!checked);

    useEffect(() => {
        setChecked(!!checked);
    }, [checked]);

    return (
            <div
                    className={classes.root}
                    onClick={event => {
                        event.stopPropagation();
                    }}
            >
                <Checkbox
                        checked={chkd}
                        onChange={(e: any) => {
                            setChecked(!chkd);
                            onChange(e);
                        }}
                />
            </div>
    );
}

export default CustomCheckbox;
