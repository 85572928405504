import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react';
import Snackbar from '@mui/material/Snackbar';
import Icon, * as Icons from '@gtb/components/Icons';
import {makeStyles, useTheme} from "@mui/styles";
import {CustomTheme, ExtendedStylesJSSProps} from "@gtb/components/Theme/interfaces";
import Fade from '@mui/material/Fade';
import MessageWrapper from './MesageWRP';
import {
    MESSAGE_ERROR,
    MESSAGE_INFO,
    MESSAGE_SUCCESS,
    MESSAGE_TIMER,
} from 'store/actions';

const common: ExtendedStylesJSSProps = {
    root: {
        padding: `2.4rem 2.4rem`,
        flexWrap: 'nowrap',
        alignItems: 'flex-start',
    },
    message: {
        minWidth: 400,
        padding: 0,
        fontSize: '1.8rem',
        fontWeight: 400,
        wordWrap: 'break-word',
    },
};

const useStyles = makeStyles(({palette, spacing, shadows}: CustomTheme) => ({
    root: {
        zIndex: 1501,
        '& > div > div': {
            alignItems: 'center',
            boxSizing: 'border-box',
            boxShadow: shadows[4],
        },
    },
    message: {
        //position: 'absolute',
        marginTop: '40vh',
        transform: 'translate(-50%, -50%)',
        alignItems: 'center',
        borderRadius: '0.7rem',
    },
    default: {
        // @ts-ignore
        ...common.root,
        background: palette.background.light,
    },
    defaultMessage: {
        // @ts-ignore
        ...common.message,
        color: palette.text.secondary,
    },
    success: {
        // @ts-ignore
        ...common.root,
        background: palette.background.light,
    },
    successMessage: {
        // @ts-ignore
        ...common.message,
        color: palette.text.secondary,
    },
    error: {
        // @ts-ignore
        ...common.root,
        background: palette.background.light,
    },
    errorMessage: {
        // @ts-ignore
        ...common.message,
        color: palette.text.secondary,
    },
    info: {
        // @ts-ignore
        ...common.root,
        background: palette.background.light,
    },
    infoMessage: {
        // @ts-ignore
        ...common.message,
        color: palette.text.secondary,
    },
    close: {
        cursor: 'pointer',
        background: 'transparent!important',
        padding: 0,
        opacity: 0.9,
        width: spacing(3),
        height: spacing(3),
        transform: 'scale(1.5)'
    },
}));

const Message: React.FC<{ [x: string]: any }> = (props) => {

    const stl = useStyles(props);
    const theme:CustomTheme = useTheme();
    const {
        classes,
        state,
        options: {
            type,
            message,
            autoHideDuration,
            html = true,
            ...rest
        },
        hideMessage,
    } = props;

    const wrp: React.Ref<any> = useRef(null);
    const [mState, setState] = useState(state);

    useEffect(() => {
        setState(state);
    }, [state]);

    useEffect(() => {
        if (state) {
            document.addEventListener("mouseup", clickAwayCallback);
        }
        return () => {
            document.removeEventListener("mouseup", clickAwayCallback);
        }
    }, [mState]);

    const handleClickAway = (e: MouseEvent) => {
        if (wrp && wrp.current && !wrp.current.contains(e.target) && mState) {
            hideMessage();
            document.removeEventListener("mouseup", clickAwayCallback);
        }
    };

    const clickAwayCallback = useCallback((e) => {
        handleClickAway(e);
    }, [mState]);

    const closeButtonColor = () => {
        return theme.palette.text.secondary;
        // switch (type) {
        // 	case Actions.MESSAGE_SUCCESS:
        // 		return theme.palette.text.secondary;
        // 	case Actions.MESSAGE_INFO:
        // 		return theme.palette.text.secondary;
        // 	case Actions.MESSAGE_ERROR:
        // 		return theme.palette.text.secondary;
        // 	default:
        // 		return theme.palette.text.secondary;
        // }
    };

    const typeSwitcher = () => {
        switch (type) {
            case MESSAGE_SUCCESS:
                return {root: stl.success, message: stl.successMessage};
            case MESSAGE_INFO:
                return {root: stl.info, message: stl.infoMessage};
            case MESSAGE_ERROR:
                return {root: stl.error, message: stl.errorMessage};
            case MESSAGE_TIMER:
                return {root: stl.info, message: stl.infoMessage};
            default:
                return {root: stl.default, message: stl.defaultMessage};
        }
    };

    return (
            <div className={stl.root} ref={wrp}>
                <Snackbar
                        data-testid={'Message'}
                        {...rest}
                        TransitionComponent={Fade}
                        message={
                            <MessageWrapper message={message} type={type} html={html}
                                            autoHideDuration={autoHideDuration}/>
                        }
                        open={mState}
                        // onClose={hideMessage}
                        className={type
                                ? type
                                : 'default'}
                        ContentProps={{
                            classes: typeSwitcher(),
                        }}
                        classes={{
                            root: stl.message,
                        }}
                        action={(
                                <div
                                        key={"GtbMessageClose"}
                                        data-testid={'MessageClose'}
                                        className={stl.close}
                                        onClick={hideMessage}
                                >
                                    <Icon view={Icons.ICON_CLOSE} fill={[closeButtonColor()]}/>
                                </div>
                        )}
                />
            </div>
    );
}

export default Message;
