import React from "react";
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "../../../@gtb/components/Theme/interfaces";

const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    root: {},
    domain: {
        fontSize: '24px',
        fontWeight: 400,
        color: palette.action.active,
    },
}));

interface CustomBreadcrumbsProps {
    path?: string[],
}

const Breadcrumbs: React.FC<CustomBreadcrumbsProps> = ({path}) => {
    const classes = useStyles();
    return (
            <div className={classes.root}>
                {path ? path.map((e: string) => (
                        <h3 key={'breadcrumbs_' + e} className={classes.domain}>{e}</h3>)) : ''}
            </div>
    );
}

export default Breadcrumbs;
