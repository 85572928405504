import React from 'react';
import {Message} from '@gtb';
import {useDispatch, useSelector} from 'react-redux';
import {hideMessage} from "store/actions";


const IMessage: React.FC<{ [x: string]: any }> = (props) => {

    // @ts-ignore
    const {message} = useSelector((state) => state.layout);
    const dispatch = useDispatch();

    return <Message
            state={message.state}
            options={message.options}
            hideMessage={() => {
                dispatch(hideMessage())
            }}
    />
};

export default IMessage;
