import _ from 'lodash';
import { getAuthToken, getServerTimezone } from 'api/auth';
import { changeTimeZone } from '@gtb/utils';
import { MESSAGE_ERROR, showMessage } from '../layout';

export const AUTH_LOGOUT = '[AUTH] LOGOUT';
export const AUTH_SET_TOKEN = '[AUTH] SET TOKEN';
export const AUTH_SET_LOGIN_INFO = '[AUTH] SET LOGIN INFO';
export const SET_TIMEZONE = 'SET TIMEZONE';

export const setAuthToken = (token: string) => {
	return { type: AUTH_SET_TOKEN, token };
};
export const logout = () => {
	return (dispatch: any) => {
		dispatch({ type: AUTH_LOGOUT });
		dispatch(setAuthToken(''));
	};
};

export const refreshAuthToken = (options: { login: string, password: string }, onSuccess?: () => void, onError?: (error: unknown) => void) => {
	return (dispatch: any, getState: () => { [x: string]: any }) => {
		const { login, password } = options;
		getAuthToken({
			auth: { login, password },
			onSuccess: (res: any) => {
				// console.log('auth res', res);
				if (res && res.token) {
					dispatch(setAuthToken(res.token));
					if (_.isFunction(onSuccess)) {
						onSuccess();
					}
				} else {
					dispatch(logout());
				}
			},
			onError: (err) => {
				// console.log('auth error', err);
				dispatch(logout());
				if (_.isFunction(onError)) {
					onError(err);
				}
				// dispatch(showMessage({
				// 	message: 'Authentication failed',
				// 	type: MESSAGE_ERROR,
				// }));
			},
		});
	};
};


export const authorize = (config: {
	options: { login: string, password: string },
	onSuccess?: () => void,
	onError?: (error: unknown) => void
}) => {
	const { options, onSuccess, onError } = config;
	return (dispatch: any) => {
		dispatch(refreshAuthToken(options, onSuccess, onError));
	};
};


export const setTimeZone = (timezone: string) => {
	changeTimeZone(timezone);
	return { type: SET_TIMEZONE, timezone };
};

export const getCurrentTimezone = (onSuccess?: (message: unknown) => void, onError?: (error: unknown) => void) => {
	return (dispatch: any, getState: () => { [x: string]: any }) => {
		getServerTimezone({
			onSuccess: (res) => {
				console.log('SUCESS GET TZ API', res);
				// dispatch();
			},
			onError: (err) => {
				console.log('ERROR GET TZ API', err);
				// dispatch();
			},
		});
	};
};
