import React from 'react';
import {Button} from '@gtb';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {alpha as fade} from '@mui/material/styles';

const useStyles = makeStyles(({palette, spacing}: CustomTheme) => ({
    root: {
        borderRadius: 0,
        borderLeftWidth: 1,
        borderStyle: 'solid',
        minWidth: 'inherit',
        background: fade(palette.background.main, 0.2),
        '&:hover': {
            background: palette.action.hover,
        },
        '&:first-child': {
            borderLeftWidth: 1,
        },
        '&:last-child': {
            borderRadius: 0,
        },
    },
}));


const InlineButton: React.FC<{ [x: string]: any }> = (props) => {
    const stl = useStyles(props);
    const {
        classes,
        icon,
        label,
        onSubmit,
        ...rest
    } = props;

    return (
            <Button
                    classes={{root: stl.root}}
                    skin={'grouped'}
                    onClick={() => {
                        onSubmit();
                    }}
                    {...rest}
            >
                {icon} {label}
            </Button>
    );
}

export default InlineButton;
