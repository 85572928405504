import { alpha, darken } from '@mui/material/styles';
import { CustomTheme, ExtendedStylesJSSProps, StylesJSSProps } from '@gtb/interfaces';

export const BorderColor = ({ palette }: CustomTheme) => ({
	borderStyle: 'solid',
	borderColor: darken(palette.background.light, 0.05),
});

export const HeaderStyles = ({ palette, typography }: CustomTheme) => ({
	...typography.tableTitle,
	background: alpha(palette.background.main, 0.5),
	borderColor: darken(palette.background.light, 0.05),
});

export const TableStyles = ({ spacing, palette, shadows, typography }: CustomTheme): ExtendedStylesJSSProps => ({
	table: {
		display: 'block',
		width: '100%',
		background: palette.common.white,
		margin: spacing(3, 0),
		borderRadius: '0.7rem',
		overflow: 'hidden',
		'&.minimizedView': {},
		'&:hover': {
			boxShadow: shadows[1],
		},
		'&.inline': {
			background: 'transparent',
		},
		'&.inverse': {},
	},
	row: {
		display: 'grid',
		borderStyle: 'solid',
		borderColor: darken(palette.background.light, 0.05),
		borderTopWidth: 0,
		borderRightWidth: 0,
		borderLeftWidth: 0,
		borderBottomWidth: 1,
		background: palette.common.white,
		'.inverse &': {
			background: alpha(palette.action.hover, 0.5),
			borderColor: palette.common.white,
		},
		'&:last-child': {
			borderBottomWidth: 0,
			// borderRadius: '0.7rem',
			borderRadius: `0px 0px 0.7rem 0.7rem`,
		},
		'&:hover': {
			'& $td.showOnRowHover': {
				opacity: 1,
			},
		},
		'.inline &': {
			background: 'transparent',
			'&.table-header-row': {
				background: palette.common.white,
			},
		},
		'&.hovered': {
			cursor: 'pointer',
			'&:hover': {
				background: palette.action.hover,
			},
		},
		'&.focused': {
			cursor: 'pointer',
			background: palette.action.focused,
			'&.hovered:hover': {
				background: darken(palette.action.focused, 0.05),
			},
		},
		'&.selected': {
			cursor: 'pointer',
			background: palette.action.selected,
			'&.focused, &.focused, &.focused.hovered': {
				background: palette.action.selected,
			},
			'&.hovered:hover, &.focused.hovered:hover': {
				background: darken(palette.action.selected, 0.05),
			},
		},
		'&.changed, &.changed.selected': {
			cursor: 'pointer',
			// background: palette.message.success,
			'&.focused, &.focused, &.focused.hovered': {
				background: palette.action.focused,
			},
			'&.focused.hovered:hover': {
				// background: darken(palette.action.focused, 0.05),
			},
			'&.hovered:hover': {
				// background: darken(palette.message.success, 0.05),
			},
		},
		'&.bold': {
			'& $th, & $td': {
				fontWeight: 900,
				color: palette.text.primary,
			},
		},
	},
	th: {
		...typography.tableTitle,
		position: 'relative',
		minHeight: spacing(7),
		margin: 0,
		display: 'flex',
		flexShrink: 0,
		overflow: 'hidden',
		alignItems: 'center',
		padding: 0,
		background: alpha(palette.background.main, 0.5),
		borderColor: darken(palette.background.light, 0.05),
		borderStyle: 'solid',
		borderTopWidth: 0,
		borderBottomWidth: 0,
		borderLeftWidth: 0,
		borderRightWidth: 1,
		// '.minimizedView &': {
		// 	minHeight: spacing(5.5),
		// },
		'.inverse &': {
			borderColor: palette.common.white,
		},
		'&.centered': {
			justifyContent: 'center',
		},
		'&:last-child': {
			borderRightWidth: 0,
		},
		'&.noBorder': {
			borderRightWidth: 0,
		},
		'&.noBg': {
			background: 'transparent',
		},
		'&.full': {
			padding: 0,
		},
	},
	td: {
		display: 'flex',
		flexShrink: 0,
		overflow: 'hidden',
		alignItems: 'center',
		...typography.tableGrid,
		margin: 0,
		padding: 0,
		minHeight: spacing(7),
		background: 'transparent',
		borderColor: darken(palette.background.light, 0.05),
		borderStyle: 'solid',
		borderTopWidth: 0,
		borderBottomWidth: 0,
		borderLeftWidth: 0,
		borderRightWidth: 1,
		'&.hovered': {
			cursor: 'pointer',
			background: palette.action.hover,
		},
		'&.focused': {
			cursor: 'pointer',
			background: palette.action.focused,
			'&.hovered': {
				background: darken(palette.action.focused, 0.05),
			},
		},
		'&.selected': {
			cursor: 'pointer',
			background: palette.action.selected,
			'&.focused': {
				background: palette.action.selected,
			},
			'&.hovered, &.focused.hovered': {
				background: darken(palette.action.selected, 0.05),
			},
		},
		'&.vr': {
			borderBottomWidth: 1,
		},
		'.highlightFirstColumn &:first-child': {
			background: alpha(palette.background.main, 0.5),
		},
		'.minimizedView &': {
			minHeight: spacing(5.5),
		},
		'.inverse &': {
			borderColor: palette.common.white,
		},
		'&.centered': {
			justifyContent: 'center',
		},
		'&.middle': {
			alignItems: 'center',
		},
		'&.showOnHover': {
			opacity: 0,
			'&:hover': {
				opacity: 1,
			},
		},
		'&.showOnRowHover': {
			opacity: 0,
		},
		'&.noBorder': {
			borderRightWidth: 0,
		},
		'&:last-child': {
			borderRightWidth: 0,
		},
	},
});
