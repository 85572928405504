import React from 'react';
// import Icon, * as Icons from '@gtb/components/GtbIcons';
// import { IconButton } from '@gtb';

const DropdownIndicator = props => {
	const {onMouseDown} = props.innerProps;
	return <div className={props.selectProps.classes.arrow} onMouseOver={onMouseDown}>{''}</div>;
	// return (
	// 	<IconButton className={props.selectProps.classes.arrow}>
	// 		<Icon view={Icons.ICON_DELETE_1} />
	// 	</IconButton>
	// );
};

export default DropdownIndicator;
