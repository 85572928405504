import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {lighten} from '@mui/material/styles';
import {Tooltip} from "@gtb";
import {SetupLabelProps} from "./interfaces";
import Icon, * as Icons from '@gtb/components/Icons';
import {CN} from '@gtb/utils';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    label: {
        ...typography.inputField,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 500,
        flexShrink: 0,
        backgroundColor: lighten(palette.background.main, 0.5),
        '& i': {
            fontWeight: 400,
        },
    },
    tooltip: {
        maxWidth: 400,
    },
    labelText: {
        display: 'flex',
        width: `calc(100% - ${spacing(4.5 + 1)})`,
        minHeight: spacing(7),
        lineHeight: spacing(3),
        padding: spacing(2.1666, 3, 1.8444),
        '.minimizedView &': {
            minHeight: spacing(5.5),
            padding: spacing(1.4166666, 3, 1.083333),
        },
    },
    labelIndex: {
        marginRight: spacing(1),
    },
    labelIcon: {
        marginRight: spacing(1),
    },
    tooltipPlacementTop: {
        margin: spacing(1.5, 0)
    },
    tooltipPlacementBottom: {},
    tooltipAlignmentBottom: {
        '&:before': {
            left: 'calc(50% - 0.6rem)',
        },
    },
    infoRoot: {
        left: '100%',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
    },
    infoIcon: {
        width: '2.4rem',
        height: '2.4rem',
        '& > path:first-child': {
            fill: palette.text.active
        },
        '&:hover > path:first-child': {
            fill: palette.text.secondary,
        }
    },
    info: {
        display: 'flex',
        cursor: 'pointer',
        opacity: 1,
        transition: 'all 0.1s ease',
        margin: spacing(0, 1.5, 0, 0),
    },
    manda: {
        width: spacing(3),
        marginRight: spacing(1.5),
    },
    mandaIcon: {
        width: '1.4rem',
        height: '1.4rem',
    },
    mandaStar: {
        fontWeight: 500,
        color: palette.error.main,
        paddingLeft: spacing(0.5),
    },
    popper: {},
}));

const SetupLabel: React.FC<SetupLabelProps> = ({
    template = {},
    position,
    minimizedView,
    label,
    labelAdditionalComponents,
    index,
    icon,
    units,
    tooltip,
    tooltipPlacement,
    tooltipDelay,
    manda,
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }
    const handleOpen = () => {
        setOpen(true);
    }

    return label ? (
            <div
                    className={CN(classes.label, {minimizedView: minimizedView})}
                    style={{...(template && template.label ? template.label : {})}}
            >
                <div
                        className={classes.labelText}
                        style={{...(template && template.labelText ? template.labelText : {})}}
                >
                    {index && <span className={classes.labelIndex}>{index}.</span>}
                    {!!icon && <div className={classes.labelIcon}>{icon}</div>}
                    {units
                            ? <span>{label}, <i>{units}</i></span>
                            : <span>{label}</span>
                    }
                    {manda ? (<span className={classes.mandaStar}> *</span>) : null}
                </div>
                {!!labelAdditionalComponents && labelAdditionalComponents}
                {
                    tooltip && (
                            <div className={classes.infoRoot}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <div>
                                        <Tooltip
                                                title={
                                                    <div
                                                            onClick={() => setOpen(true)}
                                                            onContextMenu={() => setOpen(true)}
                                                    >
                                                        {tooltip || label}
                                                    </div>
                                                }
                                                open={open}
                                                placement={tooltipPlacement ? tooltipPlacement : 'top'}
                                                classes={{
                                                    tooltip: classes.tooltip,
                                                    popper: classes.popper,
                                                    tooltipPlacementTop: classes.tooltipPlacementTop,
                                                    tooltipPlacementBottom: CN(classes.tooltipPlacementBottom, {[classes.tooltipAlignmentBottom]: position === 'first' || position === 'single'}),
                                                }}
                                                enterDelay={tooltipDelay}
                                                disableInteractive={false}
                                        >
                                            <div className={CN([classes.info, 'info'])} onClick={handleOpen}>
                                                <Icon view={Icons.ICON_INFORMATION_1}
                                                      classes={{root: classes.infoIcon}}/>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </ClickAwayListener>
                            </div>
                    )
                }
            </div>
    ) : null;
};

export default SetupLabel;
