import React from 'react';
import {Button, Tooltip} from '@gtb';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import Icon, * as Icons from '@gtb/components/Icons';
import {alpha as fade} from '@mui/material/styles';

const useStyles = makeStyles(({palette, spacing}: CustomTheme) => ({
    root: {},
    btn: {
        height: '100%',
        padding: spacing(2, 2),
        background: fade(palette.background.main, 0.2),
        borderRadius: 0,
        minWidth: 'inherit',
        borderLeftWidth: 1,
        borderColor: palette.background.main,
        borderStyle: 'solid',
        '&:hover': {
            background: palette.action.hover,
        },
    },
}));


const IconSetupButton: React.FC<{ [x: string]: any }> = (props) => {
    const stl = useStyles(props);
    const {
        classes,
        icon = <Icon view={Icons.ICON_CONNECTED}/>,
        label = null,
        tooltip,
        tooltipPlacement,
        tooltipProps,
        onSubmit = () => false,
        ...rest
    } = props;

    return (
            <Tooltip
                    title={tooltip}
                    placement={tooltipPlacement ? tooltipPlacement : 'top'}
                    disableFocusListener={true}
                    {...tooltipProps}
            >
                <div className={stl.root}>
                    <Button
                            classes={{root: stl.btn}}
                            skin={'simpleIcon'}
                            onClick={event => {
                                onSubmit(event);
                            }}
                            {...rest}
                    >
                        {icon} {label}
                    </Button>
                </div>
            </Tooltip>
    );
}


export default IconSetupButton;
