import React, {
	useRef,
	useState,
	useEffect,
} from 'react';
import { CN } from '@gtb/utils';
// import chroma from 'chroma-js';
import Icon, * as Icons from '@gtb/components/Icons';
import {
	CTooltip as Tooltip,
	IconButton,
} from '@gtb';

const SingleValue = props => {
	const [ overflowed, setOverflowed ] = useState(false);
	const wrp = useRef(null);

	useEffect(() => {
		if (wrp && wrp.current && wrp.current.scrollWidth > wrp.current.offsetWidth) {
			setOverflowed(true);
		} else {
			setOverflowed(false);
		}
	});

	const iconColor = props.data.iconColor
		? [ props.data.iconColor, props.data.iconColor, props.data.iconColor, props.data.iconColor ]
		: false;

	let ColorMarker = null;
	if (props.data.color && !props.data.icon) {
		const jColor = props.data.color
			? props.data.color
			: false; // chroma(props.data.color).alpha(0.7).css() : false;
		ColorMarker = jColor
			? (
				<IconButton className={props.selectProps.classes.chipColorIcon}>
					<Icon view={Icons.ICON_SEVERITY} fill={[ jColor, jColor, jColor ]}/>
				</IconButton>
			)
			: null;
	}

	return (
		<div className={props.selectProps.classes.singleValue} {...props.innerProps}>
			{props.selectProps.label}
			{props.data && props.data.icon && !props.data.tooltip && (
				<div className={props.selectProps.classes.icon}>
					{typeof props.data.icon == 'string'
						? (
							<Icon view={props.data.icon} fill={iconColor}/>
						)
						: (
							props.data.icon
						)}
				</div>
			)}
			{ColorMarker}
			{props.data && props.data.tooltip && (
				<Tooltip
					classes={{
						popper: props.selectProps.classes.optionTooltip,
						tooltipPlacementBottom: props.selectProps.classes.tooltipPlacementBottom,
					}}
					title={props.selectProps.withTooltip || overflowed
						? props.data.tooltip
						: false
					}
					placement={props.selectProps.tooltipPlacement || 'right'}
				>
					<div className={classNames('tooltip', { 'bold': props.data.isBolded })}>
						{props.data.icon && (
							<div className={props.selectProps.classes.icon}>
								{
									typeof props.data.icon == 'string'
										? (
											<Icon view={props.data.icon} fill={iconColor}/>
										)
										: (
											props.data.icon
										)
								}
							</div>
						)}
						<div className={CN('tooltipValue', { 'bold': props.data.isBolded })} ref={wrp}>
							{props.children}
						</div>
					</div>
				</Tooltip>
			)}
			{!props.data.tooltip && (
				<div className={CN({ 'bold': props.data.isBolded })}>
					{props.children}
				</div>
			)}
		</div>
	);
};

SingleValue.defaultProps = {
	withTooltip: false,
};

export default SingleValue;
