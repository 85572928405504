import { combineReducers } from 'redux';
import select from './select';
import data from './data';

const rootReducer = combineReducers({
	select,
	data,
});

export default rootReducer;
