import React, {
    Fragment,
    useEffect,
    useState,
    useLayoutEffect,
} from 'react';
import GtbScrollWrapper from '@gtb/components/Scroll/ScrollWrapper';
import {Preloader} from '@gtb'; // TODO: Soft preloader
import {
    ScrollSync,
} from 'react-virtualized';
import Header from './VHeader';
import {GridSimpleTableProps, GridRowCellProps} from './interfaces';

const Simple: React.FC<GridSimpleTableProps> = (props) => {

    const {
        conf,
        head,
        tbody,
        rBody,
        isInner,
        template,
        parentRef,
    } = props;

    const {
        classes,
        id,
        maxHeight,
        isLoading,
        wheelPropagation,
    } = conf;

    const [w, setW] = useState('inherit');
    // useEffect(() => {
    // 	setW(parentRef && parentRef.current ? parentRef.current.offsetWidth : 'inherit');
    // }, [ parentRef && parentRef.current ? parentRef.current.offsetWidth : 'inherit' ]);

    const updateSize = () => {
        setW(parentRef && parentRef.current ? parentRef.current.offsetWidth : 'inherit');
    };

    useLayoutEffect(() => {
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useLayoutEffect(() => {
        updateSize();
    }, [parentRef && parentRef.current ? parentRef.current.offsetWidth : 'inherit']);

    return (
            <>
                {/*// @ts-ignore */}
                <ScrollSync>
                    {({
                          // clientHeight,
                          // clientWidth,
                          onScroll,
                          // scrollHeight,
                          scrollLeft,
                          // scrollTop,
                          // scrollWidth,
                      }) => {
                        return (
                                <Fragment>
                                    <Header
                                            conf={conf}
                                            head={head}
                                            template={template}
                                            scrollLeft={scrollLeft}
                                    />
                                    <Preloader
                                            isLoading={isLoading}
                                            classes={{
                                                root: classes.preloader,
                                                content: classes.preloaderContent,
                                            }}
                                            disable={isInner}
                                    >
                                        {/*// @ts-ignore */}
                                        <GtbScrollWrapper
                                                maxHeight={maxHeight}
                                                style={{
                                                    width: '100%',
                                                    maxWidth: w ? w : 'inherit',
                                                }}
                                                onScrollX={(e: any) => onScroll(e)}
                                                option={{wheelPropagation: wheelPropagation}}
                                        >
                                            <div>
                                                {tbody.map((e: GridRowCellProps, i: number) => {
                                                    return rBody({
                                                        rowIndex: i,
                                                        key: id + 'STR_' + e._id
                                                                ? e._id
                                                                : id + 'STR_' + i,
                                                        style: {},
                                                        isVisible: true,
                                                    });
                                                })}
                                            </div>
                                        </GtbScrollWrapper>
                                    </Preloader>
                                </Fragment>
                        );
                    }}
                </ScrollSync>
            </>
    );
};

Simple.propTypes = {};

Simple.defaultProps = {};

export default Simple;
