import React from 'react';
import useStyles from '@styles/AppStyles';
import Page from 'apps/common/components/Page';
import Toolbar from './Toolbar';
import System from "./System";

export const App: React.FC = () => {
    const classes = useStyles();

    return (
            <Page
                    // isLoading={true}
                    path={['System Settings']}
                    toolbar={<Toolbar />}
            >
                <System />
            </Page>
    );
};

export default App;
