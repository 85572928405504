import { isInArray, notEmptyArray } from '@gtb/utils';

export const formatData = (data: any, options: any, {
	isCreatable,
	hideNotExistValue,
}: { isCreatable: any, hideNotExistValue: any }) => {
	return formatArrayToSimpleData(notEmptyArray(data) ? data : [], options, isCreatable, hideNotExistValue);
};

export const policyColorCheck = (color: any) => {
	if (typeof color !== 'string') {
		return color;
	}
	const old = [
		'#000000',
		'#a34aa4',
		'#032e87',
		'#00690c',
		'#750303',
		'#0095b6',
	];  // From CC
	const next: any = [
		// palette.policy.encrypted,
		// palette.policy.mislabeled,
		// palette.policy.allPatterns,
		// palette.policy.allFiles,
		// palette.policy.allDatabases,
		// palette.policy.category,
	]; // New values
	const index = old.indexOf(color.toLowerCase());
	/*  REMAPING
		#000000 => #ff7f00 | Encrypted file, Detected file, All Policies, TYPE_SET<b>
		#A34AA4 => #ba7dbb | Not File Owner, Mislabelled file, Mislabelled email, classifications
		#032e87 => #4da6ff | POLICY_TYPE_PATTERN, POLICY_TYPE_BUILTIN, All Patterns
		#00690c => #7acc29 | POLICY_TYPE_FILE, All Files
		#750303 => #ff3333 | POLICY_TYPE_DATABASE, All Databases
		#0095b6 => #0095b6 | Categories
		- // -  => #e6eef2 | Simple values
	*/
	return index < 0 ? color : next[index];
};

export const formatArrayToSimpleData = (data: any, options: any, isCreatable?: any, hideNotExistValue: boolean = false) => {
	return data.map((item: any) => {
		// eslint-disable-next-line
		let filter = options.filter((option: any) => option.value == item);
		if (hideNotExistValue && !filter.length) {
			return null;
		}
		let label = filter.length
			? filter[0].label
			: item;
		let color = filter.length && filter[0].color
			? filter[0].color // policyColorCheck(filter[0].color) // TODO: refactor if policy needed
			: '';
		// : '#000000';
		let icon = filter.length && filter[0].icon
			? filter[0].icon
			: '';
		let iconColor = filter.length && filter[0].iconColor
			? filter[0].iconColor
			: '';
		let tooltip = filter.length && filter[0].tooltip
			? filter[0].tooltip
			: null;
		return filter.length || isCreatable ? ({
			value: item,
			label: label,
			tooltip: tooltip,
			color: color,
			icon: icon,
			iconColor: iconColor,
		}) : null;
	}).filter((e: any) => e);
};

export const formatOptions = (options: any, skip = [], value = []) => {
	return options.filter((e: any) => {
		return !isInArray(skip, e.value) || isInArray(value, e.value);
	}).map((e: any) => ({ ...e, color: policyColorCheck(e.color) }));
};

export const formatComboSelectValue = (data: any, options: any[] = []) => {
	if (notEmptyArray(data)) {
		return formatArrayToSimpleData(data, options);
	}
	return options.filter((option: any) => option.value === data);	// TODO WTF ????
};
