import { darken } from '@mui/material/styles';
import { alpha as fade } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { CustomTheme } from '@gtb/components/Theme/interfaces';

export const useStyles = makeStyles(({ spacing, palette, typography, transitions, shadows }: CustomTheme) => ({
	root: {
		position: 'relative',
		flexGrow: 1,
		'&._isDisabled': {
			opacity: 0.5,
			filter: 'grayscale(100%)',
			pointerEvents: 'none',
		},
		'&._isSingle': {
			pointerEvents: 'none',
		},
		'& *': {
			boxSizing: 'border-box',
			cursor: 'pointer',
		},
		'& fieldset': {
			borderWidth: '0px!important',
			borderColor: 'transparent!important',
		},
	},
	disabled: {
		opacity: 0.5,
		filter: 'grayscale(100%)',
		pointerEvents: 'none',
	},
	input: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: 'inherit',
		padding: 0,
		'& span': {
			background: 'transparent',
		},
	},
	valueScrolledContainer: {
		display: 'block',
		width: '100%',
		height: spacing(7),
		padding: spacing(0, 3),
		boxSizing: 'border-box',
		'& .scrollbox .in > div:last-child': {
			height: spacing(7),
			lineHeight: spacing(3),
			margin: 0,
			padding: spacing(1.8444, 0, 2.16666),
		},
	},
	valueContainer: {
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
		minHeight: spacing(7),
		padding: spacing(0, 3, 0, 3),
		'& > div:last-child': {
			width: '100%',
			flexShrink: 1,
			height: spacing(7),
			lineHeight: spacing(3),
			margin: 0,
			padding: spacing(1.8444, 0, 2.1666666),
		},
		'.minimizedView &': {
			minHeight: spacing(5.5),
			'& > div:last-child': {
				height: spacing(5.5),
				padding: spacing(0.8444, 0, 1.1666),
			},
		},
		'& .bold': {
			fontWeight: 'bold',
		},
	},
	chip: {
		height: spacing(5),
		margin: spacing(0.8444, 1, 1, 0),
		borderRadius: spacing(3),
	},
	chipColorIcon: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		marginRight: spacing(1),
	},
	chipFocused: {
		backgroundColor: palette.background.main,
	},
	noOptionsMessage: {
		height: spacing(7),
		lineHeight: spacing(3),
		padding: spacing(2.1666, 3, 1.84444),
		backgroundColor: palette.action.selected,
		boxShadow: `${shadows[3]}!important`,
	},
	option: {
		...typography.mainMenu,
		cursor: 'pointer!important',
		overflow: 'hidden',
		height: spacing(5.5),
		margin: 0,
		padding: spacing(0, 2, 0, 3),
		lineHeight: '1.9rem',
		fontWeight: 400,
		color: palette.text.secondary,
		transition: 'all 0s 0s ease!important',
		borderTop: `1px solid ${darken(palette.background.light, 0.05)}`,
		background: fade(palette.action.hover, 0.5),
		'&:hover, &:active, &:focus': {
			color: palette.text.primary,
			background: `${palette.action.focused}!important`,
		},
		'& .bold': {
			fontWeight: 'bold',
		},
		'&:first-child': {
			borderTopWidth: 0,
		},
	},
	optionValue: {
		display: 'flex',
		alignItems: 'center',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'& .textValue': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
	optionTooltip: {
		zIndex: 9999999999,
	},
	isSelected: {
		background: `${palette.action.selected}!important`,
	},
	singleValue: {
		...typography.select,
		flexShrink: 0,
		height: spacing(7),
		boxSizing: 'border-box',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		lineHeight: spacing(3),
		padding: spacing(2.1666, 0, 1.84444, 0),
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		// width: '100%',
		'& .tooltip': {
			display: 'flex',
			alignItems: 'center',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			'& .tooltipValue': {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},
		},
		'.minimizedView &': {
			height: spacing(5.5),
			padding: spacing(1, 3, 0.8444, 0),
		},
		'& .bold': {
			fontWeight: 'bold',
		},
	},

	placeholder: {
		opacity: 0.7,
		...typography.select,
		lineHeight: spacing(3),
		paddingRight: spacing(3),
	},
	paper: {
		position: 'absolute',
		overflowY: 'auto',
		zIndex: 1,
		marginTop: 0,
		left: 0,
		right: 0,
		backgroundColor: palette.common.white,
		padding: '0px!important',
		boxShadow: `${shadows[3]}!important`,
		borderRadius: `0px 0px 0.7rem 0.7rem`,
		borderTop: `1px solid ${darken(palette.background.light, 0.05)}`,
		'& > div': {
			margin: 0,
			padding: 0,
		},
	},
	divider: {
		height: spacing(2),
	},
	arrow: {
		cursor: 'pointer',
		display: 'block',
		width: 0,
		height: 0,
		marginRight: spacing(2.7),
		marginTop: '0rem',
		borderStyle: 'solid',
		borderWidth: `0.7rem 0.7rem 0px 0.7rem`,
		borderColor: `${palette.text.secondary} transparent transparent`,
		transition: `all 0.1s ${transitions.easing.easeIn}`,
		'&:hover, &.active': {
			borderColor: `${palette.action.active} transparent transparent`,
		},
		'._isSingle &': {
			opacity: 0,
		},
	},
	icon: {
		display: 'flex',
		justifyContent: 'center',
		width: spacing(3),
		margin: spacing(0, 2, 0, 0),
	},
	checked: {
		marginLeft: 'auto',
		'& svg path': {
			fill: palette.action.active3,
		},
	},
	tooltipPlacementBottom: {
		'&:before': {
			left: 'calc(50% - 0.6rem)',
		},
	},
}));

export default useStyles;
