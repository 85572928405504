import React, {Fragment} from 'react';
import {
    Grid,
    AutoSizer,
} from 'react-virtualized';
import {darken} from '@mui/material/styles';
import 'react-virtualized/styles.css';
import {notEmptyArray,} from '@gtb/utils';
import Header from './Header';
import {getMinTemplateWidth} from './utils';
import {spacingToNumber} from '@gtb/utils';
import {GridVirtualizedHeaderCellProps, GridVirtualizedHeaderProps} from '@gtb/interfaces';

const VHeader: React.FC<GridVirtualizedHeaderProps> = (props) => {

    const {
        conf,
        head,
        template,
        scrollLeft,
    } = props;

    const {
        theme,
        className,
        rowHeight,
        minWidth,
        onDND,
    } = conf;

    const minTmpWidth = getMinTemplateWidth(template);
    const h = head && head[0] && head[0]['rowHeight']
            ? head[0]['rowHeight']
            : className.includes('minimizedView') && !className.includes('setupGrid')
                    ? spacingToNumber(theme.spacing(5.5))
                    : spacingToNumber(theme.spacing(7));

    const renderHeaderCell: React.FC<GridVirtualizedHeaderCellProps> = (cellProps) => {
        const {
            // columnIndex, // Horizontal (column) index of cell
            // isScrolling, // The Grid is currently being scrolled
            // isVisible, // This cell is visible within the grid (eg it is not an overscanned cell)
            key, // Unique key within array of cells
            // parent, // Reference to the parent Grid (instance)
            rowIndex, // Vertical (row) index of cell
            style, // Style object to be applied to cell (to position it);
            // This must be passed through to the rendered cell element.
        } = cellProps;
        return (
                <div key={key} style={style}>
                    <Header
                            onDND={onDND}
                            thead={true}
                            template={template}
                            {...head[rowIndex]}
                    />
                </div>
        );
    };

    return notEmptyArray(head) ? (
            <div
                    className={'ReactVirtualized__Header'}
                    style={{
                        position: 'relative',
                        width: '100%',
                        height: h + 1,
                        borderLeftWidth: 0,
                        borderBottomWidth: 1,
                        borderColor: darken(theme.palette.background.light, 0.05),
                    }}
            >
                <AutoSizer>
                    {({height, width}) => {
                        // @ts-ignore
                        return <Grid
                                cellRenderer={renderHeaderCell}
                                width={width}
                                height={h}
                                columnWidth={isNaN(minWidth)
                                        ? width < minTmpWidth
                                                ? minTmpWidth
                                                : width
                                        : minWidth
                                }
                                columnCount={1}
                                rowHeight={h}
                                rowCount={1}
                                overscanColumnCount={0}
                                overscanRowCount={0}
                                scrollLeft={scrollLeft}
                        />
                    }}
                </AutoSizer>
            </div>
    ) : null;
};

export default VHeader;
