import { API_URL } from './config';
import { fetching } from './utils';

export const getSMTP = (onSuccess?: (message: any) => void, onError?: (error: any) => void) => {
	fetching({
		url: API_URL + '/api/settings/smtp',
		options: {
			method: 'GET',
			headers: {
				accept: '*/*',
				'Content-Type': 'application/json',
			},
		},
		onSuccess,
		onError,
	});
};

export const updateSMTP = (data: { [x: string]: any }, onSuccess?: (message: any) => void, onError?: (error: any) => void) => {
	fetching({
		url: API_URL + '/api/settings/smtp',
		options: {
			method: 'PUT',
			headers: {
				accept: '*/*',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		},
		onSuccess,
		onError,
	});
};


export const testSMTP = (data: { [x: string]: any }, onSuccess?: (message: any) => void, onError?: (error: any) => void) => {
	fetching({
		url: API_URL + '/api/settings/smtp/test',
		options: {
			method: 'POST',
			headers: {
				accept: '*/*',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		},
		onSuccess,
		onError,
	});
};
