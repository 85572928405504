import React from 'react';
import {useSelector} from "react-redux";
import {Navigate} from 'react-router-dom';

interface AuthRedirectInterface {
    [x: string]: any
}

export const AuthRedirect: React.FC<AuthRedirectInterface> = (props) => {
    const {children} = props;

    // @ts-ignore
    const {token} = useSelector((state) => state.auth);

    return (
            <>
                {token ? children : <Navigate to="/login"/>}
            </>
    );
};

export default AuthRedirect;
