import { createTheme } from '@mui/material/styles';
import {
	CustomThemeGradients, CustomThemeGradientsOptions,
	CustomThemeOverridesOptions,
	CustomThemePaletteOptions,
	CustomThemeShadows,
	CustomThemeTypographyOptions,
} from '../interfaces';
import ThemePalette, { custom } from './palette';
import ThemeTypography from './typography';
import ThemeGradients, { customGradients } from './gradients';
import ThemeShadows, { customShadows } from './shadows';
import ThemeOverrides from './overrides';

declare module '@mui/material/styles' {
	interface TypeBackground {
		main: string,
		light: string,
		mainModal: string,
	}

	interface Theme {
		custom: {
			palette: CustomThemePaletteOptions,
			gradients: CustomThemeGradients,
			shadows: CustomThemeShadows,
			typo: CustomThemeTypographyOptions,
		},
		gradients: CustomThemeGradientsOptions
		overrides: CustomThemeOverridesOptions
	}

	interface ThemeOptions {
		custom: {
			palette: CustomThemePaletteOptions,
			gradients: CustomThemeGradients,
			shadows: CustomThemeShadows,
			typo: CustomThemeTypographyOptions,
		},
		gradients: CustomThemeGradientsOptions
		overrides: CustomThemeOverridesOptions
	}
}

const LightTheme = createTheme({
	custom: {
		palette: custom,
		gradients: customGradients,
		shadows: customShadows,
		typo: ThemeTypography,
	},
	palette: ThemePalette,
	typography: ThemeTypography,
	gradients: ThemeGradients,
	shadows: ThemeShadows,
	overrides: ThemeOverrides,
	spacing: 6,
});

export default LightTheme;
