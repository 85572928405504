import React, {
    useEffect,
    useState,
    useRef,
} from 'react';
import {CN} from '@gtb/utils';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "../Theme/interfaces";
import {useClickPreventionOnDoubleClick} from '@gtb/hooks';
import {chooseFieldRender} from '../Setups/Field';
import {TableStyles} from '@styles/Table.styles';
import {
    Memo,
    notEmptyArray,
} from '@gtb/utils';
import {GridRowCellProps, GridRowMouseEventTarget, GridRowMouseEvent} from '@gtb/interfaces';

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        width: '100%',
        minHeight: theme.spacing(7),
        '.minimizedView &': {
            minHeight: theme.spacing(5.5),
            boxSizing: 'border-box',
        },
        '.minimizedViewFixedHeight &': {
            height: theme.spacing(5.3),
        },
    },
    th: {},
    td: {},
    row: {},
    ...TableStyles(theme),
    disabled: {
        // pointerEvents: 'none',
        '& > div': {
            // opacity: 0.3,
        },
    },
    relative: {
        position: 'relative',
    },
}));


interface GridRowProps {
    classes?: any,
    className?: any,
    fields?: any,
    i?: number,
    id?: string | number,
    isBolded?: boolean,
    template?: any,
    styles?: object,
    focused?: boolean,
    changed?: boolean,
    disabled?: boolean,
    dragging?: boolean,
    thead?: any,
    onFocus?: (a: GridRowMouseEventTarget) => any,
    onClick?: (a: GridRowMouseEvent) => any,
    onDoubleClick?: (a: GridRowMouseEvent, index?: number) => any,
    isVisible?: boolean,
    onDND?: boolean,
}

const Row: React.FC<GridRowProps> = (props) => {
    const stl = useStyles(props);
    const {
        classes,
        className,
        fields = [],
        id = new Date().getTime(),
        isBolded = false,
        template,
        styles = {},
        focused,
        changed,
        disabled = false,
        dragging,
        thead = false,
        onFocus = () => false,
        onClick = () => false,
        onDoubleClick = () => false,
        isVisible = true,
        onDND = null,
        ...rest
    } = props; // TODO Need refactor this component

    const onClickHandler = (event: GridRowMouseEvent) => {
        const trg = event.target;
        if (!focused) {
            onFocus(trg);
        }
        onClick(event);
        if (trg && typeof trg.scrollIntoViewIfNeeded === 'function') {
            trg.scrollIntoViewIfNeeded();
        }
    };

    const onDoubleClickHandler = (event: GridRowMouseEvent) => {
        const trg = event.target;
        if (!focused) {
            onFocus(trg);
        }
        onDoubleClick(event, props.i);
        if (trg && typeof trg.scrollIntoViewIfNeeded === 'function') {
            trg.scrollIntoViewIfNeeded();
        }
    };

    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onClickHandler, onDoubleClickHandler);

    const wrp: React.Ref<any> = useRef(null);
    useEffect(() => {
        if (focused) {
            setTimeout(() => {
                if (wrp.current && typeof wrp.current.scrollIntoViewIfNeeded === 'function') {
                    wrp.current.scrollIntoViewIfNeeded();
                }
            }, 500);
        }
    }, [JSON.stringify(focused)]);

    const [hovered] = useState(false);

    const templateClassName = notEmptyArray(template)
            ? ''
            : template;
    const templateStyles = notEmptyArray(template)
            ? {gridTemplateColumns: template.join(' ')}
            : {};

    // DebuggerAnchor('setup Row');
    return (

            <div
                    ref={wrp}
                    className={CN(['tr', className, stl.root, stl.row, templateClassName], {
                        [stl.disabled]: disabled,
                        bold: isBolded,
                        hov: hovered,
                        changed: changed
                    })}
                    onClick={handleClick}
                    onDoubleClick={handleDoubleClick}
                    // onMouseMove={() => {setHovered(true)}}
                    // onMouseOut={() => {setHovered(false)}}
                    style={{...styles, ...templateStyles}}
                    {...rest}
            >
                {fields.map((i: GridRowCellProps, count: number) => {
                    const {
                        type,
                        enabled,
                        memo,
                        memoSettings,
                        actions,
                        ...restCellProps
                    } = i;

                    const Field = notEmptyArray(memo)
                            ? Memo(chooseFieldRender(type), memo, memoSettings)
                            : chooseFieldRender(type);

                    const Cell = (
                            <div
                                    key={i.id || id + '_' + count}
                                    id={i.id || id + '_' + count}
                                    className={CN(i.className, {
                                        th: thead,
                                        [stl.th]: thead,
                                        td: !thead,
                                        [stl.td]: !thead,
                                        [stl.disabled]: i.disabled,
                                        [stl.relative]: actions,
                                    })}
                            >
                                {isVisible
                                        ? <Field isBolded={isBolded} {...restCellProps} />
                                        : null}
                                {actions}
                            </div>
                    );
                    return i.hasOwnProperty('enabled')
                            ? i.enabled ? Cell : null
                            : Cell;
                })}
            </div>
    );
}

// export default Memo(Row, [
//             'classes',
//             'className',
//             'fields',
//             'id',
//             'isBolded',
//             'template',
//             'styles',
//             'focused',
//             'changed',ç
//             'disabled',
//             'dragging',
//             'thead',
//             'isVisible',
//         ], {
//             debug: false,
//             color: 'pink',
//         });

export default Row;
