//Enforcement Actions
export const ICON_LOG_EA = [
    {path: "M16.1,11.1h-8c-0.6,0-1-0.4-1-1s0.4-1,1-1h8c0.6,0,1,0.4,1,1S16.7,11.1,16.1,11.1z M17.1,13.1c0-0.6-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h8C16.7,14.1,17.1,13.7,17.1,13.1z M14.1,16.1c0-0.6-0.4-1-1-1h-5c-0.6,0-1,0.4-1,1s0.4,1,1,1h5C13.7,17.1,14.1,16.7,14.1,16.1z M21.1,6.1v4c0,0.6-0.4,1-1,1s-1-0.4-1-1v-4c0-0.6-0.4-1-1-1h-1c0,1.2-0.8,2-2,2h-6c-1.2,0-2-0.8-2-2h-1c-0.6,0-1,0.4-1,1v14c0,0.6,0.4,1,1,1h4c0.6,0,1,0.4,1,1s-0.4,1-1,1h-4c-1.7,0-3-1.3-3-3v-14c0-1.7,1.3-3,3-3h1c0-1.2,0.8-2,2-2h6c1.2,0,2,0.8,2,2h1C19.8,3.1,21.1,4.4,21.1,6.1z M15.1,3.1C15.1,3.1,15.1,3,15.1,3.1h-6v2h6V3.1z"},
	{primary: true, path: "M16,24h-2c-0.6,0-1-0.4-1-1v-2c0-0.3,0.1-0.5,0.3-0.7l7-7c0.4-0.4,1-0.4,1.4,0l2,2c0.4,0.4,0.4,1,0,1.4l-7,7C16.5,23.9,16.3,24,16,24z M15,22h0.6l6-6L21,15.4l-6,6V22z"},
];
export const ICON_BLOCKED_EA = [
	{path: "M12,23c-0.1,0-0.2,0-0.4-0.1C11.3,22.8,3,19.6,3,12V5c0-0.4,0.3-0.8,0.6-0.9l8-3c0.2-0.1,0.5-0.1,0.7,0l8,3C20.7,4.2,21,4.6,21,5v7c0,7.6-8.3,10.8-8.6,10.9C12.2,23,12.1,23,12,23z M5,5.7V12c0,5.4,5.5,8.3,7,8.9c1.5-0.7,7-3.5,7-8.9V5.7l-7-2.6L5,5.7z"},
	{color: '#FF0000', path: "M13.4,11l2.3-2.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L12,9.6L9.7,7.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l2.3,2.3l-2.3,2.3c-0.4,0.4-0.4,1,0,1.4C8.5,14.9,8.7,15,9,15s0.5-0.1,0.7-0.3l2.3-2.3l2.3,2.3c0.2,0.2,0.5,0.3,0.7,0.1s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L13.4,11z"},
];
export const ICON_S_BLOCKED_EA = [
	{path: "M7.8,20.4c-0.2,0-0.4-0.1-0.6-0.2c-2.6-2-4.1-5-4.2-8.2V5c0-0.4,0.3-0.8,0.6-0.9l8-3c0.2-0.1,0.5-0.1,0.7,0l8,3C20.7,4.2,21,4.6,21,5v5c0,0.6-0.4,1-1,1s-1-0.4-1-1V5.7l-7-2.6L5,5.7V12c0.1,2.6,1.3,5,3.4,6.6c0.4,0.3,0.5,1,0.2,1.4C8.4,20.3,8.1,20.4,7.8,20.4z"},
	{color: '#FF0000', path: "M15.7,7.3c-0.4-0.4-1-0.4-1.4,0L12,9.6L9.7,7.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l2.3,2.3l-2.3,2.3c-0.4,0.4-0.4,1,0,1.4C8.5,14.9,8.7,15,9,15s0.5-0.1,0.7-0.3l2.3-2.3l0.3,0.3c0.2,0.2,0.5,0.3,0.7,0.1s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L13.4,11l2.3-2.3C16.1,8.3,16.1,7.7,15.7,7.3z"},
    {color: '#F2CC0C', path: "M23.7 20.9l-5-8c-0.4-0.6-1-0.9-1.7-0.9 -0.7 0-1.3 0.4-1.7 0.9l-5 8c-0.4 0.6-0.4 1.4 0 2 0.4 0.6 1 1 1.8 1h10c0.7 0 1.4-0.4 1.7-1C24.1 22.3 24.1 21.6 23.7 20.9zM17.1 22c-0.6 0-1-0.4-1-1s0.4-1 1-1 1 0.4 1 1S17.6 22 17.1 22zM18.1 18c0 0.6-0.4 1-1 1s-1-0.4-1-1v-3c0-0.6 0.4-1 1-1s1 0.4 1 1V18z"},
];
export const ICON_USER_JUSTIFICATION = [
	{path: "M9,8C6.8,8,5,6.2,5,4s1.8-4,4-4s4,1.8,4,4S11.2,8,9,8z M9,2C7.9,2,7,2.9,7,4s0.9,2,2,2s2-0.9,2-2S10.1,2,9,2z M10,21c0-0.6-0.4-1-1-1c-3.8,0-7-2.3-7-5s3.2-5,7-5c2,0,3.9,0.6,5.4,1.8c0.4,0.3,1.1,0.3,1.4-0.2c0.3-0.4,0.3-1.1-0.2-1.4C13.7,8.8,11.4,8,9,8c-5,0-9,3.1-9,7s4,7,9,7C9.6,22,10,21.6,10,21z"},
	{primary: true, path: "M18.2,16c0.4,1.2,1.5,2,2.8,2c1.7,0,3-1.3,3-3s-1.3-3-3-3c-1.4,0-2.4,0.8-2.8,2 M21,16c-0.6,0-1-0.4-1-1c0-0.5,0.3-1,1-1c0.6,0,1,0.4,1,1S21.6,16,21,16z M18.2,16H13c-0.6,0-1-0.4-1-1s0.4-1,1-1h5.2 M17.8,20c-0.4-1.2-1.5-2-2.8-2c-1.7,0-3,1.3-3,3c0,1.7,1.3,3,3,3c1.3,0,2.4-0.8,2.8-2 M15,22c-0.6,0-1-0.4-1-1c0-0.5,0.3-1,1-1c0.6,0,1,0.4,1,1S15.6,22,15,22z M17.8,20H23c0.6,0,1,0.4,1,1s-0.4,1-1,1h-5.2"},
];
export const ICON_PASS = [
	{color: '#7ACC29', path: "M12.1,23.1c-0.1,0-0.2,0-0.4-0.1c-0.4-0.1-8.6-3.3-8.6-10.9v-7c0-0.4,0.3-0.8,0.6-0.9l8-3c0.2-0.1,0.5-0.1,0.7,0l8,3c0.4,0.1,0.6,0.5,0.6,0.9v7c0,7.6-8.3,10.8-8.6,10.9C12.3,23.1,12.2,23.1,12.1,23.1z M5.1,5.8v6.3c0,5.4,5.5,8.3,7,8.9c1.5-0.7,7-3.5,7-8.9V5.8l-7-2.6L5.1,5.8z M11.1,12.7l-2.3-2.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l3,3c0.2,0.2,0.5,0.3,0.7,0.3 M11.1,15.1c0.3,0,0.5-0.1,0.7-0.3l5-5c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-4.3,4.3"},
];