import React from "react";
import {useLocation} from "react-router-dom";

const NoMatch: React.FC = () => {
    let location = useLocation();
    return (
            <div>
                <h3>
                    404 page <code>{location.pathname}</code> not found
                </h3>
            </div>
    );
}

export default NoMatch;
