import React from 'react';
import {default as Popup} from '@gtb/components/Modals/Modal';
import {useDispatch, useSelector} from 'react-redux';
import {hideModal} from "store/actions";


const Modal: React.FC<{ [x: string]: any }> = (props) => {

    // @ts-ignore
    const {modal} = useSelector((state) => state.layout);
    const dispatch = useDispatch();

    return <>
        {Object.keys(Array.apply(0, Array(modal.maxIndex)))
                .map(index => (
                        <Popup
                                index={index}
                                key={index}
                                styles={{
                                    // filter: loading.open
                                    //         ? 'blur(10px) opacity(0.5)'
                                    //         : 'blur(0px) opacity(1)',
                                    // transition: 'all 0.5s ease',
                                    // pointerEvents: loading.open && 'none',
                                }}
                                open={modal.open}
                                options={modal.options}
                                hideModal={() => dispatch(hideModal())}
                        />
                ))}
    </>
};

export default Modal;
