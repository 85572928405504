import * as React from 'react';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import Theme from './light/LightTheme';

const CustomStyles: React.FC<React.PropsWithChildren<any>> = (props) => {
    return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={Theme}>
                    {props.children}
                </ThemeProvider>
            </StyledEngineProvider>
    );
}

export default CustomStyles;
