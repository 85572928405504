import React from 'react';
import {makeStyles, useTheme} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {CircularProgress} from '@mui/material';

const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    modalWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalMessage: {
        padding: spacing(0, 3),
        display: 'flex',
    },
    statistics: {
        paddingLeft: spacing(2),
    },
    counterSpan: {
        padding: spacing(0, 1)
    }
}));

export const Processing: React.FC<{ [x: string]: any }> = (props) => {
    const stl = useStyles(props);
    const theme: CustomTheme = useTheme();
    const {
        classes,
        stats = 0,
    } = props;

    const progressStyle = {color: theme.palette.action.active};

    return <div className={stl.modalWrapper}>
        <CircularProgress style={progressStyle}/>
        <div className={stl.modalMessage}>
            <div>
                {
                    'Processing'
                }
            </div>
            <div className={stl.statistics}>
                {
                    stats && (<div><span className={stl.counterSpan}>{stats}</span>
                                {'of'}
                                <span className={stl.counterSpan}>100</span>
                            </div>
                    )
                }
            </div>
        </div>
    </div>
};


export default Processing;
