import { SMTP_ERROR_GET_DATA, SMTP_SUCCESS_GET_DATA } from 'store/actions/settings';

const initState = {
	'address': '',
	'port': 465,
	'username': '',
	'password': '',
	'originator': '',
	'encrypted': 1,
};

const data = function (state = initState, action: { [x: string]: any } = {}) {
	switch (action.type) {
		case SMTP_SUCCESS_GET_DATA: {
			return { ...state, ...action.data };
		}
		case SMTP_ERROR_GET_DATA: {
			return { ...initState };
		}
		default: {
			return state;
		}
	}
};

export default data;
