import React, {Fragment} from 'react';
import {Preloader} from '@gtb'; // TODO: Soft preloader
import {
    Grid,
    AutoSizer,
    ScrollSync,
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import Header from './VHeader';
import {getMinTemplateWidth} from './utils';
import {GridVirtualizedBodyProps} from "./interfaces";
import {spacingToNumber} from "../Theme/utils";

const VBody: React.FC<GridVirtualizedBodyProps> = (props) => {

    const {
        conf,
        head,
        body,
        cellRenderer,
        template,
        getFocusedIndex,
    } = props;

    const {
        classes,
        theme,
        className,
        minWidth,
        maxHeight,
        overscanRowCount = 10,
        overscanColumnCount = 10,
        useDynamicRowHeight,
        isLoading,
    } = conf;


    const minTmpWidth = getMinTemplateWidth(template);
    const rowHeight = conf.rowHeight
            ? conf.rowHeight
            : className.includes('minimizedView')
                    ? spacingToNumber(theme.spacing(5.5))
                    : spacingToNumber(theme.spacing(7));


    // const getColumnWidth = (width) => ({ index }) => {
    // 	return width; // TODO: add proper props
    // };

    const getRowHeight = () => {
        return rowHeight; // TODO: add proper props
    };

    // @ts-ignore
    return <ScrollSync>
        {({
              // clientHeight,
              // clientWidth,
              onScroll,
              // scrollHeight,
              scrollLeft,
              // scrollTop,
              // scrollWidth,
          }) => {
            return (
                    <Fragment>
                        <Header
                                conf={conf}
                                head={head}
                                template={template}
                                scrollLeft={scrollLeft}
                        />
                        <Preloader
                                isLoading={isLoading}
                                classes={{root: classes.preloader, content: classes.preloaderContent}}
                                style={{width: '100%'}}
                        >
                            <div style={{
                                maxHeight: maxHeight,
                                height: body.length * rowHeight < maxHeight
                                        ? body.length * rowHeight
                                        : maxHeight,
                                width: '100%',
                            }}
                            >
                                <AutoSizer>
                                    {({height, width}) => {
                                        // @ts-ignore
                                        return <Grid
                                                cellRenderer={cellRenderer}

                                                height={height}
                                                width={width}
                                                className={classes.scroll}

                                                columnCount={1}
                                                columnWidth={isNaN(minWidth)
                                                        ? width < minTmpWidth
                                                                ? minTmpWidth
                                                                : width
                                                        : minWidth
                                                }

                                                rowCount={body.length}
                                                rowHeight={useDynamicRowHeight ? getRowHeight : rowHeight}

                                                overscanRowCount={overscanRowCount}
                                                overscanColumnCount={overscanColumnCount}

                                                scrollToIndex={getFocusedIndex()}
                                                scrollToRow={getFocusedIndex()}

                                                onScroll={onScroll}
                                        />
                                    }}
                                </AutoSizer>
                            </div>
                        </Preloader>
                    </Fragment>
            );
        }}
    </ScrollSync>
};

export default VBody;
