import React, {useState} from 'react';
import {Button, SetupItem} from '@gtb';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import {hideModal,} from 'store/actions';

const useStyles = makeStyles(({typography, spacing}: CustomTheme) => ({
    root: {},
    box: {
        width: '100%',
        overflow: 'hidden',
        borderRadius: '0.7rem',
    },
    row: {
        paddingTop: 22,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& button': {
            marginLeft: spacing(3)
        }
    },
}));

const template = {
    root: {
        width: '100%',
    },
    label: {
        width: 200,
    },
}


interface CustomerDataProps {
    companyName: string,
    fqdn: string,
    users?: number,
    licenseExpirationDate?: string,
    companyUrl?: string,
    description?: string,
    admin?: {
        companyUrl?: string,
        name?: string,
        email?: string,
        phone?: string
    },

    [x: string]: any
}

const DEFAULT_DATA = {
    "companyName": "",
    "fqdn": "",
    "users": 0,
    "licenseExpirationDate": "",
    "companyUrl": "",
    "description": "",
    "admin": {
        "companyUrl": "",
        "name": "",
        "email": "",
        "phone": ""
    }
}


const EditCustomer: React.FC<{ [x: string]: any }> = (props) => {

    const stl = useStyles(props);
    const {fields = {}} = props;

    const dispatch = useDispatch();

    const [data] = useState(fields && typeof fields == 'object' ? {...DEFAULT_DATA, ...fields} : DEFAULT_DATA);

    return (
            <div className={stl.root}>
                <div className={stl.box}>
                    <SetupItem
                            label={'Name'}
                            input={[{
                                type: 'simple-text',
                                value: data.admin.name,
                            }]}
                            template={template}
                    />
                    <SetupItem
                            label={'Email'}
                            input={[{
                                type: 'simple-text',
                                value: data.admin.email,
                            }]}
                            template={template}
                    />
                    <SetupItem
                            label={'Phone Number'}
                            input={[{
                                type: 'simple-text',
                                value: data.admin.phone,
                            }]}
                            template={template}
                    />
                </div>
                <div className={stl.row}>
                    <Button onClick={() => dispatch(hideModal())}>Cancel</Button>
                </div>
            </div>
    );
};

export default EditCustomer;
