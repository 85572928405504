import React from 'react';
import {makeStyles, useTheme} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {alpha as fade} from '@mui/material/styles';
import {CircularProgress} from '@mui/material';
import {CN} from '@gtb/utils';

const useStyles = makeStyles(({palette, typography, spacing}: CustomTheme) => ({
    root: {
        padding: '0px',
    },
    preloader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    message: {
        width: '100%',
        padding: spacing(3),
        margin: spacing(6, 0, 3),
        textAlign: 'center',
        background: fade(palette.common.white, 0.5),
        borderRadius: '0.7rem',
        '& p': {
            ...typography.p,
            '& bold, & strong': {
                fontWeight: 600,
            }
        }
    },
    progress: {},
    progressBox: {
        width: '100%',
        minHeight: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loader: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

interface LoadingModalProps extends React.PropsWithChildren<any> {
    message?: string,
}


const Preloader: React.FC<LoadingModalProps> = (props) => {
    const stl = useStyles();
    const theme: CustomTheme = useTheme();
    const {
        classes,
        message = 'Please wait awhile',
        withPreloader = true,
    } = props;
    const progressStyle = {color: theme.palette.action.active};


    return (
            <div className={stl.root}>
                {message &&
                <div className={stl.message}>
                    {message}
                </div>
                }
                {withPreloader ? (
                        <div className={stl.preloader}>
                            <div className={stl.progressBox}>
                                <CircularProgress className={stl.progress} style={progressStyle} disableShrink/>
                            </div>
                        </div>
                ) : null}
            </div>
    );
};

export default Preloader;
