import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import {CN} from '../../utils';
import {bool, number, string} from 'prop-types';

const styles = ({spacing}) => ({
    root: {
        cursor: 'move!important',
        zIndex: -1,
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        // backgroundColor: '#faf'
    }
});

class ModalMove extends Component {
    constructor(props) {
        super(props);

        this.dragging = false;
        this.mouseX = 0;
        this.mouseY = 0;
        this.startPosX = 0;
        this.startPosY = 0;
        this.moveDiffX = 0;
        this.moveDiffY = 0;

        this.state = {};
    }

    startDrag = (e) => {
        e.stopPropagation();
        if (this.props.disabled) {
            return;
        }

        this.dragging = true;
        this.startPosX = this.mouseX;
        this.startPosY = this.mouseY;

        this.props.onStartDrag([this.startPosX, this.startPosY]);
    };

    endDrag = (e) => {
        e.stopPropagation();
        // e.preventDefault();
        if (this.props.disabled) {
            return;
        }

        // this.props.onEndDrag(this.moveDiff);
        this.dragging = false;
    };

    onMouseMove = e => {
        if (this.props.disabled) {
            return;
        }

        this.mouseX = e.touches ? e.touches[0].screenX : e.screenX;
        this.mouseY = e.touches ? e.touches[0].screenY : e.screenY;

        if (!this.dragging) {
            return;
        }

        this.moveDiffX = this.startPosX - this.mouseX;
        this.moveDiffY = this.startPosY - this.mouseY;

        this.props.onMove(
                [this.moveDiffX, this.moveDiffY],
                [this.mouseX, this.mouseY]
        );
    };

    componentDidMount() {
        if (this.props.disabled) {
            return;
        }

        this.addEventListenersToDocument();
    }

    componentWillUnmount() {
        if (this.props.disabled) {
            return;
        }

        this.removeEventListenersFromDocument();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.disabled && !this.props.disabled) {
            this.addEventListenersToDocument();
        }

        if (!prevProps.disabled && this.props.disabled) {
            this.removeEventListenersFromDocument();
        }
    }

    addEventListenersToDocument() {
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.endDrag);

        document.addEventListener('touchmove', this.onMouseMove);
        document.addEventListener('touchend', this.endDrag);
    }

    removeEventListenersFromDocument() {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.endDrag);

        document.removeEventListener('touchmove', this.onMouseMove);
        document.removeEventListener('touchend', this.endDrag);
    }

    render() {
        const {classes, disabled, children, className} = this.props;
        var style = {
            userSelect: 'none'
        };

        if (!disabled) {
            style.cursor = 'ew-resize';
        }

        return (
                <div
                        // ref="ele"
                        style={style}
                        className={CN([className, classes.root, 'ModalMove'])}
                        onMouseDown={!disabled && this.startDrag}
                        onTouchStart={!disabled && this.startDrag}
                        onClick={e => {
                            e.stopPropagation();
                        }}
                >
                    {children}
                </div>
        );
    }
}

ModalMove.defaultProps = {
    onMove: () => {
    },
    onEndDrag: () => {
    },
    onStartDrag: () => {
    },
    disabled: false,
    minWidth: 50,
    className: ''
};

ModalMove.propTypes = {
    disabled: bool,
    minWidth: number,
    className: string
};

export default withStyles(styles, {withTheme: true})(ModalMove);
