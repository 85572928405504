import { CUSTOMERS_FOCUSED } from 'store/actions';

const focused = function (state = [], action: any = {}) {
	switch (action.type) {
		case CUSTOMERS_FOCUSED: {
			return [...action.focused];
		}
		default: {
			return state;
		}
	}
};

export default focused;
