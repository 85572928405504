import palette from './palette';
import { alpha as fade } from '@mui/material/styles';
import { CustomThemeTypographyOptions } from '../interfaces';

const typography: CustomThemeTypographyOptions = {
	mainMenu: {
		fontSize: '1.6rem',
		fontWeight: '500',
		color: palette.text!.secondary,
		'&:hover, &:focus': {
			color: palette.text!.primary,
		},
		'&.active': {
			color: palette.text!.active,
		},
	},
	mainTabsMenu: {
		fontSize: '1.8rem',
		fontWeight: '500',
		color: palette.text!.secondary,
		'&:hover, &:focus': {
			color: palette.text!.primary,
		},
		'&.active': {
			color: palette.text!.active,
		},
	},
	secondaryTabMenu: {
		fontSize: '1.6rem',
		fontWeight: '500',
		color: palette.text!.secondary,
		'&:hover, &:focus': {
			color: palette.text!.primary,
		},
		'&.active': {
			color: palette.text!.active,
		},
	},
	sidebarMenu: {
		fontSize: '1.8rem',
		fontWeight: '500',
		color: palette.text!.secondary,
		'&:hover, &:focus': {
			color: palette.text!.primary,
		},
		'&.active': {
			color: palette.common!.white,
		},
	},
	button: {
		fontSize: '1.6rem',
		fontWeight: '400',
		color: palette.text!.secondary,
		'&:hover, &:focus': {
			color: palette.text!.primary,
		},
	},
	select: {
		fontSize: '1.6rem',
		fontWeight: '400',
		color: palette.text!.secondary,
		'&:hover, &:focus': {
			color: palette.text!.primary,
		},
		'&.active': {
			color: palette.text!.active,
		},
	},
	inputField: {
		fontSize: '1.6rem',
		fontWeight: '400',
		color: palette.text!.secondary,
		'&:hover, &:focus': {
			color: palette.text!.secondary,
		},
		'&.active': {
			color: palette.text!.primary,
		},
	},
	tableTitle: {
		fontSize: '1.4rem',
		fontWeight: '500',
		textTransform: 'uppercase',
		color: palette.text!.secondary,
		'&:hover, &:focus': {
			color: palette.text!.primary,
		},
		'&.active': {
			color: palette.text!.active,
		},
	},
	tableGrid: {
		fontSize: '1.6rem',
		fontWeight: '400',
		alignItems: 'center',
		color: palette.text!.secondary,
		'&:hover, &:focus': {
			color: palette.text!.secondary,
		},
		'&.active': {
			color: palette.text!.tip,
		},
	},
	h1: {
		fontSize: '3.2rem',
		fontWeight: '300',
		color: palette.text!.secondary,
	},
	h2: {
		fontSize: '2rem',
		fontWeight: '500',
		color: palette.text!.primary,
	},
	h3: {
		fontSize: '2rem',
		fontWeight: '500',
		color: palette.text!.secondary,
	},
	p: {
		fontSize: '1.6rem',
		fontWeight: '400',
		lineHeight: '1.5em',
		color: palette.text!.secondary,
	},
	strong: {
		fontSize: '1.6rem',
		fontWeight: '500',
		lineHeight: '1.5em',
		color: palette.text!.primary,
	},
	link: {
		fontSize: '1.8rem',
		fontWeight: '400',
		lineHeight: '1.5em',
		color: palette.text!.active,
		'&:hover': {
			color: fade(palette.text!.active!, 0.8),
		},
	},
	info: {
		fontSize: '1.6rem',
		fontWeight: '400',
		lineHeight: '1.5em',
		color: fade(palette.text!.secondary!, 0.5),
	},
	list: {
		fontSize: '1.6rem',
		fontWeight: '400',
		lineHeight: '2em',
		color: palette.text!.secondary,
	},
	tip: {
		fontSize: '1.6rem',
		fontWeight: '400',
		lineHeight: '1.5em',
		color: palette.text!.tip,
	},
	selectMenuMainElement: {
		fontSize: '1.6rem',
		fontWeight: '500',
		color: palette.text!.secondary,
		'&:hover, &:focus': {
			color: palette.text!.primary,
		},
		'&.active': {
			color: palette.text!.active,
		},
	},
	selectMenuFirstLevelItem: {
		fontSize: '1.6rem',
		fontWeight: '500',
		color: palette.text!.secondary,
		'&:hover, &:focus': {
			color: palette.text!.primary,
		},
		'&.active': {
			color: palette.common!.white,
		},
	},
	selectMenuSecondaryLevelItem: {
		fontSize: '1.6rem',
		fontWeight: '500',
		color: palette.text!.secondary,
		'&:hover, &:focus': {
			color: palette.text!.primary,
		},
		'&.active': {
			color: palette.text!.active,
		},
	},
	selectMenuThirdLevelItem: {
		fontSize: '1.6rem',
		fontWeight: '400',
		color: palette.text!.secondary,
		'&:hover, &:focus': {
			color: palette.text!.primary,
		},
	},
	keywordItemSaved: {
		fontSize: '1.6rem',
		fontWeight: '400',
		color: palette.text!.secondary,
		'&:hover, &:focus': {
			color: palette.text!.primary,
		},
	},
	keywordItemNotSaved: {
		fontSize: '1.6rem',
		fontWeight: '400',
		color: fade(palette.text!.secondary!, 0.5),
		'&:hover, &:focus': {
			color: palette.text!.primary,
		},
	},
};

export default typography;
