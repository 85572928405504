import React from 'react';
import Icon, * as Icons from '@gtb/components/Icons';
import {makeStyles, useTheme} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import {CountdownCircleTimer} from 'react-countdown-circle-timer';
import {spacingToNumber} from "../Theme/utils";
import {
    MESSAGE_ERROR,
    MESSAGE_INFO,
    MESSAGE_SUCCESS,
    MESSAGE_TIMER,
} from 'store/actions';

export const COMMAND_TERMINATE_TIME = 90000;


const useStyles = makeStyles(({palette, spacing}: CustomTheme) => ({
    wrp: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    icon: {
        flexShrink: 0,
        width: spacing(6),
        height: spacing(6),
        marginRight: spacing(3),
        '& svg': {
            width: spacing(6),
            height: spacing(6),
        },
    },
    text: {
        width: '100%',
        flexShrink: 1,
        wordBreak: 'break-word',
    },
    timer: {
        height: spacing(6),
        marginRight: spacing(3),
    },
    timerText: {
        color: palette.action.active3 + '!important',
        fontSize: '2.2rem',
    },
}));

const MessageWRP: React.FC<{ [x: string]: any }> = (props) => {

    const stl = useStyles(props);
    const theme: CustomTheme = useTheme();
    const {
        html,
        classes,
        type,
        message,
        autoHideDuration,
    } = props;
    const renderTime = (value: any) => {
        return <div className={stl.timerText}>{value}</div>;
    };
    const DIcon: React.FC<{ [x: string]: any }> = ({type}) => {
        switch (type) {
            case MESSAGE_SUCCESS:
                return <Icon view={Icons.ICON_COMPLEATED}
                             fill={[theme.palette.action.active3, theme.palette.action.active3]}/>;
            case MESSAGE_INFO:
                return <Icon view={Icons.ICON_INFORMATION}
                             fill={[theme.palette.text.active, theme.palette.text.active]}/>;
            case MESSAGE_ERROR:
                return <Icon view={Icons.ICON_INFORMATION}
                             fill={[theme.palette.error.main, theme.palette.error.main]}/>;
            case MESSAGE_TIMER:
                return <CountdownCircleTimer
                        renderTime={renderTime}
                        size={spacingToNumber(theme.spacing(6))}
                        strokeWidth={3.5}
                        isPlaying={true}
                        durationSeconds={autoHideDuration ? autoHideDuration / 1000 : COMMAND_TERMINATE_TIME / 1000}
                        //@ts-ignore
                        colors={[theme.palette.action.active3HEX]}
                />;
            default:
                return <Icon view={Icons.ICON_INFORMATION}
                             fill={[theme.palette.text.secondary, theme.palette.text.secondary]}/>;
        }
    };

    const Message = html ? (
            <div
                    data-testid={'MessagePopup'}
                    className={stl.wrp}>
                <div className={stl.icon}>
                    <DIcon type={type}/>
                </div>
                <div
                        data-testid={'MessagePopupText'}
                        className={stl.text} dangerouslySetInnerHTML={{__html: message}}
                />
            </div>
    ) : <div className={stl.wrp}>
        <div className={type !== 'timer' ? stl.icon : stl.timer}>
            <DIcon type={type}/>
        </div>
        <div className={stl.text}>
            {message}
        </div>
    </div>;

    return Message;
}

export default MessageWRP;
