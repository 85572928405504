import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AUTH_SET_TOKEN, AUTH_SET_LOGIN_INFO, SET_TIMEZONE } from 'store/actions';
import { TIME_ZONE } from '@gtb/utils';

const initialState = {
	token: '',
	expirationInterval: 172800000,
	lastUpdated: new Date().getTime(),
	timezone: TIME_ZONE,
};

const settingsConfig = {
	key: 'mt_management_auth_data',
	storage,
};

const auth = function (state = initialState, action: { [x: string]: any } = {}) {
	switch (action.type) {
		case AUTH_SET_TOKEN: {
			return { ...state, token: action.token, lastUpdated: new Date().getTime() };
		}
		case SET_TIMEZONE: {
			return { ...state, timezone: action.timezone };
		}

		case AUTH_SET_LOGIN_INFO: {
			return { ...state, ...action.options };
		}
		default: {
			return state;
		}
	}
};

export default persistReducer(settingsConfig, auth);
