import _ from 'lodash';

export const spacingToNumber = (value: string) => Number(value.slice(0, -2));

export const linearGradient = (colors: string[], angle: string): string => {
	return 'linear-gradient(' + angle + colors.join(', ') + ')';
};

export const hex2RGB = (str: string): number[] => {
	const [, short, long] = String(str).match(/^#?(?:([\da-f]{3})[\da-f]?|([\da-f]{6})(?:[\da-f]{2})?)$/i) || [];

	if (long) {
		const value = Number.parseInt(long, 16);
		return [value >> 16, value >> 8 & 0xFF, value & 0xFF];
	} else if (short) {
		return Array.from(short, s => Number.parseInt(s, 16)).map(n => (n << 4) | n);
	} else {
		return [];
	}
};


export const CN = (str: undefined | string | string[], options?: { [key: string]: boolean | number | string | undefined }): string => {
	let additional: string[] = [];
	if (typeof options === 'object') {
		additional = Object.keys(options).filter(key => options[key]);
	}

	if (typeof str === 'string') {
		return additional.length > 0 ? [str, ...additional].join(' ') : str;
	} else if (_.isArray(str)) {
		return !_.isEmpty(str) ? [...str, ...additional].join(' ') : str.join(' ');
	} else {
		return additional.length > 0 ? additional.join(' ') : '';
	}
};
