import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";

export const useStyles = makeStyles(({spacing}: CustomTheme) => ({
    root: {
        display: 'block',
        width: 'auto',
        lineHeight: spacing(3),
        padding: spacing(2.16666, 0, 1.8444444, 3),
        '.noPadding &, .noPadding.td &': {
            padding: spacing(1.844444, 0),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '.smallPadding &, .smallPadding.td &': {
            padding: spacing(2.16666, 1, 1.8444444),
        },
        '.td &': {
            padding: spacing(2.16666, 3, 1.8444444),
        },
        '.td.centered &': {
            textAlign: 'center',
        },
        '.minimizedView &': {
            // display: 'flex',
            alignItems: 'center',
            minHeight: spacing(5),
            padding: spacing(2.16666, 0, 1.8444444, 3),
        },
        '.minimizedView .noPadding &': {
            padding: spacing(1.16666, 0, 0.8444444),
        },
        '.minimizedView .smallPadding &': {
            padding: spacing(1.16666, 0, 0.8444444),
        },
        '.minimizedView .td &': {
            padding: spacing(1.16666, 3, 0.8444444),
        },
        '.minimizedView .td.noPadding &': {
            padding: spacing(1.16666, 0, 0.8444444),
        },
        '.minimizedView .td.smallPadding &': {
            padding: spacing(1.16666, 1, 1),
        },
        '.minimizedView .td.mediumPadding &': {
            padding: spacing(1.16666, 2, 0.8444444),
        },
        '&.inOneLine': {
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'pre',
            textOverflow: 'ellipsis',
        },
        '.bold &': {
            fontWeight: 'bold',
        },
    },
    tooltip: {
        wordWrap: 'break-word',
        maxWidth: 400,
        zIndex: 200,
    },
    copy: {
        height: '100%',
        width: '100%',
    },
}));

export default useStyles;
