import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {CN} from '@gtb/utils';
import { withStyles } from '@mui/styles';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

const propTypes = {
    onScrollY: PropTypes.func,
    onScrollX: PropTypes.func,
    onScrollUp: PropTypes.func,
    onScrollDown: PropTypes.func,
    onScrollLeft: PropTypes.func,
    onScrollRight: PropTypes.func,
    onYReachStart: PropTypes.func,
    onYReachEnd: PropTypes.func,
    onXReachStart: PropTypes.func,
    onXReachEnd: PropTypes.func,
};

const defaultProps = {
    className: '',
    enable: true,
    option: undefined,
    containerRef: () => {
    },
    onScrollY: undefined,
    onScrollX: undefined,
    onScrollUp: undefined,
    onScrollDown: undefined,
    onScrollLeft: undefined,
    onScrollRight: undefined,
    onYReachStart: undefined,
    onYReachEnd: undefined,
    onXReachStart: undefined,
    onXReachEnd: undefined,
    onUpdate: () => {
    },
    style: {},
};

const handlerNameByEvent = {
    'ps-scroll-y': 'onScrollY',
    'ps-scroll-x': 'onScrollX',
    'ps-scroll-up': 'onScrollUp',
    'ps-scroll-down': 'onScrollDown',
    'ps-scroll-left': 'onScrollLeft',
    'ps-scroll-right': 'onScrollRight',
    'ps-y-reach-start': 'onYReachStart',
    'ps-y-reach-end': 'onYReachEnd',
    'ps-x-reach-start': 'onXReachStart',
    'ps-x-reach-end': 'onXReachEnd',
};

Object.freeze(handlerNameByEvent);

const styles = () => ({
    root: {
        '& .ps__rail-x': {
            opacity: '0.6!important',
            cursor: 'pointer!important',
        },
        '& .ps__rail-y': {
            opacity: '0.6!important',
            cursor: 'pointer!important',
        },
    },
    wrapper: {},
});

class GtbScrollbars extends Component {
    constructor(props) {
        super(props);
        this._handlerByEvent = new Map();
        this.wrp = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.customScrollbars) {
            setTimeout(() => {
                this.createPs();
            });
        } else {
            setTimeout(() => {
                this.destroyPs();
            });
        }

        this.updatePs();
    }

    componentDidMount() {
        this.createPs();
        // TODO: check actions below
        document.addEventListener('click', (event) => {
            setTimeout(() => {
                this.updatePs();
            }, 500);
        });
    }

    componentWillUnmount() {
        this.destroyPs();
    }

    updatePs = () => {
        if (!this._ps) {
            return;
        }
        this._ps.update();
        this.props.onUpdate(this._container, this._ps);
    };

    destroyPs = () => {
        if (!this._ps) {
            return;
        }
        // unhook up evens
        Object.keys(this._handlerByEvent).forEach((value, key) => {
            this._container.removeEventListener(key, value, false);
        });
        this._handlerByEvent.clear();
        this._ps.destroy();
        this._ps = null;
    };

    createPs = () => {
        if (!this._container || this._ps) {
            return;
        }
        this._ps = new PerfectScrollbar(this._container, this.props.option);

        // hook up events
        Object.keys(handlerNameByEvent).forEach((key) => {
            const callback = this.props[handlerNameByEvent[key]];
            if (callback) {
                const handler = () => callback(this._container);
                this._handlerByEvent.set(key, handler);
                this._container.addEventListener(key, handler, false);
            }
        });
    };

    handleRef = (ref) => {
        this._container = ref;
        this.props.containerRef(ref);
    };

    render() {
        const { classes, children, style, className, enable } = this.props;

        return (
                enable
                        ?
                        (
                                <div
                                        className={CN([className, classes.root])}
                                        style={{
                                            position: 'relative',
                                            overflow: 'hidden',
                                            ...style,
                                        }}
                                        ref={this.handleRef}
                                >
                                    {children}
                                </div>
                        )
                        :
                        (
                                <div className={this.props.className} ref={this.handleRef}>
                                    {this.props.children}
                                </div>
                        )
        );
    }
}

GtbScrollbars.propTypes = propTypes;
GtbScrollbars.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(GtbScrollbars);
