import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {Button, SetupItem} from "@gtb";
import {ActionIconButton} from '@gtb/components/Setups/components'
import {useDispatch, useSelector} from "react-redux";
import {
    getSettingsData,
    showMessage,
    MESSAGE_ERROR,
    updateSettingsData,
    hideModal,
    testSMTPSettingsData,
    setTimeZone
} from "store/actions";
import Icon from "@gtb/components/Icons";
import * as Icons from "@gtb/components/Icons";
import {
    emailValidation,
    validURL,
    TIME_ZONE,
    TIME_ZONES_LIST
} from "@gtb/utils";
import _ from 'lodash';

const useStyles = makeStyles(({typography, spacing}: CustomTheme) => ({
    root: {
        width: '100%',
    },
    box: {
        width: '100%',
        overflow: "hidden",
        borderRadius: '0.7rem'
    },
    title: {
        ...typography.h1,
        marginTop: 0,
        marginBottom: 0,
        lineHeight: spacing(7),
    },
    row: {
        paddingTop: 22,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& button': {
            marginLeft: spacing(3)
        }
    }
}));

const DEFAULT_FIELDS = {
    'address': '',
    'port': 465,
    'username': '',
    'password': '',
    'originator': '',
    'encrypted': 1,
};

interface SetupFormProps {
    portal?: boolean,
    classes?: any
}


const SysInfo: React.FC<SetupFormProps> = (props) => {
    const stl = useStyles(props);

    const {classes, portal = false} = props;

    // @ts-ignore
    const {data} = useSelector((state) => state.settings);
    const dispatch = useDispatch();

    useEffect(() => {
        // @ts-ignore
        dispatch(getSettingsData());
    }, []);

    const [fields, setFields] = useState(_.isObject(data) ? {...DEFAULT_FIELDS, ...data} : DEFAULT_FIELDS);
    useEffect(() => {
        setFields(_.isObject(data) ? {...DEFAULT_FIELDS, ...data} : DEFAULT_FIELDS);
    }, [JSON.stringify(data)]);

    // @ts-ignore
    const {timezone} = useSelector((state) => state.auth);

    const onChange = (name: string) => (event: any) => {
        setFields({...fields, [name]: event.target.value});
    }
    const onNumberChange = (name: string) => (value: any) => {
        setFields({...fields, [name]: value});
    }


    const [passwordValue, setPassword] = useState(data.password);

    useEffect(() => {
        setPassword(data.password)
    }, [JSON.stringify(data.password)])

    const onPasswordChange = (value: any) => {
        setFields({...fields, password: value});
    }

    const fieldsValidation = () => {
        if (fields.originator && !emailValidation(fields.originator)) {
            dispatch(showMessage({
                message: 'Please fix Email Originator it isn`t email',
                type: MESSAGE_ERROR,
            }));
            return false;
        } else if (fields && fields.address) {
            if (validURL(fields.address)) {
                // console.log('F', fields);
                // @ts-ignore
                return true;
            } else {
                dispatch(showMessage({
                    message: 'Please check SMTP Server Address it is incorrect',
                    type: MESSAGE_ERROR,
                }));
                return false;
            }
        } else {
            dispatch(showMessage({
                message: 'Please add SMTP Server Address',
                type: MESSAGE_ERROR,
            }));
            return false;
        }
    }


    // console.log('SMTP FIELDS', fields)

    const template = {
        root: {
            width: 555,
        },
        label: {
            width: 255,
        }
    }
    return (
            <div className={stl.root}>
                <div className={stl.box}>
                    <SetupItem
                            label={'SMTP Server Address'}
                            input={[
                                {
                                    type: 'input',
                                    placeholder: 'Please add SMTP Server Address',
                                    value: fields.address,
                                    onChange: onChange('address'),
                                },
                            ]}
                            actions={
                                <ActionIconButton
                                        tooltip={'Send Test Email'}
                                        onSubmit={() => {
                                            if (fieldsValidation()) {
                                                // console.log('F', fields);
                                                // @ts-ignore
                                                dispatch(testSMTPSettingsData(fields));
                                            }
                                        }}
                                >
                                    <Icon view={Icons.ICON_TEST_CONNECTION}/>
                                </ActionIconButton>
                            }
                            template={template}
                    />
                    <SetupItem
                            label={'SMTP Server Port'}
                            input={[{
                                type: 'number',
                                placeholder: 'Please add SMTP Server Port',
                                value: fields.port,
                                onChange: onNumberChange('port'),
                            }]}
                            template={template}
                    />
                    <SetupItem
                            label={'Username'}
                            input={[{
                                type: 'input',
                                placeholder: 'Please add Username',
                                value: fields.username,
                                onChange: onChange('username'),
                            }]}
                            template={template}
                    />
                    <SetupItem
                            label={'Password'}
                            input={[{
                                // type: 'password',
                                // placeholder: 'Please add password',
                                // value: fields.password,
                                // onChange: onChange('password'),
                                autoComplete: 'new-password',
                                value: fields.password,
                                placeholder: 'Please add password',
                                type: 'password',
                                onFocus: () => {
                                    //hardcode to understand if the value was changed or not
                                    if (passwordValue === '********') {
                                        setPassword('');
                                    }
                                },
                                onBlur: () => {
                                    if (passwordValue && passwordValue.length > 0) {
                                        onPasswordChange(passwordValue);
                                    } else {
                                        setPassword(fields.password);
                                    }
                                },
                                onChange: (e: any) => {
                                    setPassword(e.target.value);
                                },
                                onReset: () => {
                                    onPasswordChange(null);
                                },
                                clearable: true,
                                fastChange: true,
                            }]}
                            template={template}
                    />
                    <SetupItem
                            label={'Email Originator'}
                            input={[{
                                type: 'input',
                                placeholder: 'Please add Email Originator',
                                value: fields.originator,
                                onChange: onChange('originator'),
                            }]}
                            template={template}
                    />
                    <SetupItem
                            label={'Encryption'}
                            input={[{
                                type: 'combo-select',
                                value: fields.encrypted, // ? fields.encrypted === 1 ? 'SSL' : 'TLS' : 'NONE',
                                options: [
                                    {value: 0, label: 'NONE'},
                                    {value: 1, label: 'SSL'},
                                    {value: 2, label: 'TLS'},
                                ],
                                onChange: (v: any) => {
                                    setFields({...fields, encrypted: v.value});
                                }
                            }]}
                            template={template}
                    />
                </div>
                <div className={stl.row}>
                    <Button onClick={() => {
                        if (fieldsValidation()) {
                            // console.log('F', fields);
                            // @ts-ignore
                            dispatch(updateSettingsData(fields));
                        }
                        if (portal) {
                            dispatch(hideModal());
                        }
                    }}>
                        {data
                                ? <><Icon view={Icons.ICON_SAVE}/>{'Save'}</>
                                : <><Icon view={Icons.ICON_ADD}/>{'Add'}</>
                        }
                    </Button>
                    {portal ? (
                            <Button onClick={() => {
                                dispatch(hideModal());
                            }}>
                                Cancel
                            </Button>
                    ) : null}
                </div>
                {!portal ? (
                        <>
                            <h4 className={stl.title}>Time settings</h4>
                            {/*<h4 className={classes.title}>System Date and Time settings</h4>*/}
                            <div className={stl.box}>
                                {/*<SetupItem*/}
                                {/*        label={'NTP Server Address'}*/}
                                {/*        input={[*/}
                                {/*            {*/}
                                {/*                type: 'input',*/}
                                {/*                value: 'pool.ntp.org'*/}
                                {/*            },*/}
                                {/*        ]}*/}
                                {/*        template={template}*/}
                                {/*/>*/}
                                {/*<SetupItem*/}
                                {/*        label={'Date and Time'}*/}
                                {/*        input={[{*/}
                                {/*            type: 'input',*/}
                                {/*        }]}*/}
                                {/*        template={template}*/}
                                {/*/>*/}
                                <SetupItem
                                        label={'Timezone'}
                                        input={[{
                                            type: 'combo-select',
                                            value: timezone,
                                            options: TIME_ZONES_LIST,
                                            onChange: (v: any) => {
                                                dispatch(setTimeZone(v.value));
                                            },
                                            menuShouldScrollIntoView: true,
                                            inOneLine: true,
                                            autoFocus: true,
                                            isSearchable: true,
                                            formatCreateLabel: () => false,
                                            isValidNewOption: () => false,
                                        }]}
                                        template={template}


                                />
                            </div>
                        </>
                ) : null}
            </div>
    );
};

export default SysInfo;
