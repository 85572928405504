import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {getUserInformation} from "store/actions";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles(({palette, spacing, typography}: CustomTheme) => ({
    root: {
        ...typography.h3,
        fontSize: '1.4rem',
        lineHeight: '1.4rem',
        textAlign: 'right',
        padding: spacing(0, 1.6, 0, 0),
        marginTop: 1,
        marginBottom: 3,
        // width: 200,
    },
}));

interface CCVersion {
    [x: string]: any
}


export const CCTime: React.FC<CCVersion> = (props) => {
    const stl = useStyles(props);
    // @ts-ignore
    const {revision} = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const [version, setVersion] = useState(`v${revision?.system ? revision.system + '-' : ''}${revision?.manager || ''}`);
    useEffect(() => {
        // @ts-ignore
        dispatch(getUserInformation());
    }, []);
    useEffect(() => {
        setVersion(`v${revision?.system ? revision.system + '-' : ''}${revision?.manager || ''}`);
    }, [JSON.stringify(revision)]);

    return (
            <div className={stl.root}>
                {version}
            </div>
    );
};

export default CCTime;
