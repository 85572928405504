import _ from 'lodash';

export const StringToOneRow = (str: string) => {
	return typeof str === 'string' ? str.replace(/(\r\n|\n|\r)/gm, ' ') : str;
};

export const isInArray = (arr: unknown[], id: unknown) => arr.indexOf(id) !== -1;

export const notEmptyArray = (arr: unknown) => _.isArray(arr) && arr.length > 0;

export const removeFromArray = (arr: unknown[], val: unknown, toogle: boolean = false) => {
	const arrIndex = arr.indexOf(val);
	let newArr: unknown[] = [];

	if (arrIndex === -1) {
		if (toogle) {
			newArr = newArr.concat(arr, val);
		} else {
			newArr = arr;
		}
	} else if (arrIndex === 0) {
		newArr = newArr.concat(arr.slice(1));
	} else if (arrIndex === arr.length - 1) {
		newArr = newArr.concat(arr.slice(0, -1));
	} else if (arrIndex > 0) {
		newArr = newArr.concat(arr.slice(0, arrIndex), arr.slice(arrIndex + 1));
	}
	return newArr;
};
