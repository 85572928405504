import React from 'react';
import { CN } from '@gtb/utils';
import { CTooltip as Tooltip } from '@gtb';
import Icon from '@gtb/components/Icons';

const MultiValue = props => {
	const iconColor = props.data.iconColor
		? [ props.data.iconColor, props.data.iconColor, props.data.iconColor, props.data.iconColor ]
		: false;
	return (
		<Tooltip
			title={props.children}
			placement={'top'}
		>
			<div className={CN(props.selectProps.classes.icon)}>
				{typeof props.data.icon == 'string'
					? (
						<Icon view={props.data.icon} fill={iconColor}/>
					)
					: (
						props.data.icon
					)}
			</div>
		</Tooltip>
	);
};

export default MultiValue;
