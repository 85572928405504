import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import Icon, * as Icons from '@gtb/components/Icons';

const useStyles = makeStyles(() => ({
    root: {
        margin: '0px',
        padding: 0,
    },
}));

const defaultProps = {
    disableRipple: true,
    icon: <Icon view={Icons.ICON_CHECKBOX_UNCHECKED} fill={['#7A8A99']}/>,
    checkedIcon: <Icon view={Icons.ICON_CHECKBOX_CHECKED} fill={['#4da6ff']}/>,
    indeterminateIcon: <Icon view={Icons.ICON_CHECKBOX_INDETERMINATE}/>,
};

const CustomCheckbox: React.FC<{ [x: string]: any }> = (props) => {
    const classes = useStyles(props);
    const next = {...props, ...defaultProps}

    return <Checkbox classes={classes} {...next}/>;
}

CustomCheckbox.propTypes = {
    icon: PropTypes.element,
    checkedIcon: PropTypes.element,
    indeterminateIcon: PropTypes.element,
};


export default CustomCheckbox;
