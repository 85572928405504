import React, {useCallback, useState, useEffect} from 'react';
import Page from 'apps/common/components/Page';
import Toolbar from './Toolbar';
import {Grid} from '@gtb';
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import Columns from '../configs/GridConfig';
import {generateDefaultView} from '@gtb/components/Grid/utils';
import {useSelector, useDispatch} from 'react-redux';
import {
    customersSelect,
    customersGetData,
    customersSetRefreshing,
    customersRefreshData,
    showModal, customersFocus
} from 'store/actions';
import _ from "lodash";
import EditCustomer from "./toolbar/EditCustomer";
import License from './cells/License';
import Link from './cells/Link';
import {FULL_TIME_SHORT_FORMAT, normalizeUrl} from "@gtb/utils";

const useStyles = makeStyles(({palette, typography, spacing}: CustomTheme) => ({
    link: {
        display: 'flex',
        justifyContent: 'space-between',
        color: palette.action.active,
        textDecoration: 'none!important',
        paddingTop: spacing(2.16666),
        paddingBottom: spacing(1.8444444),
        '&:hover': {
            textDecoration: 'underline!important',
        }
    },
    id: {
        display: 'flex',
        width: '100%',
    },
    title: {
        ...typography.h1,
        marginTop: 22,
    },
    rowTemplate: {
        gridTemplateColumns: '72px auto 735px 475px',
    },
    notLicensed: {
        color: palette.action.active5
    },
    unlimitedLicense: {
        color: palette.action.active
    },
    customerRow: {
        borderBottomWidth: 4,
    },
    table: {
        margin: spacing(0)
    }
}));

interface CustomersDataInterface {
    '_id': string,
    "companyName"?: string,
    "fqdn": string,
    "users"?: number,
    "licenseExpirationDate"?: string,
    "companyUrl"?: string,
    "description"?: string,
    "admin"?: {
        "companyUrl"?: string,
        "name"?: string,
        "email"?: string,
        "phone"?: string,
        "_id"?: string
    },
    "createdAt": string,
    "updatedAt": string
    // [x: string]: any,
}


let refresh: NodeJS.Timer;

export const App: React.FC = () => {
            const stl = useStyles();

            // @ts-ignore
            const {select, focused, data, refreshing} = useSelector((state) => state.customers);
            const dispatch = useDispatch();

            // console.log('CUSTOMERS STORE SELECT', select)
            // console.log('CUSTOMERS STORE DATA', data)
            // console.log('CUSTOMERS STORE REFRESH DATA', refreshing)

            const onSelect = (s: string[]) => {
                dispatch(customersSelect(s));
            }

            useEffect(() => {
                // @ts-ignore
                dispatch(customersGetData());
                dispatch(customersSetRefreshing(true));
                return () => {
                    dispatch(customersSetRefreshing(false));
                }
            }, []);

            useEffect(() => {
                refresh = setInterval(() => {
                    if (refreshing.value) {
                        // console.log('refresh!', refreshing)
                        // @ts-ignore
                        dispatch(customersRefreshData())
                    }
                }, refreshing.interval);
                return () => {
                    // @ts-ignore
                    clearInterval(refresh);
                }
            }, [JSON.stringify(refreshing)]);

            const tbody = useCallback(dataCallback => {
                return dataCallback.map((row: CustomersDataInterface, rowIndex: number) => {

                    return {
                        _id: row.hasOwnProperty('_id') ? row['_id'] : rowIndex + 1,
                        className: stl.customerRow,
                        onClick: () => {
                            // console.log('RoW click');
                        },
                        onDoubleClick: (event: any, i: any) => {
                            if (_.isArray(data) && data.length > 0) {
                                // console.log('DRoW click', event, data[i]);
                                const fields = data[i];
                                dispatch(showModal({
                                    width: '105rem',
                                    height: 'auto',
                                    maxHeight: 'auto',
                                    title: <div className={stl.title}>{'Edit customer'}</div>,
                                    content: <EditCustomer fields={fields}/>,
                                }));
                            }
                        },
                        fields: [
                            {
                                type: 'custom',
                                slug: '_id',
                                className: 'middle smallPadding',
                                full: true,
                                content: <div className={stl.id}>{rowIndex + 1}</div>
                            },
                            {
                                ...generateDefaultView(row, 'companyName'),
                                className: 'middle smallPadding',
                            },
                            {
                                type: 'custom',
                                slug: 'fqdn',
                                className: 'middle smallPadding',
                                full: true,
                                content: <a className={stl.link} target={'_blank'} href={normalizeUrl(row['fqdn'])}>
                                    {row['fqdn']}
                                </a>
                            },
                            {
                                ...generateDefaultView(row, 'users'),
                                className: 'middle centered smallPadding',
                            },
                            {
                                type: 'date-view',
                                slug: 'licenseExpirationDate',
                                className: 'middle centered smallPadding',
                                value: row['licenseExpirationDate'],
                                Wrapper: License,
                            },
                            {
                                type: 'custom',
                                slug: 'companyUrl',
                                className: 'middle smallPadding',
                                full: true,
                                content: <Link row={row}/>
                            },
                            {
                                type: 'date-view',
                                slug: 'createdAt',
                                className: 'middle centered smallPadding',
                                value: row['createdAt'],
                                format: FULL_TIME_SHORT_FORMAT

                            },
                            {
                                type: 'date-view',
                                slug: 'updatedAt',
                                className: 'middle centered smallPadding',
                                value: row['updatedAt'],
                                format: FULL_TIME_SHORT_FORMAT
                            },
                            {
                                ...generateDefaultView(row, 'description'),
                                className: 'middle smallPadding',
                            },
                        ],
                    };
                });
            }, [JSON.stringify(data)]);

            return (
                    <Page
                            path={['Customers']}
                            toolbar={<Toolbar/>}
                    >
                        <Grid
                                // @ts-ignore
                                filters={[]}
                                focused={focused}
                                onFocus={(focused: string[]) => {
                                    dispatch(customersFocus(focused))
                                }}
                                tableClasses={{root: stl.table}}
                                virtualizer={false}
                                className={'hovered'}
                                isLoading={refreshing.isLoading}
                                thead={[{fields: Columns}]}
                                data={data}
                                tbody={typeof tbody === 'function'
                                        ? tbody
                                        : () => {
                                            return [];
                                        }}
                                selected={select}
                                onSelect={onSelect}
                                maxHeight={800}
                        />
                    </Page>
            );
        }
;

export default App;
