import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    container: {
        minWidth: '100%',
        // height: '100vh',
    },
    box: {
        // backgroundColor: 'rgb(230, 238, 242)',
        height: '100vh',
    },
});

export default useStyles;
