import React from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";

const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    root: {},
    top: {},
    navigation: {
        height: 'calc(100vh - 60px)',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    custom: {
        ...typography.h4,
        color: palette.common.white,
        width: '100%',
        height: 60,
        display: 'flex',
        alignItems: "center",
        justifyContent: "space-between",
        background: palette.action.active,
        padding: spacing(0, 0, 0, 2),
    },
    logo: {
        flexShrink: 0,
        width: 36,
        marginRight: spacing(2),
        display: 'flex',
        alignItem: 'center',
        '& img': {
            width: 36,
        }
    },
    name: {
        flexShrink: 1,
        width: '100%',
        fontStyle: 'italic',
        color: palette.common.white,
        fontSize: 14,
        lineHeight: spacing(2.33333),
        letterSpacing: '-0.025em',
        fontWeight: 400,
        '& b': {
            fontSize: '2rem',
            fontWeight: 600,
        }
    }
}));

interface NavbarProps extends React.PropsWithChildren<any> {
    top?: JSX.Element,
}

const Navbar: React.FC<NavbarProps> = ({top, children}) => {
    const classes = useStyles();

    return (
            <div className={classes.root}>
                <div className={classes.top}>
                    {top ? top : (
                            <div className={classes.custom}>
                                <div className={classes.logo}><img src={'/assets/logo.svg'} alt="logo"/></div>
                                <div className={classes.name}><b>GTB Multi-Tenant</b><br/>Central Console</div>
                            </div>
                    )}
                </div>
                <div className={classes.navigation}>
                    {children}
                </div>
            </div>
    );
};

export default Navbar;
