import { CUSTOMERS_DELETE, CUSTOMERS_SELECT } from '../../actions';

const select = function (state = [], action: { [x: string]: any } = {}) {
	switch (action.type) {
		case CUSTOMERS_SELECT: {
			return [...action.selected];
		}
		case CUSTOMERS_DELETE: {
			return [];
		}
		default: {
			return state;
		}
	}
};

export default select;
