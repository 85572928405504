export const MODAL_SKIN_DEFAULT = '[MODAL SKIN] DEFAULT';
export const MODAL_SKIN_WHITE = '[MODAL SKIN] WHITE';
export const MODAL_SKIN_SYSTEM_MESSAGE = '[MODAL SKIN] SYSTEM MESSAGE';

export const HIDE_MODAL = '[MODAL] CLOSE';
export const SHOW_MODAL = '[MODAL] SHOW';
export const EDIT_MODAL = '[MODAL] EDIT';


export function hideModal () {
	return {
		type: HIDE_MODAL,
	};
}

export function showModal (options: any) {
	return {
		type: SHOW_MODAL,
		options,
	};
}

export function editModal (options: any, index: number) {
	return {
		type: EDIT_MODAL,
		options,
		index,
	};
}
