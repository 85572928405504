import React, {Fragment} from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from '@gtb/components/Theme/interfaces';
import {SvgIcon} from '@mui/material';
import {ICON_DEFAULT} from './icons';
import {IconProps, ComplexIconViewProps} from './interfaces'
import _ from 'lodash';

const useStyles = makeStyles(({palette}: CustomTheme) => ({
    root: {
        width: '2.4rem',
        height: '2.4rem',
    },
    secondary: {
        fill: palette.text.secondary,
        '.active &': {
            fill: palette.action.active,
        },
    },
    primary: {
        fill: palette.action.active,
    },
}));

const Icon: React.FC<IconProps> = (props) => {
    const classes = useStyles();
    const {
        view,
        fill,
        color,
    } = props;

    return (
            _.isArray(view)
                    ? (
                            <SvgIcon classes={{root: classes.root}}>
                                <Fragment>
                                    {
                                        view.map((icon: ComplexIconViewProps, index) => (
                                                <path
                                                        key={'GtbIcon_' + index}
                                                        className={icon.primary
                                                                ? classes.primary
                                                                : classes.secondary}
                                                        style={{
                                                            fill: fill
                                                                    ? fill[index]
                                                                    : (icon.color
                                                                            ? icon.color
                                                                            : ''),
                                                        }}
                                                        d={icon.path
                                                                ? icon.path
                                                                : ICON_DEFAULT}
                                                />
                                        ))
                                    }
                                </Fragment>
                            </SvgIcon>
                    )
                    : (
                            <SvgIcon classes={{root: classes.root}}>
                                <path
                                        className={classes.secondary}
                                        style={{
                                            fill: fill
                                                    ? fill[0]
                                                    : (color
                                                            ? color
                                                            : ''),
                                        }}
                                        d={view
                                                ? view
                                                : ICON_DEFAULT}
                                />
                            </SvgIcon>
                    )
    );
}

export default Icon;

