import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import Icon, * as Icons from '@gtb/components/Icons';
import {setAuthToken} from "store/actions";
import ReactJson from 'react-json-view';
import {CN} from '@gtb/utils'

const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    debug: {
        zIndex: 999999,
        position: 'fixed',
        top: 0,
        right: 0,
        width: 5,
        height: '100vh',
        overflow: 'auto',
        fontSize: 14,
        // background: 'transparent',
        color: palette.common.white,
        '& .debug_content': {
            display: 'none',
            opacity: 0,
        },
        '&:hover, &.pinned': {
            width: '50vw',
            padding: spacing(3),
            background: '#404040',
            '&:before': {
                content: "''",
                display: 'block',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                width: 5,
                background: palette.action.active,
            },
            '& .debug_content': {
                display: 'block',
                opacity: 1,
            }
        }
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& svg path': {
            fill: palette.common.white
        },
    },
    pin: {
        cursor: "pointer",
    },
    title: {
        fontWeight: 300,
    },
    store: {
        position: 'relative',
        width: '100%',
        maxHeight: 600,
        overflow: 'auto',
    },
    action: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        ...typography.list,
        color: palette.common.white,
        '& svg': {
            marginRight: spacing(2),
        },
        '& svg path': {
            fill: palette.common.white
        },

        '&:hover': {
            color: palette.action.active5,
            '& svg path': {
                fill: palette.action.active5
            },
        },
    }
}));

interface DebuggerInterface {
    [x: string]: any
}

export const Debugger: React.FC<DebuggerInterface> = (props) => {
    const stl = useStyles(props);

    // @ts-ignore
    const store = useSelector((state) => state);
    const dispatch = useDispatch();
    const [pinned, setPinned] = useState(false);

    return process.env.NODE_ENV === 'development' ? (
            <div className={CN(stl.debug, {'pinned': pinned})}>
                <div className={'debug_content'}>
                    <div className={stl.header}>
                        <h1 className={stl.title}>{'Debugger'}</h1>
                        <div
                                className={stl.pin}
                                onClick={() => {
                                    setPinned(!pinned)
                                }}
                        >
                            {pinned ? <Icon view={Icons.ICON_TOP_LOCATION}/> : <Icon view={Icons.ICON_DLP_MENU_IRM}/>}
                        </div>
                    </div>
                    <div
                            className={stl.action}
                            onClick={() => {
                                dispatch(setAuthToken('bad_token'))
                            }}
                    >
                        <Icon view={Icons.ICON_PASSWORD}/>
                        {'Reset token'}
                    </div>
                    <h1 className={stl.title}>Store</h1>
                    <div className={stl.store}>
                        {/* @ts-ignore */}
                        <ReactJson src={store} collapsed={1} theme={'monokai'} enableClipboard/>
                    </div>
                </div>
            </div>
    ) : null;
};

export default Debugger;
