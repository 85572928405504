import React, { memo } from 'react';
import moment from 'moment';
import _ from 'lodash';

export const isEmptyObject = (obj: object) => {
	let name;
	for (name in obj) {
		return false;
	}
	return true;
};


export const diffObject = (obj1: { [key: string]: any } = {}, obj2: { [key: string]: any } = {}) => {
	let result: { [key: string]: any } = {};
	let change;

	for (var key in obj1) {
		if (typeof obj2[key] == 'object' && typeof obj1[key] == 'object') {
			change = diffObject(obj1[key] || {}, obj2[key] || {});
			if (!isEmptyObject(change)) {
				result[key] = change;
			}
		} else if (obj2[key] !== obj1[key]) {
			result[key] = obj2[key];
		}
	}

	return result;
};

export interface MemoSettingsProps {
	debug?: boolean,
	skip?: boolean,
	color?: string,
}

export type MemoConfigType = string[] | [];

function consoleChangedProps (config: MemoConfigType, prev: { [key: string]: any }, next: { [key: string]: any }, settings: MemoSettingsProps) {
	const { debug, color } = settings;
	console.groupCollapsed(
		'%c memo %c%s%c unequal props %c@ %s',
		'color:grey;font-weight:normal;',
		// `color:white;background:${color? color : '#1f6eff'};text-transform:uppercase;font-weight:bold;`,
		`color:white;background:${color
			? color
			: '#1f6eff'};font-weight:bold;`,
		typeof debug === 'string'
			? '[' + debug + ']'
			: '',
		'color:black;',
		'color:gray;font-weight:normal;',
		moment(new Date()).format('HH:mm:ss.SSS'),
	);
	config.forEach((c: string | Array<any>) => {
		if (typeof c === 'string' && !_.isEqual(prev[c], next[c])) {
			console.groupCollapsed('%c%s', 'color: red;', c);
			console.log('Prev:', prev[c]);
			if (_.isObject(prev[c]) && _.isObject(next[c])) {
				console.log('Diff:', diffObject(prev[c], next[c]));
			}
			console.log('Next:', next[c]);
			console.groupEnd();
		} else if (
			_.isArray(c) &&
			typeof c[0] === 'string' &&
			typeof c[1] === 'function'
		) {
			const [label, equal] = c;
			const eq = equal(prev[label], next[label]);
			if (!eq) {
				console.groupCollapsed('%c%s', 'color: red;', label);
				console.log('Prev:', prev[label]);
				if (_.isObject(prev[label]) && _.isObject(next[label])) {
					console.log('Diff:', diffObject(prev[label], next[label]));
				}
				console.log('Next:', next[label]);
				console.log('Comparison:', eq);
				console.groupEnd();
			}
		}
	});
	console.groupEnd();
}

export const areEqualProps = (config: MemoConfigType = [], settings: MemoSettingsProps) => (prev: { [key: string]: any }, next: { [key: string]: any }) => {
	const { debug, skip } = settings;

	const rule = config.filter((c: string | Array<any>) => {
		if (typeof c === 'string') {
			return _.isEqual(prev[c], next[c]);
		} else if (
			c &&
			typeof c[0] === 'string' &&
			typeof c[1] === 'function'
		) {
			const [label, equal] = c;
			return equal(prev[label], next[label]);
		} else {
			return true;
		}
	});
	if (
		debug &&
		process.env.NODE_ENV === `development` &&
		rule.length !== config.length
	) {
		consoleChangedProps(config, prev, next, settings);
	}

	return typeof skip === 'boolean'
		? skip
		: rule.length === config.length;
};

interface MemoComponentProps extends React.FC {
	[x: string]: any;
}

export const Memo = (Element: MemoComponentProps, config: string[] = [], settings: MemoSettingsProps = {}) => {
	return memo(Element, areEqualProps(config, settings));
};

export const MemoDebugger = (Element: MemoComponentProps, config: MemoConfigType | undefined | boolean, settings = {}) => {
	if (process.env.NODE_ENV === `development`) {
		let cfg: MemoConfigType = [];
		if (_.isArray(config) && config.length) {
			cfg = config;
		} else {
			cfg = _.isObjectLike(Element) && _.isObjectLike(Element.props)
				? Object.keys(Element.props)
				: [];
		}

		return memo(
			Element,
			areEqualProps(cfg, {
				debug: true,
				skip: false,
				...settings,
			}),
		);
	} else {
		return Element;
	}
};

export const DebuggerAnchor = (name: string = '') => {
	if (process.env.NODE_ENV === `development`) {
		console.log(
			'%c Check point %c[%s]%c  @ %s',
			'color:grey;font-weight:100',
			'color:white;background:green;',
			name,
			'color:white;font-weight:100',
			moment(new Date()).format('HH:mm:ss.SSS'),
		);
	}
};
