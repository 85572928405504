import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {useSelector, useDispatch} from 'react-redux';
import {SetupItem} from "@gtb";
import {ActionInlineButton} from "@gtb/components/Setups/components";
import Icon, * as Icons from '@gtb/components/Icons';
import _ from 'lodash'
import {customersGetData, MESSAGE_ERROR, showMessage, showModal} from "store/actions";
import {backupCreate, backupRestore} from "store/actions/backup";
import ConfirmationModal from "apps/common/components/layout/ConfirmationModal";

const useStyles = makeStyles(({spacing, palette}: CustomTheme) => ({
    root: {
        width: '100%',
        marginTop: spacing(-10),
        overflow: "hidden",
        borderRadius: '0.7rem'
    },
    cc: {
        width: '100rem',
        marginBottom: spacing(3),
        borderRadius: '0.7rem',
        overflow: 'hidden'
    },
    backup: {
        width: '120rem',
        borderRadius: '0.7rem',
        overflow: 'hidden'
    },
    downloadBackup: {
        minWidth: '20rem',
        marginBottom: 0,
        borderRadius: 0,
        justifyContent: 'flex-start',
        '&:last-child': {
            borderRadius: 0,
        },
    },
    chooseFile: {
        marginBottom: 0,
        borderRadius: '0px 0px 0.7rem 0px',
        '& > div': {
            maxWidth: '65rem'
        }
    },
    chooseFileSubmit: {
        marginBottom: 0,
    },
    placeholder: {
        color: palette.action.active5
    }
}));

export const backupOptions = [
    {
        label: 'Configuration only',
        value: 'config',
    },
    {
        label: 'Events only',
        value: 'data',
    },
    {
        label: 'Events and Configuration',
        value: 'all',
    },
];

const template = {
    root: {
        width: '100%',
    },
    label: {
        width: '35rem',
    },
};

interface SetupFormProps {

}

const Backup: React.FC<SetupFormProps> = (props) => {
    const stl = useStyles(props);

    // @ts-ignore
    const {data} = useSelector((state) => state.customers);
    const dispatch = useDispatch();
    useEffect(() => {
        // @ts-ignore
        dispatch(customersGetData());
    }, []);

    const [customer, setCustomer] = useState(_.isArray(data) && data.length > 0 ? data[0]['fqdn'] : '');
    useEffect(() => {
        setCustomer(_.isArray(data) && data.length > 0 ? data[0]['_id'] : '');
    }, [JSON.stringify(data)]);

    const onCustomerChange = (cc: any) => {
        setCustomer(cc.value)
    }

    const [options, setOptions] = useState('all');

    const onOptionsChange = (cc: any) => {
        setOptions(cc.value)
    }

    const backupDownload = () => {
        // @ts-ignore
        dispatch(backupCreate(customer, {type: options}));
    }


    const onRestore = (files: any) => {
        const confirmation = {
            width: '58rem',
            height: 'auto',
            title: 'Restore backup',
            content: <ConfirmationModal
                    message={'Are you sure you want to start the restore process? Current events and settings will be overwritten.'}
                    handleYes={() => {
                        // @ts-ignore
                        dispatch(backupRestore(customer, files));
                    }}
            />
        };

        dispatch(showModal(confirmation));
    }

    return (
            <div className={stl.root}>
                <div className={stl.cc}>
                    <SetupItem
                            label={'Customer Name'}
                            tooltip={'Company Name by FQDN'}
                            input={[
                                {
                                    type: 'select',
                                    placeholder: <span className={stl.placeholder}>No Customers created yet</span>,
                                    value: customer,
                                    options: data.map((c: any) => {
                                        return {label: c.companyName, value: c._id};
                                    }),
                                    onChange: onCustomerChange,
                                },
                            ]}
                            template={template}
                    />
                </div>
                <div className={stl.backup}>
                    <SetupItem
                            label={'Backup to Local Drive'}
                            tooltip={'Backup both Events and Settings'}
                            input={[
                                {
                                    type: 'select',
                                    placeholder: 'Select Drive',
                                    value: options,
                                    options: backupOptions,
                                    onChange: onOptionsChange,
                                },
                            ]}
                            actions={
                                <ActionInlineButton
                                        classes={{root: stl.downloadBackup}}
                                        icon={<Icon view={Icons.ICON_DOWNLOAD}/>}
                                        label={'Backup Now'}
                                        onSubmit={backupDownload}
                                />
                            }
                            template={template}
                    />
                    <SetupItem
                            label={'Restore Backup'}
                            tooltip={'Restore Backup'}
                            input={[{
                                type: 'choose-file',
                                classes: {
                                    chooseFile: stl.chooseFile,
                                    // submit: stl.chooseFileSubmit
                                },
                                // className: 'minimizedView',
                                placeholder: 'Browse Backup file',
                                label: 'Browse file',
                                multiple: true,
                                accept: {
                                    'application/x-tar': ['.tar'],
                                },
                                onSubmit: onRestore,
                                onError: () => {
                                    dispatch(showMessage({
                                        message: 'No file was chosen',
                                        type: MESSAGE_ERROR,
                                    }));
                                },
                                submitProps: {
                                    icon: <Icon view={Icons.ICON_RESTORE}/>,
                                    label: 'Restore',
                                    styles: {
                                        borderRadius: `0px 0px 0.7rem 0px`,
                                    },
                                },
                            }]}
                            template={template}
                    />
                </div>
            </div>
    );
};

export default Backup;
