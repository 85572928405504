import React from 'react';
import useStyles from '@styles/AppStyles';
import Page from 'apps/common/components/Page';
import Toolbar from './Toolbar';
import Backup from "./Backup";

export const App: React.FC = () => {
    const classes = useStyles();

    return (
            <Page
                    // isLoading={true}
                    path={['Backup and restore']}
                    toolbar={<Toolbar/>}
            >
                <Backup/>
            </Page>
    );
};

export default App;
