import { getResourcesData, updateResource } from 'api';
import { MESSAGE_ERROR, MESSAGE_SUCCESS, showMessage } from '../layout';

export const RESOURCES_GET_DATA = '[RESOURCES] GET DATA';
export const RESOURCES_SUCCESS_GET_DATA = '[RESOURCES] SUCCESS GET DATA';
export const RESOURCES_UPDATE = '[RESOURCES] UPDATE';

export const resourcesGetData = (onSuccess?: (message: unknown) => void, onError?: (error: unknown) => void) => {
	return (dispatch: any, getState: () => { [x: string]: any }) => {
		dispatch({ type: RESOURCES_GET_DATA });
		getResourcesData((result) => {
			// console.log('GET CUSTOMERS', result);
			dispatch({ type: RESOURCES_SUCCESS_GET_DATA, data: result.data });
			if (typeof onSuccess === 'function') {
				onSuccess(result);
			}

		});
	};
};


export const resourcesUpdateData = (id: string, data: { [x: string]: any }, onSuccess?: (message: unknown) => void, onError?: (error: unknown) => void) => {
	return (dispatch: any, getState: () => { [x: string]: any }) => {
		updateResource(id, data, (result) => {
			dispatch(resourcesGetData());
			dispatch({ type: RESOURCES_UPDATE, data: result.data });
			if (typeof onSuccess === 'function') {
				onSuccess(result);
			}
			dispatch(showMessage({
				message: 'Resource was updated',
				type: MESSAGE_SUCCESS,
			}));
		}, onError);
	};
};
