import React, { Fragment } from 'react';
import { CN } from '@gtb/utils';
import { Chip } from '@mui/material';
// import chroma from 'chroma-js';
import Icon, * as Icons from '@gtb/components/Icons';
import { IconButton } from '@gtb';
// import {alpha as fade} from '@mui/material/styles';

export const ChipDefaultStyle = {
	backgroundColor: 'rgba(79, 181, 160, 0.15)',
	color: 'rgb(47, 111, 98)', // '#7A8A99'
	fontWeight: 300,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: 'rgb(79, 181, 160)', // '#7A8A99',
};

const MultiValue = props => {
	const color = props.data.color
		? props.data.color
		: false; // chroma(props.data.color) : false;
	const icon = props.data.icon
		? props.data.icon
		: null;
	const iconColor = color
		? color.css()
		: ChipDefaultStyle.color;
	const removeProps = props.selectProps.readOnly
		? { onDelete: props.removeProps.onClick }
		: {};
	const { style, ...restChipOptions } = props.selectProps.chipOptions;

	return (
		<Chip
			style={{
				backgroundColor: color
					? color.alpha(0.1).css()
					: ChipDefaultStyle.backgroundColor, //fade(props.selectProps.theme.palette.background.main, 0.5),
				color: color
					? iconColor
					: ChipDefaultStyle.color, // '#7A8A99',
				fontWeight: color
					? 500
					: ChipDefaultStyle.fontWeight,
				borderWidth: ChipDefaultStyle.borderWidth,
				borderStyle: ChipDefaultStyle.borderStyle,
				borderColor: color
					? iconColor
					: ChipDefaultStyle.borderColor, // '#7A8A99',
				...style,
			}}
			tabIndex={-1}
			label={
				<Fragment>
					{icon && (
						<IconButton className={props.selectProps.classes.chipColorIcon}>
							{icon}
						</IconButton>
					)}

					<div
						style={{
							flexShrink: 1,
							width: '100%',
							overflow: 'hidden',
							whiteSpace: 'pre',
							textOverflow: 'ellipsis',
						}}
					>
						{props.children}
					</div>
				</Fragment>
			}
			classes={{}}
			className={CN(props.selectProps.classes.chip, {
				[props.selectProps.classes.chipFocused]: props.isFocused,
			})}
			deleteIcon={
				<div {...props.removeProps}>
					<IconButton className={props.selectProps.classes.chipDeleteIcon}>
						<Icon view={Icons.ICON_CLOSE} fill={[ iconColor, iconColor, iconColor ]}/>
					</IconButton>
				</div>
			}
			{...restChipOptions}
			{...removeProps}
		/>
	);
};

export default MultiValue;
