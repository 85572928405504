import { linearGradient } from '../utils';
import { CustomThemeGradientsOptions, CustomThemeGradients } from '../interfaces';
import {
	common,
	custom,
} from './palette';


export const customGradients: CustomThemeGradients = {
	gray: (angle: string): string => linearGradient([custom.gradient.darkGray, custom.gradient.lightGray], angle),
	green: (angle: string): string => linearGradient([custom.gradient.darkGreen, custom.gradient.lightGreen], angle),
	greenLight: (angle: string): string => linearGradient([custom.gradient.darkGreen, custom.gradient.middleGreen, custom.gradient.lightGreen], angle),
	softWhiteBlue: (angle: string): string => linearGradient([common.white, custom.gradient.lightenBlue], angle),
	orangeLight: (angle: string): string => linearGradient([custom.gradient.orangeMiddle, custom.gradient.orange], angle),
};

const gradients: CustomThemeGradientsOptions = [
	customGradients.gray,
	customGradients.green,
	customGradients.softWhiteBlue,
	customGradients.greenLight,
	customGradients.orangeLight,
];
export default gradients;
