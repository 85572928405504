//Licenses
export const ICON_LICENSES_DOWNLOAD = [
	{path: "M13,6H5C4.4,6,4,5.6,4,5s0.4-1,1-1h8c0.6,0,1,0.4,1,1S13.6,6,13,6z M14,8c0-0.6-0.4-1-1-1H5C4.4,7,4,7.4,4,8s0.4,1,1,1h8C13.6,9,14,8.6,14,8z M14,11c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h8C13.6,12,14,11.6,14,11z M9,14c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h3C8.6,15,9,14.6,9,14z M10,21c0-0.6-0.4-1-1-1H3c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h12c0.6,0,1,0.4,1,1v6c0,0.6,0.4,1,1,1s1-0.4,1-1V3c0-1.7-1.3-3-3-3H3C1.3,0,0,1.3,0,3v16c0,1.7,1.3,3,3,3h6C9.6,22,10,21.6,10,21z"},
	{primary: true, path: "M18,12c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S21.3,12,18,12z M21.7,18.7l-3,3c-0.1,0.1-0.2,0.2-0.3,0.2C18.3,22,18.1,22,18,22c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2l-3-3c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l1.3,1.3V15c0-0.6,0.4-1,1-1s1,0.4,1,1v3.6l1.3-1.3c0.4-0.4,1-0.4,1.4,0C22.1,17.7,22.1,18.3,21.7,18.7z"},
];
export const ICON_LICENSES_SEND_EMAIL = [
	{path: "M13,6H5C4.4,6,4,5.6,4,5s0.4-1,1-1h8c0.6,0,1,0.4,1,1S13.6,6,13,6z M14,8c0-0.6-0.4-1-1-1H5C4.4,7,4,7.4,4,8s0.4,1,1,1h8C13.6,9,14,8.6,14,8z M14,11c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h8C13.6,12,14,11.6,14,11z M9,14c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h3C8.6,15,9,14.6,9,14z M10,21c0-0.6-0.4-1-1-1H3c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h12c0.6,0,1,0.4,1,1v6c0,0.6,0.4,1,1,1s1-0.4,1-1V3c0-1.7-1.3-3-3-3H3C1.3,0,0,1.3,0,3v16c0,1.7,1.3,3,3,3h6C9.6,22,10,21.6,10,21z"},
	{primary: true, path: "M18,12c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S21.3,12,18,12z M21,15.7l-2,6c-0.1,0.2-0.2,0.3-0.4,0.3c0,0,0,0-0.1,0c-0.2,0-0.3-0.1-0.4-0.2l-1-1.5c-0.1-0.1-0.1-0.3,0-0.5l0.8-1.7l-1.7,0.8c-0.2,0.1-0.4,0.1-0.5,0l-1.5-1c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.2,0.2-0.3,0.3-0.4l6-2c0.2-0.1,0.4,0,0.5,0.1C21,15.3,21,15.5,21,15.7z"},
];

export const ICON_CONFIGURE_SMTP = [
	{path: "M19,0H3C1.3,0,0,1.4,0,3v12c0,1.6,1.3,3,3,3h3c0.6,0,1-0.4,1-1s-0.4-1-1-1H3c-0.6,0-1-0.5-1-1V6.9L7.9,11c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4C9.6,10.4,9.5,9.7,9,9.4L2,4.5V3c0-0.6,0.5-1,1-1h16c0.6,0,1,0.5,1,1v1.5l-1.9,1.3c-0.5,0.3-0.6,0.9-0.2,1.4c0.2,0.3,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2L20,6.9v0.6c0,0.6,0.4,1,1,1s1-0.4,1-1V3	C22,1.4,20.7,0,19,0z"},
	{primary: true, path: "M16.5,19c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5S17.9,19,16.5,19z M16.5,16c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5S16.8,16,16.5,16z M16.5,24c-1.1,0-2.1-0.7-2.4-1.7c-0.9,0.5-2.1,0.3-2.9-0.5c-0.8-0.8-0.9-2-0.5-2.9c-1-0.3-1.7-1.3-1.7-2.4s0.7-2.1,1.7-2.4c-0.5-0.9-0.3-2.1,0.4-2.9c0.8-0.8,2-0.9,2.9-0.4c0.3-1,1.3-1.7,2.4-1.7s2.1,0.7,2.4,1.7c0.9-0.5,2.1-0.3,2.9,0.5c0.5,0.5,0.7,1.1,0.7,1.8c0,0.4-0.1,0.8-0.3,1.1c1,0.3,1.7,1.3,1.7,2.4s-0.7,2.1-1.7,2.4c0.5,0.9,0.3,2.1-0.5,2.9s-2,0.9-2.9,0.5C18.6,23.3,17.6,24,16.5,24z M20,17.5c0.1-0.4,0.5-0.7,0.9-0.7h0.8c0.3,0,0.5-0.2,0.5-0.5S21.8,16,21.5,16h-0.8c-0.4,0-0.8-0.3-1-0.7v-0.1v0.1c-0.2-0.4-0.1-1.1,0.2-1.4l0.5-0.5c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.2-0.1-0.4c-0.2-0.2-0.5-0.2-0.7,0l-0.5,0.5c-0.3,0.3-0.9,0.3-1.3,0.1c0,0,0.2-0.3,0,0l-0.2-0.1C17.3,13,17,12.6,17,12.2v-0.8c0-0.3-0.2-0.5-0.5-0.5S16,11.2,16,11.5v0.8c0,0.4-0.3,0.8-0.7,0.9L15,13.3c-0.4,0.2-0.8,0.1-1.1-0.2l-0.5-0.5c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l0.5,0.5c0.3,0.3,0.2,1.1,0,1.5c0,0-0.7-0.3-0.2-0.2l0.2,0.2c-0.1,0.4-0.5,0.7-0.9,0.7h-0.8c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h0.8c0.4,0,0.8,0.4,1,0.8l0,0l0.1,0.2c0.2,0.4,0.1,0.8-0.2,1.1l-0.5,0.5c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.4-0.1l0.5-0.5c0.2-0.2,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0,0,0-0.1,0,0l0.2,0.1c0.4,0.1,0.7,0.5,0.7,0.9v0.8c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5v-0.8c0-0.4,0.3-0.8,0.7-1l0.3-0.1c0.4-0.2,0.8-0.1,1.1,0.2l0.5,0.5c0.1,0.1,0.3,0.1,0.4,0.1s0.2,0,0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.4s0-0.2-0.1-0.4L20,19.1c-0.3-0.3-0.4-0.8-0.2-1.2"},
];
export const ICON_LICENSES_UPLOAD = [
	{path: "M13,6H5C4.4,6,4,5.6,4,5s0.4-1,1-1h8c0.6,0,1,0.4,1,1S13.6,6,13,6z M14,8c0-0.6-0.4-1-1-1H5C4.4,7,4,7.4,4,8s0.4,1,1,1h8C13.6,9,14,8.6,14,8z M14,11c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h8C13.6,12,14,11.6,14,11z M9,14c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h3C8.6,15,9,14.6,9,14z M10,21c0-0.6-0.4-1-1-1H3c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h12c0.6,0,1,0.4,1,1v6c0,0.6,0.4,1,1,1s1-0.4,1-1V3c0-1.7-1.3-3-3-3H3C1.3,0,0,1.3,0,3v16c0,1.7,1.3,3,3,3h6C9.6,22,10,21.6,10,21z"},
	{primary: true, path: "M18,24c3.3,0,6-2.7,6-6s-2.7-6-6-6s-6,2.7-6,6S14.7,24,18,24z M14.3,17.3l3-3c0.1-0.1,0.2-0.2,0.3-0.2C17.7,14,17.9,14,18,14s0.3,0,0.4,0.1s0.2,0.1,0.3,0.2l3,3c0.4,0.4,0.4,1,0,1.4s-1,0.4-1.4,0L19,17.4V21c0,0.6-0.4,1-1,1s-1-0.4-1-1v-3.6l-1.3,1.3c-0.4,0.4-1,0.4-1.4,0C13.9,18.3,13.9,17.7,14.3,17.3z"},
];
export const ICON_LICENSES_RESET = [
	{path: "M13,6H5C4.4,6,4,5.6,4,5s0.4-1,1-1h8c0.6,0,1,0.4,1,1S13.6,6,13,6z M14,8c0-0.6-0.4-1-1-1H5C4.4,7,4,7.4,4,8s0.4,1,1,1h8C13.6,9,14,8.6,14,8z M14,11c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h8C13.6,12,14,11.6,14,11z M9,14c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h3C8.6,15,9,14.6,9,14z M10,21c0-0.6-0.4-1-1-1H3c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h12c0.6,0,1,0.4,1,1v6c0,0.6,0.4,1,1,1s1-0.4,1-1V3c0-1.7-1.3-3-3-3H3C1.3,0,0,1.3,0,3v16c0,1.7,1.3,3,3,3h6C9.6,22,10,21.6,10,21z"},
	{primary: true, path: "M17,16c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S15.9,16,17,16z M21.2,13.8c-2.3-2.3-6.1-2.3-8.5,0c-2.3,2.3-2.3,6.1,0,8.5c1.2,1.2,2.7,1.8,4.2,1.8c1.5,0,3.1-0.6,4.2-1.8c0.3-0.3,0.5-0.6,0.8-0.9c0.3-0.5,0.2-1.1-0.3-1.4c-0.5-0.3-1.1-0.2-1.4,0.3c-0.1,0.2-0.3,0.4-0.5,0.6c-1.6,1.6-4.1,1.6-5.7,0c-1.6-1.6-1.6-4.1,0-5.7c1.6-1.6,4.1-1.6,5.7,0 M21.2,13.8l0.7-0.7c0.1-0.1,0.3-0.2,0.5-0.1c0.2,0.1,0.3,0.2,0.3,0.4l0.5,3.3c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.2-0.4,0.1l-3.3-0.5c-0.2,0-0.3-0.2-0.4-0.3c-0.1-0.2,0-0.4,0.1-0.5"},
];
export const ICON_LICENSED = [	
    {path: "M13,6H5C4.4,6,4,5.6,4,5s0.4-1,1-1h8c0.6,0,1,0.4,1,1S13.6,6,13,6z M14,8c0-0.6-0.4-1-1-1H5C4.4,7,4,7.4,4,8s0.4,1,1,1h8C13.6,9,14,8.6,14,8z M14,11c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h8C13.6,12,14,11.6,14,11z M9,14c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h3C8.6,15,9,14.6,9,14z M10,21c0-0.6-0.4-1-1-1H3c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h12c0.6,0,1,0.4,1,1v6c0,0.6,0.4,1,1,1s1-0.4,1-1V3c0-1.7-1.3-3-3-3H3C1.3,0,0,1.3,0,3v16c0,1.7,1.3,3,3,3h6C9.6,22,10,21.6,10,21z"},
    {color: "#7ACC29", path: "M18,12c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S21.3,12,18,12z M21.7,16.7l-4,4c-0.4,0.4-1,0.4-1.4,0l-2-2c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l1.3,1.3l3.3-3.3c0.4-0.4,1-0.4,1.4,0S22.1,16.3,21.7,16.7z"},
];
export const ICON_NOT_LICENSED = [	
    {path: "M13,6H5C4.4,6,4,5.6,4,5s0.4-1,1-1h8c0.6,0,1,0.4,1,1S13.6,6,13,6z M14,8c0-0.6-0.4-1-1-1H5C4.4,7,4,7.4,4,8s0.4,1,1,1h8C13.6,9,14,8.6,14,8z M14,11c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h8C13.6,12,14,11.6,14,11z M9,14c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h3C8.6,15,9,14.6,9,14z M8,21c0-0.6-0.4-1-1-1H3c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h12c0.6,0,1,0.4,1,1v6c0,0.6,0.4,1,1,1s1-0.4,1-1V3c0-1.7-1.3-3-3-3H3C1.3,0,0,1.3,0,3v16c0,1.7,1.3,3,3,3h4C7.6,22,8,21.6,8,21z"},
    {color: "#FF3333", path: "M23.7,21l-5-8c-0.4-0.5-1-0.9-1.7-0.9s-1.3,0.3-1.7,0.9l-5,8c-0.4,0.6-0.5,1.4-0.1,2s1.1,1,1.8,1h10c0.7,0,1.4-0.4,1.8-1C24.1,22.4,24.1,21.6,23.7,21z M17.1,22c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S17.7,22,17.1,22z M18.1,18c0,0.6-0.4,1-1,1s-1-0.4-1-1v-3c0-0.6,0.4-1,1-1s1,0.4,1,1V18z"},
];
export const ICON_PARTIALLY_LICENSED = [	
    {path: "M13,6H5C4.4,6,4,5.6,4,5s0.4-1,1-1h8c0.6,0,1,0.4,1,1S13.6,6,13,6z M14,8c0-0.6-0.4-1-1-1H5C4.4,7,4,7.4,4,8s0.4,1,1,1h8C13.6,9,14,8.6,14,8z M14,11c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h8C13.6,12,14,11.6,14,11z M9,14c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h3C8.6,15,9,14.6,9,14z M8,21c0-0.6-0.4-1-1-1H3c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h12c0.6,0,1,0.4,1,1v6c0,0.6,0.4,1,1,1s1-0.4,1-1V3c0-1.7-1.3-3-3-3H3C1.3,0,0,1.3,0,3v16c0,1.7,1.3,3,3,3h4C7.6,22,8,21.6,8,21z"},
    {color: "#FF7F00", path: "M18,12c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S21.3,12,18,12z M21.7,16.7l-4,4c-0.4,0.4-1,0.4-1.4,0l-2-2c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l1.3,1.3l3.3-3.3c0.4-0.4,1-0.4,1.4,0S22.1,16.3,21.7,16.7z"},
];