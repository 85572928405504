import React from 'react';
import {makeStyles, useTheme} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {CircularProgress} from '@mui/material';
import {CN} from '@gtb/utils';

const useStyles = makeStyles(({palette}: CustomTheme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    full: {
        zIndex: 10001,
        position: 'fixed',
        top: '50%',
        left: '50%',
        width: '150vw',
        height: '150vh',
        background: palette.text.primary,
        transform: 'translate(-50%, -50%)',
        transition: 'opacity 0s 0s ease, transform 0s 1.5s ease',
        '& $progressBox': {
            minHeight: 100,
        },
    },
    wrp: {
        height: '100%',
        minHeight: '100vh',
    },
    logo: {
        display: 'block',
        width: 100,
        height: 100,
        margin: '0px auto',
    },
    progressBox: {
        width: '100%',
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    progress: {},
    loader: {
        display: 'flex',
        flexDirection: 'column',
    },
    loaded: {
        '& $full': {
            opacity: 0,
            transform: 'translate(-50%, -200vh)',
        },
    },
}));

interface PreloaderProps extends React.PropsWithChildren<any> {
    isLoading: boolean | undefined,
    fullPage?: boolean,
    width?: string,
    height?: string,
    progressStyle?: { [key: string]: string },
}


const Preloader: React.FC<PreloaderProps> = ({
    children,
    isLoading,
    fullPage,
    width,
    height,
    progressStyle
}) => {
    const classes = useStyles();
    const theme: CustomTheme = useTheme();


    const preloader = (
            <div className={CN([classes.root], {[classes.full]: fullPage})}>
                <h1 className={classes.loader}
                    style={{
                        width: width ? width : '100%',
                        height: height ? height : 'auto',
                    }}
                >
                    {fullPage ? <img className={classes.logo} src={window!.WEB_ROOT +'/assets/logo.svg'} alt="logo"/> : null}
                    <div className={classes.progressBox}>
                        <CircularProgress
                                className={classes.progress}
                                style={progressStyle ? progressStyle : {color: theme.palette.action.active}}
                        />
                    </div>
                </h1>
            </div>
    );

    return fullPage
            ? (
                    <div className={CN([classes.wrp], {[classes.loaded]: !isLoading})}>
                        {preloader}
                        {children}
                    </div>
            )
            : (isLoading ? preloader : <>{children}</>);
};

export default Preloader;
