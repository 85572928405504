import { lighten } from '@mui/material/styles';
import { PaletteOptions } from '@mui/material/styles/createPalette';
import { CustomThemeCustomPaletteOptions, CustomThemePaletteOptions } from '../interfaces';

declare module '@mui/material/styles' {
	interface PaletteOptions {
		button: { [x: string]: string },
		severity: { [x: string]: string },
		message: { [x: string]: string },
		shadow: { [x: string]: string },
		policy: { [x: string]: string },
	}

	interface TypeAction {
		active2: string,
		active3: string,
		active3HEX: string,
		active4: string,
		active5: string,
		active6: string,
		focused: string,
		computing: string,
		selectedSecondary: string,
	}

	interface TypeText {
		tip: string,
		active: string,
		hint: string,
	}
}

const LightColors: { [key: string]: string } = {
	50: '#ECECEE',
	100: '#C5C6CB',
	200: '#9EA1A9',
	300: '#7D818C',
	400: '#5C616F',
	500: '#3C4252',
	600: '#353A48',
	700: '#2D323E',
	800: '#262933',
	900: '#1E2129',
	A100: '#C5C6CB',
	A200: '#9EA1A9',
	A400: '#5C616F',
	A700: '#2D323E',
};

export const common: CustomThemeCustomPaletteOptions = {
	white: '#fff',
	black: '#000',
};

export const custom: CustomThemePaletteOptions = {
	text: {
		blueGray: 'rgb(120, 135, 151)', // #788797
		darkBlueGray: 'rgb(77, 102, 128)', //#4D6680
	},
	bg: {
		blue: 'rgb(230, 238, 242)', // #E6EEF2
		grey: 'rgb(213,225,231)', // #D5E1E7
	},
	hilight: {
		blue: 'rgb(242, 251, 255)', // #F2FBFF
	},
	severity: {
		info: 'rgb(77, 166, 255, 1)', // '#4DA6FF'
		low: 'rgb(122, 204, 41, 1)', // '#7ACC29'
		medium: 'rgba(255, 168, 0, 1)', // '#FFA800'
		high: 'rgba(210, 8, 22, 1)', // '#D20816'
		critical: 'rgba(117, 0, 0, 1)', // '#750000'
	},
	shadow: {
		staticGray: 'rgb(26, 38, 51, 0.05)', // #F4F4F5 opacity:0.5
		activeBlue: 'rgb(128, 191, 255, 0.3)', // #D9ECFF opacity: 0.3
		darkWhite: 'rgba(255, 255, 255, 0.6)',
	},
	active: {
		red: 'rgb(255, 0, 0)', // #FF0000
		orange: 'rgb(255, 127, 0)', // #FF7F00
		yellow: 'rgb(230, 195, 23)', // #E6C317
		green: 'rgb(122,  204, 41)', // #FF0000
		blue: 'rgb(77, 166, 255)', // #4DA6FF
		mint: '#0891B2', // #0891B2
		lightBlue: 'rgb(226, 245, 255)', //#E2F5FF
		brightYellow: 'rgb(255, 243, 177)', //#FFF3B1
		blueFocused: 'rgb(190, 233, 252)', // #BEE9FC
		greenHEX: '#7ACC29',
		mixedBlue: 'rgb(102, 153, 204)', //#6699cc
	},
	tip: {
		lightYellow: 'rgb(255, 249, 217)', // #FFF9D9
		creamyYellow: 'rgb(242, 237, 206)', // #F2EDCE
		brown: 'rgb(128, 127, 121)', // #807F79
	},
	message: {
		lightGreen: 'rgb(236, 255, 217)', //
		darkGreen: 'rgb(122, 153, 992)', //
		lightRed: 'rgb(255, 217, 217)', //
		darkRed: 'rgb(153, 92, 92)', //
	},
	gradient: {
		darkGray: 'rgb(115, 121, 128, 0.5)', // #B9BCC0 opacity: 0.5
		lightGray: 'rgb(184, 194, 204, 0.5)', // #DCE1E6 opacity: 0.5
		darkGreen: 'rgb(92, 153, 31)', // #5C991F
		middleGreen: 'rgb(114, 190, 38)', // #72BE26
		lightGreen: 'rgb(122, 204, 41, 0.5)', // #7ACC29 opacity: 0.5
		lightenBlue: 'rgb(245, 247, 250)', // #F5F7FA
		yellow: 'rgb(230, 195, 23)', // #E6C317
		orangeMiddle: 'rgb(255, 206, 0)', // #FFCE00
		orange: 'rgb(255, 163, 0)', // #FFA300
	},
	policy: {
		mislabeled: 'rgb(186, 125, 187)', //#ba7dbb
		allDatabases: 'rgb(255, 51, 51)', //#ff3333
		category: 'rgb(0, 149, 182)', //#0095b6
		allFiles: 'rgb(122, 204, 41)',//'#7ACC29'
	},
	button: {
		deploy: 'rgb(255, 127, 0)', // #FF7F00,
		deployBG: 'rgb(255, 219, 184)', // #FFDBB8,
		add: 'rgb(122, 204, 41)', // #7ACC29,
		addBG: 'rgb(211, 242, 179)', // #d3f2b3,
		back: 'rgb(77, 166, 255)', // #7ACC29,
		backBG: 'rgb(209, 232, 255)', // #d1e8ff,
		// TODO: ADD other button types
	},
};

const palette: PaletteOptions = {
	common: common,
	primary: LightColors,
	secondary: {
		light: LightColors[400],
		main: LightColors[600],
		dark: LightColors[700],
	},
	background: {
		main: custom.bg.blue,
		light: lighten(custom.bg.blue, 0.5),
		mainModal: custom.bg.grey,
	},
	button: { ...custom.button },
	severity: {
		info: custom.severity.info,
		low: custom.severity.low,
		medium: custom.severity.medium,
		high: custom.severity.high,
		critical: custom.severity.critical,
	},
	action: {
		active: custom.active.mint,
		active2: custom.active.lightBlue,
		active3: custom.active.green,
		active3HEX: custom.active.greenHEX,
		active4: custom.active.yellow,
		active5: custom.active.orange,
		active6: custom.active.red,
		hover: custom.active.lightBlue,
		focused: custom.active.blueFocused,
		selected: custom.tip.lightYellow,
		computing: custom.active.mixedBlue,
		selectedSecondary: custom.active.brightYellow,
	},
	message: {
		success: custom.message.lightGreen,
		info: custom.message.darkGreen,
		error: custom.message.lightRed,
	},
	text: {
		primary: custom.text.darkBlueGray, // #4D6680
		secondary: custom.text.blueGray, // #7A8A99
		tip: custom.tip.brown,
		active: custom.active.blue,
		disabled: custom.gradient.lightGray,
		hint: custom.gradient.lightGray,
	},
	shadow: {
		transparent: 'transparent',
		...custom.shadow,
	},
	policy: {
		encrypted: custom.active.orange,
		mislabeled: custom.policy.mislabeled,
		allPatterns: custom.active.blue,
		allFiles: custom.policy.allFiles,
		allDatabases: custom.policy.allDatabases,
		category: custom.policy.category,
	},
};
export default palette;
