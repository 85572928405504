import { combineReducers } from 'redux';
import modal from './modal';
import message from './message';

const rootReducer = combineReducers({
	modal,
	message,
});

export default rootReducer;
