import React, {useState} from 'react';
import {Button, SetupItem} from '@gtb';
import Icon, * as Icons from '@gtb/components/Icons';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import {
    customersAddData,
    customersUpdateData,
    hideModal,
    MESSAGE_ERROR,
    showMessage,
} from 'store/actions';
import {isInCustomerGrid} from '../../utils'
import License from "../cells/License";

const useStyles = makeStyles(({typography, spacing}: CustomTheme) => ({
    root: {},
    box: {
        width: '100%',
        overflow: 'hidden',
        borderRadius: '0.7rem',
    },
    title: {
        ...typography.h1,
        marginTop: 22,
        marginBottom: spacing(2),
    },
    row: {
        paddingTop: 22,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& button': {
            marginLeft: spacing(3)
        }
    },
}));

const template = {
    root: {
        width: '100%',
    },
    label: {
        width: 200,
    },
}


interface CustomerDataProps {
    companyName: string,
    fqdn: string,
    users?: number,
    licenseExpirationDate?: string,
    companyUrl?: string,
    description?: string,
    admin?: {
        companyUrl?: string,
        name?: string,
        email?: string,
        phone?: string
    },

    [x: string]: any
}

const DEFAULT_DATA = {
    "companyName": "",
    "fqdn": "",
    "users": 0,
    "licenseExpirationDate": "",
    "companyUrl": "",
    "description": "",
    "admin": {
        "companyUrl": "",
        "name": "",
        "email": "",
        "phone": ""
    }
}


const EditCustomer: React.FC<{ [x: string]: any }> = (props) => {

    const stl = useStyles(props);
    const {fields = {}} = props;

    // @ts-ignore
    const {data: grid} = useSelector((state) => state.customers);
    const dispatch = useDispatch();

    // console.log('Fields', props.fields);

    const [data, setData] = useState(fields && typeof fields == 'object' ? {...DEFAULT_DATA, ...fields} : DEFAULT_DATA);
    const onChange = (name: string) => (event: any) => {
        setData({...data, [name]: event.target.value});
    }
    const onAdminChange = (name: string) => (event: any) => {
        setData({...data, admin: {...data.admin, [name]: event.target.value}});
    }


    return (
            <div className={stl.root}>
                <div className={stl.box}>
                    <SetupItem
                            label={'Company Name'}
                            input={[
                                {
                                    type: 'input',
                                    placeholder: 'Please fill Company Name',
                                    value: data.companyName,
                                    onChange: onChange('companyName'),
                                },
                            ]}
                            template={template}
                    />
                    <SetupItem
                            label={'FQDN'}
                            input={props?.fields?._id ? [{
                                type: 'simple-text',
                                value: data.fqdn,
                                tooltip: 'You cannot modify this data',
                            }] : [{
                                type: 'input',
                                placeholder: 'Please fill FQDN',
                                value: data.fqdn,
                                // InputProps: {
                                //     readOnly: props.fields && props.fields._id,
                                // },
                                // fastChange: true,
                                onChange: onChange('fqdn')
                            }]}
                            template={template}
                    />
                    <SetupItem
                            label={'Users Quantity'}
                            input={[{
                                type: 'simple-text',
                                value: data.users ? data.users : 'No data',
                                ...(!data.users ? {
                                    tooltip: 'You cannot modify this data',
                                } : {}),
                                // placeholder: '',
                                // onChange: onChange('users')
                            }]}
                            template={template}
                    />
                    <SetupItem
                            label={'License Expiration Date'}
                            input={data.licenseExpirationDate ? [{
                                type: 'date-view',
                                slug: 'licenseExpirationDate',
                                className: `middle`,
                                value: data.licenseExpirationDate,
                                Wrapper: License,
                            }] : [{
                                type: 'simple-text',
                                value: 'No data',
                                tooltip: 'You cannot modify this data',
                            }]}
                            template={template}
                    />
                    <SetupItem
                            label={'Company website'}
                            input={[{
                                type: 'input',
                                placeholder: 'Please fill company website url',
                                value: data.companyUrl,
                                onChange: onChange('companyUrl')
                            }]}
                            template={template}
                    />
                    <SetupItem
                            label={'Description'}
                            input={[{
                                type: 'text',
                                rows: 3,
                                placeholder: 'Please fill Description',
                                value: data.description,
                                onChange: onChange('description')
                            }]}
                            template={template}
                    />
                </div>
                <h1 className={stl.title}>Administrator contacts</h1>
                <div className={stl.box}>
                    <SetupItem
                            label={'Name'}
                            input={[{
                                type: 'input',
                                placeholder: 'Please fill admin name',
                                value: data.admin.name,
                                onChange: onAdminChange('name')
                            }]}
                            template={template}
                    />
                    <SetupItem
                            label={'Email'}
                            input={[{
                                type: 'input',
                                placeholder: 'Please fill admin email',
                                value: data.admin.email,
                                onChange: onAdminChange('email')
                            }]}
                            template={template}
                    />
                    <SetupItem
                            label={'Phone Number'}
                            input={[{
                                type: 'input',
                                placeholder: 'Please fill admin phone number',
                                value: data.admin.phone,
                                onChange: onAdminChange('phone')
                            }]}
                            template={template}
                    />
                </div>
                <div className={stl.row}>
                    <Button
                            onClick={() => {
                                const next = {
                                    ...data,
                                    admin: {...data.admin, companyUrl: data.companyUrl}
                                };
                                // console.log('SEND DATA', next)

                                if (!next.fqdn) {
                                    dispatch(showMessage({
                                        message: !next.fqdn ? 'Please add "fqdn" field' : 'Please fix "fqdn" field',
                                        type: MESSAGE_ERROR,
                                    }));
                                } else if (!next.companyName) {
                                    dispatch(showMessage({
                                        message: 'Please add Company Name field',
                                        type: MESSAGE_ERROR,
                                    }));
                                } else {

                                    if (props.fields && props.fields._id) {
                                        // @ts-ignore
                                        dispatch(customersUpdateData(props.fields._id, next));
                                        dispatch(hideModal());
                                        // console.log('NEXT', next)
                                    } else {

                                        if (isInCustomerGrid(grid, {
                                            fqdn: next.fqdn,
                                            // companyName: next.companyName
                                        })) {
                                            dispatch(showMessage({
                                                message: '"fqdn" field should be unique',
                                                type: MESSAGE_ERROR,
                                            }));
                                        } else {
                                            dispatch(hideModal());
                                            // @ts-ignore
                                            dispatch(customersAddData(next));
                                            // console.log('NEXT', next, grid)
                                        }
                                    }


                                }

                            }}
                    >
                        {props.fields && props.fields._id
                                ? <><Icon view={Icons.ICON_SAVE}/>{'Save'}</>
                                : <><Icon view={Icons.ICON_ADD}/>{'Add'}</>
                        }
                    </Button>
                    <Button onClick={() => dispatch(hideModal())}>Cancel</Button>
                </div>
            </div>
    );
};

export default EditCustomer;
