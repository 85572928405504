import React, {useState, useEffect, useCallback} from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {useDispatch} from "react-redux";
import {SetupItem, Button} from "@gtb";
import Icon, * as Icons from '@gtb/components/Icons';
import Page from 'apps/auth/common/components/AuthPage';
import {authorize} from "store/actions";
import {Navigate} from "react-router-dom";
import Message from 'apps/auth/common/components/Message';
import {CN} from '@gtb/utils';

const useStyles = makeStyles(({palette, spacing, typography, shadows}: CustomTheme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    fields: {
        width: '100%',
        position: "relative",
        overflow: "hidden",
        borderRadius: '1rem',
        margin: spacing(4, 0, 3),
        '& input': {
            height: '2.4em'
        }
    },
    label: {
        opacity: 0.7,
        display: 'flex',
        alignItems: "center",
        fontSize: '1.8rem',
        fontWeight: 400,
        '& svg': {
            height: spacing(3),
            margin: spacing(0, 2, 0, 1)
        }
    },
    signIn: {},
    capsLockMessage: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...typography.p,
        fontSize: '1.8rem',
        color: palette.action.active5,
    },
    capsLockWrapper: {
        width: '100%',
        minHeight: spacing(7),
        marginTop: spacing(-4),
        marginBottom: spacing(-3),
        display: 'none',
    },
    active: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

interface LoginInterface {
    [x: string]: any
}


const template = {
    root: {
        width: '100%',
    },
    label: {
        width: 220,
    },
}

const EVENT_KEY_DOWN = 'keydown';

export const Login: React.FC<LoginInterface> = (props) => {
    const stl = useStyles(props);
    // const { ... } = props;

    // @ts-ignore
    // const {email, password} = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const [message, setMessage] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);


    const [isCapsLockActive, setIsCapsLockActive] = useState(false);

    const wasCapsLockActivated = useCallback(event => {
        if (
                event.getModifierState &&
                event.getModifierState('CapsLock') &&
                isCapsLockActive === false
        ) {
            setIsCapsLockActive(true);
        } else {
            setIsCapsLockActive(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener(EVENT_KEY_DOWN, wasCapsLockActivated);
        return () => {
            document.removeEventListener(EVENT_KEY_DOWN, wasCapsLockActivated);
        };
    }, []);

    const onSubmit = () => {
        setMessage('');
        // @ts-ignore
        dispatch(authorize({
            options: {
                login: login,
                password: password
            },
            onError: (err: any) => {
                setMessage(err.message);
            },
            onSuccess: () => {
                setLoggedIn(true);
            }
        }));
    }

    return (
            <Page title={<span><b>GTB Multi-Tenant</b> Central Console</span>}>
                {loggedIn ? <Navigate to="/customers"/> : null}
                <form className={stl.root} onSubmit={(event) => {
                    event.preventDefault();
                    onSubmit()
                }}>
                    <Message message={message}/>
                    <div className={stl.fields}>
                        <SetupItem
                                label={<div className={stl.label}><Icon view={Icons.ICON_USERS}/>{'Username'}</div>}
                                input={[{
                                    type: 'input',
                                    value: login,
                                    autoFocus: true,
                                    fastChange: true,
                                    onChange: (v: any) => {
                                        setLogin(v.target.value);
                                    }
                                }]}
                                template={template}
                        />
                        <SetupItem
                                label={<div className={stl.label}><Icon view={Icons.ICON_PASSWORD}/>{'Password'}</div>}
                                input={[{
                                    type: 'password',
                                    value: password,
                                    fastChange: true,
                                    onChange: (v: any) => {
                                        setPassword(v.target.value);
                                    }
                                }]}
                                template={template}
                        />
                    </div>
                    <Button
                            className={'active'}
                            classes={{root: stl.signIn}}
                            type={'submit'}
                            onClick={onSubmit}
                    >
                        <Icon view={Icons.ICON_LOGIN_V1}/>
                        {'Sign in'}
                    </Button>
                    <div className={CN(stl.capsLockWrapper, {[stl.active]: isCapsLockActive})}>
                        <div className={stl.capsLockMessage}>
                            {'Warning. Caps Lock is turned on!'}
                        </div>
                    </div>
                </form>
            </Page>
    );
};

export default Login;
