import React, {useEffect} from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {Button, Preloader} from "@gtb";
import Icon, * as Icons from '@gtb/components/Icons';
import {IconViewProps} from '@gtb/interfaces'

const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    root: {
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        borderRadius: '0.7rem',
        overflow: 'hidden',
    },
    action: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: spacing(3),
    },
    cancel: {},
    save: {
        marginRight: spacing(3),
    },
}));

interface SetupFormProps extends React.PropsWithChildren<any> {
    isLoading?: boolean,
    disabledSubmit?: boolean,
    onSubmit?: () => any,
    onCancel?: () => any,
    refreshData?: () => any,
    additionalConfig?: {
        submitIcon?: IconViewProps,
        submitLabel?: string | JSX.Element,
    }
}

const SetupForm: React.FC<SetupFormProps> = (props) => {
    const classes = useStyles(props);
    const {
        isLoading = false,
        disabledSubmit = false,
        onSubmit,
        onCancel,
        refreshData,
        additionalConfig = {},
        children
    } = props;

    useEffect(() => {
        if (typeof refreshData === 'function') {
            refreshData();
        }
    }, []);

    const config = {...additionalConfig};

    return (
            <div className={classes.root}>
                <Preloader isLoading={isLoading}>
                    <div className={classes.content}>
                        {children}
                    </div>
                    {(onSubmit || onCancel) ? (
                            <div className={classes.action}>
                                {onSubmit ? (
                                        <Button
                                                classes={{root: classes.save}}
                                                disabled={disabledSubmit}
                                                onClick={() => {
                                                    onSubmit();
                                                }
                                                }>
                                            <Icon view={config.submitIcon ? config.submitIcon : Icons.ICON_SAVE}/>
                                            {config.submitLabel ? config.submitLabel : 'Save'}
                                        </Button>
                                ) : null}
                                {(onCancel || refreshData) ? (
                                        <Button
                                                classes={{root: classes.cancel}}
                                                onClick={() => {
                                                    if (onCancel) {
                                                        onCancel();
                                                    } else if (refreshData) {
                                                        refreshData();
                                                    }
                                                }}
                                        >
                                            {'Cancel'}
                                        </Button>
                                ) : null}
                            </div>
                    ) : null}
                </Preloader>
            </div>
    );
};

export default SetupForm;
