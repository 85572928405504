//Actions
export const ICON_TEST_CONNECTION = [
    {path: "M23.7,0.3c-0.4-0.4-1-0.4-1.4,0l-1,1C20.9,1.1,20.5,1,20,1h-4.2c-0.8,0-1.5,0.3-2.1,0.9l-2.4,2.4c-0.4,0.4-0.4,1,0,1.4l7,7c0.2,0.2,0.4,0.3,0.7,0.1s0.5-0.1,0.7-0.3l2.4-2.4C22.7,9.7,23,9,23,8.2V4c0-0.5-0.1-0.9-0.3-1.3l1-1C24.1,1.3,24.1,0.7,23.7,0.3z M21,8.2c0,0.3-0.1,0.5-0.3,0.7L19,10.6L13.4,5l1.7-1.7C15.3,3.1,15.5,3,15.8,3H20c0.6,0,1,0.4,1,1V8.2z"},
	{primary: true, path: "M11,14.6l2.3-2.3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L12.4,16 M9.4,13L9.4,13l2.3-2.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L8,11.6 M12.4,16l1.3,1.3c0.4,0.4,0.4,1,0,1.4l-2.4,2.4C10.7,21.7,10,22,9.2,22H5c-0.5,0-0.9-0.1-1.3-0.3l0,0l-2,2C1.5,23.9,1.3,24,1,24s-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l2-2l0,0C2.1,19.9,2,19.5,2,19v-4.2c0-0.8,0.3-1.5,0.9-2.1l2.4-2.4c0.4-0.4,1-0.4,1.4,0L8,11.6 M9.4,13l1.6,1.6 M11.6,18L6,12.4l-1.7,1.7C4.1,14.3,4,14.5,4,14.8V19c0,0.6,0.4,1,1,1h4.2c0.3,0,0.5-0.1,0.7-0.3L11.6,18z"},
];
export const ICON_CONNECTED = [
	{primary: true, path: "M4.7,20.7l-3,3C1.5,23.9,1.3,24,1,24s-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l3-3 M3.3,19.3C3.1,18.9,3,18.5,3,18v-4.2c0-0.8,0.3-1.5,0.9-2.1l2.4-2.4c0.4-0.4,1-0.4,1.4,0l7,7c0.4,0.4,0.4,1,0,1.4l-2.4,2.4C11.7,20.7,11,21,10.2,21H6c-0.5,0-0.9-0.1-1.3-0.3 M12.6,17L7,11.4l-1.7,1.7C5.1,13.3,5,13.5,5,13.8V18c0,0.6,0.4,1,1,1h4.2c0.3,0,0.5-0.1,0.7-0.3L12.6,17z"},
	{path: "M19.3,3.3C18.9,3.1,18.5,3,18,3h-4.2c-0.8,0-1.5,0.3-2.1,0.9L9.3,6.3c-0.4,0.4-0.4,1,0,1.4l7,7c0.2,0.2,0.5,0.3,0.7,0.1s0.5-0.1,0.7-0.3l2.4-2.4c0.6-0.6,0.9-1.3,0.9-2.1V6c0-0.5-0.1-0.9-0.3-1.3 M19,10.2c0,0.3-0.1,0.5-0.3,0.7L17,12.6L11.4,7l1.7-1.7C13.3,5.1,13.5,5,13.8,5H18c0.6,0,1,0.4,1,1V10.2z M19.3,3.3l3-3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-3,3"},
];
export const ICON_CONNECTION_ERROR = [
	{color: '#FF3333', path: "M23.7,21l-5-8c-0.4-0.5-1-0.9-1.7-0.9s-1.3,0.3-1.7,0.9l-5,8c-0.4,0.6-0.5,1.4-0.1,2s1.1,1,1.8,1h10c0.7,0,1.3-0.4,1.8-1C24.1,22.4,24.1,21.6,23.7,21z M17,22c-0.6,0-1-0.4-1-1s0.5-1,1-1c0.6,0,1,0.4,1,1S17.6,22,17,22z M18,18c0,0.6-0.4,1-1,1s-1-0.4-1-1v-3c0-0.6,0.4-1,1-1s1,0.4,1,1V18z"},
	{primary: true, path: "M11.7,9.3c-0.4-0.4-1-0.4-1.4,0L8,11.6l-1.3-1.3c-0.4-0.4-1-0.4-1.4,0l-2.4,2.4C2.3,13.3,2,14,2,14.8V19c0,0.5,0.1,0.9,0.3,1.3l-2,2c-0.4,0.4-0.4,1,0,1.4C0.5,23.9,0.7,24,1,24s0.5-0.1,0.7-0.3l2-2C4.1,21.9,4.5,22,5,22h2c0.6,0,1-0.4,1-1s-0.4-1-1-1H5c-0.6,0-1-0.4-1-1v-4.2c0-0.3,0.1-0.5,0.3-0.7L6,12.4l3.3,3.3c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4L9.4,13l2.3-2.3C12.1,10.3,12.1,9.7,11.7,9.3z"},
	{path: "M23.7,0.3c-0.4-0.4-1-0.4-1.4,0l-1,1C20.9,1.1,20.5,1,20,1h-4.2c-0.8,0-1.5,0.3-2.1,0.9l-2.4,2.4C11.1,4.5,11,4.7,11,5s0.1,0.5,0.3,0.7l4,4c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4L13.4,5l1.7-1.7C15.3,3.1,15.5,3,15.8,3H20c0.6,0,1,0.4,1,1v4.2c0,0.3-0.1,0.5-0.3,0.7l-0.4,0.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.1s0.5-0.1,0.7-0.3l0.4-0.4C22.7,9.7,23,9,23,8.2V4c0-0.5-0.1-0.9-0.3-1.3l1-1C24.1,1.3,24.1,0.7,23.7,0.3z"},
];
export const ICON_REFRESH_LDAP = [
	{path: "M22,17l-3.3,0.6c-0.1,0-0.2,0-0.3,0.1c-0.2,0.2-0.2,0.5,0,0.7l0.7,0.7C15.2,23,8.8,23,4.9,19.1c-2.4-2.5-3.4-5.8-2.7-9.2c0.1-0.5-0.3-1.1-0.8-1.2C0.9,8.6,0.3,8.9,0.2,9.5c-0.8,4,0.4,8,3.3,11C5.8,22.8,8.9,24,12,24s6.2-1.2,8.5-3.5l0.7,0.7c0.2,0.1,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.5-0.4l0.6-3.3C22.6,17.2,22.3,17,22,17z"},
	{primary: true, path: "M12.1,11.1c-2.8,0-5,1.6-5,3.5s2.2,3.5,5,3.5s5-1.6,5-3.5S14.9,11.1,12.1,11.1z M12.1,6.1c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S11,6.1,12.1,6.1z M20.5,3.5c-4.7-4.7-12.3-4.7-17,0L2.8,2.8C2.7,2.7,2.6,2.7,2.4,2.7C2.1,2.7,2,3,2,3.1L1.5,6.4v0.1C1.5,6.8,1.7,7,2,7l3.3-0.6c0.3-0.1,0.1,0,0.3-0.1c0.2-0.1,0.2-0.5,0-0.7L4.9,4.9C8.8,1,15.2,1,19.1,4.9c2.4,2.5,3.4,5.8,2.7,9.2c-0.1,0.5,0.3,1.1,0.8,1.2c0.1,0,0.1,0,0.2,0c0.5,0,0.9-0.3,1-0.8C24.5,10.5,23.4,6.5,20.5,3.5z"},
];
export const ICON_ADD_CREATE_LDAP = [
	{primary: true, path: "M18,12c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6S14.6,12,18,12z M18.9,17v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v2h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2h2c0.6,0,1-0.4,1-1s-0.4-1-1-1H18.9z"},
	{path: "M9,20H3c-1.7,0-3-1.3-3-3V3c0-1.7,1.3-3,3-3h18c1.7,0,3,1.3,3,3v7c0,0.6-0.4,1-1,1s-1-0.4-1-1V3c0-0.6-0.4-1-1-1H3C2.4,2,2,2.4,2,3v14c0,0.6,0.4,1,1,1h6c0.6,0,1,0.4,1,1S9.6,20,9,20z M7.5,8C6.8,8,6,7.3,6,6.5S6.8,5,7.5,5S9,5.8,9,6.5C9,7.4,8.3,8,7.5,8z M7.5,15c-1.8,0.1-3.4-1.2-3.5-3c0.1-1.8,1.7-3.1,3.5-3c1.8-0.1,3.4,1.2,3.5,3C10.8,13.8,9.3,15.1,7.5,15z M14.5,6h-1C13.3,6,13,5.8,13,5.5S13.3,5,13.5,5h1C14.8,5,15,5.3,15,5.5C15,5.8,14.8,6,14.5,6z M19.5,6h-3C16.3,6,16,5.8,16,5.5S16.3,5,16.5,5h3C19.8,5,20,5.3,20,5.5C20,5.8,19.8,6,19.5,6z M19.5,8h-6C13.3,8,13,7.8,13,7.5S13.3,7,13.5,7h6C19.8,7,20,7.3,20,7.5C20,7.8,19.8,8,19.5,8z M19.5,10h-6C13.3,10,13,9.8,13,9.5S13.3,9,13.5,9h6C19.8,9,20,9.3,20,9.5C20,9.8,19.8,10,19.5,10z"},
];
export const ICON_INFORMATION_1 = [
	{path: "M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12C2,6.4,6.4,2,12,2 M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12C23.9,5.3,18.6,0,12,0z"},
	{primary: true, path: "M12,18c-0.6,0-1-0.4-1-1v-6c0-0.6,0.4-1,1-1s1,0.4,1,1v6C13,17.6,12.6,18,12,18z M11,7c0,0.6,0.4,1,1,1s1-0.4,1-1s-0.4-1-1-1S11,6.4,11,7z"},
];
export const ICON_INFORMATION_2 = [
	{color: '#FF3333', path: "M12.1,2c5.5,0,10,4.5,10,10s-4.5,10-10,10s-10-4.5-10-10C2.1,6.5,6.4,2,12.1,2 M12.1,0c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12C23.9,5.4,18.6,0,12.1,0z M11,17c0-0.6,0.4-1,1-1s1,0.4,1,1s-0.4,1-1,1S11,17.6,11,17z M13,13.1L13.2,7c0-0.3-0.1-0.5-0.3-0.7C12.8,6.1,12.5,6,12.3,6h-0.5c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.5-0.3,0.7l0.3,6.1c0,0.5,0.5,1,1,1S13,13.6,13,13.1z"},
];
export const ICON_EDIT_OCR_SERVER = [
	{primary: true, path: "M16,24h-2c-0.6,0-1-0.4-1-1v-2c0-0.3,0.1-0.5,0.3-0.7l7-7c0.4-0.4,1-0.4,1.4,0l2,2c0.4,0.4,0.4,1,0,1.4l-7,7C16.5,23.9,16.3,24,16,24z M15,22h0.6l6-6L21,15.4l-6,6V22z"},
	{path: "M11,17c-3.4,0-6-2.6-6-6s2.6-6,6-6s6,2.6,6,6S14.4,17,11,17z M11,7c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S13.2,7,11,7z M2,6V3c0-0.6,0.4-1,1-1h3c0.6,0,1-0.4,1-1S6.6,0,6,0H3C1.3,0,0,1.3,0,3v3c0,0.6,0.4,1,1,1S2,6.6,2,6z M22,6V3c0-1.7-1.3-3-3-3h-3c-0.6,0-1,0.4-1,1s0.4,1,1,1h3c0.6,0,1,0.4,1,1v3c0,0.6,0.4,1,1,1S22,6.6,22,6z M7,21c0-0.6-0.4-1-1-1H3c-0.6,0-1-0.4-1-1v-3c0-0.6-0.4-1-1-1s-1,0.4-1,1v3c0,1.7,1.3,3,3,3h3C6.6,22,7,21.6,7,21z M11,8c1.7,0,3,1.3,3,3s-1.3,3-3,3	s-3-1.3-3-3C8,9.3,9.3,8,11,8z"},
];
export const ICON_CREATE_COMPLIANCE = [
	{path: "M18,12c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6C12,14.6,14.6,12,18,12z M18.9,17v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v2h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2h2c0.6,0,1-0.4,1-1s-0.4-1-1-1H18.9z"},
	{primary: true, path: "M5,15c-0.6,0-1,0.4-1,1s0.4,1,1,1h4c0.6,0,1-0.4,1-1s-0.4-1-1-1H5z M10,12H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h5c0.6,0,1-0.4,1-1S10.6,12,10,12z M13,9H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h8c0.6,0,1-0.4,1-1S13.6,9,13,9z M4,2v5l2-1l2,1V2 M8,2h7c0.6,0,1,0.4,1,1v6c0,0.6,0.4,1,1,1s1-0.4,1-1V3c0-1.7-1.3-3-3-3H3C1.3,0,0,1.3,0,3v16c0,1.7,1.3,3,3,3h6c0.6,0,1-0.4,1-1s-0.4-1-1-1H3c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h1"},
];
export const ICON_EDIT_COMPLIANCE = [
	{path: "M5,15c-0.6,0-1,0.4-1,1s0.4,1,1,1h4c0.6,0,1-0.4,1-1s-0.4-1-1-1H5z M10,12H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h5c0.6,0,1-0.4,1-1S10.6,12,10,12z M13,9H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h8c0.6,0,1-0.4,1-1S13.6,9,13,9z M4,2v5l2-1l2,1V2 M8,2h7c0.6,0,1,0.4,1,1v10c0,0.6,0.4,1,1,1s1-0.4,1-1V3c0-1.7-1.3-3-3-3H3C1.3,0,0,1.3,0,3v16c0,1.7,1.3,3,3,3h6c0.6,0,1-0.4,1-1s-0.4-1-1-1H3c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h1"},
	{primary: true, path: "M16,24h-2c-0.6,0-1-0.4-1-1v-2c0-0.3,0.1-0.5,0.3-0.7l7-7c0.4-0.4,1-0.4,1.4,0l2,2c0.4,0.4,0.4,1,0,1.4l-7,7C16.5,23.9,16.3,24,16,24z M15,22h0.6l6-6L21,15.4l-6,6V22z"},
];
export const ICON_ADD_CLOUD_ACCOUNT = [
	{path: "M23,11c-0.6,0-1-0.4-1-1V9.4c0-1.5-1.1-2.6-2.5-2.6c-0.1,0-0.1,0-0.2,0l0,0c-0.5,0.1-1.1-0.3-1.1-0.8c-0.3-2.3-2.2-4-4.4-4c-1.6,0-3.2,1-3.9,2.4C9.6,4.9,9,5.1,8.6,4.9C8.1,4.6,7.9,4,8.1,3.6C9.2,1.4,11.4,0,13.8,0c2.9,0,5.4,2,6.2,4.8c2.3,0.3,4,2.2,4,4.6V10C24,10.6,23.6,11,23,11z M4.9,12.1L4.9,12.1c0.8-1.9,2.6-3.2,4.7-3.2c1.4,0,2.8,0.6,3.8,1.7c0.4,0.4,1,0.4,1.4,0.1c0.4-0.4,0.4-1,0.1-1.4C13.5,7.8,11.7,7,9.7,7c-2.8,0-5.4,1.6-6.5,4 M4.9,12.1c-0.1,0.2-0.3,0.5-0.6,0.6C2.9,13.2,2,14.6,2,16.1c0,2.1,1.7,3.8,3.8,3.8h3.3c0.6,0,1,0.4,1,1s-0.4,1-1,1H5.8c-3.2,0-5.8-2.6-5.8-5.8c0-2.2,1.3-4.2,3.2-5.1"},
	{primary: true, path: "M18,12c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6S14.6,12,18,12z M18.9,17v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v2h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2h2c0.6,0,1-0.4,1-1s-0.4-1-1-1H18.9z"},
];
export const ICON_DEPLOY_ALL = [
	{color: '#FF7F00', path: "M5.3,22.2c-0.8,0-1.6-0.1-2.5-0.2c-0.4-0.1-0.7-0.4-0.8-0.8c-0.3-1.8-0.3-3.6,0.1-5.3c0.3-0.9,0.6-1.6,1.3-2.3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4s-0.5,0.6-0.7,1.2l0,0c-0.3,1.2-0.4,2.6-0.2,3.9c1.3,0.2,2.7,0.1,3.9-0.2l0,0c0.4-0.1,0.8-0.3,1.2-0.7c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4c-0.7,0.7-1.5,1.1-2.3,1.3C7.3,22.1,6.3,22.2,5.3,22.2z"},
	{path: "M22.8,2c-0.1-0.4-0.1-0.6-0.8-0.8c-3.1-0.5-7.1-0.6-10.8,3.1c-0.4,0.4-0.7,0.8-1.1,1.1l-3-0.6c-0.3-0.1-0.7,0-0.9,0.3L1.3,10c-0.3,0.3-0.4,0.7-0.2,1s0.4,0.6,0.8,0.7l4.5,0.6c-0.2,1.5,0.3,2.9,1.3,4c0.9,1,2,1.4,3.3,1.5l0,0c0.2,0,0.4,0,0.6,0l0,0l0.6,4.4c0.1,0.4,0.3,0.7,0.7,0.8c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.5-0.1,0.7-0.3l4.9-4.9c0.2-0.2,0.3-0.6,0.3-0.9l-0.6-3l0,0c0.4-0.4,0.8-0.7,1.1-1.1C23.4,9.1,23.3,5.1,22.8,2z M6.9,10.4L4.2,10l3.1-3.1l1.4,0.3C7.9,8.3,7.3,9.4,6.9,10.4z M11.1,15.7c-0.8,0-1.4-0.3-1.9-0.8c-2.4-2.5,1.1-6.8,3.5-9.2C14.5,3.8,16.5,3,19.2,3c0.6,0,1.2,0,1.8,0.1c0.4,3.6-0.3,6-2.6,8.3C15.6,14.2,12.9,15.7,11.1,15.7z M14.1,19.9l-0.4-2.7c1.1-0.4,2.2-1.1,3.2-1.9l0,0l0,0l0.3,1.5L14.1,19.9z M18.3,5.8c-1.2-1.2-3.1-1.2-4.2,0c-0.6,0.6-0.9,1.3-0.9,2.1S13.5,9.4,14,10c0.6,0.6,1.3,0.9,2.1,0.9c0.8,0,1.5-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1S18.8,6.4,18.3,5.8L18.3,5.8z M16.8,8.6c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4c0.2-0.2,0.4-0.3,0.7-0.3c0.2,0,0.5,0.1,0.7,0.3l0,0c0.2,0.2,0.3,0.4,0.3,0.7S17,8.4,16.8,8.6z"},
];
export const ICON_EDIT_RULE = [
	{primary: true, path: "M16,24h-2c-0.6,0-1-0.4-1-1v-2c0-0.3,0.1-0.5,0.3-0.7l7-7c0.4-0.4,1-0.4,1.4,0l2,2c0.4,0.4,0.4,1,0,1.4l-7,7C16.5,23.9,16.3,24,16,24z M15,22h0.6l6-6L21,15.4l-6,6V22z"},
	{path: "M13,11H8c-0.6,0-1-0.4-1-1s0.4-1,1-1h5c0.6,0,1,0.4,1,1S13.6,11,13,11z M14,6c0-0.6-0.4-1-1-1H5C4.4,5,4,5.4,4,6s0.4,1,1,1h8C13.6,7,14,6.6,14,6z M14,13c0-0.6-0.4-1-1-1H8c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h5C13.6,14,14,13.6,14,13z M11,16c0-0.6-0.4-1-1-1H8c-0.6,0-1,0.4-1,1s0.4,1,1,1h2C10.6,17,11,16.6,11,16z M11,21c0-0.6-0.4-1-1-1H3c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h12c0.6,0,1,0.4,1,1v10c0,0.6,0.4,1,1,1s1-0.4,1-1V3c0-1.7-1.3-3-3-3H3C1.3,0,0,1.3,0,3v16c0,1.7,1.3,3,3,3h7C10.6,22,11,21.6,11,21z M4,10c0,0.6,0.4,1,1,1s1-0.4,1-1S5.6,9,5,9S4,9.4,4,10z M4,13c0,0.6,0.4,1,1,1s1-0.4,1-1s-0.4-1-1-1S4,12.4,4,13z M4,16c0,0.6,0.4,1,1,1s1-0.4,1-1s-0.4-1-1-1S4,15.4,4,16z"},
];
export const ICON_REFRESH_AD_ODJECTS = [
	{color: '#7ACC29', path: "M24,19.6c-0.1-0.1-0.2-0.2-0.3-0.2l-3.4-0.5c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.2-0.1,0.3,0,0.5l0.6,1.2c-1.2,0.8-4.2,0.6-4.9-1.9c-0.2-0.5-0.7-0.7-1.2-0.6c-0.5,0.2-0.7,0.7-0.6,1.2c0.1,0.3,0.2,0.6,0.4,1c0.7,1.3,2,2.5,4.6,2.7c0.8,0,1.7-0.2,2.4-0.6l0.1,0.1l0.6,1.1c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.3l1.2-3.3C24.1,19.7,24,19.7,24,19.6z M13.6,15.7c0.1,0.1,0.2,0.2,0.3,0.2l3.4,0.5c0.2,0,0.3-0.1,0.4-0.2c0.1-0.2,0.1-0.3,0-0.5l-0.6-1.2c1.2-0.8,4.2-0.6,4.9,1.9c0.2,0.5,0.7,0.7,1.2,0.6c0.5-0.2,0.7-0.7,0.6-1.2c-0.1-0.3-0.2-0.6-0.4-1c-0.7-1.3-2-2.5-4.6-2.7c-0.8,0-1.7,0.2-2.4,0.6l-0.2,0.2l-0.6-1.1c-0.1-0.1-0.2-0.2-0.4-0.2s-0.3,0.1-0.4,0.3l-1.2,3.3C13.5,15.6,13.6,15.6,13.6,15.7z"},
	{path: "M3,8.5h8c0.6,0,1-0.4,1-1s-0.4-1-1-1H3c-1.7,0-3,1.3-3,3v7c0,1.7,1.3,3,3,3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1H3c-0.6,0-1-0.4-1-1v-7C2,8.9,2.4,8.5,3,8.5z M11,20.5H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h6c0.6,0,1-0.4,1-1S11.5,20.5,11,20.5z M16,3.6c0-0.6,0.5-1.1,1.1-1.1h3.7c0.6,0,1.1,0.5,1.1,1.1v6.9c0,0.6,0.4,1,1,1l0,0c0.6,0,1-0.4,1-1V3.6c0-1.7-1.4-3.1-3.1-3.1h-3.7c-1.7,0-3.1,1.4-3.1,3.1v5.9c0,0.6,0.4,1,1,1l0,0c0.6,0,1-0.4,1-1V3.6z"},
];