import React from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {lighten} from '@mui/material/styles';
import {Preloader} from "@gtb";
import _ from 'lodash';

const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    root: {
        width: '100%',
        marginTop: spacing(3),
        backgroundColor: palette.common.white,
        borderRadius: '0.7rem 0.7rem 0 0',
    },
    title: {
        ...typography.mainTabsMenu,
        display: 'flex',
        height: spacing(7),
        padding: spacing(1.844444, 3, 2.16666),
        borderRadius: '0.7rem 0.7rem 0 0',
        backgroundColor: lighten(palette.background.main, 0.5),
    },
    tr: {
        display: 'flex',
        justifyContent: 'space-between',
        height: spacing(7),
        borderRadius: '0.7rem 0.7rem 0 0',
        backgroundColor: lighten(palette.background.main, 0.5),
    },
    td: {
        ...typography.mainTabsMenu,
        borderLeftWidth: 1,
        borderColor: palette.background.main,
        padding: spacing(1.844444, 3, 2.16666),
        '&:first-child': {
            borderLeftWidth: 0,
        },
    },
}));

interface SetupFormProps {
    title?: string | JSX.Element,
    isLoading?: boolean,
}

const SetupList: React.FC<React.PropsWithChildren<SetupFormProps>> = ({
    title,
    isLoading,
    children,
    ...rest
}) => {
    const classes = useStyles();

    let Header;
    if (_.isArray(title)) {
        Header = (
                <div className={classes.tr}>
                    {title.map((e, ek) => (
                            <div
                                    key={'GtbSetupList_item_' + ek}
                                    className={classes.td}
                                    style={e.style}
                            >
                                {e.text}
                            </div>
                    ))}
                </div>
        );
    } else {
        Header = <div className={classes.title}>{title}</div>;
    }

    return (
            <div className={classes.root}>
                {Header}
                <Preloader isLoading={isLoading!}>
                    {/*TODO: Implement NestedList*/}
                    {/*<NestedList*/}
                    {/*        {...rest}*/}
                    {/*/>*/}
                </Preloader>
            </div>
    );
};

export default SetupList;
