import React from 'react';
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import {CN} from '@gtb/utils';

const useStyles = makeStyles(({palette, spacing}: CustomTheme) => ({
    root: {},
    html: {
        maxHeight: 'inherit',
        overflowY: 'auto',
    },
    filled: {
        padding: spacing(1, 1.5),
        background: palette.common.white,
        borderRadius: '0.7rem',
    },
}));

const LegendItem: React.FC<{ [x: string]: any }> = (props) => {

    const stl = useStyles(props);

    const {
        classes,
        filled,
        content
    } = props;


    return (
            <div className={stl.root}>
                <div
                        className={CN(stl.html, {[stl.filled]: filled})}
                        dangerouslySetInnerHTML={{__html: '<div>' + content + '</div>'}}
                />
            </div>
    );
}

export default LegendItem;
