import React from 'react';
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import Icon, * as Icons from '@gtb/components/Icons';
import {normalizeUrl} from "@gtb/utils";
import {useDispatch, useSelector} from "react-redux";
import {MESSAGE_ERROR, showMessage, showModal} from "../../../../store/actions";
import EditLicensing from "../toolbar/EditLicensing";
import Contacts from './Contacts';

const useStyles = makeStyles(({palette, spacing}: CustomTheme) => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    link: {
        color: palette.action.active,
        textDecoration: 'none!important',
        paddingTop: spacing(2.16666),
        paddingBottom: spacing(1.8444444),
        '&:hover': {
            textDecoration: 'underline!important',
        }
    },
    icon: {
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        marginLeft: 'auto',
    },
    title: {},
}));

interface LinkInterface {
    url?: string,

    [x: string]: any
}

const Link: React.FC<LinkInterface> = (props) => {
    const stl = useStyles(props);
    const {
        classes,
        row,
    } = props;

    // @ts-ignore
    // const {data: smtp} = useSelector((state) => state.settings);
    const dispatch = useDispatch();

    return (
            <div className={stl.root}>
                {row.companyUrl ? (
                        <a className={stl.link} target={'_blank'} href={normalizeUrl(row.companyUrl)}>
                            {row.companyUrl}
                        </a>
                ) : null}
                <div
                        className={stl.icon}
                        onClick={() => {
                            if (row && row._id) {
                                dispatch(showModal({
                                    width: '67rem',
                                    height: 'auto',
                                    maxHeight: 'auto',
                                    title: <div className={stl.title}>{'Administrator contacts'}</div>,
                                    content: <Contacts fields={row}/>,
                                }));
                            } else {
                                dispatch(showMessage({
                                    message: 'Something goes wrong',
                                    type: MESSAGE_ERROR,
                                }));
                            }
                        }}
                >
                    <Icon view={Icons.ICON_USER_JUSTIFICATION}/>
                </div>
            </div>
    );
}

export default Link;
