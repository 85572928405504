import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {  USER_SUCCESS_GET_DATA } from 'store/actions';

const initialState = {
	'user': {
		'_id': '',
		'login': '',
	},
	'revision': {
		'manager': '',
		'system': '',
	},
};

const settingsConfig = {
	key: 'mt_management_user_data',
	storage,
};

const auth = function (state = initialState, action: { [x: string]: any } = {}) {
	switch (action.type) {
		case USER_SUCCESS_GET_DATA: {
			return { ...state, ...action.data };
		}

		default: {
			return state;
		}
	}
};

export default persistReducer(settingsConfig, auth);
