import React, {Component} from 'react';
import Scroll from './Scroll';

class GtbScrollWrapper extends Component {

    render() {
        const {
            maxHeight,
            maxWidth,
            style,
            children,
            ...rest
        } = this.props;

        return (maxHeight || maxWidth || style)
                ? (
                        <Scroll
                                style={{
                                    maxHeight: maxHeight ? maxHeight : 'inherit',
                                    maxWidth: maxWidth ? maxWidth : 'inherit',
                                    ...style,
                                }}
                                {...rest}
                        >
                            {children}
                        </Scroll>
                )
                : children;
    }
};

export default GtbScrollWrapper;
