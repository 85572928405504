import {
	BACKUP_ERROR_DOWNLOAD,
	BACKUP_ERROR_START,
	BACKUP_START, BACKUP_SUCCESS_DOWNLOAD,
	BACKUP_SUCCESS_GET_STATUS,
} from 'store/actions/backup';

const initialState = {
	inProgress: '',
	data: [],
};

const backup = function (state = initialState, action: { [x: string]: any } = {}) {
	switch (action.type) {
		case BACKUP_START: {
			return { ...state, inProgress: true };
		}
		case BACKUP_SUCCESS_GET_STATUS: {
			return { ...state, inProgress: action.data._id, data: [action.data] };
		}
		case BACKUP_SUCCESS_DOWNLOAD:
		case BACKUP_ERROR_DOWNLOAD:
		case BACKUP_ERROR_START: {
			return { ...state, inProgress: '', data: [] };
		}
		default: {
			return state;
		}
	}
};

export default backup;
