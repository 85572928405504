import { API_URL } from './config';
import { downloadFile, fetching } from './utils';

export const getCustomersData = (onSuccess?: (message: any) => void, onError?: (error: any) => void) => {
	fetching({
		url: API_URL + '/api/customers',
		onSuccess,
		onError,
	});
};

export const deleteCustomer = (id: string, onSuccess?: (message: any) => void, onError?: (error: any) => void) => {
	fetching({
		url: API_URL + `/api/customers/${id}`,
		options: {
			method: 'DELETE',
		},
		onSuccess,
		onError,
	});
};

export const addCustomer = (
	data: { [x: string]: any },
	onSuccess?: (message: any) => void,
	onError?: (error: any) => void,
) => {
	fetching({
		url: API_URL + `/api/customers`,
		options: {
			method: 'POST',
			headers: {
				accept: '*/*',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		},
		onSuccess,
		onError,
	});
};

export const updateCustomer = (
	id: string,
	data: { [x: string]: any },
	onSuccess?: (message: any) => void,
	onError?: (error: any) => void,
) => {
	fetching({
		url: API_URL + `/api/customers/${id}`,
		options: {
			method: 'PUT',
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		},
		onSuccess,
		onError,
	});
};

export const uploadCustomerLicense = (
	id: string,
	files: any,
	onSuccess?: (message: any) => void,
	onError?: (error: any) => void,
) => {
	const formData = new FormData();
	if (files && files.length > 0) {
		formData.append('file', files[0], files[0].name);
	}
	fetching({
		url: API_URL + `/api/customers/${id}/license-upload`,
		options: {
			method: 'POST',
			headers: {
				accept: '*/*',
				// 'Content-Type': 'application/json',
			},
			body: formData,
		},
		onSuccess,
		onError,
	});
};

export const removeCustomerLicense = (
	id: string,
	onSuccess?: (message: any) => void,
	onError?: (error: any) => void,
) => {
	fetching({
		url: API_URL + `/api/customers/${id}/license-remove`,
		options: {
			method: 'POST',
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
			},
		},
		onSuccess,
		onError,
	});
};

export const sendCustomerSysInfoToSupport = (
	id: string,
	data: { [x: string]: any },
	onSuccess?: (message: any) => void,
	onError?: (error: any) => void,
) => {
	fetching({
		url: API_URL + `/api/customers/${id}/send-sysinfo`,
		options: {
			method: 'POST',
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		},
		onSuccess,
		onError,
	});

};

export const downloadCustomerSysInfo = (
	id: string,
	name: string,
	onSuccess?: () => void,
	onError?: (error: any) => void,
) => {
	downloadFile({
		url: API_URL + `/api/customers/${id}/sysinfo-file`,
		options: { method: 'GET', headers: { accept: '*/*' } },
		defaultFileName: `sysinfo_cc_${name}.dat`,
		onSuccess,
		onError,
	});
};

