import React from 'react';
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import {Button} from '@gtb';
import {alpha as fade} from '@mui/material/styles';
import {useDispatch} from "react-redux";
import {hideModal} from 'store/actions';
import _ from 'lodash';

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        padding: '0px',
    },
    message: {
        padding: '24px',
        margin: `12px 0px 12px`,
        textAlign: 'center',
        background: fade(theme.palette.common.white, 0.5),
        borderRadius: 7,
        '& p': {
            ...theme.typography.p,
            '& bold, & strong': {
                fontWeight: 600,
            }
        }
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        marginTop: '12px',
    },
    button: {
        marginTop: 12,
        marginLeft: 24,
    }
}));

export const ConfirmationModal: React.FC<{ [x: string]: any }> = (props) => {

    const stl = useStyles(props);
    const {
        classes,
        active = true,
        message,
        handleYes,
        handleNo,
    } = props;

    const dispatch = useDispatch();

    return (
            <div className={stl.root}>
                {message ? (
                        <div className={stl.message}>
                            {message}
                        </div>
                ) : null}
                <div className={stl.actions}>
                    {active ? (
                            <React.Fragment>
                                <Button className={stl.button} onClick={() => {
                                    dispatch(hideModal());
                                    if (_.isFunction(handleYes)) {
                                        handleYes();
                                    }
                                }}>
                                    Yes
                                </Button>
                                <Button className={stl.button} onClick={() => {
                                    if (_.isFunction(handleNo)) {
                                        handleNo();
                                    }
                                    dispatch(hideModal());
                                }}>
                                    No
                                </Button>
                            </React.Fragment>
                    ) : (
                            <Button className={stl.button} onClick={() => {
                                dispatch(hideModal());
                            }}>
                                Cancel
                            </Button>
                    )}
                </div>
            </div>
    );
}

export default ConfirmationModal;




