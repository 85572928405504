import React from 'react';
import {Button} from '@gtb';
import Icon, * as Icons from '@gtb/components/Icons';
import {MESSAGE_ERROR, showMessage, showModal} from 'store/actions';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import SysInfo from 'apps/system/components/SysInfo'


const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    title: {
        ...typography.h1,
        marginTop: 22,
    },
}));

const Edit: React.FC = (props) => {

    const stl = useStyles(props);
    // @ts-ignore
    // const {select, data} = useSelector((state) => state.customers);
    const dispatch = useDispatch();

    return (
            <Button
                    skin={'groupedIcon'}
                    onClick={() => {

                        dispatch(showModal({
                            width: 600,
                            height: 'auto',
                            maxHeight: 'auto',
                            title: <div className={stl.title}>{'Emails and Alerts'}</div>,
                            content: <SysInfo portal={true}/>,
                        }));

                        // dispatch(showMessage({
                        //     message: 'Please select one item!',
                        //     type: MESSAGE_ERROR,
                        // }));
                    }}
            >
                <Icon view={Icons.ICON_CONFIGURE_SMTP}/>
            </Button>
    );
};

export default Edit;
