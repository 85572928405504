import React from 'react';
import { Typography } from '@mui/material';

const NoOptionsMessage = props => {
	return (
		props.children
			? <Typography
				color="textSecondary"
				className={props.selectProps.classes.noOptionsMessage}
				{...props.innerProps}
			>
				{props.children}
			</Typography>
			: <span style={{ 'display': 'none' }}/>
	);
};

export default NoOptionsMessage;
