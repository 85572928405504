import React, {useRef} from 'react';
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import Icon, * as Icons from '@gtb/components/Icons';
// @ts-ignore
import {ContextMenu} from 'react-inheritable-contextmenu';
import {Theme} from '@gtb';


const useStyles = makeStyles(({typography, palette, spacing, shadows}: CustomTheme) => ({
    root: {
        position: 'relative',
        '& > div': {
            zIndex: '9999',
        },
    },
    wrp: {},
    copy: {
        ...typography.p,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        width: 100,
        height: spacing(5.5),
        background: palette.common.white,
        padding: spacing(0, 3),
        borderRadius: '0.7rem',
        boxShadow: shadows[3],
        '&:hover': {
            background: palette.action.hover,
        },
        '& svg': {
            marginRight: spacing(2),
        },
    },
}));

const Copy: React.FC<{ classes: any }> = (props) => {
    const classes = useStyles(props);
    const {children} = props;
    const wrp: React.Ref<any> = useRef();

    const onCopy = () => {
        if (navigator && navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
            navigator.clipboard
                    .writeText(wrp.current.textContent!)
                    .then(
                            function () {
                                console.log(`Copy to clipboard`, wrp.current.textContent);
                            },
                            function () {
                                console.log(`Fail copy to clipboard`);
                            },
                    );
        }
    };

    ContextMenu.DefaultZIndex = '9999';

    return (
            <div className={classes.root}>
                <ContextMenu>
                    <Theme>
                        <div className={classes.copy} onClick={onCopy}>
                            <Icon view={Icons.ICON_COPY_TO}/>
                            <span>{'Copy'}</span>
                        </div>
                    </Theme>
                </ContextMenu>
                <div className={classes.root} ref={wrp}>
                    {children}
                </div>
            </div>
    );
}

export default Copy;
