import React from 'react';
import {CN} from '@gtb/utils';
import GtbScrollWrapper from '@gtb/components/Scroll/ScrollWrapper';
import {Preloader} from '@gtb'; // TODO: Soft preloader
import {
    ScrollSync,
} from 'react-virtualized';
import {GridEmptyTableProps } from './interfaces';
import Header from './VHeader';


const Empty: React.FC<GridEmptyTableProps> = (props) => {

    const {
        conf,
        head,
        rHead,
        isInner,
        template,
    } = props;

    const {
        classes,
        maxHeight,
        isLoading,
        noDataMessage,
    } = conf;

    return (
            <>
                {/*// @ts-ignore */}
                <ScrollSync>
                    {({
                          // clientHeight,
                          // clientWidth,
                          onScroll,
                          // scrollHeight,
                          scrollLeft,
                          // scrollTop,
                          // scrollWidth,
                      }) => {
                        return (
                                <>
                                    <Header
                                    	conf={conf}
                                    	head={head}
                                    	template={template}
                                    	scrollLeft={scrollLeft}
                                    />
                                    <Preloader
                                            isLoading={isLoading}
                                            classes={{root: classes.preloader, content: classes.preloaderContent}}
                                    >
                                        {/*// @ts-ignore */}
                                        <GtbScrollWrapper
                                                maxHeight={maxHeight}
                                                option={{wheelPropagation: false}}
                                                onScrollX={(e: any) => onScroll(e)}
                                        >
                                            <div className={CN(
                                                    classes.emptyData,
                                                    isInner && classes.minimizedView,
                                            )}>
                                                {isLoading
                                                        ? ''
                                                        : noDataMessage
                                                                ? noDataMessage
                                                                : 'No Data available'
                                                }
                                            </div>
                                        </GtbScrollWrapper>
                                    </Preloader>
                                </>
                        );
                    }}
                </ScrollSync>
            </>
    );
};

export default Empty;
