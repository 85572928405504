import React from 'react';
import {CN} from '@gtb/utils';
import useStyles from './ComboSelect.styles';
import {useTheme} from '@mui/styles';
import {CustomTheme} from '@gtb/components/Theme/interfaces';
import {lighten} from '@mui/material/styles';
import CreatableSelect from 'react-select/creatable';
import {isInArray, notEmptyArray} from '@gtb/utils';
import Icon, * as Icons from '@gtb/components/Icons';
import {formatData, formatOptions} from './utils';


import Control from './components/Control';
import Menu from './components/Menu';
import MultiValue from './components/MultiValue';
import NoOptionsMessage from './components/NoOptionsMessage';
import OptionWithIcon from './components/OptionWithIcon';
import Placeholder from './components/Placeholder';
import SingleValue from './components/SingleValue';
import ValueContainer from './components/ValueContainer';
import DropdownIndicator from './components/DropdownIndicator';

const ComboSelect: React.FC<{ [x: string]: any }> = (props) => {
    const stl = useStyles(props);
    const theme: CustomTheme = useTheme();
    const {
        classes,
        options,
        value,
        skip,
        placeholder,
        formatCreateLabel,
        noOptionsMessage,
        hideSelectedOptions,
        hideNotExistValue,
        isCreatable,
        isClearable,
        isSearchable,
        isDisabled,
        withIcons,
        chipOptions,
        ...rest
    } = props;

    const handleChange = (value: any) => {
        if (props.onChange) {
            props.onChange(value);
        }
    };

    const components = {
        Control,
        Menu,
        MultiValue,
        NoOptionsMessage,
        Option: withIcons
                ? OptionWithIcon
                : Option,
        Placeholder,
        SingleValue,
        ValueContainer,
        DropdownIndicator: notEmptyArray(options)
                ? DropdownIndicator
                : '',
    };

    const selectStyles = {
        input: (base: any) => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
        menuList: (base: any) => ({
            ...base,
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
            borderRadius: [[0, 0, '0.7rem', '0.7rem']],
            cursor: 'pointer!important',
        }),
        clearIndicator: (base: any) => ({
            ...base,
            opacity: props.onChange
                    ? 1
                    : 0,
            cursor: 'pointer',
            marginRight: theme.spacing(1),
            color: lighten(theme.palette.action.active6, 0.5) + '!important',
            ':hover': {
                color: theme.palette.action.active6 + '!important',
            }
        }),
        menuPortal: (base: any) => ({...base, zIndex: 9999}),
    };


    return (
            <div
                    className={CN(stl.root, {
                        _isDisabled: isDisabled,
                        _isSingle: isClearable === false && options.length < 2,
                    })}
                    onClick={e => e.stopPropagation()}
            >
                <CreatableSelect
                        classes={stl}
                        // menuIsOpen={true} // DEBUG MODE
                        // @ts-ignore
                        theme={theme}
                        styles={selectStyles}
                        textFieldProps={{
                            //label: 'Label',
                            InputLabelProps: {
                                shrink: true,
                            },
                        }}
                        isClearable={isClearable || true}
                        isSearchable={isSearchable || true}
                        // isValidNewOption= {() => undefined} // IF isn`t creatable
                        options={formatOptions(options, skip, value)}
                        // @ts-ignore
                        components={components}
                        hideSelectedOptions={hideSelectedOptions || false}
                        value={formatData(value, options, {isCreatable, hideNotExistValue})}
                        onChange={handleChange}
                        placeholder={
                            placeholder || <Icon view={Icons.ICON_ADD}/>
                        }
                        noOptionsMessage={
                            noOptionsMessage ||
                            (({inputValue}) => {
                                if (
                                        notEmptyArray(value) && isInArray(
                                                value.map((e: any) => e.value),
                                                inputValue,
                                        )
                                ) {
                                    return 'This value is already added';
                                } else {
                                    return false;
                                }
                            })
                        }
                        closeMenuOnSelect={false}
                        // menuShouldBlockScroll={true}
                        formatCreateLabel={
                            formatCreateLabel ||
                            (inputValue => (
                                    <div
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                width: '100%',
                                                height: '100%',
                                                alignItems: 'center',
                                            }}
                                    >
                                        <Icon view={Icons.ICON_ADD}/>
                                        <span style={{marginLeft: theme.spacing(2)}}>{inputValue}</span>
                                    </div>
                            ))
                        }
                        menuPortalTarget={document.body}
                        menuPosition={'absolute'}
                        menuPlacement="auto"
                        isMulti
                        readOnly={props.onChange
                                ? true
                                : false}
                        chipOptions={chipOptions}
                        onKeyDown={(e: any) => {
                            switch (e.key) {
                                case "Home": {
                                    e.preventDefault();
                                    if (e.shiftKey) {
                                        e.target.selectionStart = 0;
                                    } else {
                                        e.target.setSelectionRange(0, 0);
                                    }
                                    break;
                                }
                                case "End": {
                                    e.preventDefault();
                                    const length = e.target.value.length;
                                    if (e.shiftKey) {
                                        e.target.selectionEnd = length;
                                    } else {
                                        e.target.setSelectionRange(length, length);
                                    }
                                    break;
                                }
                                default:
                            }
                        }}
                        {...rest}
                />
            </div>
    );
};


export default ComboSelect;
