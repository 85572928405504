import React from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {Button} from '@gtb';
import Icon, * as Icons from '@gtb/components/Icons';
import {useDispatch, useSelector} from "react-redux";
import Edit from './toolbar/Edit'
import Legend from "apps/common/components/Legend";
import LegendConfig from "../configs/LegendConfig";
import {showModal} from "store/actions";

const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: spacing(3),
        '& .MuiButton-root': {
            marginBottom: 0,
        }
    },
    left: {},
    right: {
        marginLeft: "auto",
        display: 'flex',
    },
    edit: {
        marginRight: spacing(1.5)
    },
    additional: {
        '& .MuiButton-root': {
            marginRight: spacing(1.5),
        }
    },
    import: {
        marginRight: spacing(1.5)
    },
    info: {},
}));

interface NavbarProps extends React.PropsWithChildren<any> {
}

const Toolbar: React.FC<NavbarProps> = ({children,}) => {
    const classes = useStyles();

    // @ts-ignore
    const select = useSelector((state) => state.customers.select);
    const dispatch = useDispatch();


    return (
            <div className={classes.root}>
                {/*<div className={classes.edit}>*/}
                {/*    /!*    <Add />*!/*/}
                {/*    <Edit/>*/}
                {/*    /!*    <Delete/>*!/*/}
                {/*</div>*/}

                {/*<div className={classes.additional}>*/}
                {/*    <Button skin={'simple'}><Icon view={Icons.ICON_LICENSES_UPLOAD}/>Upload Certificate</Button>*/}
                {/*    /!*<Button skin={'simple'}><Icon view={Icons.ICON_BACKUP_AND_RESTORE}/>Backup and Restore</Button>*!/*/}
                {/*</div>*/}

                <div className={classes.right}>
                    {/*<div className={classes.import}>*/}
                    {/*    <Button skin={'groupedIcon'}><Icon view={Icons.ICON_IMPORT}/></Button>*/}
                    {/*    <Button skin={'groupedIcon'}><Icon view={Icons.ICON_EXPORT}/></Button>*/}
                    {/*</div>*/}

                    <div className={classes.info}>
                        <Button
                                skin={'simpleIcon'}
                                onClick={() => {
                                    dispatch(showModal({
                                        width: '113rem',
                                        height: 'auto',
                                        title: 'Licensing Description',
                                        content: <Legend config={LegendConfig}/>,
                                    }));
                                }}
                        >
                            <Icon view={Icons.ICON_INFORMATION_1}/>
                        </Button>
                    </div>
                </div>
            </div>
    );
};

export default Toolbar;
