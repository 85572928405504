import { useRef } from 'react';

export const useCancellablePromises = () => {
	const pendingPromises = useRef([]);

	return {
		appendPendingPromise: promise =>
			pendingPromises.current = [ ...pendingPromises.current, promise ],
		removePendingPromise: promise =>
			pendingPromises.current = pendingPromises.current.filter(p => p !== promise),
		clearPendingPromises: () =>
			pendingPromises.current.map(p => p.cancel()),
	};
};

export default useCancellablePromises;
