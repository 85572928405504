import React from 'react';

const Placeholder = props => {
	return (
		<div
			className={props.selectProps.classes.placeholder}
			{...props.innerProps}
		>
			{props.children}
		</div>
	);
};

export default Placeholder;
