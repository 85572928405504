import React, {useEffect, useState} from 'react';
import {Button, SetupItem} from '@gtb';
import Icon, * as Icons from '@gtb/components/Icons';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import _ from 'lodash';
import SysInfo from './SysInfo'
import {
    hideModal, showModal,
    customersUploadLicense,
    customersDownloadSysInfo,
    showMessage,
    MESSAGE_ERROR,
    customersRemoveLicense,
    customerSendSysInfoToSupport,
    getSettingsData,
} from 'store/actions';
import License from "../cells/License";
import {emailValidation, validURL} from "@gtb/utils";
import ConfirmationModal from "apps/common/components/layout/ConfirmationModal";

const useStyles = makeStyles(({typography, spacing}: CustomTheme) => ({
    root: {},
    box: {
        width: '100%',
        overflow: 'hidden',
        borderRadius: '0.7rem',
    },
    title: {
        ...typography.h1,
        marginTop: 22,
        marginBottom: spacing(2),
    },
    config: {
        display: 'flex',
        '& button:first-child': {
            flexShrink: 0,
            minWidth: 160,
        },
        '& button:last-child ': {
            flexShrink: 0,
        }
    },
    sendSysInfo: {
        // width: '100%',
        // flexShrink: 1,
    },
    row: {
        paddingTop: 22,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // '& button': {
        //     marginLeft: spacing(3)
        // }
    },
    apply: {
        marginRight: spacing(3)
    },
    actions: {}
}));


interface CustomerDataProps {
    companyName: string,
    fqdn: string,
    users?: number,
    licenseExpirationDate?: string,
    companyUrl?: string,
    description?: string,
    admin?: {
        companyUrl?: string,
        name?: string,
        email?: string,
        phone?: string
    }
}

const DEFAULT_DATA = {
    "companyName": "",
    "fqdn": "",
    "users": 0,
    "licenseExpirationDate": "",
    "companyUrl": "",
    "description": "",
    "admin": {
        "companyUrl": "",
        "name": "",
        "email": "",
        "phone": ""
    }
}

const template = {
    root: {
        width: '100%',
    },
    label: {
        width: 160,
    }
}


const EditCustomer: React.FC<{ [x: string]: any }> = (props) => {

    const stl = useStyles(props);

    // @ts-ignore
    const {data: smtp} = useSelector((state) => state.settings);
    const dispatch = useDispatch();

    useEffect(() => {
        // @ts-ignore
        dispatch(getSettingsData());
    }, [])

    // console.log('Fields', props.fields);
    // console.log('L SMTP', smtp, props.fields);

    const [data, setData] = useState(props && props.fields ? props.fields : DEFAULT_DATA);
    const onChange = (name: string) => (event: any) => {
        setData({...data, [name]: event.target.value});
    }
    const [files, setFiles] = useState([])


    const removeOptions = {
        width: '450px',
        height: 'auto',
        title: 'Delete Licenses',
        content: <ConfirmationModal
                message={'Do you want to delete licenses?'}
                handleYes={() => {
                    // @ts-ignore
                    dispatch(customersRemoveLicense(props.fields._id));
                }}
        />
    };

    return (
            <div className={stl.root}>
                <div className={stl.config}>
                    <Button
                            skin={'grouped'}
                            onClick={() => {
                                if (props.fields && props.fields._id) {
                                    // @ts-ignore
                                    dispatch(customersDownloadSysInfo(props.fields._id, props.fields.companyName,))
                                }
                            }}
                    >
                        <Icon view={Icons.ICON_LICENSES_DOWNLOAD}/>
                        {'Download SysInfo'}
                    </Button>
                    <Button
                            skin={'grouped'}
                            className={stl.sendSysInfo}
                            onClick={() => {

                                if (!props.fields || !props.fields.admin || !props.fields.admin.email || !emailValidation(props.fields.admin.email)) {
                                    dispatch(showMessage({
                                        message: 'Please check administrator email',
                                        type: MESSAGE_ERROR,
                                    }));
                                } else if (!smtp || !smtp.address || !validURL(smtp.address)) {
                                    dispatch(showMessage({
                                        message: 'Please check SMTP Server Address',
                                        type: MESSAGE_ERROR,
                                    }));
                                } else if (!smtp || !smtp.originator || !emailValidation(smtp.originator)) {
                                    dispatch(showMessage({
                                        message: 'Please check Email Originator',
                                        type: MESSAGE_ERROR,
                                    }));
                                } else {
                                    // @ts-ignore
                                    dispatch(customerSendSysInfoToSupport(props.fields._id, {}));
                                }
                            }}
                    >
                        <Icon view={Icons.ICON_LICENSES_SEND_EMAIL}/>
                        {'Send SysInfo to Support'}
                    </Button>
                    <SysInfo/>
                </div>
                <div className={stl.box}>
                    <SetupItem
                            label={'Company Name'}
                            input={[
                                {
                                    type: 'simple text',
                                    placeholder: 'Please fill Company Name',
                                    value: data.companyName,
                                    onChange: onChange('companyName'),
                                },
                            ]}
                            template={template}
                    />
                    <SetupItem
                            label={'Expiration Date'}
                            input={[{
                                type: 'date-view',
                                value: data.licenseExpirationDate,
                                Wrapper: License,
                                // placeholder: '',
                                // onChange: onChange('licenseExpirationDate')
                            }]}
                            template={template}
                    />
                    <SetupItem
                            label={'License File'}
                            input={{
                                type: 'choose-file',
                                placeholder: 'Browse License file',
                                label: 'Upload new image',
                                submitProps: false,
                                onSubmit: (file: any) => {
                                    setFiles(file)
                                },
                                accept: {
                                    'application/vnd.ms-excel': ['.dat'],
                                },
                            }}
                            template={{...template}}
                    />
                </div>
                <div className={stl.row}>
                    <Button
                            onClick={() => {
                                if (props.fields && props.fields._id) {
                                    dispatch(showModal(removeOptions));
                                } else {
                                    dispatch(showMessage({
                                        message: 'Something goes wrong. Temporary action is unsupported. Please check your data and retry',
                                        type: MESSAGE_ERROR,
                                    }));
                                }
                            }}
                    >
                        <Icon view={Icons.ICON_NOT_LICENSED}/>{'Delete Licenses'}
                    </Button>


                    <div className={stl.actions}>
                        <Button
                                className={stl.apply}
                                onClick={() => {
                                    if (props.fields && props.fields._id && _.isArray(files) && (files.length > 0)) {
                                        // @ts-ignore
                                        dispatch(customersUploadLicense(props.fields._id, files));
                                        // dispatch(hideModal());
                                    } else {
                                        dispatch(showMessage({
                                            message: !_.isArray(files) || (files.length < 1)
                                                    ? 'Please browse license file'
                                                    : 'Something goes wrong. Temporary action is unsupported. Please check your data and retry',
                                            type: MESSAGE_ERROR,
                                        }));
                                    }
                                }}
                        >
                            <Icon view={Icons.ICON_LICENSES_UPLOAD}/>{'Apply'}
                        </Button>

                        <Button onClick={() => dispatch(hideModal())}>Cancel</Button>
                    </div>
                </div>
            </div>
    );
};

export default EditCustomer;
