import React from 'react';
import Icon from '@gtb/components/Icons/Icon';
import {CTooltip as Tooltip} from '@gtb';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from '@gtb/interfaces';

const useStyles = makeStyles(({spacing}: CustomTheme) => ({
    root: {
        display: 'flex',
        minWidth: spacing(3),
        minHeight: spacing(3),
        alignItems: 'center',
    },
    tooltip: {
        wordWrap: 'break-word',
        maxWidth: 400,
    },
    delimiter: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            // marginRight: spacing(1),
            // marginLeft: spacing(1),
        },
    },
}));

const IconButton: React.FC<{ [x: string]: any }> = (props) => {

    const stl = useStyles(props);
    const {
        classes,
        children,
        tooltip = '',
        tooltipPlacement = 'top',
        view = null,
        fill,
        color,
        ...rest
    } = props;

    return (
            <Tooltip
                    title={tooltip
                            ? tooltip
                            : null}
                    placement={tooltipPlacement}
                    classes={{tooltip: stl.tooltip}}
            >
                <div className={stl.root} {...rest}>
                    {
                        view
                                ? (
                                        <Icon view={view} fill={fill} color={color}/>
                                )
                                : (
                                        <div className={stl.delimiter}>
                                            {children}
                                        </div>
                                )
                    }
                </div>
            </Tooltip>
    );
}

export default IconButton;
