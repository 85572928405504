import React from 'react';
import {makeStyles, useTheme} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import Icon, * as Icons from '@gtb/components/Icons';
import {SetupItem, Button} from "@gtb";
import {useDispatch, useSelector} from "react-redux";
import {
    Avatar,
    IconButton,
    Menu,
    MenuItem,
} from '@mui/material';
import {NavLink as Link} from "react-router-dom";
import Version from 'apps/common/components/layout/Version';
import {logout} from 'store/actions'

const useStyles = makeStyles(({typography, palette, spacing, transitions, shadows}: CustomTheme) => ({
    root: {
        minWidth: 160,
    },
    wrap: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    profile: {
        paddingRight: '2rem',
        cursor: 'pointer',
        display: 'flex',
        marginLeft: spacing(1.5),
        alignItems: 'center',
    },
    avatar: {
        width: '4.8rem',
        height: '4.8rem',
        boxSizing: 'border-box',
    },
    userInfo: {
        color: palette.text.secondary,
        position: 'relative',
        display: 'inline-block',
        maxWidth: spacing(80),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginLeft: '1.2rem',
        paddingRight: '2.4rem',
        transition: 'all 0.1s ' + transitions.easing.easeIn,
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: '50%',
            right: '1%',
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '0.7rem 0.7rem 0px 0.7rem',
            borderColor: palette.text.secondary + ' transparent transparent transparent',
            transition: 'all 0.1s ' + transitions.easing.easeIn,
        },
        '&:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: '50%',
            right: '-1%',
            width: spacing(3),
            height: spacing(6),
            transform: 'translateY(-50%)',
        },
        '&:hover': {
            color: palette.text.primary,
            '&:before': {
                borderColor: palette.text.primary + ' transparent transparent transparent',
            },
        },
    },
    userNameWrapper: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: 0,
        borderTopWidth: 0,
    },
    userName: {
        fontSize: '1.8rem',
        fontWeight: 'bolder',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '3.3rem',
        whiteSpace: 'nowrap',
    },
    menu: {
        marginTop: '0.3rem',
        padding: 0,
        boxShadow: shadows[1],
        '&:hover': {
            boxShadow: shadows[3],
        },
        '& ul': {
            margin: 0,
            padding: 0,
        },
    },
    menuItem: {
        height: spacing(6),
        minWidth: 250,
        width: 250,
        boxSizing: 'border-box',
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.background.main,
        padding: '0',
    },
    link: {
        ...typography.inputField,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        outline: 0,
        textDecoration: 'none',
        color: palette.text.secondary,
        transition: 'all 0.1s ' + transitions.easing.easeIn,
        padding: spacing(0, 0, 0, 2.66666),

    },
    iconButton: {
        padding: 0,
        marginLeft: spacing(0.3333333),
        marginRight: spacing(3),
    },
}));

interface UserIsLoggedProps extends React.PropsWithChildren<any> {
    [x: string]: any
}

const UserIsLogged: React.FC<UserIsLoggedProps> = (props) => {
    const stl = useStyles(props);
    const theme: CustomTheme = useTheme();

    // @ts-ignore
    const {user} = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const userLogout = () => {
        // @ts-ignore
        dispatch(logout());
        handleClose();
    }


    return (
            <div className={stl.root}>
                <Menu
                        id="user-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        classes={{paper: stl.menu}}
                >
                    <MenuItem className={stl.menuItem} disableRipple={true}>
                        <Link
                                className={stl.link} onClick={userLogout}
                                to={'/login'}
                        >
                            <IconButton classes={{root: stl.iconButton}} color="inherit" disableRipple={true}>
                                <Icon view={Icons.ICON_LOGIN_V1}/>
                            </IconButton>
                            <p>{'Logout'}</p>
                        </Link>
                    </MenuItem>
                </Menu>
                <div className={stl.wrap}>
                    <div
                            className={stl.profile}
                            onClick={handleProfileMenuOpen}
                    >
                        <Avatar
                                className={stl.avatar}
                                style={{
                                    color: theme.palette.common.white,
                                    background: theme.palette.action.active,
                                }}
                        >
                            {
                                'A' // user.name.charAt(0)
                            }
                        </Avatar>
                        <div className={stl.userInfo}>
                            <SetupItem
                                    classes={{root: stl.userNameWrapper}}
                                    input={[
                                        {
                                            type: 'simple-text',
                                            classes: {
                                                root: stl.userNameWrapper
                                            },
                                            value: <div className={stl.userName}>
                                                {user.login}
                                            </div>,
                                            tooltip: user.login
                                        },
                                    ]}
                            />
                            <Version/>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default UserIsLogged;
