import React, {
    useEffect,
    useState,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import {CN} from '@gtb/utils';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "../Theme/interfaces";
import {useClickPreventionOnDoubleClick} from '@gtb/hooks';
import {chooseFieldRender} from '../Setups/Field';
import {TableStyles} from '@styles/Table.styles';
import {
    Memo,
    notEmptyArray,
} from '@gtb/utils';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from 'react-beautiful-dnd';
import {GridRowCellProps, GridHeaderProps, GridRowMouseEvent} from '@gtb/interfaces';

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        width: '100%',
        minHeight: theme.spacing(7),
        '.minimizedView &': {
            minHeight: theme.spacing(5.5),
            boxSizing: 'border-box',
        },
        '.minimizedViewFixedHeight &': {
            height: theme.spacing(5.3), // Math.ceil
        },
    },
    th: {},
    td: {},
    row: {},
    ...TableStyles(theme),
    disabled: {
        // pointerEvents: 'none',
        '& > div': {
            // opacity: 0.3,
        },
    },
    relative: {
        position: 'relative',
    },
}));

const Header: React.FC<GridHeaderProps> = (props) => {

    const classes = useStyles(props);
    const {
        className,
        fields = [],
        id = new Date().getTime(),
        isBolded = false,
        template,
        styles = {},
        focused,
        disabled = false,
        dragging,
        thead = false,
        onFocus = () => false,
        onClick = () => false,
        onDoubleClick = () => false,
        isVisible = true,
        onDND = null,
        rowHeight,
        ...rest
    } = props;
    // TODO Need refactor this component

    const onClickHandler = (event: GridRowMouseEvent) => {
        const trg = event.target;
        if (!focused) {
            onFocus(trg);
        }
        onClick(event);
        if (trg && typeof trg.scrollIntoViewIfNeeded === 'function') {
            trg.scrollIntoViewIfNeeded();
        }
    };

    const onDoubleClickHandler = (event: GridRowMouseEvent) => {
        const trg = event.target;
        if (!focused) {
            onFocus(trg);
        }
        onDoubleClick(event);
        if (trg && typeof trg.scrollIntoViewIfNeeded === 'function') {
            trg.scrollIntoViewIfNeeded();
        }
    };

    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onClickHandler, onDoubleClickHandler);

    const wrp: React.Ref<any> = useRef(null);
    useEffect(() => {
        if (focused) {
            setTimeout(() => {
                if (wrp.current && typeof wrp.current.scrollIntoViewIfNeeded === 'function') {
                    wrp.current.scrollIntoViewIfNeeded();
                }
            }, 500);
        }
    }, [JSON.stringify(focused)]);

    const [hovered] = useState(false);

    const templateClassName = notEmptyArray(template)
            ? ''
            : template;
    const templateStyles = notEmptyArray(template)
            ? {gridTemplateColumns: template.join(' ')}
            : {};

    const onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination || typeof onDND !== 'function') {
            return;
        }
        onDND(result);
    };

    // DebuggerAnchor('setup Row');
    return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                            <div
                                    // ref={wrp}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className={CN(['tr table-header-row', className, classes.root, classes.row, templateClassName,], {
                                                [classes.disabled]: disabled,
                                                bold: isBolded,
                                                hov: hovered
                                            }
                                    )}
                                    onClick={handleClick}
                                    onDoubleClick={handleDoubleClick}
                                    // onMouseMove={() => {setHovered(true)}}
                                    // onMouseOut={() => {setHovered(false)}}
                                    style={{...styles, ...templateStyles}}
                                    {...rest}
                            >
                                {fields.map((i: GridRowCellProps, count: number) => {
                                    const {
                                        type,
                                        className,
                                        enabled,
                                        disabled,
                                        memo,
                                        memoSettings,
                                        actions,
                                        ...rest
                                    } = i;

                                    const Field = notEmptyArray(memo)
                                            ? Memo(chooseFieldRender(type), memo, memoSettings)
                                            : chooseFieldRender(type);

                                    const Cell = (
                                            <Draggable
                                                    key={i.id || id + '_' + count}
                                                    // isDragDisabled={dragging || typeof onDND !== 'function'}
                                                    isDragDisabled={true}
                                                    draggableId={i.slug || i.id || id + '_' + count}
                                                    index={count}
                                            >
                                                {(provided, snapshot) => (
                                                        <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                id={i.id || id + '_' + count}
                                                                className={CN(i.className, {
                                                                    th: thead,
                                                                    [classes.th]: thead,
                                                                    td: !thead,
                                                                    [classes.td]: !thead,
                                                                    [classes.disabled]: disabled,
                                                                    [classes.relative]: actions,
                                                                })}
                                                        >
                                                            {isVisible
                                                                    ? <Field isBolded={isBolded} {...rest} />
                                                                    : null}
                                                            {actions}
                                                        </div>
                                                )}
                                            </Draggable>
                                    );
                                    return i.hasOwnProperty('enabled')
                                            ? i.enabled
                                                    ? Cell
                                                    : null
                                            : Cell;
                                })}
                            </div>
                    )}
                </Droppable>
            </DragDropContext>
    );
}

export default Memo(Header, [
    'classes',
    'className',
    'fields',
    'id',
    'isBolded',
    'template',
    'styles',
    'focused',
    'disabled',
    'dragging',
    'thead',
    'isVisible',
], {
    debug: false,
    color: 'pink',
});
