import React from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {Button} from '@gtb';
import Icon, * as Icons from '@gtb/components/Icons';
import {showModal} from "store/actions";
import Legend from "apps/common/components/Legend";
import LegendConfig from "../configs/LegendConfig";
import {useDispatch} from "react-redux";

const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: spacing(3),
        '& .MuiButton-root': {
            marginBottom: 0,
        }
    },
    left: {},
    info: {
        marginLeft: "auto",
        display: 'flex',
    },
}));

interface NavbarProps extends React.PropsWithChildren<any> {
}

const Toolbar: React.FC<NavbarProps> = ({children}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
            <div className={classes.root}>
                <div className={classes.left}>

                </div>

                <div className={classes.info}>
                    <Button
                            skin={'simpleIcon'}
                            onClick={() => {
                                dispatch(showModal({
                                    width: '113rem',
                                    height: 'auto',
                                    title: 'Licensing Description',
                                    content: <Legend config={LegendConfig}/>,
                                }));
                            }}
                    >
                        <Icon view={Icons.ICON_INFORMATION_1}/>
                    </Button>
                </div>
            </div>
    );
};

export default Toolbar;
