import { combineReducers } from 'redux';
import data from './data';
import select from './select';
import focused from './focused';
import refreshing from './refreshing';

const rootReducer = combineReducers({
	data,
	select,
	focused,
	refreshing,
});

export default rootReducer;
