import React from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {Text as Field} from '@gtb'

const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    root: {
        background: 'transparent',
        backgroundColor: 'transparent',
        height: '100%',
        width: '100%',
        '& > div': {
            height: '100%',
            '& > div': {
                height: '100%',
            },
        },
    },
}));

interface TextProps {
    classes?: unknown,
    value?: unknown,
    autoComplete?: string,
    multiline?: boolean,

    [x: string]: any,
}

const Text: React.FC<TextProps> = (props) => {
    const {
        classes,
        value,
        autoComplete,
        multiline,
        ...rest
    } = props;
    const styles = useStyles(props);

    return (
            <Field
                    classes={{root: styles.root}}
                    type={'text'}
                    value={(value !== undefined && value !== null) ? value : ''}
                    multiline={multiline || true}
                    {...rest}
            />
    );
};

export default Text;
