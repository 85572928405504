import React, {
    useCallback,
    useEffect,
    useState,
    useRef,
} from 'react';
import {CN} from '@gtb/utils';
import {makeStyles, useTheme} from "@mui/styles";
import {CustomTheme} from "../Theme/interfaces";
import Row from './Row';
import Header from './Header';
import _ from 'lodash';
import {
    Memo,
    isInArray,
    removeFromArray,
    notEmptyArray,
} from '@gtb/utils';
import {TableStyles} from '@styles/Table.styles';
import VBody from './VBody';
import Empty from './Empty';
import Simple from './Simple';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {GridTableProps, GridVirtualizedBodyCellProps} from "./interfaces";

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        overflow: 'hidden',

        '&.dragging': {
            cursor: 'ew-resize!important',
        },
        '& .ReactVirtualized__Header .ReactVirtualized__Grid': {
            overflow: 'hidden!important',
        },
    },

    table: {},
    th: {},
    td: {},
    row: {},
    ...TableStyles(theme),

    hidden: {
        display: 'none',
    },
    disabled: {
        pointerEvents: 'none',
        opacity: 0.3,
    },
    checkbox: {
        height: 'auto',
        padding: theme.spacing(2.166666, 0, 1.833333, 1),
    },
    emptyData: {
        ...theme.typography.h1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: theme.spacing(21),
        background: theme.palette.common.white,
    },
    minimizedView: {
        height: 'auto',
    },
    preloader: {},
    preloaderContent: {},
    scroll: {
        overflow: 'overlay!important',
        scrollBehavior: 'smooth',
    },
}));

const defaultProps = {
    id: 'ST',
    thead: [],
    tbody: [],
    selected: null,
    className: ' ',
    enabled: true,
    disabled: false,
    dragging: false,
    isLoading: false,
    hideOnLoading: false,
    virtualizer: false,
    onDND: null,
}

const Table: React.FC<GridTableProps> = (tProps) => {
    const props: GridTableProps = {...defaultProps, ...tProps};
    const classes = useStyles(props);
    const theme = useTheme();

    const {
        id = 'ST',
        thead = [],
        tbody = [],
        template,
        selected = null,
        changed,
        className = ' ',
        enabled = true,
        disabled = false,
        dragging = false,
        isLoading = false,
        hideOnLoading = false,
        virtualizer = false,
        autoScroll,
        onDND = null,
        inner,
        // maxHeight,
        // minWidth = 'inherit',
        // overscanRowCount,
        // noDataMessage = '',
        // Props that used in components children
    } = props;

    const isInner = inner ? inner : className ? className.includes('inline') : false;

    const tableClassName = className || ' ';
    const tableTemplate = (notEmptyArray(template) && props.onSelect)
            ? ['26px', ...template]
            : template;

    const [focused, setFocus] = useState(props.focused || []);
    useEffect(() => {
        setFocus(_.isArray(props.focused)
                ? props.focused
                : []);
    }, [JSON.stringify(props.focused)]);
    const onUnfocus = useCallback(() => {
        setFocus([]);
        if (props.onFocus) {
            props.onFocus([]);
        }
    }, [])

    const onFocus = useCallback(idCallback => {
        // console.log('focused', focused, tbody);
        // console.log('focused and callback', idCallback);
        // console.log('just focused', focused,);
        // console.log('TEST',isInArray(focused, idCallback) );
        // console.log('PROPS', props.focused)
        const fcs = _.isArray(focused) && isInArray(focused, idCallback) && props.focused.length
                ? []
                : [idCallback];
        setFocus(fcs);
        if (props.onFocus) {
            props.onFocus(fcs);
        }
    }, [props.onFocus, JSON.stringify(props.focused)]);

    const onSelect = useCallback(idCallback => {
        if (props.onSelect) {
            props.onSelect(removeFromArray(selected, idCallback, true));
        }
    }, [props.onSelect, JSON.stringify(props.selected)]);

    const onSelectAll = () => {
        if (props.onSelect && selected && props.tbody) {
            const slcd = selected.length !== props.tbody.length
                    ? props.tbody.map((e: any, index: number) =>
                            e._id
                                    ? e._id
                                    : index,
                    )
                    : [];
            props.onSelect(slcd);
        }
    };
    //Variant 1, implemented for allEvents
    const getFocusedIndex = () => {
        if (focused && focused.length && tbody && tbody.length && autoScroll) {
            const validate = (elem: any) => {
                return focused.includes(elem._id);
            };
            return tbody.findIndex(validate);
        } else {
            return undefined;
        }
    };

    const [head, setHead] = useState(thead);
    useEffect(() => {
        if (props.onSelect !== null) {
            setHead(
                    thead.map((e: any) => ({
                        ...e,
                        fields: [
                            {
                                type: 'checkbox',
                                classes: {root: classes.checkbox},
                                className: 'noBorder centered',
                                checked: selected && props.tbody && (selected.length === props.tbody.length),
                                onChange: () => {
                                    onSelectAll();
                                },
                            },
                            ...e.fields,
                        ],
                    })),
            );
        } else {
            setHead(thead);
        }
    }, [thead, JSON.stringify(selected), JSON.stringify(props.focused)]);

    const [body, setBody] = useState(tbody);
    useEffect(() => {
        if (props.onSelect !== null) {
            setBody(
                    tbody.map((e: any, index: number) => ({
                        ...e,
                        fields: [
                            {
                                type: 'checkbox',
                                classes: {root: classes.checkbox},
                                className: 'noBorder centered middle',
                                checked: isInArray(selected || [], e._id
                                        ? e._id
                                        : index),
                                onChange: () => {
                                    onSelect(e._id
                                            ? e._id
                                            : index);
                                },
                            },
                            ...e.fields,
                        ],
                    })),
            );
        } else {
            setBody(tbody);
        }
    }, [tbody, JSON.stringify(selected), JSON.stringify(props.focused)]);

    const rHead = (th: any, count: number) => {
        return th && notEmptyArray(th.fields)
                ? (
                        <Header
                                onDND={onDND}
                                key={id + 'thead' + count}
                                thead={true}
                                template={tableTemplate}
                                {...th}
                        />
                )
                : null;
    };

    const rBody: React.FC<GridVirtualizedBodyCellProps> = ({
                                                               columnIndex, // Horizontal (column) index of cell
                                                               isScrolling, // The Grid is currently being scrolled
                                                               isVisible, // This cell is visible within the grid (eg it is not an overscanned cell)
                                                               key, // Unique key within array of cells
                                                               parent, // Reference to the parent Grid (instance)
                                                               rowIndex, // Vertical (row) index of cell
                                                               style, // Style object to be applied to cell (to position it);
                                                               // This must be passed through to the rendered cell element.
                                                           }) => {

        const tr = body[rowIndex];
        if (tr) {
            const {className: trClassName, ...rest} = tr;
            const ID = tr._id
                    ? id + 'STR_' + tr._id
                    : key
                            ? key
                            : id + 'STR_' + rowIndex;
            return (
                    <Row
                            key={ID}
                            i={rowIndex}
                            // isVisible={isVisible}
                            template={tableTemplate}
                            className={CN(trClassName, {
                                selected: isInArray(selected || [], tr._id
                                        ? tr._id
                                        : rowIndex),
                                focused: isInArray(focused || [], tr._id
                                        ? tr._id
                                        : rowIndex),
                                changed: isInArray(changed || [], tr._id
                                        ? tr._id
                                        : rowIndex),
                                hovered: tableClassName.includes('hovered'),
                            })}
                            focused={isInArray(
                                    focused || [],
                                    tr._id
                                            ? tr._id
                                            : rowIndex,
                            )}
                            onFocus={(target) => {
                                if (props.focused) {
                                    onFocus(
                                            tr._id
                                                    ? tr._id
                                                    : rowIndex,
                                    );
                                }
                            }}
                            styles={{...style, ...tr.style}}
                            {...rest}
                    />
            );
        } else {
            return null;
        }
    };

    const parentRef = useRef(null);

    return ((!hideOnLoading || (hideOnLoading && !isLoading)) && (
            <ClickAwayListener onClickAway={onUnfocus}>
                <div
                        ref={parentRef}
                        id={id}
                        className={CN([classes.root, classes.table, className], {
                            [classes.hidden]: !enabled,
                            [classes.disabled]: disabled,
                            ['dragging']: dragging,
                        })}
                >
                    {notEmptyArray(tbody)
                            ? virtualizer
                                    ? (
                                            <VBody
                                                    conf={{...props, classes, theme}}
                                                    head={head}
                                                    rHead={rHead}
                                                    body={body}
                                                    cellRenderer={rBody}
                                                    template={tableTemplate}
                                                    getFocusedIndex={getFocusedIndex}
                                            />
                                    )
                                    : (
                                            <Simple
                                                    parentRef={parentRef}
                                                    conf={{...props, classes, theme}}
                                                    head={head}
                                                    rHead={rHead}
                                                    tbody={tbody}
                                                    rBody={rBody}
                                                    isInner={isInner}
                                                    template={tableTemplate}
                                            />
                                    )
                            : isInner
                                    ? notEmptyArray(thead)
                                            ? (
                                                    <Simple
                                                            parentRef={parentRef}
                                                            conf={{...props, classes, theme}}
                                                            head={head}
                                                            rHead={rHead}
                                                            tbody={tbody}
                                                            rBody={rBody}
                                                            isInner={isInner}
                                                            template={tableTemplate}
                                                    />
                                            )
                                            : null
                                    : (
                                            <Empty
                                                    conf={{...props, classes, theme}}
                                                    head={head}
                                                    rHead={rHead}
                                                    isInner={isInner}
                                                    template={tableTemplate}
                                            />
                                    )
                    }
                </div>
            </ClickAwayListener>
    ));
}


export default Memo(Table, [
    'classes',
    'theme',
    'id',
    'maxHeight',
    'autoScroll',
    'thead',
    'tbody',
    'template',
    'className',
    'enabled',
    'disabled',
    'dragging',
    'focused',
    'changed',
    'selected',
    'isLoading',
    'hideOnLoading',
    'noDataMessage',
    'overscanRowCount',
    'minWidth',
], {
    debug: true,
    // debug: false,
    color: 'orange',
});
