import React from 'react';
import {Button} from '@gtb';
import Icon, * as Icons from '@gtb/components/Icons';
import {showModal} from 'store/actions';
import {useDispatch} from "react-redux";
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import EditCustomer from './EditCustomer';


const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    title: {
        ...typography.h1,
        marginTop: 22,
    },
}));

const Delete: React.FC = (props) => {

    const stl = useStyles(props)
    const dispatch = useDispatch();

    return (
            <Button
                    skin={'grouped'}
                    onClick={() => {
                        dispatch(showModal({
                            width: '105rem',
                            height: 'auto',
                            maxHeight: 'auto',
                            title: <div className={stl.title}>{'Add customer'}</div>,
                            content: <EditCustomer/>,
                        }));
                    }}
            >
                <Icon view={Icons.ICON_ADD}/>Add Customer
            </Button>
    );
};

export default Delete;
