import React from 'react';
import Icon, * as Icons from '@gtb/components/Icons';
import {NavigationRoutes} from '@interface/IRoutes';
import Customers from 'apps/customers/components/App';
import SystemSettings from 'apps/system/components/App';
import Security from 'apps/security/components/App';
import Resources from 'apps/resources/components/App';
import Backup from 'apps/backup/components/App';
import Users from 'apps/common/components/Page';
import Login from 'apps/auth/login/components/Login';
import RestoreLogin from 'apps/common/components/Page';

const routes: NavigationRoutes = [
    {
        path: '/',
        hidden: true,
        label: '',
        component: <Customers/>
    },
    {
        path: '/customers',
        label: 'Customers',
        icon: <Icon view={Icons.ICON_SCAN_AND_LOGS}/>,
        component: <Customers/>
    },
    {
        path: '/system-settings',
        label: 'System Settings',
        icon: <Icon view={Icons.ICON_SYSTEM_SETTING}/>,
        component: <SystemSettings/>
    },
    // {
    //     path: '/security',
    //     label: 'Security',
    //     icon: <Icon view={Icons.ICON_SECURITY}/>,
    //     component: <Security/>
    // },
    {
        path: '/resources',
        label: 'Resources',
        icon: <Icon view={Icons.ICON_OTHER_COLORIZED}/>,
        component: <Resources/>
    },
    {
        path: '/backup',
        label: 'Backup',
        icon: <Icon view={Icons.ICON_BACKUP_AND_RESTORE}/>,
        component: <Backup />
    },
    // {
    //     path: '/users',
    //     label: 'Users',
    //     icon: <Icon view={Icons.ICON_ACOUNT_MANAGER}/>,
    //     component: <Users path={['Users']}/>
    // },
    {
        path: '/login',
        hidden: true,
        label: 'Login',
        component: <Login/>
    },
    {
        path: '/restore-login',
        hidden: true,
        label: 'Restore Login',
        component: <RestoreLogin path={['Restore l=Login']}/>
    },
]

export default routes;
