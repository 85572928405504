import React from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme, SetupItemProps} from "@gtb/interfaces";
import {CN} from '@gtb/utils';
import Label from './Label';
import Field from './Field';

const useStyles = makeStyles(({typography, palette}: CustomTheme) => ({
    rootWrapper: {
        width: '100%',
        display: 'flex',
        position: 'relative',
    },
    root: {
        width: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        backgroundColor: palette.common.white,
        borderStyle: 'solid',
        borderColor: palette.background.main,
        borderWidth: '1px 0px 0px 0px',
        overflow: 'hidden',
    },
    disabled: {
        // pointerEvents: 'none',
        opacity: 0.3,
    },
    input: {
        ...typography.inputField,
        width: '100%',
        borderStyle: 'solid',
        borderColor: palette.background.main,
        borderWidth: 0,
        padding: 0,
    },
    actions: {
        ...typography.inputField,
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
    },
    positionFirst: {
        borderRadius: '0.7rem 0.7rem 0 0',
    },
    positionLast: {
        borderRadius: '0 0 0.7rem 0.7rem',
    },
    positionSingle: {
        borderRadius: '0.7rem',
    },
}));

const SetupList: React.FC<SetupItemProps> = (props) => {
    const {
        id,
        input,
        actions,
        position,
        disabled,
        minimizedView,
        template = {},

        ...labelProps
    } = props;
    const stl = useStyles(props);

    const checkPosition = (): string => {
        switch (position) {
            case 'first':
                return stl.positionFirst;
            case 'last':
                return stl.positionLast;
            case 'single':
                return stl.positionSingle;
            default:
                return '';
        }
    }

    return (
            <div className={stl.rootWrapper}>
                <div
                        className={CN([stl.root, checkPosition()])}
                        style={{...template.root}}
                >
                    <Label
                            template={template}
                            position={position}
                            minimizedView={minimizedView}
                            {...labelProps}
                    />
                    {input ? (
                            <Field
                                    id={id}
                                    input={input}
                                    template={{ ...template.input }}
                                    disabled={disabled}
                                    minimizedView={minimizedView}
                            />
                    ) : null}
                    {actions ? (
                            <div className={CN(
                                    stl.actions,
                                    {
                                        [stl.disabled]: disabled,
                                        minimizedView: minimizedView,
                                    },
                            )}
                                 style={{...template.actions}}
                            >
                                {actions}
                            </div>
                    ) : null}
                </div>
            </div>
    );
};

export default SetupList;
