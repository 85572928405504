import { combineReducers } from 'redux';
import customers from './customers';
import resources from './resources';
import layout from './layout';
import settings from './settings';
import auth from './auth/auth';
import user from './auth/user';
import backup from './backup/backup';

const rootReducer = combineReducers({
	customers,
	resources,
	layout,
	settings,
	backup,
	auth,
	user,
});

export default rootReducer;
