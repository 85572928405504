import CssBaseline from '@mui/material/CssBaseline';
import {Provider} from 'react-redux';
import {persistor, store} from './store/Store';
import {PersistGate} from 'redux-persist/integration/react';
import Router from 'apps/Router';
import * as React from 'react';
import ReactDOM from 'react-dom';
import Theme from '@gtb/components/Theme/Theme';
import './@styles/global.css';
import './configs/settingsConfig';
import Debugger from "./apps/common/components/layout/Debugger";

ReactDOM.render(
        <Provider store={store}>
            {/*// @ts-ignore*/}
            <PersistGate loading={null} persistor={persistor}>
                <Theme>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline/>
                    <Debugger/>
                    <Router/>
                </Theme>
            </PersistGate>
        </Provider>,
        document.querySelector('#root'),
);
