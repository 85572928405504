import { customersGetData } from './data';

export const CUSTOMERS_REFRESHING_INTERVAL = 300000;
export const CUSTOMERS_SET_REFRESHING = '[CUSTOMERS] SET REFRESHING';
export const CUSTOMERS_REFRESHING = '[CUSTOMERS] REFRESHING';
export const CUSTOMERS_SET_LOADING = '[CUSTOMERS] SET LOADING';

export const customersSetLoading = (value: boolean) => {
	return {
		type: CUSTOMERS_SET_LOADING,
		value,
	};
};

export const customersSetRefreshing = (value: boolean) => {
	return {
		type: CUSTOMERS_SET_REFRESHING,
		value,
	};
};

export const customersRefreshData = (onSuccess: any) => {
	return (dispatch: any) => {
		dispatch({
			type: CUSTOMERS_REFRESHING,
		});
		dispatch(customersGetData(onSuccess));
	};
};
