import React from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import Header from './Header';

const useStyles = makeStyles(({palette, spacing, typography, shadows}: CustomTheme) => ({
    root: {
        minHeight: `calc(100vh - 60px)`,
        marginTop: 60,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: palette.background.main,
        '&:after': {
            zIndex: 1,
            display: 'block',
            content: '""',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: '0px!important',
            backgroundColor: palette.text.secondary,
            opacity: 0.5,
        },
        '&:before': {
            zIndex: 0,
            display: 'block',
            content: '""',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: '0px!important',
            backgroundColor: palette.text.secondary,
            backgroundImage: `url('${window.WEB_ROOT}/assets/dashboard.png')`,
            filter: 'blur(15px) brightness(0.5) opacity(0.5)',
        },
    },
    wrp: {
        zIndex: 10,
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: spacing(23, 0),
    },
    title: {
        ...typography.h1,
        fontSize: '4.8rem',
        color: palette.common.white,
        marginBottom: 0,
    },
    content: {
        zIndex: 10,
        position: 'relative',
        width: '100%',
        maxWidth: 630,
        padding: spacing(3, 7),
        margin: spacing(3, 'auto'),
        borderRadius: 16,
        background: palette.background.main,
        boxShadow: shadows[2],
    },
}));

interface AuthPageInterface {
    [x: string]: any
}

export const AuthPage: React.FC<AuthPageInterface> = (props) => {
    const stl = useStyles(props);
    const {
        title = '',
        children,
    } = props;

    return (
            <div className={stl.root}>
                <Header/>
                <div className={stl.wrp}>
                    <h1 className={stl.title}>{title}</h1>
                    <div className={stl.content}>
                        {children}
                    </div>
                </div>
            </div>
    );
};

export default AuthPage;
