import { useCancellablePromises } from './useCancellablePromises';
import {
	cancellablePromise,
	delay,
} from './cancellablePromise';

export const useClickPreventionOnDoubleClick = (onClick, onDoubleClick) => {
	const {
		appendPendingPromise,
		removePendingPromise,
		clearPendingPromises,
	} = useCancellablePromises();

	const handleClick = ({ ...rest }) => {
		clearPendingPromises();
		const waitForClick = cancellablePromise(delay(300));
		appendPendingPromise(waitForClick);

		return waitForClick.promise
			.then(() => {
				removePendingPromise(waitForClick);
				onClick({ ...rest });
			})
			.catch(errorInfo => {
				removePendingPromise(waitForClick);
				if (!errorInfo.isCanceled) {
					throw errorInfo.error;
				}
			});
	};

	const handleDoubleClick = ({ ...rest }) => {
		clearPendingPromises();
		onDoubleClick({ ...rest });
	};

	return [ handleClick, handleDoubleClick ];
};

export default useClickPreventionOnDoubleClick;
