import * as Actions from 'store/actions';

const initialState = {
	state: null,
	options: {
		anchorOrigin: {
			vertical: 'top',
			horizontal: 'center',
		},
		autoHideDuration: 5000,
		message: ' ',
		type: 'default',
	},
};

const message = function (state = { ...initialState }, action: any = {}) {
	switch (action.type) {
		case Actions.SHOW_MESSAGE: {
			const autoHideDuration = action.options.autoHideDuration;
			return {
				...state,
				state: true,
				options: {
					...state.options,
					...action.options,
					autoHideDuration: autoHideDuration ? autoHideDuration :
						action.options.type === 'error'
							? 15000
							: 5000,
				},
			};
		}
		case Actions.HIDE_MESSAGE: {
			return {
				...state,
				state: null,
				// ...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default message;
