import _ from 'lodash';
import moment from 'moment';
import store from 'store/Store';
import { logout } from 'store/actions';

export const exceptionFetchCatch = (error: any) => {
	console.error(error);
};

export const exceptionFetchUnsupportedFormat = (data: any) => {
	console.error('Error unsupported format');
};

export const getFilenameFromDisposition = (disposition: any) => {
	let filename = '';
	if (disposition && disposition.indexOf('attachment') !== -1) {
		var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		var matches = filenameRegex.exec(disposition);
		if (matches != null && matches[1]) {
			filename = matches[1].replace(/['"]/g, '');
		}
	}

	return filename;
};

export const downloadFileFromBlob = (blob: any, filename = '') => {
	let link = document.createElement('a');
	document.body.appendChild(link);
	link.style.display = 'none;';
	link.setAttribute('target', '_blank');
	let urlLink = window.URL.createObjectURL(blob);
	link.href = urlLink;
	if (filename) {
		link.download = filename;
	}
	link.click();
	window.URL.revokeObjectURL(urlLink);
};

interface DownloadFileInterface {
	url: string,
	defaultFileName?: string,
	options?: any,
	onSuccess?: (message?: any) => any,
	onError?: (err?: any) => any
}

export const downloadFile = (props: DownloadFileInterface) => {

	const {
		url,
		defaultFileName = '',
		options = {},
		onSuccess,
		onError,
	} = props;

	// @ts-ignore
	const { auth } = store.getState();
	const dispatch = store.dispatch;

	// console.log('AUTH', auth);
	const lastUpdated = auth && auth.lastUpdated ? moment(auth.lastUpdated) : false;
	const expirationInterval = auth.expirationInterval ? auth.expirationInterval : 600000;
	if (auth && auth.token && lastUpdated && (Math.abs(lastUpdated.diff(new Date())) < expirationInterval)) {
		const AUTH_HEADERS = {
			Authorization: `Bearer ${auth.token}`,
			...(options && options.headers ? options.headers : {}),
		};
		const OPTIONS_WITH_TOKEN = { ...(options ? options : {}), headers: AUTH_HEADERS };


	let filename = '';
	return fetch(url, OPTIONS_WITH_TOKEN)
		// @ts-ignore
		.then((response, status, xhr) => {
			if (response.status === 200 || response.status === 0) {
				console.log('R HEADERS', response, response.headers, response.headers.get('content-disposition'));
				return response;
			} else {
				throw new Error('Error loading');
			}
		})
		.then(response => {
			filename = getFilenameFromDisposition(response.headers.get('content-disposition'));
			return response;
		})
		.then(response => response.blob())
		.then(blob => {
			downloadFileFromBlob(blob, filename ? filename : defaultFileName);
			if (_.isFunction(onSuccess)) {
				onSuccess();
			}
		})
		.catch(function (err) {
			if (_.isFunction(onError)) {
				onError(err);
			}
		});

} else {
	// @ts-ignore
	dispatch(logout());
}
};


interface FetchDataInterface {
	url: string,
	options?: { [x: string]: any },
	onSuccess?: (message?: any) => void,
	onError?: (error?: any) => void,
	toJSON?: boolean,
	// status: {[x:string]:any}
}


const fetchData = ({ url, options = {}, onSuccess, onError, toJSON = true }: FetchDataInterface) => {
	return fetch(url, options).then(res => {
		// console.log('FETCHING RESPONSE', res);
		if (!res.ok) {
			if (res.status === 400) {
				throw new Error('Incorrect username or password!');
			} else {
				throw new Error('HTTP status ' + res.status);
			}
		}
		return toJSON ? res.json() : false;
	})
		.then(
			(result) => {
				if (_.isFunction(onSuccess)) {
					onSuccess(result);
				}
			},
			(error) => {
				if (_.isFunction(onError)) {
					onError(error);
				}
			},
		);
};


const fetchDataWithAuth = (props: FetchDataInterface) => {
	const {
		url,
		options = {},
		onSuccess,
		onError,
		toJSON = true,
	} = props;

	// @ts-ignore
	const { auth } = store.getState();
	const dispatch = store.dispatch;

	// console.log('AUTH', auth);
	const lastUpdated = auth && auth.lastUpdated ? moment(auth.lastUpdated) : false;
	const expirationInterval = auth.expirationInterval ? auth.expirationInterval : 600000;
	if (auth && auth.token && lastUpdated && (Math.abs(lastUpdated.diff(new Date())) < expirationInterval)) {
		const AUTH_HEADERS = {
			Authorization: `Bearer ${auth.token}`,
			...(options && options.headers ? options.headers : {}),
		};
		const OPTIONS_WITH_TOKEN = { ...(options ? options : {}), headers: AUTH_HEADERS };

		return fetch(url, OPTIONS_WITH_TOKEN).then(res => {
			// console.log('FETCHING RESPONSE', res);
			if (!res.ok) {
				if (res.status === 401) {
					// @ts-ignore
					dispatch(logout());
					throw new Error('Incorrect auth token');
				} else {
					throw new Error('HTTP status ' + res.status);
				}
			}
			return toJSON ? res.json() : false;
		})
			.then(
				(result) => {
					if (_.isFunction(onSuccess)) {
						onSuccess(result);
					}
				},
				(error) => {
					if (error.code == 401) {
						// @ts-ignore
						dispatch(logout());
					} else {
						if (_.isFunction(onError)) {
							onError(error);
						}
					}
				},
			);

	} else {
		// @ts-ignore
		dispatch(logout());
	}
};


export const fetching = (props: FetchDataInterface, auth: boolean = true) => { // TODO: enabled AUTH
	if (auth) {
		return fetchDataWithAuth(props);
	} else {
		return fetchData(props);
	}

};
