// Authorization
export const ICON_MAIL = "M20,3H4C2.3,3,1,4.3,1,6v12c0,1.7,1.3,3,3,3h16c1.7,0,3-1.3,3-3V6C23,4.3,21.7,3,20,3z M21,7.5l-9,6.3L3,7.5V6c0-0.6,0.4-1,1-1h16c0.6,0,1,0.4,1,1V7.5 M21,9.9V18c0,0.6-0.4,1-1,1H4c-0.6,0-1-0.4-1-1V9.9l8.4,5.9c0.2,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2L21,9.9";
export const ICON_COMPLEATED = "M12,23C5.9,23,1,18.1,1,12S5.9,1,12,1C18,1,23,5.9,23,12S18,23,12,23z M12,3c-5,0-9,4-9,9c0,5,4,9,9,9s9-4,9-9	C21,7,16.9,3,12,3z M11.7,15.2c0.4-0.4,0.4-1,0-1.4l-3-3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l3,3c0.2,0.2,0.5,0.3,0.7,0.3	S11.5,15.4,11.7,15.2z M11.7,15.2l5-5c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-5,5c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3	S11.5,15.4,11.7,15.2z";
export const ICON_USERS = "M12,24c-5,0-9-3.1-9-7s4-7,9-7c5,0,9,3.1,9,7S17,24,12,24z M12,12c-3.9,0-7,2.2-7,5s3.1,5,7,5c3.9,0,7-2.2,7-5S15.9,12,12,12z M12,9C9.8,9,8,7.2,8,5s1.8-4,4-4c2.2,0,4,1.8,4,4S14.2,9,12,9z M12,3c-1.1,0-2,0.9-2,2s0.9,2,2,2c1.1,0,2-0.9,2-2	S13.1,3,12,3z";
export const ICON_PASSWORD = "M17.5,10c0.8,0,1.5,0.7,1.5,1.5S18.3,13,17.5,13S16,12.3,16,11.5S16.7,10,17.5,10z M17.5,18	c-2.2,0-4.3-1.1-5.5-3h-0.6l-2.7,2.7c-0.4,0.4-1,0.4-1.4,0L4.6,15H3.5C1.6,15,0,13.4,0,11.5S1.6,8,3.5,8H12c1.2-1.9,3.2-3,5.5-3h0	c3.6,0,6.5,2.9,6.5,6.5S21.1,18,17.5,18z M11,13h1.6c0.4,0,0.7,0.2,0.9,0.5c0.8,1.5,2.3,2.5,4,2.5c2.5,0,4.5-2,4.5-4.5S20,7,17.5,7	h0c-1.7,0-3.2,0.9-4,2.5C13.3,9.8,13,10,12.6,10H3.5C2.7,10,2,10.7,2,11.5S2.7,13,3.5,13H5c0.3,0,0.5,0.1,0.7,0.3L8,15.6l2.3-2.3	C10.5,13.1,10.7,13,11,13z";
export const ICON_PASSWORD_RECOVERY = [
    {path: "M17.5,7C18.3,7,19,7.7,19,8.5S18.3,10,17.5,10S16,9.3,16,8.5S16.7,7,17.5,7z M8.7,14.7l1-1c0.4-0.4,0.4-1,0-1.4	s-1-0.4-1.4,0L8,12.6l-2.3-2.3C5.5,10.1,5.3,10,5,10H3.5C2.7,10,2,9.3,2,8.5S2.7,7,3.5,7h9.1c0.4,0,0.7-0.2,0.9-0.5	C14.3,5,15.8,4,17.5,4l0,0C20,4,22,6,22,8.5c0,0.4-0.1,0.8-0.2,1.2c-0.2,0.5,0.2,1.1,0.7,1.2c0.5,0.1,1.1-0.2,1.2-0.7	C23.9,9.7,24,9.1,24,8.5C24,4.9,21.1,2,17.5,2l0,0c-2.2,0-4.3,1.1-5.5,3H3.5C1.6,5,0,6.6,0,8.5S1.6,12,3.5,12h1.1l2.7,2.7	C7.5,14.9,7.7,15,8,15S8.5,14.9,8.7,14.7z"},
    {primary: true, path: "M23.8,16.4c-0.4-1.5-1.4-2.8-2.8-3.6s-3.1-1-4.6-0.6c-0.7,0.2-1.3,0.4-1.8,0.8l-0.1,0.1L13.4,12	c-0.1-0.1-0.3-0.2-0.5-0.1c-0.2,0.1-0.3,0.2-0.3,0.4l-0.4,3.9c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.4,0.1l3.9-0.4	c0.2,0,0.4-0.1,0.4-0.3c0.1-0.2,0-0.4-0.1-0.5L16,14.6c0.2-0.2,0.5-0.3,0.9-0.4c1-0.3,2.1-0.1,3,0.4c1,0.5,1.6,1.4,1.9,2.4	s0.1,2.1-0.4,3c-0.5,1-1.4,1.6-2.4,1.9c-1,0.3-2,0.1-3-0.4s-1.6-1.4-1.9-2.4c-0.1-0.5-0.7-0.8-1.2-0.7c-0.5,0.1-0.8,0.7-0.7,1.2	c0.7,2.6,3.2,4.4,5.8,4.4c0.5,0,1-0.1,1.6-0.2c1.5-0.4,2.8-1.4,3.6-2.8S24.2,17.9,23.8,16.4z"},
];
export const ICON_CREDENTIALS = [
    {path: "M6.5,24c-2,0-3.5-1.5-3.5-3.5v-1.4l-2.1-1.8c-0.2-0.2-0.4-0.5-0.4-0.8s0.1-0.6,0.4-0.8L3,14.1V12c-1.8-1.2-3-3.3-3-5.5C0,4,1.4,1.7,3.8,0.6c0.5-0.2,1.1,0,1.3,0.5s0,1.1-0.5,1.3C3,3.2,2,4.7,2,6.5c0,1.7,1,3.2,2.4,4c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3C4.9,11,5,11.1,5,11.3v0.1v3.1c0,0,0,0,0,0.1l0,0l0,0c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3l0,0l-1.5,1.2l1.6,1.3l0,0C4.9,17.9,5,18,5,18.1c0,0.2,0,0.4,0,0.5l0,0v1.9C5,21.3,5.7,22,6.5,22S8,21.3,8,20.5v-5c0-0.6,0.4-1,1-1s1,0.4,1,1v5C10,22.5,8.5,24,6.5,24z"},
    {primary: true, path: "M20.5,19.8c-0.9,0-1.8-0.4-2.5-1L17.2,18h-3.5c-0.6,0-1-0.4-1-1v-3.5L12,12.8c-2.1,0.5-4.4-0.1-6-1.7C4.8,9.8,4.1,8.2,4.1,6.5c0-1.8,0.7-3.4,1.9-4.6c2.6-2.5,6.7-2.5,9.2,0c1.6,1.6,2.2,3.8,1.7,6l6,6c0.6,0.6,1,1.4,1,2.3c0,1-0.4,1.9-1,2.6C22.3,19.4,21.5,19.8,20.5,19.8L20.5,19.8z M18,16.1c0.1,0,0.2,0.1,0.3,0.2l1.1,1.1c0.3,0.3,0.7,0.5,1.1,0.5s0.7-0.2,0.9-0.4c0.3-0.3,0.5-0.7,0.5-1.2c0-0.4-0.2-0.7-0.4-0.9L15.1,9L15,8.9c-0.1-0.1-0.2-0.2-0.2-0.4s0-0.3,0-0.4V8c0.5-1.7,0.1-3.4-1.1-4.6c-1.7-1.7-4.6-1.7-6.4,0C6.5,4.1,6.1,5.2,6.1,6.5c0,1.2,0.5,2.4,1.3,3.3c1.2,1.2,3,1.6,4.5,1.1c0,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.2l0.1,0.1l1.4,1.4l0,0l0,0l0,0l0,0l0,0c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.1,0.4V16h2.9l0,0C17.8,16,17.9,16,18,16.1z M10.6,5.5c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S10,5.5,10.6,5.5z"},
];
export const ICON_INFORMATION = "M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12C2,6.5,6.5,2,12,2 M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12	s12-5.4,12-12C24,5.4,18.6,0,12,0z M13,17v-6c0-0.6-0.4-1-1-1s-1,0.4-1,1v6c0,0.6,0.4,1,1,1S13,17.6,13,17z M12,8c-0.6,0-1-0.4-1-1	s0.4-1,1-1c0.6,0,1,0.4,1,1S12.6,8,12,8z";
export const ICON_LOGIN_V1 = [
    {path: "M21,22H5c-0.8,0-1.6-0.3-2.1-0.9C2.3,20.6,2,19.8,2,19v-3c0-0.6,0.4-1,1-1s1,0.4,1,1v3c0,0.3,0.1,0.5,0.3,0.7	C4.5,19.9,4.7,20,5,20h16c0.3,0,0.5-0.1,0.7-0.3S22,19.3,22,19V5c0-0.3-0.1-0.5-0.3-0.7C21.5,4.1,21.3,4,21,4H5	C4.7,4,4.5,4.1,4.3,4.3C4.1,4.5,4,4.7,4,5v3c0,0.6-0.4,1-1,1S2,8.6,2,8V5c0-0.8,0.3-1.6,0.9-2.1C3.4,2.3,4.2,2,5,2h16	c0.8,0,1.6,0.3,2.1,0.9C23.7,3.4,24,4.2,24,5v14c0,0.8-0.3,1.6-0.9,2.1S21.8,22,21,22z"},
    {primary: true, path: "M14.7,11.3l-4-4c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l2.3,2.3H1c-0.6,0-1,0.4-1,1s0.4,1,1,1h10.6l-2.3,2.3c-0.4,0.4-0.4,1,0,1.4C9.5,16.9,9.7,17,10,17s0.5-0.1,0.7-0.3l4-4C15.1,12.3,15.1,11.7,14.7,11.3z"}
];
export const ICON_LOGIN_V2 = [
    {path: "M0.9,21.1C0.3,20.6,0,19.8,0,19L0,5c0-0.8,0.3-1.6,0.9-2.1C1.4,2.3,2.2,2,3,2h16c0.8,0,1.6,0.3,2.1,0.9	C21.7,3.4,22,4.2,22,5v3c0,0.6-0.4,1-1,1s-1-0.4-1-1V5c0-0.3-0.1-0.5-0.3-0.7C19.5,4.1,19.3,4,19,4H3C2.7,4,2.5,4.1,2.3,4.3	C2.1,4.5,2,4.7,2,5v14c0,0.3,0.1,0.5,0.3,0.7S2.7,20,3,20h16c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-3c0-0.6,0.4-1,1-1	s1,0.4,1,1v3c0,0.8-0.3,1.6-0.9,2.1C20.6,21.7,19.8,22,19,22H3C2.2,22,1.5,21.6,0.9,21.1z"},
    {primary: true, path: "M23,11H12.4l2.3-2.3c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-4,4c-0.4,0.4-0.4,1,0,1.4l4,4c0.2,0.2,0.4,0.3,0.7,0.1s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L12.4,13H23c0.6,0,1-0.4,1-1S23.6,11,23,11z"},
];
export const ICON_PASSWORD_REPEAT = "M17.5,13.3c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5S16,15.6,16,14.8S16.7,13.3,17.5,13.3z M17.5,21.3	c-2.2,0-4.3-1.1-5.5-3h-0.6L8.7,21c-0.4,0.4-1,0.4-1.4,0l-2.7-2.7H3.5c-1.9,0-3.5-1.6-3.5-3.5s1.6-3.5,3.5-3.5H12	c1.2-1.9,3.2-3,5.5-3h0c3.6,0,6.5,2.9,6.5,6.5S21.1,21.3,17.5,21.3z M11,16.3h1.6c0.4,0,0.7,0.2,0.9,0.5c0.8,1.5,2.3,2.5,4,2.5	c2.5,0,4.5-2,4.5-4.5s-2-4.5-4.5-4.5h0c-1.7,0-3.2,0.9-4,2.5c-0.2,0.3-0.5,0.5-0.9,0.5H3.5c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5	H5c0.3,0,0.5,0.1,0.7,0.3L8,18.8l2.3-2.3C10.5,16.4,10.7,16.3,11,16.3z M22.3,6 M2,9.3c0-0.8,0.7-1.5,1.5-1.5h9.1	c0.4,0,0.7-0.2,0.9-0.5c0.8-1.5,2.3-2.5,4-2.5h0c1.6,0,3.1,0.9,3.9,2.2c0.3,0.5,0.9,0.6,1.4,0.4c0.5-0.3,0.6-0.9,0.4-1.4	c-1.2-2-3.3-3.2-5.6-3.2h0c-2.2,0-4.3,1.1-5.5,3H3.5C1.6,5.8,0,7.3,0,9.3c0,0.6,0.4,1,1,1S2,9.8,2,9.3z M1,8.8 M1,8.8";
export const ICON_PASSWORD_APPLE = "M18,12c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S21.3,12,18,12z M21.7,16.7l-4,4c-0.4,0.4-1,0.4-1.4,0l0,0l-2-2	c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l1.3,1.3l3.3-3.3c0.4-0.4,1-0.4,1.4,0C22.1,15.7,22.1,16.3,21.7,16.7z M17.5,7.7	c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5S16,10,16,9.2S16.7,7.7,17.5,7.7z M8,15.7c-0.3,0-0.5-0.1-0.7-0.3l-2.7-2.7H3.5	C1.6,12.7,0,11.1,0,9.2s1.6-3.5,3.5-3.5H12c1.2-1.9,3.2-3,5.5-3h0c3.6,0,6.5,2.9,6.5,6.5c0,0.5,0,0.9-0.1,1.3	c-0.1,0.5-0.6,0.9-1.2,0.8c-0.5-0.1-0.9-0.6-0.8-1.2C22,9.8,22,9.5,22,9.2c0-2.5-2-4.5-4.5-4.5h0c-1.7,0-3.2,0.9-4,2.5	c-0.2,0.3-0.5,0.5-0.9,0.5H3.5C2.7,7.7,2,8.4,2,9.2s0.7,1.5,1.5,1.5H5c0.3,0,0.5,0.1,0.7,0.3L8,13.3l2.3-2.3	c0.2-0.2,0.4-0.3,0.7-0.3h1.6c0.6,0,1,0.4,1,1s-0.4,1-1,1h-1.2l-2.7,2.7C8.5,15.6,8.3,15.7,8,15.7z";
