import {makeStyles} from '@mui/styles';
import * as React from 'react';
import {CustomTheme} from "@gtb/interfaces";

const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    root: {
        width: '100%',
        height: 60,
        display: 'flex',
        alignItems: "center",
        justifyContent: "space-between",
        padding: spacing(0, 0, 0, 3),
    },
    wrp: {
        width: '100%',
        height: 60,
        display: 'flex',
        alignItems: "center",
        justifyContent: "flex-start",
    },
    time: {
        flexShrink: 0,
    },
    version: {
        marginLeft: 'auto',
    },
    breadcrumbs: {}
}));

interface TopBarProps extends React.PropsWithChildren<any> {
    time?: JSX.Element,
    userIsLogged?: JSX.Element,
    version?: JSX.Element,
}

const TopBar: React.FC<TopBarProps> = (props) => {
    const stl = useStyles();
    const {time, userIsLogged, version, children} = props;

    return (
            <div className={stl.root}>
                <div className={stl.wrp}>
                    {children}
                </div>
                {userIsLogged}
                {version ? <div className={stl.version}>{version}</div> : null}
                {time ? <div className={stl.time}>{time}</div> : null}
            </div>
    );
};

export default TopBar;
