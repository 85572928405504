import palette from './palette';
import { CustomThemeOverridesOptions } from '../interfaces';

const overrides: CustomThemeOverridesOptions = {
	MuiPickersToolbar: {
		toolbar: {
			backgroundColor: palette.action!.active,
		},
	},
	MuiPickerDTHeader: {
		dateHeader: {
			'& h4': {
				marginTop: '1.5rem',
			},
		},
		timeHeader: {
			alignItems: 'center',
		},
		hourMinuteLabel: {
			'& h3': {
				top: 'inherit',
				fontSize: 48,
			},
		},
	},
	MuiInput: {
		underline: {
			'&:before': { //underline color when textfield is inactive
				borderBottom: 'none',
				content: '',
			},
			'&:hover:not($disabled):before': { //underline color when hovered
				borderBottom: 'none',
				content: '',
			},
			'&:after': { //underline color when textfield is inactive
				borderBottom: 'none',
				content: '',
			},
			'&:hover:not($disabled):after': { //underline color when hovered
				borderBottom: 'none',
				content: '',
			},
		},
		input: {
			padding: 0,
			//color: 'rgba(41, 71, 102, .8) !important',
		},
	},
	MuiCheckbox: {
		root: {
			color: palette.text!.secondary,
			'&$checked': {
				color: palette.action!.active,
			},
		},
		colorSecondary: {
			color: palette.text!.secondary,
			'&$checked': {
				color: palette.action!.active,
			},
		},
	},
	MuiPaper: {
		elevation1: {
			boxShadow: 'none',
		},
		elevation2: {
			boxShadow: 'none',
		},
		elevation3: {
			boxShadow: 'none',
		},
		elevation4: {
			boxShadow: 'none',
		},
		elevation5: {
			boxShadow: 'none',
		},
		elevation6: {
			boxShadow: 'none',
		},
		elevation7: {
			boxShadow: 'none',
		},
		elevation8: {
			boxShadow: 'none',
		},
	},
};

export default overrides;
