import { notEmptyArray, StringToOneRow } from '@gtb/utils';

export const defaultView = {
	type: 'simple-text',
	className: 'middle',
	inOneLine: true,
};


export const generateDefaultView = (row: { [x: string]: any }, slug: string, validation?: (x: any) => any) => {
	return {
		...defaultView,
		slug: slug,
		value: typeof validation === 'function'
			? validation(row[slug])
			: StringToOneRow(row[slug]),
	};
};

const parseWidth = (width: string, m = 50) => {
	const w = parseInt(width.replace('px', ''));
	return isNaN(w) || w < m ? m : w;
};

export const getGridTemplate = (template: (string | number)[]) => {
	return notEmptyArray(template) ? template.map((t: string | number) => {
		if (typeof t === 'string') {

			/*  chek for 'minmax(***px, 1fr)' expression and get min width */
			if (t.includes('minmax')) {
				let args = /\(\s*([^)]+?)\s*\)/.exec(t);
				if (args && args[1]) {
					// @ts-ignore
					args = args[1].split(/\s*,\s*/);
				}
				// @ts-ignore
				return parseWidth(notEmptyArray(args) ? args[0] : t, 50);
			}

			return parseWidth(t, 50);
		} else {
			return 50; /// TODO: replace min width to dynamic value
		}
	}) : [];
};

export const getMinTemplateWidth = (template: (string | number)[]) => {
	const tmp = getGridTemplate(template);
	return notEmptyArray(tmp) ? tmp.reduce((a, b) => a + b, 0) : 0;
};
