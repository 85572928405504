import React, {useCallback, useState, useEffect} from 'react';
import Page from 'apps/common/components/Page';
import Toolbar from './Toolbar';
import {Grid} from '@gtb';
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import Columns from '../configs/GridConfig';
import {generateDefaultView} from '@gtb/components/Grid/utils';
import Icon, * as Icons from '@gtb/components/Icons';
import {CN, FULL_TIME_FORMAT} from '@gtb/utils';
import {useSelector, useDispatch} from 'react-redux';
import {resourcesSelect, resourcesGetData} from 'store/actions';

const useStyles = makeStyles(({palette, spacing}: CustomTheme) => ({
    grid: {
        maxWidth: 1000,
    },
    content: {
        marginTop: spacing(-13)
    },
    status: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        textDecoration: 'none!important',
        '&.active': {
            color: palette.action.active3,
        }
    },
    id: {
        display: 'flex',
        width: '100%',
    },
    rowTemplate: {
        gridTemplateColumns: '72px auto 735px 475px',
    },
}));

interface ResourcesDataInterface {
    '_id'?: string,
    serverIp?: string,
    status?: number,
    space?: number,
    cpu?: number,
    memory?: number,
    updatedAt?: string,
}

export const App: React.FC = () => {
            const stl = useStyles();

            // @ts-ignore
            const {select, data} = useSelector((state) => state.resources);
            const dispatch = useDispatch();

            // console.log('RESOURCES STORE SELECT', select)
            // console.log('RESOURCES STORE DATA', data)

            const [isLoading, isLoaded] = useState<boolean>(true);
            const [selected, setSelected] = useState<string[]>(select);

            const onSelect = (s: string[]) => {
                dispatch(resourcesSelect(s))
                setSelected(s)
            }

            useEffect(() => {
                // @ts-ignore
                dispatch(resourcesGetData(() => {
                    isLoaded(false)
                }))
            }, [])

            const tbody = useCallback(dataCallback => {
                return dataCallback.map((row: ResourcesDataInterface, rowIndex: number) => {

                    return {
                        _id: row.hasOwnProperty('_id') ? row['_id'] : rowIndex + 1,
                        onClick: () => {
                            console.log('RoW click');
                        },
                        onDoubleClick: () => {
                            console.log('RoW double click');
                        },
                        fields: [
                            {
                                type: 'custom',
                                slug: '_id',
                                className: 'middle smallPadding',
                                full: true,
                                content: <div className={stl.id}>{rowIndex + 1}</div>
                            },
                            {
                                ...generateDefaultView(row, 'serverIp'),
                                className: 'middle smallPadding',
                            },
                            {
                                type: 'custom',
                                slug: 'status',
                                className: 'middle smallPadding',
                                full: true,
                                content: <div className={CN(stl.status, {active: row['status']})}>
                                    {row['status'] ? 'Running' : 'Powered Off'}
                                </div>
                            },
                            {
                                type: 'size',
                                slug: 'space',
                                dynamicFormat: true,
                                value: row['space'],
                                className: 'centered minimizedView',
                            },
                            {
                                ...generateDefaultView(row, 'cpu'),
                                className: 'middle centered smallPadding',
                            },
                            {
                                type: 'size',
                                slug: 'memory',
                                dynamicFormat: true,
                                value: row['memory'],
                                className: 'centered minimizedView',
                            },
                            {
                                type: 'date-view',
                                slug: 'updatedAt',
                                format: FULL_TIME_FORMAT,
                                className: 'middle centered smallPadding',
                                value: row['updatedAt'],
                            },
                        ],
                    };
                });
            }, [JSON.stringify(data)]);

            return (
                    <Page
                            path={['Resources']}
                            toolbar={<Toolbar/>}

                            classes={{content: stl.content}}
                    >
                        <Grid
                                // @ts-ignore
                                classes={{root: stl.grid}}
                                filters={[]}
                                virtualizer={false}
                                className={'hovered'}
                                isLoading={isLoading}
                                thead={[{fields: Columns}]}
                                data={data}
                                tbody={typeof tbody === 'function'
                                        ? tbody
                                        : () => {
                                            return [];
                                        }}
                                selected={selected}
                                onSelect={onSelect}
                                maxHeight={800}
                        />
                    </Page>
            );
        }
;

export default App;
