import React from 'react';
import {CN} from '@gtb/utils';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "../../Theme/interfaces";
import {Copy} from '@gtb/components/Setups/components/index';

const useStyles = makeStyles(({spacing}: CustomTheme) => ({
    root: {
        display: 'flex',
        '&.full': {
            width: '100%',
            height: '100%',
            alignItems: 'center',
        },
        '&.centered': {
            justifyContent: 'center',
        },
        '&.disabled': {
            pointerEvents: 'none',
            opacity: 0.3,
        },
        '.th &': {
            padding: spacing(0, 3),
        },
        // '.td &': {
        // 	padding: [[0, spacing.unit * 3]],
        // },
        '.td.smallPadding &, .th.smallPadding &': {
            padding: spacing(0, 1),
        },
        '&.inOneLine': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        '.noPadding &, .noPadding.th &': {
            padding: '0',
        },
        '.withPadding &': {
            padding: spacing(0, 3),
        },
    },
    copy: {
        height: '100%',
        width: '100%',
    },
}));


interface CustomFieldProps {
    content?: JSX.Element,
    disabled?: boolean,
    full?: boolean,
    centered?: boolean,
    inOneLine?: boolean,
    copy?: boolean
}

const Custom: React.FC<CustomFieldProps> = (props) => {

    const classes = useStyles(props);
    const {content, children, disabled, full, centered, inOneLine, copy} = props;

    const Cell = (
            <div
                    className={CN(classes.root, {
                        disabled: disabled,
                        full: full,
                        centered: centered,
                        inOneLine: inOneLine,
                    })}
            >
                {content}
                {children}
            </div>
    );

    return (copy ? <Copy classes={{root: classes.copy}}>{Cell}</Copy> : Cell);
};

export default Custom;
