import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {useDispatch, useSelector} from "react-redux";
import Time from 'apps/common/components/layout/Time';

const useStyles = makeStyles(({palette, spacing, typography}: CustomTheme) => ({
    root: {
        zIndex: 99,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'fixed',
        width: '100%',
        height: 60,
        top: 0,
        left: 0,
        background: palette.action.active,
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
    },
    logoIcon: {
        width: '4.8rem',
        marginLeft: '1.6rem',
        height: '4.8rem',
    },
    logoText: {
        fontSize: '2rem',
        marginLeft: '0.8rem',
        fontWeight: 300,
        color: palette.common.white,
    },
}));

interface LoginHeaderInterface {
    [x: string]: any
}


export const LoginHeader: React.FC<LoginHeaderInterface> = (props) => {
    const stl = useStyles(props);
    // @ts-ignore
    const {revision} = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        // @ts-ignore

    }, []);

    return (
            <div className={stl.root}>
                <div className={stl.logo}>
                    <img className={stl.logoIcon}
                         src={window.WEB_ROOT + '/assets/logo.svg'}
                         alt={'logo'}
                    />
                    <div className={stl.logoText}>
                        {'GTB Technologies, Inc'}
                    </div>
                </div>
                {
                    <Time className={'unlogged'}/>
                }
            </div>
    );
};

export default LoginHeader;
