import React from 'react';
import {Tooltip, TooltipProps} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "../Theme/interfaces";


export const useStyles = makeStyles(({typography, spacing, custom, shadows}: CustomTheme) => ({
    popper: {
        opacity: 1,
    },
    tooltip: {
        border: '1px solid ' + '#7ACC29',
        ...typography.p,
        maxWidth: 1200,
        padding: spacing(3),
        background: custom.palette.message.lightGreen,
        borderRadius: '0.7rem',
        boxShadow: shadows[2],
        worldWrap: 'break-word',
        '& .MuiTooltip-arrow': {
            color: '#7ACC29',
        }

    },
    tooltipPlacementBottom: {},
    tooltipPlacementTop: {
        marginBottom: spacing(0.5) + '!important',

    },
    tooltipPlacementRight: {
        marginBottom: spacing(0.5) + '!important',
        transition: 'all 0s 0s ease!important',

    },
    tooltipPlacementLeft: {
        marginBottom: spacing(0.5) + '!important',
        transition: 'all 0s 0s ease!important',
    },
}));

export interface CustomTooltip extends TooltipProps {
    classes?: any,
    wrapped?: boolean
};

const CustomTooltip: React.FC<CustomTooltip> = (props) => {
    const styles = useStyles(props);
    const {classes, wrapped, ...rest} = props;

    return wrapped
            ? rest.title
                    ? <Tooltip classes={styles} {...rest} arrow/>
                    : rest.children
            : <Tooltip classes={styles} {...rest} arrow/>;
}

export default CustomTooltip;
