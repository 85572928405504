declare global {
	interface Window {
		HTTP_PROTOCOL: string,
		APP_DEV: string,
		CC_API: string,
	}
}

export const HTTP_PROTOCOL = 'https://';
export const APP_DEV = 'app_dev.php';
export const CC_API = 'ccapi.php';

window.HTTP_PROTOCOL = HTTP_PROTOCOL;
window.APP_DEV = APP_DEV;
window.CC_API = CC_API;
