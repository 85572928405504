import React, { useRef } from 'react';
import { CN } from '@gtb/utils';
// import chroma from 'chroma-js';
import { MenuItem } from '@mui/material';
import Icon, * as Icons from '@gtb/components/Icons';
import {
	CTooltip as Tooltip,
	IconButton,
} from '@gtb';

const Option = props => {
	const wrp = useRef(null);
	const color = props.data.color
		? props.data.color
		: false; // chroma(props.data.color) : false;
	const marckerColor = color
		? color.alpha(0.7).css()
		: null;
	const iconColor = props.data.iconColor
		? [ props.data.iconColor, props.data.iconColor, props.data.iconColor, props.data.iconColor ]
		: false;

	const checkForOverflow = tooltip => {
		if (wrp && wrp.current && wrp.current.scrollWidth > wrp.current.clientWidth) {
			return tooltip;
		} else {
			return '';
		}
	};

	/*
	 if (props.data.unactive) {
	 return (
	 <MenuItem
	 style={{
	 color: props.isDisabled && ' #FF7F00',
	 cursor: props.isDisabled ? 'not-allowed' : 'default',
	 pointerEvents: 'none',
	 opacity: 0.5,
	 filter: 'grayscale(100%)',
	 }}
	 buttonRef={props.innerRef}
	 selected={props.isSelected}
	 component={'div'}
	 disableRipple={true}
	 classes={{
	 root: props.selectProps.classes.option,
	 selected: props.selectProps.classes.isSelected,
	 disabled: props.selectProps.classes.disabled,
	 }}
	 {...props.innerProps}
	 >
	 {marckerColor && (
	 <GtbIconButton className={props.selectProps.classes.chipColorIcon}>
	 <Icon view={Icons.ICON_SEVERITY} fill={[ marckerColor, marckerColor, marckerColor ]}/>
	 </GtbIconButton>
	 )}
	 {props.data && props.data.icon && !props.data.tooltip && (
	 <Tooltip
	 classes={{ popper: props.selectProps.classes.optionTooltip }}
	 title={props.selectProps.onlyIcons ? props.children : ''}
	 placement={props.selectProps.onlyIcons ? 'right' : 'top'}
	 >
	 <div className={props.selectProps.classes.icon}>
	 {typeof props.data.icon == 'string' ? (
	 <Icon view={props.data.icon} fill={iconColor}/>
	 ) : (
	 props.data.icon
	 )}
	 </div>
	 </Tooltip>
	 )}
	 {props.data.tooltip && (
	 <Tooltip
	 classes={{
	 popper: props.selectProps.classes.optionTooltip,
	 tooltipPlacementBottom: props.selectProps.classes.tooltipPlacementBottom,
	 }}
	 title={props.data.withTooltip
	 ? checkForOverflow(props.data.tooltip)
	 : props.data.tooltip}
	 placement={props.selectProps.tooltipPlacement || 'right'}
	 >
	 <div className={props.selectProps.classes.optionValue}>
	 {props.data.icon && (
	 <div className={props.selectProps.classes.icon}>
	 {typeof props.data.icon == 'string' ? (
	 <Icon view={props.data.icon} fill={iconColor}/>
	 ) : (
	 props.data.icon
	 )}
	 </div>
	 )}
	 <div className={classNames('textValue', { 'bold': props.data.isBolded })} ref={wrp}>
	 {props.children}
	 </div>
	 </div>
	 </Tooltip>
	 )}
	 {!props.selectProps.onlyIcons && !props.data.tooltip && (
	 <div className={classNames({ 'bold': props.data.isBolded })}>
	 {props.children}
	 </div>
	 )}
	 {props.isSelected && (
	 <div className={props.selectProps.classes.checked}>
	 <Icon view={Icons.ICON_CHECKBOX_CHECKED_ACTIVE}/>
	 </div>
	 )}
	 </MenuItem>
	 )
	 }
	 /**/

	return (
		<MenuItem
			style={{
				color: props.isDisabled && ' #FF7F00',
				cursor: props.isDisabled
					? 'not-allowed'
					: 'default',
				pointerEvents: props.isDisabled && 'none',
			}}
			ref={props.innerRef}
			selected={props.isSelected}
			disabled={props.data.unactive}
			component={'div'}
			disableRipple={true}
			classes={{
				root: props.selectProps.classes.option,
				selected: props.selectProps.classes.isSelected,
			}}
			{...props.innerProps}
		>
			{marckerColor && (
				<IconButton className={props.selectProps.classes.chipColorIcon}>
					<Icon view={Icons.ICON_SEVERITY} fill={[ marckerColor, marckerColor, marckerColor ]}/>
				</IconButton>
			)}
			{props.data && props.data.icon && !props.data.tooltip && (
				<Tooltip
					classes={{ popper: props.selectProps.classes.optionTooltip }}
					title={props.selectProps.onlyIcons
						? props.children
						: ''}
					placement={props.selectProps.onlyIcons
						? 'right'
						: 'top'}
				>
					<div className={props.selectProps.classes.icon}>
						{typeof props.data.icon == 'string'
							? (
								<Icon view={props.data.icon} fill={iconColor}/>
							)
							: (
								props.data.icon
							)}
					</div>
				</Tooltip>
			)}
			{props.data.tooltip && (
				<Tooltip
					classes={{
						popper: props.selectProps.classes.optionTooltip,
						tooltipPlacementBottom: props.selectProps.classes.tooltipPlacementBottom,
					}}
					title={props.data.withTooltip
						? checkForOverflow(props.data.tooltip)
						: props.data.tooltip}
					placement={props.selectProps.tooltipPlacement || 'right'}
				>
					<div className={props.selectProps.classes.optionValue}>
						{props.data.icon && (
							<div className={props.selectProps.classes.icon}>
								{typeof props.data.icon == 'string'
									? (
										<Icon view={props.data.icon} fill={iconColor}/>
									)
									: (
										props.data.icon
									)}
							</div>
						)}
						<div className={CN('textValue', { 'bold': props.data.isBolded })} ref={wrp}>
							{props.children}
						</div>
					</div>
				</Tooltip>
			)}
			{!props.selectProps.onlyIcons && !props.data.tooltip && (
				<div className={CN({ 'bold': props.data.isBolded })}>
					{props.children}
				</div>
			)}
			{props.isSelected && (
				<div className={props.selectProps.classes.checked}>
					<Icon view={Icons.ICON_CHECKBOX_CHECKED_ACTIVE}/>
				</div>
			)}
		</MenuItem>
	);
};

export default Option;
