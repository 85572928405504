import { CUSTOMERS_SET_REFRESHING, CUSTOMERS_REFRESHING_INTERVAL, CUSTOMERS_SET_LOADING } from 'store/actions';

const initialState = {
	isLoading: true,
	value: false,
	interval: CUSTOMERS_REFRESHING_INTERVAL,
};

const refreshing = function (state = initialState, action: { [x: string]: any } = {}) {
	switch (action.type) {
		case CUSTOMERS_SET_REFRESHING: {
			return { ...state, value: action.value };
		}
		case CUSTOMERS_SET_LOADING: {
			return { ...state, isLoading: action.value };
		}
		default: {
			return state;
		}
	}
};

export default refreshing;
