import { API_URL } from './config';
import { fetching } from './utils';

export const getResourcesData = (onSuccess?: (message: any) => void, onError?: (error: any) => void) => {
	fetching({
		url: API_URL + '/api/resources',
		onSuccess,
		onError,
	});
};


export const updateResource = (
	id: string,
	data: { [x: string]: any },
	onSuccess?: (message: any) => void,
	onError?: (error: any) => void,
) => {

	fetching({
		url: API_URL + `/api/resources/${id}`,
		options: {
			method: 'PUT',
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		},
		onSuccess,
		onError,
	});
};
