import React from 'react';
import {CN} from '@gtb/utils';
import {makeStyles, useTheme} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {NavLink as Link} from "react-router-dom";
import {NavigationRoutes} from '@interface/IRoutes';
import Icon, * as Icons from '@gtb/components/Icons';

const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    root: {
        display: 'flex',
        flexDirection: "column",
    },
    li: {
        position: 'relative',
        display: 'flex',
        padding: 0,
        lineHeight: '2.4rem',
        borderBottomWidth: 1,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderStyle: 'solid',
        borderColor: palette.common.white,
        '&:last-child': {
            borderBottomWidth: 0,
        },
        '&:focus': {
            // background: 'inherit',
        },
        '&:hover': {
            background: palette.action.hover,
        },
    },
    icon: {
        height: spacing(3),
        width: spacing(3),
        marginLeft: spacing(1),
        marginRight: spacing(3)
    },
    link: {
        ...typography.mainMenu,
        display: 'flex',
        alignItems: "center",
        width: '100%',
        height: spacing(7),
        padding: '1.35rem 0.8rem 1.35rem 2rem',
        color: palette.text.primary,
        textDecoration: 'none',
        '&:hover': {
            color: palette.text.primary,
        },
        '&.active': {
            backgroundColor: palette.action.hover,
            '& span': {
                backgroundColor: 'transparent',
                pointerEvents: 'none',
                color: palette.action.active,
                fontWeight: 'bold',
            }
        },
    },
    checked: {
        marginLeft: "auto",
        display: 'none',
        '.active &': {
            display: 'block',
        }
    }
}));

interface MainMenuProps {
    nav: NavigationRoutes,
}

const MainMenu: React.FC<MainMenuProps> = ({nav,}
) => {
    const classes = useStyles();
    const theme: CustomTheme = useTheme();

    return (
            <div className={classes.root}>
                {nav.filter(e => !e.hidden).map((li => {
                    return (
                            <div key={'main-menu_' + li.path} className={classes.li}>
                                <Link
                                        className={navData => CN(classes.link, {active: navData && navData.isActive})}
                                        to={li.path}
                                >
                                    {li.icon ? <div className={classes.icon}>{li.icon}</div> : null}
                                    {li.label}
                                    <div className={classes.checked}>
                                        <Icon view={Icons.ICON_CHECKBOX_CHECKED_ACTIVE}
                                              fill={[theme.palette.action.active3, theme.palette.action.active3, theme.palette.action.active3]}/>
                                    </div>
                                </Link>
                            </div>
                    );
                }))}
            </div>
    );
};

export default MainMenu;
