import {RESOURCES_SELECT} from '../../actions';

const select = function (state = [], action: {[x:string]: any} = {}) {
	switch (action.type) {
		case RESOURCES_SELECT: {
			return [ ...action.selected ];
		}
		default: {
			return state;
		}
	}
};

export default select;
