import React from 'react';
import {
    deleteCustomer,
    updateCustomer,
    getCustomersData,
    addCustomer,
    uploadCustomerLicense,
    downloadCustomerSysInfo, removeCustomerLicense, sendCustomerSysInfoToSupport,
} from 'api';
import _ from 'lodash';
import moment from "moment";
import {
    MESSAGE_SUCCESS,
    MESSAGE_ERROR,
    MODAL_SKIN_SYSTEM_MESSAGE,
    showMessage,
    showModal,
    hideModal,
} from '../layout';
import {customersRefreshData, customersSetLoading, customersSetRefreshing} from './refreshing';
import {LoadingModal as Loading} from '@gtb';
import {isInCustomerGrid} from 'apps/customers/utils'

export const CUSTOMERS_GET_DATA = '[CUSTOMERS] GET DATA';
export const CUSTOMERS_SUCCESS_GET_DATA = '[CUSTOMERS] SUCCESS GET DATA';
export const CUSTOMERS_ADD = '[CUSTOMERS] ADD';
export const CUSTOMERS_UPDATE = '[CUSTOMERS] UPDATE';
export const CUSTOMERS_DELETE = '[CUSTOMERS] DELETE';

export const CUSTOMERS_UPLOAD_LICENSE = '[CUSTOMERS] UPLOAD LICENSE';
export const CUSTOMERS_SUCCESS_UPLOAD_LICENSE = '[CUSTOMERS] SUCCESS UPLOAD LICENSE';
export const CUSTOMERS_ERROR_UPLOAD_LICENSE = '[CUSTOMERS] ERROR UPLOAD LICENSE';

export const CUSTOMERS_REMOVE_LICENSE = '[CUSTOMERS] REMOVE LICENSE';
export const CUSTOMERS_SUCCESS_REMOVE_LICENSE = '[CUSTOMERS] SUCCESS REMOVE LICENSE';
export const CUSTOMERS_ERROR_REMOVE_LICENSE = '[CUSTOMERS] ERROR REMOVE LICENSE';

export const CUSTOMERS_DOWNLOAD_SYS_INFO = '[CUSTOMERS] DOWNLOAD SYS INFO';
export const CUSTOMERS_SUCCESS_DOWNLOAD_SYS_INFO = '[CUSTOMERS] SUCCESS DOWNLOAD SYS INFO';
export const CUSTOMERS_ERROR_DOWNLOAD_SYS_INFO = '[CUSTOMERS] ERROR DOWNLOAD SYS INFO';

export const CUSTOMERS_SEND_SYS_INFO = '[CUSTOMERS] SEND SYS INFO';
export const CUSTOMERS_SUCCESS_SEND_SYS_INFO = '[CUSTOMERS] SUCCESS SEND SYS INFO';
export const CUSTOMERS_ERROR_SEND_SYS_INFO = '[CUSTOMERS] ERROR SEND SYS INFO';

export const customersGetData = (onSuccess?: (message: unknown) => void, onError?: (error: unknown) => void) => {
    return (dispatch: any, getState: () => { [x: string]: any }) => {
        // dispatch(customersSetLoading(true));
        dispatch({type: CUSTOMERS_GET_DATA});
        getCustomersData((result) => {
            // console.log('GET CUSTOMERS', result);
            dispatch({type: CUSTOMERS_SUCCESS_GET_DATA, data: result.data});
            if (_.isFunction(onSuccess)) {
                onSuccess(result);
            }
            dispatch(customersSetLoading(false));
        }, () => {
            dispatch(customersSetLoading(false));
        });
    };
};


export const customersDelete = (selected: string[]) => {
    const DELETE_CUSTOMER_INTERVAL = 600000;
    const CHECK_CUSTOMER_ADD_INTERVAL = 2000;
    let refresh: NodeJS.Timer;

    return (dispatch: any, getState: () => { [x: string]: any }) => {
        const onEnd = () => {
            dispatch(customersSetRefreshing(true));
            dispatch(customersGetData());
            dispatch(hideModal());
        }

        if (_.isArray(selected) && selected[0]) {
            dispatch(customersSetRefreshing(false));
            dispatch(showModal({
                width: '78rem',
                height: 'auto',
                title: 'Preparing data',
                content: (<Loading message={'Customer delete process is in progress'}/>),
                skin: MODAL_SKIN_SYSTEM_MESSAGE,
            }));

            const timeStamp = moment(new Date());
            refresh = setInterval(() => {
                const ago = Math.abs(timeStamp.diff(new Date()));
                if (ago > DELETE_CUSTOMER_INTERVAL) {
                    // @ts-ignore
                    clearInterval(refresh);
                    onEnd();
                }
            }, CHECK_CUSTOMER_ADD_INTERVAL);

            deleteCustomer(selected[0], onEnd, onEnd);
        }
        dispatch({type: CUSTOMERS_DELETE});
    };
};


export const customersAddData = (data: { [x: string]: any }, onSuccess?: (message: unknown) => void, onError?: (error: unknown) => void) => {
    const ADD_CUSTOMER_INTERVAL = 600000; // 5000; // 600000;
    const CHECK_CUSTOMER_ADD_INTERVAL = 5000;
    let refresh: NodeJS.Timer;

    return (dispatch: any, getState: () => { [x: string]: any }) => {
        dispatch(customersSetRefreshing(false));
        dispatch({type: CUSTOMERS_ADD});
        dispatch(showModal({
            width: '78rem',
            height: 'auto',
            title: <span>{'New customer creation could take'}<br />{'about 20 minutes'}</span>,
            content: (<Loading message={'Preparing data'}/>),
            skin: MODAL_SKIN_SYSTEM_MESSAGE,
        }));

        const onEnd = () => {
            dispatch(customersSetRefreshing(true));
            dispatch(customersGetData());
            dispatch(hideModal());
        }
        const onStart = () => {
            const timeStamp = moment(new Date());

            refresh = setInterval(() => {
                dispatch(customersGetData((res: any) => {
                    const grid = res.data;
                    const m = isInCustomerGrid(grid, {
                        fqdn: data.fqdn,
                        // companyName: next.companyName
                    });
                    // @ts-ignore
                    const isCreated = m && _.isObject(m) && m.port & !isNaN(m.users) && parseInt(m.users) > 0; // && m.licenseExpirationDate;
                    // console.log('CHECK IF CUSTOMER IS ALREADY IN DATA', m, grid, data.fqdn);

                    const ago = Math.abs(timeStamp.diff(new Date()));
                    // console.log('CHECK IF TIME RUN OUT', ago, ago > ADD_CUSTOMER_INTERVAL);
                    if (isCreated || (ago > ADD_CUSTOMER_INTERVAL)) {
                        // @ts-ignore
                        clearInterval(refresh);
                        onEnd();
                        if (isCreated) {
                            dispatch(showMessage({
                                message: 'Customer creation process was initiated',
                                type: MESSAGE_SUCCESS,
                            }));
                        } else {
                            dispatch(showMessage({
                                message: 'Customer creation process was initiated',
                                type: MESSAGE_SUCCESS,
                            }));
                        }
                    }
                }, () => {
                    // @ts-ignore
                    clearInterval(refresh);
                    onEnd();
                }));
            }, CHECK_CUSTOMER_ADD_INTERVAL);
        }

        addCustomer(data, (result) => {
            // if (_.isFunction(onSuccess)) {
            //     onSuccess(result);
            // }
            onStart();

        }, (err: any) => {
            // if (_.isFunction(onError)) {
            //     onError(err);
            // }
            // dispatch(showMessage({
            //     message: 'Customer wasn`t added',
            //     type: MESSAGE_ERROR,
            // }));
            onStart();
        });
    };
};

export const customersUpdateData = (id: string, data: { [x: string]: any }, onSuccess?: (message: unknown) => void, onError?: (error: unknown) => void) => {
    return (dispatch: any, getState: () => { [x: string]: any }) => {
        updateCustomer(id, data, (result) => {
            dispatch(customersGetData());
            dispatch({type: CUSTOMERS_UPDATE, data: result.data});
            if (_.isFunction(onSuccess)) {
                onSuccess(result);
            }
            dispatch(showMessage({
                message: 'Customer was updated',
                type: MESSAGE_SUCCESS,
            }));
        }, (err: any) => {
            if (_.isFunction(onError)) {
                onError(err);
            }
            dispatch(showMessage({
                message: 'Customer wasn`t updated',
                type: MESSAGE_ERROR,
            }));
        });
    };
};

export const customersUploadLicense = (id: string, files: { [x: string]: any }[], onSuccess?: (message: unknown) => void, onError?: (error: unknown) => void) => {
    return (dispatch: any, getState: () => { [x: string]: any }) => {
        dispatch({type: CUSTOMERS_UPLOAD_LICENSE});
        dispatch(customersSetRefreshing(false));

        dispatch(showModal({
            width: '78rem',
            height: 'auto',
            title: 'Preparing data',
            content: (<Loading message={'Customer license was uploading is in progress'}/>),
            skin: MODAL_SKIN_SYSTEM_MESSAGE,
        }));

        uploadCustomerLicense(id, files, (result) => {
            setTimeout(() => {
                dispatch({type: CUSTOMERS_SUCCESS_UPLOAD_LICENSE});
                dispatch(customersGetData());
                if (typeof onSuccess === 'function') {
                    onSuccess(result);
                }
                dispatch(showMessage({
                    message: 'Customer license was uploaded',
                    type: MESSAGE_SUCCESS,
                }));
                dispatch(customersSetRefreshing(true));
                dispatch(hideModal());
                dispatch(hideModal());
            }, 31000)
        }, (error) => {
            dispatch({type: CUSTOMERS_ERROR_UPLOAD_LICENSE});
            dispatch(showMessage({
                message: 'Can not apply the license: Machine unique information does not match the license',
                type: MESSAGE_ERROR,
            }));
            if (_.isFunction(onError)) {
                onError(error);
            }
            dispatch(customersSetRefreshing(true));
            dispatch(hideModal());
        });
    };
};

export const customersRemoveLicense = (id: string, onSuccess?: (message: unknown) => void, onError?: (error: unknown) => void) => {
    return (dispatch: any, getState: () => { [x: string]: any }) => {
        dispatch({type: CUSTOMERS_REMOVE_LICENSE});
        dispatch(customersSetRefreshing(false));
        dispatch(hideModal());
        dispatch(showModal({
            width: '78rem',
            height: 'auto',
            title: 'Preparing data',
            content: (<Loading message={'Removing customer license is in progress'}/>),
            skin: MODAL_SKIN_SYSTEM_MESSAGE,
        }));

        removeCustomerLicense(id, (result) => {
            dispatch(customersSetRefreshing(true));
            setTimeout(() => {
                dispatch({type: CUSTOMERS_SUCCESS_REMOVE_LICENSE});
                dispatch(customersGetData());
                if (_.isFunction(onSuccess)) {
                    onSuccess(result);
                }
                dispatch(showMessage({
                    message: 'Customer license was removed',
                    type: MESSAGE_SUCCESS,
                }));
                dispatch(hideModal());
            }, 31000)
        }, (error) => {
            dispatch({type: CUSTOMERS_ERROR_REMOVE_LICENSE});
            dispatch(showMessage({
                message: 'Error while removing license',
                type: MESSAGE_ERROR,
            }));
            if (_.isFunction(onError)) {
                onError(error);
            }
            dispatch(customersSetRefreshing(true));
            dispatch(hideModal());
        });
    };
};

export const customersDownloadSysInfo = (id: string, name: string, onSuccess?: () => void, onError?: (error: unknown) => void) => {
    return (dispatch: any, getState: () => { [x: string]: any }) => {
        dispatch({type: CUSTOMERS_DOWNLOAD_SYS_INFO});
        dispatch(showModal({
            width: '78rem',
            height: 'auto',
            title: 'Preparing data',
            content: (<Loading message={'SysInfo downloading is in progress'}/>),
            skin: MODAL_SKIN_SYSTEM_MESSAGE,
        }));

        downloadCustomerSysInfo(id, name, () => {
            dispatch({type: CUSTOMERS_SUCCESS_DOWNLOAD_SYS_INFO});
            if (_.isFunction(onSuccess)) {
                onSuccess();
            }
            dispatch(showMessage({
                message: 'SysInfo was uploaded',
                type: MESSAGE_SUCCESS,
            }));
            dispatch(hideModal());
        }, (error) => {
            dispatch({type: CUSTOMERS_ERROR_DOWNLOAD_SYS_INFO});
            dispatch(showMessage({
                message: 'Error while downloading SysInfo',
                type: MESSAGE_ERROR,
            }));
            if (_.isFunction(onError)) {
                onError(error);
            }
            dispatch(hideModal());
        });
    };
};

export const customerSendSysInfoToSupport = (id: string, data: { [x: string]: any }, onSuccess?: (message: unknown) => void, onError?: (error: unknown) => void) => {
    return (dispatch: any, getState: () => { [x: string]: any }) => {
        dispatch({type: CUSTOMERS_SEND_SYS_INFO});
        sendCustomerSysInfoToSupport(id, data, (result) => {
            dispatch({type: CUSTOMERS_SUCCESS_SEND_SYS_INFO});
            if (_.isFunction(onSuccess)) {
                onSuccess(result);
            }
            dispatch(showMessage({
                message: 'The System Info file has been generated and sent to support',
                type: MESSAGE_SUCCESS,
            }));
        }, (error) => {
            dispatch({type: CUSTOMERS_ERROR_SEND_SYS_INFO});
            dispatch(showMessage({
                message: 'Error while sending System information to support',
                type: MESSAGE_ERROR,
            }));
            if (_.isFunction(onError)) {
                onError(error);
            }
        });
    };
};


