export const delay = (n: number | undefined) => new Promise(resolve => setTimeout(resolve, n));

export const cancellablePromise = (promise: PromiseLike<any>) => {
	let isCanceled = false;

	const wrappedPromise = new Promise((resolve, reject) => {
		promise.then(
			value => (isCanceled
				? reject({ isCanceled, value })
				: resolve(value)),
			error => reject({ isCanceled, error }),
		);
	});

	return {
		promise: wrappedPromise,
		cancel: () => (isCanceled = true),
	};
};
