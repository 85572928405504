import React from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {Button, ButtonProps} from "@mui/material";
import {toNumber} from "lodash";

const useGroupedButtonStyles = makeStyles(({
                                               typography,
                                               spacing,
                                               palette,
                                               transitions,
                                               shadows,
                                               gradients
                                           }: CustomTheme) => ({
    root: {
        ...typography.button,
        height: spacing(7),
        lineHeight: spacing(3),
        minWidth: spacing(14),
        padding: spacing(2.1666, 3, 1.8444),
        marginBottom: spacing(3),
        textTransform: 'inherit',
        textDecoration: 'none!important',
        textAlign: 'center',
        outline: 0,
        backgroundColor: palette.common.white,
        borderRadius: 0,
        borderLeft: `1px solid ${palette.background.main}`,
        transition: `all 0.1s ${transitions.easing.easeIn}`,
        '&:first-child': {
            borderRadius: '0.7rem 0px 0px 0.7rem',
            borderLeftWidth: 0,
        },
        '&:last-child': {
            borderRadius: '0px 0.7rem 0.7rem 0px',
        },
        '&:hover': {
            backgroundColor: palette.action.hover,
            textDecoration: 'none!important',
        },
        '&:active': {
            backgroundColor: palette.common.white,
            boxShadow: shadows[3],
        },
        '& svg': {
            marginLeft: 0,
            marginRight: spacing(2),
        },
        '.minimizedView > &': {
            height: spacing(5.5),
            padding: [[toNumber(spacing(1.25)) + 1, spacing(3), toNumber(spacing(1.25)) - 1]],
        },
        '&.softDisabled': {
            pointerEvents: 'none!important',
        },
    },
    label: {},
    disabled: {
        opacity: 1,
        color: 'inherit !important',
    },
}));

interface GtbButtonProps extends ButtonProps {
    classes?: object,
}

export const GtbGroupedButton: React.FC<GtbButtonProps> = ({classes = {}, ...rest}) => {
    const stl = useGroupedButtonStyles({classes});
    return (
            <Button classes={stl} {...rest} />
    );
};

export default GtbGroupedButton;
