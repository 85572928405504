export const columns = [
	{
		type: 'th',
		slug: '_id',
		width: 50,
		className: 'middle idMiddle',
		sortable: false,
		hidable: false,
		filters: ['number'],
		resizable: false,
		movable: false,
		description: '#',
		content: '#',
	},
	{
		type: 'th',
		slug: 'serverIp',
		className: 'smallPadding',
		width: 200,
		hidable: false,
		sortable: false,
		filters: ['keywords'],
		resizable: false,
		movable: false,
		description: 'Server IP',
		content: 'Server IP',
	},
	{
		type: 'th',
		slug: 'status',
		className: 'smallPadding',
		width: 150,
		hidable: false,
		sortable: false,
		filters: ['keywords'],
		resizable: false,
		movable: false,
		description: 'Status',
		content: 'Status',
	},
	{
		type: 'th',
		slug: 'space',
		className: 'smallPadding centered',
		width: 90,
		hidable: false,
		sortable: false,
		filters: ['keywords'],
		resizable: false,
		movable: false,
		description: 'Used space',
		content: 'Used space',
	},
	{
		type: 'th',
		slug: 'cpu',
		className: 'smallPadding centered',
		width: 90,
		hidable: false,
		sortable: false,
		filters: ['keywords'],
		resizable: false,
		movable: false,
		description: 'CPUs',
		content: 'CPUs',
	},
	{
		type: 'th',
		slug: 'memory',
		className: 'smallPadding centered',
		width: 90,
		hidable: false,
		sortable: false,
		filters: ['keywords'],
		resizable: false,
		movable: false,
		description: 'Memory',
		content: 'Memory',
	},
	{
		type: 'th',
		slug: 'updatedAt',
		className: 'centered smallPadding',
		hidable: false,
		sortable: false,
		filters: ['keywords'],
		resizable: false,
		movable: false,
		description: 'Last update time',
		content: 'Last Update Time',
	},
];

export default columns;
