import { getUserInfo } from 'api/auth';

export const USER_START_GET_DATA = '[USER] START GET DATA';
export const USER_SUCCESS_GET_DATA = '[USER] SUCCESS GET DATA';
export const USER_ERROR_GET_DATA = '[USER] ERROR GET DATA';

export const getUserInformation = (onSuccess?: () => void, onError?: (error: unknown) => void) => {
	return (dispatch: any, getState: () => { [x: string]: any }) => {
		dispatch({ type: USER_START_GET_DATA });
		getUserInfo({
			onSuccess: (res: any) => {
				console.log('user info', res);
				dispatch({ type: USER_SUCCESS_GET_DATA, data: res });
			},
			onError: (err) => {
				console.log('user error', err);
				dispatch({ type: USER_ERROR_GET_DATA });
			},
		});
	};
};
