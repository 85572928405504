const timeZones: { id: number, name: string }[] = [
    {
        id: 1,
        name: "Etc/GMT+12",
    },
    {
        id: 2,
        name: "Pacific/Apia",
    },
    {
        id: 3,
        name: "Pacific/Honolulu",
    },
    {
        id: 4,
        name: "America/Anchorage",
    },
    {
        id: 5,
        name: "America/Los_Angeles",
    },
    {
        id: 6,
        name: "America/Phoenix",
    },
    {
        id: 7,
        name: "America/Denver",
    },
    {
        id: 8,
        name: "America/Chihuahua",
    },
    {
        id: 9,
        name: "America/Managua",
    },
    {
        id: 10,
        name: "America/Regina",
    },
    {
        id: 11,
        name: "America/Mexico_City",
    },
    {
        id: 12,
        name: "America/Chicago",
    },
    {
        id: 13,
        name: "America/Indianapolis",
    },
    {
        id: 14,
        name: "America/Bogota",
    },
    {
        id: 15,
        name: "America/New_York",
    },
    {
        id: 16,
        name: "America/Caracas",
    },
    {
        id: 17,
        name: "America/Santiago",
    },
    {
        id: 18,
        name: "America/Halifax",
    },
    {
        id: 19,
        name: "America/St_Johns",
    },
    {
        id: 20,
        name: "America/Buenos_Aires",
    },
    {
        id: 21,
        name: "America/Godthab",
    },
    {
        id: 22,
        name: "America/Sao_Paulo",
    },
    {
        id: 23,
        name: "America/Noronha",
    },
    {
        id: 24,
        name: "Atlantic/Cape_Verde",
    },
    {
        id: 25,
        name: "Atlantic/Azores",
    },
    {
        id: 26,
        name: "Africa/Casablanca",
    },
    {
        id: 27,
        name: "Europe/London",
    },
    {
        id: 28,
        name: "Africa/Lagos",
    },
    {
        id: 29,
        name: "Europe/Berlin",
    },
    {
        id: 30,
        name: "Europe/Paris",
    },
    {
        id: 31,
        name: "Europe/Sarajevo",
    },
    {
        id: 32,
        name: "Europe/Belgrade",
    },
    {
        id: 33,
        name: "Africa/Johannesburg",
    },
    {
        id: 34,
        name: "Asia/Jerusalem",
    },
    {
        id: 35,
        name: "Europe/Istanbul",
    },
    {
        id: 36,
        name: "Europe/Helsinki",
    },
    {
        id: 37,
        name: "Africa/Cairo",
    },
    {
        id: 38,
        name: "Europe/Bucharest",
    },
    {
        id: 39,
        name: "Africa/Nairobi",
    },
    {
        id: 40,
        name: "Asia/Riyadh",
    },
    {
        id: 41,
        name: "Europe/Moscow",
    },
    {
        id: 42,
        name: "Asia/Baghdad",
    },
    {
        id: 43,
        name: "Asia/Tehran",
    },
    {
        id: 44,
        name: "Asia/Muscat",
    },
    {
        id: 45,
        name: "Asia/Tbilisi",
    },
    {
        id: 46,
        name: "Asia/Kabul",
    },
    {
        id: 47,
        name: "Asia/Karachi",
    },
    {
        id: 48,
        name: "Asia/Yekaterinburg",
    },
    {
        id: 49,
        name: "Asia/Calcutta",
    },
    {
        id: 50,
        name: "Asia/Katmandu",
    },
    {
        id: 51,
        name: "Asia/Colombo",
    },
    {
        id: 52,
        name: "Asia/Dhaka",
    },
    {
        id: 53,
        name: "Asia/Novosibirsk",
    },
    {
        id: 54,
        name: "Asia/Rangoon",
    },
    {
        id: 55,
        name: "Asia/Bangkok",
    },
    {
        id: 56,
        name: "Asia/Krasnoyarsk",
    },
    {
        id: 57,
        name: "Australia/Perth",
    },
    {
        id: 58,
        name: "Asia/Taipei",
    },
    {
        id: 59,
        name: "Asia/Singapore",
    },
    {
        id: 60,
        name: "Asia/Hong_Kong",
    },
    {
        id: 61,
        name: "Asia/Irkutsk",
    },
    {
        id: 62,
        name: "Asia/Tokyo",
    },
    {
        id: 63,
        name: "Asia/Seoul",
    },
    {
        id: 64,
        name: "Asia/Yakutsk",
    },
    {
        id: 65,
        name: "Australia/Darwin",
    },
    {
        id: 66,
        name: "Australia/Adelaide",
    },
    {
        id: 67,
        name: "Pacific/Guam",
    },
    {
        id: 68,
        name: "Australia/Brisbane",
    },
    {
        id: 69,
        name: "Asia/Vladivostok",
    },
    {
        id: 70,
        name: "Australia/Hobart",
    },
    {
        id: 71,
        name: "Australia/Sydney",
    },
    {
        id: 72,
        name: "Asia/Magadan",
    },
    {
        id: 73,
        name: "Pacific/Fiji",
    },
    {
        id: 74,
        name: "Pacific/Auckland",
    },
    {
        id: 75,
        name: "Pacific/Tongatapu",
    },
];

export default timeZones;
