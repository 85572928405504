import React from 'react';
import {makeStyles, useTheme} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import {Button, ScrollWrapper as Scroll} from '@gtb';
import LegendItem from './LegentItem';
import {useDispatch} from "react-redux";
import {hideModal} from 'store/actions';

const useStyles = makeStyles(({typography, spacing}: CustomTheme) => ({
    root: {},
    item: {},
    button: {
        marginLeft: spacing(3),
        marginBottom: spacing(1),
    },
    actionRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        margin: 0,
    },
    btnNoMessage: {
        marginTop: spacing(3),
    },
    nonDomain: {},
    domain: {},
    textRoot: {},
    labelText: {},
}));

const Legend: React.FC<{ [x: string]: any }> = (props) => {

    const stl = useStyles(props);
    const theme: CustomTheme = useTheme();
    const dispatch = useDispatch();


    const {
        classes,
        config = [],
        legendItemClasses,
    } = props;

    return (
            <div className={stl.root}>
                {/* @ts-ignore */}
                <Scroll maxHeight={(theme.spacing.unit * 5.5 + 1) * 20}>
                    {
                        config && config.map((item: any, itemKey: any) => {
                            return <LegendItem
                                    key={'LegendItem_' + itemKey}
                                    classes={legendItemClasses}
                                    {...item}
                            />;
                        })
                    }
                </Scroll>
                <div className={stl.actionRow}>
                    <Button
                            className={config.length > 1
                                    ? stl.button
                                    : stl.btnNoMessage}
                            onClick={() => {
                                dispatch(hideModal());
                            }}
                    >
                        {'Close'}
                    </Button>
                </div>
            </div>
    );
}

export default Legend;
