import _ from 'lodash';

interface CustomerRowInterface {
	fqdn: string,
	companyName: string,

	[x: string]: any,
}

type CustomerGridInterface = CustomerRowInterface[]

interface IsInCustomerGridConfigInterface {
	fqdn?: string,
	companyName?: string,

	[x: string]: any,
}

export const isInCustomerGrid = (grid: CustomerGridInterface, config: IsInCustomerGridConfigInterface) => {
	if (!_.isArray(grid) || !config) {
		return false;
	}
	const keys = _.isObject(config) ? Object.keys(config) : [];
	if (keys.length < 0) {
		return false;
	}
	let m: boolean | CustomerRowInterface = false;
	keys.forEach((k: string) => {
		const row = grid.filter((g) => g[k] === config[k])[0];
		if (row) {
			m = row;
		}
	});

	return m;
};
