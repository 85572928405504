import React from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import SisInfo from './SysInfo'

const useStyles = makeStyles(({typography, spacing}: CustomTheme) => ({
    root: {
        width: 550,
        marginTop: spacing(-10)
    },
    title: {
        ...typography.h1,
        marginTop: 0,
        marginBottom: 0,
        lineHeight: spacing(7),
    }
}));

interface SetupFormProps {

}

const System: React.FC<SetupFormProps> = ({}) => {
    const classes = useStyles();

    const template = {
        root: {
            width: 555,
        },
        label: {
            width: 255,
        }
    }
    return (
            <div className={classes.root}>
                <h4 className={classes.title}>Emails and Alerts</h4>
                <SisInfo/>
            </div>
    );
};

export default System;
