import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {lighten} from '@mui/material/styles';
import {CN} from '@gtb/utils';
import {TextField} from '@mui/material';
import Icon, * as Icons from '@gtb/components/Icons';

const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    root: {
        display: 'flex',
        minHeight: spacing(7),
        height: '100%',
        margin: 0,
        padding: 0,
        backgroundColor: palette.common.white,
        borderRadius: 7,
        '.minimizedView &': {
            minHeight: spacing(5.5),
        },
        '& input:focus::placeholder': {
            color: 'transparent!important'
        },
        '& textarea:focus::placeholder': {
            color: 'transparent!important'
        },

    },
    multiline: {
        cursor: 'inherit',
        '&::-webkit-scrollbar': {
            cursor: 'pointer!important',
        },

    },
    text: {
        ...typography.inputField,
        minHeight: spacing(7),
        height: '100%',
        lineHeight: spacing(3),
        boxSizing: 'border-box',
        padding: spacing(2.166666, 3, 1.833333),
        '.nowrap &': {
            position: 'relative',
            whiteSpace: 'pre',
            padding: 0,
            '& > div': {
                display: 'flex',
            },
        },
        '&::-webkit-inner-spin-button': {
            appearance: 'none',
        },
        '.minimizedView &': {
            minHeight: spacing(5.5),
            padding: spacing(1.41666, 3, 1.083333),
        },
    },
    input: {
        padding: 0,
        '& .Mui-focused': {
            borderColor: 'transparent!important',
        },
        '&:hover, &:focus': {
            borderColor: 'transparent!important',
        },
        '.nowrap &': {
            whiteSpace: 'pre',
            padding: spacing(2.166666, 3, 1.833333),
        },
        '.minimizedView &': {
            padding: 0,
        },
        '&::-webkit-scrollbar': {
            cursor: 'pointer!important',
        },
    },
    notchedOutline: {
        borderWidth: '0px!important',
        borderColor: 'transparent!important',
    },
    underline: {
        '&:before': {
            borderBottom: 'none',
            content: '',
        },
        '&:hover:not($disabled):before': {
            borderBottom: 'none',
            content: '',
        },
        '&:after': {
            borderBottom: 'none',
            content: '',
        },
        '&:hover:not($disabled):after': {
            borderBottom: 'none',
            content: '',
        },
    },
    focused: {
        backgroundColor: lighten(palette.action.selected, 0.3),
        '&::-webkit-inner-spin-button': {
            appearance: 'none',
        },
    },
    icon: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: spacing(3),
        height: spacing(3),
        '& svg': {
            display: 'block',
            margin: spacing(0, 2),
        },
        '&.active svg path': {
            fill: palette.action.active,
        },
    },
    remove: {
        '& svg path': {
            fill: lighten(palette.action.active6, 0.5) + '!important',
        },
        '&:hover svg path': {
            fill: palette.action.active6 + '!important',
        },
    },
}));

interface FieldProps { //  extends TextFieldProps
    classes?: any,
    value?: unknown,
    validation?: (value: unknown) => boolean,
    onChange?: (e: any) => any,
    fastChange?: boolean,
    onBlur?: (e?: any) => any,
    error?: boolean,
    changeOnKeyEnter?: (event: any) => any,
    clearable?: boolean,
    emptyChange?: boolean,
    InputProps?: { [x: string]: any },
    isBolded?: any,
    onReset?: () => any,
    isStrict?: boolean,
    changeOnBlur?: boolean,
    type?: string,

    [x: string]: any,
}

type TextChangeProps = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

const Text: React.FC<FieldProps> = (props) => {
    const stl = useStyles(props);
    const {
        classes,
        value,
        fastChange = false,
        error,
        changeOnKeyEnter,
        clearable = false,
        emptyChange,
        InputProps = {},
        onReset,
        isStrict = false,
        validation = () => false,
        onChange = () => false,
        onBlur = (e: any) => e,
        changeOnBlur = true,
        ...rest
    } = props;


    const [stateValue, setState] = useState({
        value: (typeof value !== 'undefined' && value !== null)
                ? value
                : '',
    });

    const [allowWrite, setWrite] = useState(true)

    useEffect(() => {
        setState((prevState) => {
            return value !== prevState ? ({
                ...stateValue,
                value: (typeof value !== 'undefined' && value !== null)
                        ? value
                        : '',
            }) : {value: ''}
        });
    }, [value]);

    const setValue = (event: TextChangeProps, name: string) => {
        if ((!validation(event.target.value))) {
            setState({
                ...stateValue,
                [name]: event.target.value,
            });
            if (fastChange) {
                onChange(event);
            }
        }
    };

    const handleChange = (name: string) => (event: TextChangeProps) => {
        if (isStrict) {
            if (allowWrite) {
                setValue(event, name);
            }
        } else {
            if ((!validation(event.target.value))) {
                setValue(event, name);
            }
        }
    };

    const handleBlur = (event: TextChangeProps) => {
        if (onBlur) {
            onBlur(event);
        }
        if (changeOnBlur) {
            onChange(event);
        }
    };

    const onResetHandler = () => {
        if (typeof onReset === 'function') {
            onReset();
        } else {
            setState({
                ...stateValue,
                value: '',
            });
            onChange({
                target: {
                    value: '',
                },
            });
        }
    };

    const renderClearIcon = () => {
        return rest.type && (rest.type === 'text' || rest.type === 'password') && (
                <div className={CN([stl.icon, stl.remove], {'active': !!stateValue.value})}
                     onClick={onResetHandler}>
                    <Icon view={Icons.ICON_CLOSE}/>
                </div>
        );
    };

    return <TextField
            classes={{root: stl.root}}
            value={stateValue.value}
            error={error}
            onChange={handleChange('value')}
            InputProps={{
                classes: {
                    root: stl.text,
                    focused: stl.focused,
                    input: stl.input,
                    multiline: stl.multiline,
                    // underline: stl.underline,
                    notchedOutline: stl.notchedOutline,
                },
                onBlur: e => {
                    if (fastChange) {
                        onBlur(e);
                    } else {
                        handleBlur(e);
                    }
                },
                onKeyDown: (event) => {
                    if (isStrict) {
                        const keys = ['e', '.', '-', '+', 'E'];
                        if (keys.includes(event.key)) {
                            event.preventDefault();
                            event.stopPropagation();
                            setWrite(false);
                        } else {
                            setWrite(true);
                        }
                    }
                    if ((typeof changeOnKeyEnter === 'function') && event.key === 'Enter') {
                        if (emptyChange || event.target) {
                            changeOnKeyEnter(event);
                        }
                    }
                },
                endAdornment: !!stateValue.value && clearable && renderClearIcon(),
                ...InputProps,
            }}
            {...rest}
    />;
};

export default Text;
