import React from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {Button, ButtonProps} from "@mui/material";
import {toNumber} from "lodash";

const useSimpleButtonStyles = makeStyles(({
    typography,
    spacing,
    palette,
    transitions,
    shadows,
    gradients
}: CustomTheme) => ({
    root: {
        ...typography.button,
        height: spacing(7),
        lineHeight: spacing(3),
        minWidth: spacing(14),
        padding: spacing(2.166,3,2.166),
        marginBottom: spacing(3),
        textTransform: 'inherit',
        textDecoration: 'none!important',
        textAlign: 'center',
        outline: 0,
        backgroundColor: palette.common.white,
        borderRadius: '0.7rem',
        transition: `all 0.1s ${transitions.easing.easeIn}`,
        '&:hover': {
            backgroundColor: palette.action.hover,
            boxShadow: shadows[1],
            textDecoration: 'none!important',
        },
        '&:active': {
            backgroundColor: palette.common.white,
            boxShadow: shadows[3],
        },
        '& svg': {
            marginLeft: 0,
            marginRight: spacing(2),
        },
        '&.justIcon': {
            minWidth: spacing(7),
            '& svg': {
                marginLeft: 0,
                marginRight: 0,
            },
        },
        '&.activeButton': {
            '& svg': {
                marginLeft: 0,
                marginRight: spacing(1.5),
            },
        },
        '&.active': {
            backgroundColor: palette.action.active,
            color: palette.common.white,
            '& svg path': {
                fill: palette.common.white + '!important',
            },
        },
        '&.inline': {
            minWidth: 'auto',
            padding: spacing(1),
            height: spacing(5),
            margin: 0,
            '& span': {
                display: 'flex',
            },
            '& svg': {
                marginRight: spacing(1),
            },
            '&.add': {
                padding: spacing(0.8333, 1),
            },
        },
        '&.add': {
            color: palette.action.active3,
            borderWidth: [[1, 1, 1, 1]],
            borderStyle: 'solid',
            borderColor: palette.action.active3,
            backgroundColor: palette.common.white,
            padding: spacing(2, 3, 1.666666),
            '& svg path': {
                fill: palette.action.active3 + '!important',
            },
            '&:hover': {
                color: palette.common.white,
                background: gradients[3]('180deg'),
                '& svg path': {
                    fill: palette.common.white + '!important',
                },
            },
        },
        '&.active3': {
            backgroundColor: palette.action.active3,
            color: palette.common.white,
            '& svg path': {
                fill: palette.common.white,
            },
        },
        '&.pressed': {
            backgroundColor: palette.action.selected,
        },
        '.minimizedView > &': {
            height: spacing(5.5),
            padding: spacing(1.416666, 3, 108333),
        },
        '&.softDisabled': {
            pointerEvents: 'none!important',
        },
    },
    label: {},
    disabled: {
        opacity: 1,
        color: 'inherit !important',
    },
}));

interface GtbButtonProps extends ButtonProps {
    classes?: object,
}

export const GtbSimpleButton: React.FC<GtbButtonProps> = ({classes = {}, ...rest}) => {
    const stl = useSimpleButtonStyles({classes});
    return (
            <Button classes={stl} {...rest} />
    );
};

export default GtbSimpleButton;
