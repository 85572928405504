import React, {useState} from 'react';
import {Tooltip} from "@mui/material";
import {useStyles} from './Tooltip';

const ControlledTooltip: React.FC<any> = (props) => {

    const styles = useStyles(props);
    const {
        classes,
        title,
        delay,
        children,
        ...rest
    } = props;

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
        setTimeout(handleClose, delay);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (delay && title) {
        return (
                <div
                        onMouseOver={() => handleOpen()}
                        onMouseOut={() => handleClose()}
                >
                    <Tooltip
                            classes={styles}
                            title={title}
                            open={open}
                            {...rest}
                    >
                        {children}
                    </Tooltip>
                </div>
        );
    }

    return title
            ? (
                    <Tooltip
                            classes={styles}
                            title={title}
                            onClose={(e: React.MouseEvent<HTMLDivElement>) => {
                                handleClose();
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            arrow={true}
                            {...rest}
                    >
                        {children}
                    </Tooltip>
            )
            : children;
}

export default ControlledTooltip;
