import { RESOURCES_SUCCESS_GET_DATA } from '../../actions';

const data = function (state = [], action: { [x: string]: any } = {}) {
	switch (action.type) {
		case RESOURCES_SUCCESS_GET_DATA: {
			return [...action.data];
		}
		default: {
			return state;
		}
	}
};

export default data;
