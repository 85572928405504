import React, {
    useState,
    useEffect,
    useCallback, useRef,
} from 'react';
import {alpha as fade} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {useDropzone} from 'react-dropzone';
import {Button} from '@gtb';
import {CN} from '@gtb/utils';
import Icon, * as Icons from '@gtb/components/Icons';

const useStyles = makeStyles(({palette, spacing, typography, shadows}: CustomTheme) => ({
    root: {
        width: 'auto',
        height: '100%',
        display: 'flex',
        padding: `0px 0px`,
        // flexWrap: 'no-wrap',
        backgroundColor: palette.common.white,
        borderRadius: '0.7rem',
        overflow: 'hidden',
        transition: `all 0.1s ease`,
        '&:hover': {
            boxShadow: shadows[1],
        },
        '&:active, &.active': {
            boxShadow: shadows[3],
        },
        '.minimizedView &': {
            height: spacing(5.5)
        },
    },
    field: {
        width: '100%',
        height: '100%',
        flexShrink: 1,
        display: 'flex',
        justifyContent: 'space-between',
        padding: `0px 0px`,
        backgroundColor: palette.common.white,
        ...typography.inputField,
    },
    dropzone: {
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    input: {
        ...typography.inputField,
        height: '100%!important',
        lineHeight: spacing(3),
        padding: spacing(2.3333333, 3, 1.666666),
        cursor: 'pointer',
        flexGrow: 1,
        margin: `0px`,
        backgroundColor: palette.common.white,
        borderRadius: 7,
    },
    text: {
        ...typography.inputField,
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        padding: spacing(0, 3),
        height: '100%',
        width: '100%',
        flexShrink: 1,
        color: fade(palette.text.secondary, 0.5),
        caretColor: 'transparent',
    },
    button: {
        height: '100%',
        margin: `0px 0px 0px auto`,
        padding: spacing(0, 3),
        backgroundColor: fade(palette.background.main, 0.2),
        textTransform: 'inherit',
        borderRightWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.background.main,
        borderRadius: 0,
        '&:first-child': {
            borderRadius: 0,
        },
    },
    label: {
        marginLeft: spacing(2),
    },
    submit: {
        marginBottom: 0,
        height: '100%',
        minWidth: '20rem',
        whiteSpace: 'nowrap',
        justifyContent: 'flex-start',
        background: fade(palette.background.main, 0.2),
        '&:last-child': {
            borderRadius: 0,
        },
    },
}));


interface ChooseFileInterface {
    id?: string,
    width?: string,
    height?: string,
    maxFiles?: number,
    forceSubmit?: boolean,
    placeholder?: string,
    activePlaceholder?: string,
    clearFilesAfterSubmit?: boolean,
    onSubmit?: (f: any) => any,
    onError?: (f: any) => any,
    accept?: { [x: string]: string[] },

    [x: string]: any
};

const ChooseFile: React.FC<ChooseFileInterface> = (props) => {

    const stl = useStyles(props);
    const {
        classes,
        id = 'dropzone',
        width = '100%',
        height = '100%',
        files = [],
        maxFiles = 1,
        placeholder = 'Please, choose a File',
        activePlaceholder = 'Drop the files here ...',
        icon = <Icon view={Icons.ICON_CHOOSE_FILE}/>,
        submitProps = {label: false},
        accept = {},
        forceSubmit = false,
        clearFilesAfterSubmit = false,
        onChange = () => false,
        onError = () => false,
        onSubmit = () => false,
    } = props;


    const [state, setState] = useState({
        files: files || [],
        error: false,
    });

    useEffect(() => {
        setState({
            ...state,
            files: files,
        })
    }, [JSON.stringify(files)]);

    const getFileName = () => {
        return state.files.length > 0
                ? state.files[state.files.length - 1].name
                : placeholder;
    };

    const onDrop = useCallback((files: any) => {
        const newFiles = maxFiles < 2
                ? files
                : [...state.files, ...files];
        setState({
            ...state,
            files: newFiles,
            error: false,
        });
        onChange(newFiles);
        if (!submitProps) {
            onSubmit(newFiles);
        }
    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, maxFiles, accept})

    const onSubmitHandler = () => {
        if (state.files.length > 0 || forceSubmit) {
            onSubmit(state.files);
            if (clearFilesAfterSubmit) {
                setState({
                    ...state,
                    files: [],
                });
            }
        } else {
            setState({
                ...state,
                // @ts-ignore
                error: {code: 0, message: 'No file was chosen'},
            });
            onError({code: 0, message: 'No file was chosen'});
            console.log('No file was chosen');
        }
    };


    return (<div className={stl.root}>
        <div className={stl.field} {...getRootProps()}>
            <Button className={stl.button} skin="groupedIcon">
                {icon}
                {/* {label && <div className={stl.label}>{label}</div>} */}
            </Button>
            <div className={stl.text}>
                <input {...getInputProps()} />
                <div className={CN(stl.dropzone)}>
                    {
                        state.files && state.files.length > 0
                                ? state.files.map((f: { name: string, [x: string]: any }) => f.name).join(', ')
                                : isDragActive
                                        ? activePlaceholder
                                        : placeholder
                    }
                </div>
            </div>
        </div>
        {submitProps && (
                <Button
                        // softDisabled={state.files.length === 0}
                        // noSelectMessage={translate('No file was chosen')}
                        classes={submitProps.classes
                                ? submitProps.classes
                                : {root: stl.submit}}
                        skin={'grouped'}
                        onClick={onSubmitHandler}
                >
                    {submitProps.icon}
                    {submitProps.label
                            ? submitProps.label
                            : 'Apply'}
                </Button>
        )}
    </div>)
}

export default ChooseFile;
