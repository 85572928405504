import {makeStyles} from '@mui/styles';
import * as React from 'react';
import {CustomTheme} from "@gtb/interfaces";
import Navbar from "./Navbar";
import TopBar from './TopBar';
import Time from './Time';
import Version from './Version';
import UserIsLogged from "./UserIsLogged";
import Modal from './Modal';
import Message from './Message';
import Auth from 'apps/auth/common/components/Auth';
import {useSelector} from "react-redux";

export const LAYOUT: { [key: string]: string | number } = {
    navBarWidth: 192,
    topBarHeight: 60,
}

const useStyles = makeStyles(({palette, spacing}: CustomTheme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: palette.background.main,
        width: '100%',
        minHeight: '100vh',
        paddingLeft: LAYOUT.navBarWidth,
    },
    navbar: {
        zIndex: 91,
        width: LAYOUT.navBarWidth,
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        backgroundColor: palette.common.white
    },
    wrapper: {
        paddingTop: LAYOUT.topBarHeight,
    },
    top: {
        zIndex: 90,
        position: 'fixed',
        height: LAYOUT.topBarHeight,
        top: 0,
        left: 192,
        right: 0,
        backgroundColor: palette.common.white
    },
    toolbar: {},
    content: {
        padding: spacing(0, 3, 3),
    },
    footer: {},
}));

type LayoutProps = {
    navbar?: JSX.Element,
    top?: JSX.Element,
    toolbar?: JSX.Element,
    footer?: JSX.Element,
    classes?: any
}

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = (props) => {
    const stl = useStyles(props);
    const {
        navbar,
        top,
        toolbar,
        footer,
        children,
    } = props;
    // @ts-ignore
    const {modal} = useSelector((state) => state.layout);

    return (
            <Auth>
                <div className={stl.root}>
                    {navbar ? (
                            <div className={stl.navbar}>
                                <Navbar>{navbar}</Navbar>
                            </div>
                    ) : null}
                    {top ? (
                            <div className={stl.top}>
                                <TopBar
                                        time={<Time/>}
                                        userIsLogged={<UserIsLogged/>}
                                        // version={<Version/>}
                                >
                                    {top}
                                </TopBar>
                            </div>
                    ) : null}
                    <div className={stl.wrapper}>
                        {toolbar ? <div className={stl.toolbar}>{toolbar}</div> : null}
                        <div className={stl.content}>{children}</div>
                        {footer ? <div className={stl.footer}>{footer}</div> : null}
                    </div>
                    <Modal/>
                    <Message/>
                </div>
            </Auth>
    );
};

Layout.defaultProps = {
    navbar: <span>{''}</span>,
    top: <span>{''}</span>,
}

export default Layout;
