import React, {useEffect, useState} from 'react';
import {Processing as Progress} from '@gtb/components/Modals';
import {useSelector} from "react-redux";

export const Processing: React.FC<{ [x: string]: any }> = (props) => {

    // @ts-ignore
    const {data, inProgress} = useSelector((state) => state.backup);

    const [stats, setStats] = useState('0');
    useEffect(() => {
        const b = data.filter((c: any) => c._id === inProgress)[0];
        setStats(b ? JSON.stringify(b.percent) : '0');
    }, [JSON.stringify(data), inProgress])


    return <Progress stats={stats}/>
};

export default Processing;
