import React from 'react';
import {
    startBackup,
    setBackupOptions,
    removeBackup,
    getBackupStatus,
    getAllBackupsStatus,
    downloadBackup,
} from 'api';
import _ from 'lodash';
import moment from "moment";
import {
    MESSAGE_SUCCESS,
    MESSAGE_ERROR,
    MODAL_SKIN_SYSTEM_MESSAGE,
    showMessage,
    showModal,
    hideModal,
} from '../layout';
import {LoadingModal as Loading} from '@gtb';
import Processing from 'apps/backup/components/Processing'


export const BACKUP_START = '[backup] START BACKUP';
export const BACKUP_SUCCESS_START = '[backup] START BACKUP SUCCESS';
export const BACKUP_ERROR_START = '[backup]  START BACKUP ERROR';

export const BACKUP_GET_ALL_STATUS = '[backup] BACKUP GET ALL STATUS';
export const BACKUP_SUCCESS_GET_ALL_STATUS = '[backup] BACKUP GET ALL STATUS SUCCESS';
export const BACKUP_ERROR_GET_ALL_STATUS = '[backup]  BACKUP GET ALL STATUS ERROR';

export const BACKUP_GET_STATUS = '[backup] BACKUP GET STATUS';
export const BACKUP_SUCCESS_GET_STATUS = '[backup] BACKUP GET STATUS SUCCESS';
export const BACKUP_ERROR_GET_STATUS = '[backup]  BACKUP GET STATUS ERROR';


export const BACKUP_DOWNLOAD = '[backup] BACKUP DOWNLOAD';
export const BACKUP_SUCCESS_DOWNLOAD = '[backup] BACKUP DOWNLOAD SUCCESS';
export const BACKUP_ERROR_DOWNLOAD = '[backup]  BACKUP DOWNLOAD ERROR';


export const backupGetAllStatus = (onSuccess?: (message: unknown) => void, onError?: (error: unknown) => void) => {
    return (dispatch: any, getState: () => { [x: string]: any }) => {
        dispatch({type: BACKUP_GET_ALL_STATUS});
        getAllBackupsStatus((result) => {
            // console.log('GET ALL BACKUP STATUS RESULT', result)
            dispatch({type: BACKUP_SUCCESS_GET_ALL_STATUS, data: result.data});
            if (_.isFunction(onSuccess)) {
                onSuccess(result);
            }

        }, (err: any) => {
            dispatch({type: BACKUP_ERROR_GET_ALL_STATUS, err: err});
            if (_.isFunction(onError)) {
                onError(err);
            }
            // dispatch(showMessage({
            //     message: 'Can`t get backup status',
            //     type: MESSAGE_ERROR,
            // }));
        });
    };
};

export const backupGetStatus = (id: string, onSuccess?: (message: unknown) => void, onError?: (error: unknown) => void) => {
    return (dispatch: any, getState: () => { [x: string]: any }) => {
        dispatch({type: BACKUP_GET_STATUS, id: id});
        getBackupStatus(id, (result) => {
            // console.log('GET BACKUP STATUS RESULT', result)
            dispatch({type: BACKUP_SUCCESS_GET_STATUS, data: result.data});
            if (_.isFunction(onSuccess)) {
                onSuccess(result);
            }

        }, (err: any) => {
            dispatch({type: BACKUP_ERROR_GET_STATUS, err: err});
            if (_.isFunction(onError)) {
                onError(err);
            }
            // dispatch(showMessage({
            //     message: 'Can`t get backup status',
            //     type: MESSAGE_ERROR,
            // }));
        });
    };
};


export const backupDownload = (id: string, onSuccess?: () => void, onError?: (error: unknown) => void) => {
    return (dispatch: any, getState: () => { [x: string]: any }) => {
        dispatch({type: BACKUP_DOWNLOAD});
        downloadBackup(id, () => {
            dispatch({type: BACKUP_SUCCESS_DOWNLOAD});
            if (_.isFunction(onSuccess)) {
                onSuccess();
            }
            dispatch(hideModal());
            dispatch(showMessage({
                message: 'Backup was downloaded',
                type: MESSAGE_SUCCESS,
            }));
        }, (error) => {
            dispatch({type: BACKUP_ERROR_DOWNLOAD});
            dispatch(hideModal());
            dispatch(showMessage({
                message: 'Error while downloading Backup',
                type: MESSAGE_ERROR,
            }));
            if (_.isFunction(onError)) {
                onError(error);
            }
        });
    };
};

export const backupCreate = (
        id: string,
        data: { [x: string]: any },
        onSuccess?: (message?: unknown) => void,
        onError?: (error?: unknown) => void
) => {
    const BACKUP_ERROR_INTERVAL = 3600000;
    const BACKUP_REFRESH_INTERVAL = 5000;
    let refresh: NodeJS.Timer;

    return (dispatch: any, getState: () => { [x: string]: any }) => {
        const onEnd = () => {
            // @ts-ignore
            clearInterval(refresh);
            dispatch(hideModal());
        }

        if (id && data) {
            dispatch({type: BACKUP_START, id: id, data: data});
            dispatch(showModal({
                width: '78rem',
                height: 'auto',
                title: <Processing/>,
                content: (
                        <Loading
                                message={'Creating a backup,\n' + 'this may take a few minutes'}
                                withPreloader={false}
                        />
                ),
                skin: MODAL_SKIN_SYSTEM_MESSAGE,
            }));

            startBackup(
                    id,
                    data,
                    (result) => {
                        const timeStamp = moment(new Date());
                        refresh = setInterval(() => {
                            if (result?.data?._id) {
                                dispatch(backupGetStatus(result.data._id, (res: any) => {
                                    if (res && res.data && res.data._id) {
                                        if (res.data.percent > 99) {
                                            dispatch(backupDownload(res.data._id, onSuccess, onError));
                                            // @ts-ignore
                                            clearInterval(refresh);
                                            // onEnd();
                                        } else {
                                            console.log('BKP res', res);
                                        }
                                    }
                                }));
                            }

                            const ago = Math.abs(timeStamp.diff(new Date()));
                            if (ago > BACKUP_ERROR_INTERVAL) {
                                onEnd();
                                dispatch({type: BACKUP_ERROR_START});
                                dispatch(showMessage({
                                    message: 'Can`t create a backup now. Something  goes wrong.',
                                    type: MESSAGE_ERROR,
                                }));
                            }
                        }, BACKUP_REFRESH_INTERVAL);
                    }, (err) => {
                        onEnd();
                        dispatch({type: BACKUP_ERROR_START, err});
                        dispatch(showMessage({
                            message: 'Can`t create a backup now. Please check if it is available.',
                            type: MESSAGE_ERROR,
                        }));
                    }
            );

        } else {
            dispatch({type: BACKUP_ERROR_START});
            dispatch(showMessage({
                message: 'Can`t create a backup now. Please check backup data.',
                type: MESSAGE_ERROR,
            }));
        }
    };
};
