import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {
    CN,
    getFormattedFullDate,
    getTimeWithTimeZone,
    changeTimeZone,
    FULL_TIME_SHORT,
    INPUT_TIME_SHORT,
    INPUT_DATE_ONLY_FORMAT_REVERSE,
} from '@gtb/utils';
import {toNumber} from "lodash";
import {Tooltip} from "@gtb";
import {
    TIME_ZONE,
    TIME_ZONES_LIST
} from "@gtb/utils";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentTimezone} from "store/actions";

const useStyles = makeStyles(({palette, spacing}: CustomTheme) => ({
    root: {
        minHeight: spacing(7),
        paddingLeft: spacing(0),
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        borderLeftWidth: 1,
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderColor: palette.background.main,
        borderStyle: 'solid',
        cursor: 'pointer',

    },
    values: {
        letterSpacing: '0px',
        padding: spacing(0, 2, 0, 1),
        '&:first-child': {
            padding: spacing(0, 2, 0, 1),
        },
        color: palette.text.active,
        fontSize: '1.6rem',
        fontWeight: 600,
        userSelect: 'none',
        '.unlogged &':{
            color: palette.common.white,
        }
    },
    disabled: {
        pointerEvents: 'none',
        alignSelf: 'flex-end',
    },
    authValues: {
        color: 'white',
    },
    tooltip: {
        marginRight: 6
    },
    timeValuesRoot: {
        height: spacing(7),
        justifyContent: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    itemFirst: {
        fontSize: '1.8rem',
        fontWeight: 'bolder',
        lineHeight: '4.8rem',
        paddingBottom: '4px!important',
        display: 'flex',
        alignItems: 'center',
        height: spacing(4),
    },
    loggedItemSecond: {
        paddingBottom: spacing(0.222) + '!important',
    }
}));

interface CCTimeInterface {
    loggedIn?: boolean,
    folded?: boolean,
    cfgFolded?: boolean,
    ccTime?: string,
    className?: string,
}


export const CCTime: React.FC<CCTimeInterface> = (props) => {
    const stl = useStyles(props);
    const {
        loggedIn = true,
        folded,
        cfgFolded,
        ccTime,
        className = 'loggedIn'
    } = props;

    // @ts-ignore
    const {timezone} = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    let intervalID: NodeJS.Timer;
    const [time, setTime] = useState(new Date());
    const [timeZone, setTimeZone] = useState(timezone);

    useEffect(() => {
        if (intervalID) {
            // @ts-ignore
            clearInterval(intervalID);
        }
        setTimeZone(timezone);

        // Sync TIME_ZONE  with storage
        if (timezone !== TIME_ZONE) {
            changeTimeZone(timezone);
        }

        // @ts-ignore
        // dispatch(getCurrentTimezone());

    }, []);

    const tick = useCallback((time) => {
        let d: Date = new Date(time);
        const currentSeconds = new Date();
        d.setSeconds(currentSeconds.getSeconds() + 1);
        setTime(d);
    }, [JSON.stringify(time)]);

    useEffect(() => {
        intervalID = setInterval(() => tick(time), 1000);

        return () => {
            // @ts-ignore
            clearInterval(intervalID);
        };
    }, [JSON.stringify(time)]);

    useEffect(() => {
        // @ts-ignore
        clearInterval(intervalID);
        setTimeZone(TIME_ZONE);
        setTime(ccTime ? new Date(getFormattedFullDate(ccTime)) : new Date());
    }, [TIME_ZONE, JSON.stringify(ccTime)]);

    return (
            <div
                    className={CN([stl.root, className, !loggedIn ? (stl.disabled) : ''])}
            >
                <Tooltip
                        title={getTimeWithTimeZone(
                                time,
                                timeZone,
                                FULL_TIME_SHORT,
                        )}
                        classes={{
                            tooltip: stl.tooltip,
                        }}
                        placement={'bottom'}
                >
                    <div className={stl.timeValuesRoot}>
                        <div className={CN([stl.values, stl.itemFirst, !loggedIn ? stl.authValues : ''])}>
                            {
                                getTimeWithTimeZone(
                                        time,
                                        timeZone,
                                        INPUT_TIME_SHORT,
                                )
                            }
                        </div>
                        <div className={CN([stl.values, !loggedIn ? stl.authValues : stl.loggedItemSecond])}>
                            {
                                getTimeWithTimeZone(
                                        time,
                                        timeZone,
                                        INPUT_DATE_ONLY_FORMAT_REVERSE,
                                )
                            }
                        </div>
                    </div>
                    {/* </Link> */}
                </Tooltip>
            </div>
    );
};

export default CCTime;
