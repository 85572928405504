import moment from 'moment-timezone';
import Config from '../configs/timeZoneConfig';

export const DATE_LAST_24_HOURS = 4;
export const DATE_LAST_7_DAYS = 3;
export const DATE_LAST_30_DAYS = 2;
export const DATE_LAST_12_MONTH = 1;
export const DATE_LAST_3_MONTH = 6;
export const DATE_LAST_6_MONTH = 7;

export const FULL_TIME_FORMAT = 'MMMM DD, YYYY hh:mm:ss A';
export const FULL_TIME_SHORT_WITH_SECONDS = 'MMM DD, YYYY hh:mm:ss A';
export const FULL_TIME_SHORT = 'MMM DD, YYYY h:mm:ss A z';
export const FULL_TIME_SHORT_FORMAT = 'MMM DD, YYYY hh:mm A';
export const WITHOUT_SECONDS_FORMAT = 'MMMM DD, YYYY hh:mm A';
export const WITHOUT_SECONDS_SHORT_FORMAT = 'MMM DD, YYYY LT z';

export const ONLY_DATA_FORMAT = 'MMM DD, YYYY';

export const INPUT_FULL_TIME_FORMAT_BACK = 'DD/MM/YYYY HH:mm:ss';
export const INPUT_FULL_TIME_FORMAT = INPUT_FULL_TIME_FORMAT_BACK + ' Z';

export const INPUT_WITHOUT_SECONDS_TIME_FORMAT_BACK = 'DD/MM/YYYY HH:mm';
export const INPUT_WITHOUT_SECONDS_TIME_FORMAT = INPUT_WITHOUT_SECONDS_TIME_FORMAT_BACK + ' Z';

export const INPUT_DATE_ONLY_FORMAT = 'DD/MM/YYYY';
export const INPUT_DATE_ONLY_FORMAT_REVERSE = 'MM/DD/YYYY';

export const INPUT_TIME_BACK = 'HH:mm';
export const INPUT_TIME = 'hh:mm A';
export const INPUT_TIME_SHORT = 'h:mm A';


export let TIME_ZONE: string = moment.tz.guess();
const TIME_ZONES: string[] = moment.tz.names();

const sortByZone = (a: any, b: any) => {
	let [ahh, amm] = a.label.split('GMT')[1].split(')')[0].split(':');
	let [bhh, bmm] = b.label.split('GMT')[1].split(')')[0].split(':');
	return (+parseInt(ahh) * 60 + parseInt(amm)) - (+parseInt(bhh) * 60 + parseInt(bmm));
};
export const TIME_ZONES_LIST = TIME_ZONES.map((t: string) => {
	return { value: t, label: `(GMT${moment.tz(t).format('Z')}) ${t}` };
}).sort(sortByZone);

export const changeTimeZone = (value: string) => {
	TIME_ZONE = value;
};

export const getTimeZone = (TimeZones: { id: number, name: string }[], ccTimezone: number) => {
	const timezone = TimeZones.filter(e => e.id === ccTimezone);
	return timezone && timezone.length
		? timezone[0].name
		: moment.tz.guess(true);
};


export const getTimeWithTimeZone = (time: string | Date, timeZone: number | string = new Date().getTimezoneOffset(), format: string = WITHOUT_SECONDS_SHORT_FORMAT) => {
	return moment(time)
		.tz(typeof timeZone == 'string' ? timeZone : getTimeZone(Config, timeZone))
		.format(format);
};

export const getFormatFullDate = (locale: string = 'en') => {
	switch (locale) {
		case 'en':
			return FULL_TIME_FORMAT;
		default:
			return FULL_TIME_FORMAT;
	}
};

export const getFormattedFullDate = (
	date: string | Date,
	outputFormat: string = getFormatFullDate(),
	inputFormat: string = INPUT_FULL_TIME_FORMAT,
) => {
	const parsedDate: moment.Moment = moment(date + ' +0000', inputFormat).tz(TIME_ZONE);
	return parsedDate.isValid()
		? parsedDate.utc(true).format(outputFormat)
		: '';
};

export const setFormattedDate = (
	date: string | Date,
	outputFormat: string = ONLY_DATA_FORMAT,
) => {
	if (!date) {
		return '';
	}
	const parsedDate: moment.Moment = moment(new Date(date)).tz(TIME_ZONE);
	return parsedDate.isValid()
		? parsedDate.utc(true).format(outputFormat)
		: '';
};
