import React, {
    useState,
    useEffect,
    useRef,
} from 'react';
import {CN, notEmptyArray, spacingToNumber} from '@gtb/utils';
import {makeStyles, useTheme} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {CTooltip as Tooltip} from '@gtb';
// import {GtbIconButton} from '@gtb';
import {darken} from '@mui/material/styles';
// import Resizer from './Resizer';
import Row from '@gtb/components/Grid/Row';
import Icon, * as Icons from '@gtb/components/Icons';

const useStyles = makeStyles(({palette, spacing}: CustomTheme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: spacing(7),
        padding: 0,
        margin: 0,
        '&.enabled': {
            cursor: 'pointer',
        },
        '&.filtered': {
            color: palette.action.active5 + '!important',
        },
        '.dragging &': {
            pointerEvents: 'none!important',
            cursor: 'ew-resize!important',
        },
        '.minimizedView &': {},
        '.th.centered &': {
            justifyContent: 'center',
            '&.minimized $wrp': {
                padding: 0,
            },
            '&.minimized:hover $wrp': {
                padding: 0,
            },
            '&.minimized:hover $text': {
                marginLeft: spacing(3),
            },
            '&.disableFilters:hover $text': {
                margin: '0!important',
            },
        },
        '.th.middle &': {
            alignItems: 'center',
        },

        '&:hover $filter': {
            opacity: 1,
        },
        '&:hover $text': {
            marginRight: spacing(3),
        },
        '&.minimized $text': {
            marginRight: 0,
        },
        '&.disableFilters $text': {
            marginRight: 0,
        },
        '&.disableFilters $wrp': {
            padding: `0px ${spacing(1)} important`,
        },
        '.paddingLeft &.disableFilters $wrp': {
            paddingLeft: spacing(3) + '!important',
        },
        '.paddingRight &.disableFilters $wrp': {
            paddingRight: spacing(3) + '!important',
        },
        '&.minimized:hover $text': {
            marginRight: spacing(3),
        },
        '&.minimized $sorting': {
            opacity: '0!important',
        },
        '&:hover $asc': {
            opacity: 1,
        },

        '&.full': {
            padding: 0,
            height: '100%',
            '& $wrp': {
                padding: 0,
                height: '100%',
            },
        },
        '.minimizedView > &': {
            height: spacing(5.5),
        },
    },
    wrp: {
        position: 'relative',
        display: 'flex',
        width: 'auto',
        flexShrink: 1,
        lineHeight: spacing(2.844444),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        padding: spacing(0, 0, 0, 3),
        '.noPadding &': {
            padding: 0,
        },
        '.smallPadding &': {
            padding: spacing(1) + '!important',
        },
        '.idMiddle &': {
            padding: spacing(1, 1, 1.166666),
        },
        '.centered &': {
            textAlign: 'center',
        },
        '.normalTextWrap &': {
            '& div': {
                whiteSpace: 'normal',
            },
        },

    },
    text: {
        overflow: 'hidden',
        position: 'relative',
        width: `auto`,
        marginRight: spacing(3),
        flexShrink: 1,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '.centeredIcon &': {
            display: 'flex',
        }
    },
    tooltip: {
        maxWith: 400,
    },
    sorting: {
        position: 'absolute',
        top: '50%',
        right: 0,
        alignSelf: 'center',
        transform: 'translateY(-50%)',
        display: 'flex',
        opacity: 1,
        '$root.active &': {
            opacity: 1,
        },
    },
    asc: {
        opacity: 0,
        display: 'block',
        cursor: 'pointer',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0px 6px 6px 6px',
        borderColor: `transparent transparent ${palette.text.secondary}`,
        '&.active': {
            right: 0,
            opacity: 1,
            borderColor: `transparent transparent ${palette.action.active}`,
        },
        '&:hover': {
            borderColor: `transparent transparent ${darken(
                    palette.action.active,
                    0.5,
            )}`,
        },
    },
    desc: {
        opacity: 0,
        display: 'block',
        cursor: 'pointer',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0px 6px 6px 6px',
        borderColor: `${palette.text.secondary} transparent transparent`,
        '&.active': {
            right: 0,
            opacity: 1,
            borderColor: `${palette.action.active} transparent transparent`,
        },
        '&:hover': {
            borderColor: `${darken(
                    palette.action.active,
                    0.5,
            )} transparent transparent`,
        },
    },
    filter: {
        opacity: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '10%',
        right: 0,
        cursor: 'pointer',
        width: spacing(3),
        height: '80%',
        borderStyle: 'solid',
        borderWidth: '0px 0px 0px 1px',
        borderColor: palette.background.main,
        '& svg': {
            width: spacing(2),
            height: spacing(2),
        },
        '&:hover svg path': {
            fill: palette.action.active5,
        },
    },
    colorIcon: {
        height: spacing(3),
        width: spacing(3),
        display: 'inline-block',
        alignItems: 'center',
        marginRight: spacing(1),
        '& svg': {
            margin: 0,
        },
    },
}));

const TH: React.FC<{ [x: string]: any }> = (props) => {


    const classes = useStyles(props);
    const theme: CustomTheme = useTheme();
    const {
        style = {},

        index,
        thead,

        slug,
        // name,
        description,
        // label,
        content,

        color,
        sortable = false,
        minimizedDisabled,
        srt,
        onSort,
        onFocus = () => false,

        // resizable,
        // movable,
        onResize = undefined,
        onEndDrag = () => false,
        onStartDrag = () => false,
        // width,
        filtered,
        template,

        filters = [],
        onFiltering = null,

        // TODO: Potential props
        // hidable,
        // isBolded,
        // ...rest
    } = props;


    const [minimized, setMinimized] = useState(false);
    const [tooltipAllowed, setTooltipAllowed] = useState(false);
    const wrp: React.Ref<any> = useRef(null);
    const text: React.Ref<any> = useRef(null);

    const enableFilters = (onFiltering && notEmptyArray(filters));

    useEffect(() => {

        if (text.current.scrollWidth > text.current.clientWidth) {
            setTooltipAllowed(true);
            setMinimized(true);
        } else {
            setTooltipAllowed(false);
            setMinimized(false);
        }
        if ((wrp.current.clientWidth - text.current.clientWidth) < spacingToNumber(theme.spacing(1)) * (enableFilters ? 12 : 6)) {
            setMinimized(true);
        } else {
            setMinimized(false);

        }
    });

    return (
            <Tooltip
                    title={description && tooltipAllowed
                            ? description
                            : null}
                    placement={'top'}
                    classes={{tooltip: classes.tooltip}}
            >
                <div
                        ref={wrp}
                        className={CN(classes.root, {
                            active: srt === 1 || srt === -1,
                            enabled: sortable,
                            filtered: filtered,
                            full: notEmptyArray(content),
                            minimized: minimized && !minimizedDisabled,
                            disableFilters: !enableFilters,
                        })}
                        onClick={() => {
                            if (sortable && onSort) {
                                const s = srt === 1
                                        ? -1
                                        : 1;
                                onSort(slug, s);
                                onFocus([]);
                            }
                        }}
                >
                    {/*{onResize*/}
                    {/*        ? <Resizer*/}
                    {/*                thead={thead}*/}
                    {/*                index={index}*/}
                    {/*                onResize={onResize}*/}
                    {/*                onEndDrag={onEndDrag}*/}
                    {/*                onStartDrag={onStartDrag}*/}
                    {/*        />*/}
                    {/*        : null}*/}
                    <div
                            className={classes.wrp}
                            style={{...style}}
                    >
                        {/*{color ? (*/}
                        {/*        <GtbIconButton className={classes.colorIcon}>*/}
                        {/*            <Icon view={Icons.ICON_SEVERITY} fill={[ color, color, color ]}/>*/}
                        {/*        </GtbIconButton>*/}
                        {/*) : null}*/}
                        <div className={classes.text} ref={text}>
                            {notEmptyArray(content)
                                    ? (
                                            <Row
                                                    template={template}
                                                    styles={{height: '100%'}}
                                                    thead={true}
                                                    fields={[...content]}
                                            />
                                    )
                                    : content
                            }
                        </div>
                        {(sortable && onSort) ? (
                                <div className={classes.sorting}>
                                    {/* eslint-disable-next-line eqeqeq */}
                                    {(srt !== -1) &&
                                    <div
                                            className={CN(classes.asc, {
                                                active: srt === 1,
                                            })}
                                    >
                                        {' '}
                                    </div>
                                    }
                                    {(srt === -1) &&
                                    <div
                                            className={CN(classes.desc, {
                                                active: srt === -1,
                                            })}
                                    >
                                        {' '}
                                    </div>
                                    }
                                </div>
                        ) : null}
                    </div>
                    {enableFilters ? (
                            <div
                                    className={classes.filter}
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onFiltering(slug);
                                    }}
                            >
                                <Icon view={Icons.ICON_FILTERS}/>
                            </div>
                    ) : null}
                </div>
            </Tooltip>
    );
}

export default TH;
