/* 
 * Menu
 *
 */

// Help
export const ICON_TESTING_LAB = "M12 22.3c-2.3 0-4.6-0.9-6.4-2.6C3.9 18 3 15.7 3 13.3 3 10.9 3.9 8.7 5.6 7c0 0 0 0 0 0l5.6-5.7C11.5 1.1 11.7 1 12 1h0c0.3 0 0.5 0.1 0.7 0.3L18.4 7c1.7 1.7 2.6 4 2.6 6.4 0 2.4-0.9 4.7-2.6 6.4C16.6 21.4 14.3 22.3 12 22.3zM7.1 8.4C7.1 8.4 7 8.4 7.1 8.4 5.7 9.7 5 11.5 5 13.3s0.7 3.6 2.1 4.9c2.7 2.7 7.2 2.7 9.9 0 1.3-1.3 2-3.1 2-5 0-1.9-0.7-3.6-2.1-4.9l-5-5L7.1 8.4C7.1 8.4 7.1 8.4 7.1 8.4zM6.3 7.7L6.3 7.7 6.3 7.7z"; 
export const ICON_SDK_CONFIG = "M12 23c-0.2 0-0.3 0-0.4-0.1l-10-5c-0.5-0.2-0.7-0.8-0.4-1.3 0.2-0.5 0.8-0.7 1.3-0.4l9.6 4.8 9.6-4.8c0.5-0.2 1.1 0 1.3 0.4 0.2 0.5 0 1.1-0.4 1.3l-10 5C12.3 23 12.2 23 12 23zM12 18c-0.2 0-0.3 0-0.4-0.1l-10-5c-0.5-0.2-0.7-0.8-0.4-1.3 0.2-0.5 0.8-0.7 1.3-0.4L12 16l2-1.1 7.5-3.7c0.5-0.2 1.1 0 1.3 0.5 0.2 0.5 0 1.1-0.5 1.3l-7.5 3.7L12.3 18C12.3 18 12.2 18 12 18zM12 13c-0.2 0-0.3 0-0.4-0.1l-10-5C1.3 7.7 1 7.4 1 7s0.2-0.7 0.6-0.9l10-5c0.3-0.1 0.6-0.1 0.9 0l10 5c0.3 0.2 0.6 0.5 0.6 0.9s-0.2 0.7-0.6 0.9l-10 5C12.3 13 12.2 13 12 13zM4.2 7l7.8 3.9L19.8 7 12 3.1 4.2 7z";
export const ICON_POLICY = "M12.4 22.9c-0.3 0.1-0.6 0.1-0.9 0 -0.3-0.1-0.7-0.4-1.3-0.8 -1-0.6-2-1.3-2.9-2.1 -2.7-2.4-4.3-5-4.3-8v-8c0-0.5 0.3-0.9 0.8-1l8-2c0.2 0 0.3 0 0.5 0l8 2c0.4 0.1 0.8 0.5 0.8 1v8c0 3-1.6 5.6-4.3 8 -0.9 0.8-1.9 1.5-2.9 2.1C13.2 22.5 12.7 22.8 12.4 22.9zM12.7 20.4c0.9-0.6 1.8-1.2 2.6-1.9 2.3-2 3.7-4.2 3.7-6.5v-7.2l-7-1.8 -7 1.8v7.2c0 2.3 1.4 4.5 3.7 6.5 0.8 0.7 1.7 1.4 2.6 1.9 0.3 0.2 0.5 0.3 0.7 0.4C12.2 20.7 12.5 20.6 12.7 20.4z";
export const ICON_OBJECTS = "M12,23.8c-0.5,0-0.9-0.1-1.3-0.3l-8-4C1.7,19.1,1,18,1,16.8V7.3C1,6.1,1.7,5,2.7,4.6l7.9-4c0.9-0.4,1.8-0.4,2.7,0l8,4C22.3,5,23,6.1,23,7.3v9.5c0,1.2-0.7,2.3-1.7,2.7l-7.9,4C12.9,23.7,12.5,23.8,12,23.8z M12,2.2c-0.1,0-0.3,0-0.5,0.1l-8,4C3.1,6.5,3,6.8,3,7.2v9.5c0,0.4,0.2,0.7,0.5,0.9l8.1,4c0.3,0.2,0.6,0.2,0.9,0l8-4c0.4-0.2,0.5-0.5,0.5-0.9V7.2c0-0.4-0.2-0.7-0.5-0.9l-8.1-4C12.3,2.3,12.1,2.2,12,2.2z M21.1,7.5l-8.7,4.3c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1L1.9,7C1.4,6.8,1.2,6.2,1.4,5.7S2.2,5,2.7,5.2L12,9.8l9.3-4.6 M11,22.8V11.7c0-0.6,0.4-1.6,1-1.6s1,0.4,1,1v11.7";

// Main
export const ICON_PASSWORD_DASHBOARD = [
    {path: "M21.2,21c-0.2,0-0.4-0.1-0.5-0.2c-0.5-0.3-0.6-0.9-0.3-1.4c2.6-3.9,2-9.2-1.3-12.6C15.7,3.5,10.4,3,6.4,5.6	C4.2,7.1,2.7,9.3,2.2,12c-0.5,2.6,0,5.3,1.5,7.5c0.3,0.5,0.2,1.1-0.3,1.4C2.9,21.2,2.3,21,2,20.6c-1.8-2.7-2.4-5.9-1.8-9	c0.6-3.1,2.5-5.8,5.1-7.6c4.8-3.2,11.1-2.5,15.2,1.6c4,4,4.6,10.3,1.5,15C21.8,20.9,21.5,21,21.2,21z"},
    {color: '#FF0000', path: "M18.4,8.4L18.4,8.4c0.5,0.5,0.5,1.2,0.1,1.8l-5.3,6.5c-0.7,0.9-2.1,1.1-3.1,0.2L10,16.8l0,0c-0.8-0.8-0.8-2.3,0-3.1l0.1-0.1l6.5-5.3C17.2,7.9,17.9,7.9,18.4,8.4z"},
];
export const ICON_EVENTS = [
    {path: "M18.8,24h-13c-1.7,0-3-1.3-3-3s1.3-3,3-3h13c1.7,0,3,1.3,3,3S20.4,24,18.8,24z M5.8,20c-0.6,0-1,0.4-1,1s0.4,1,1,1h13c0.6,0,1-0.4,1-1s-0.4-1-1-1H5.8z"},
    {color: '#FF7F00', path: "M12,3.3c0.6,0,1.1-0.5,1.1-1.1V1.1C13.1,0.5,12.6,0,12,0s-1.1,0.5-1.1,1.1v1.1C10.9,2.8,11.4,3.3,12,3.3 M15.8,4.2c0.2,0.1,0.3,0.1,0.5,0.1c0.4,0,0.8-0.2,1-0.6l0.6-1.1c0.3-0.5,0.1-1.2-0.4-1.5S16.3,1,16,1.5l-0.6,1.1  C15.1,3.3,15.3,3.9,15.8,4.2 M19.6,7.6c0.3,0,0.6-0.1,0.8-0.3l1.1-1.1c0.4-0.4,0.4-1.1,0-1.5s-1.1-0.4-1.5,0l-1.1,1.1   c-0.4,0.4-0.4,1.1,0,1.5C19.1,7.5,19.4,7.6,19.6,7.6 M21.8,9.8h-1.1c-0.6,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1h1.1   c0.6,0,1.1-0.5,1.1-1.1S22.4,9.8,21.8,9.8 M3.3,12c0.6,0,1.1-0.5,1.1-1.1S3.9,9.8,3.3,9.8H2.2c-0.6,0-1.1,0.5-1.1,1.1S1.6,12,2.2,12   H3.3z M3.6,7.3c0.2,0.2,0.5,0.3,0.8,0.3S5,7.5,5.2,7.3c0.4-0.4,0.4-1.1,0-1.5L4,4.7c-0.4-0.4-1.1-0.4-1.5,0s-0.4,1.1,0,1.5L3.6,7.3z    M6.7,3.8c0.2,0.4,0.6,0.6,1,0.6c0.2,0,0.3,0,0.5-0.1c0.5-0.3,0.7-1,0.4-1.5L8.1,1.7c-0.3-0.5-1-0.8-1.5-0.5S5.8,2.1,6.1,2.7   L6.7,3.8z M18,16H6v-5.6c0-3.3,2.7-6,6-6s6,2.7,6,6V16z M8,14h8v-3.6c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4V14z"},
];
export const ICON_REPORTS = [
    {path: "M23,24H1c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1s1,0.4,1,1v21h21c0.6,0,1,0.4,1,1S23.6,24,23,24z M8.5,18.5v-4c0-0.6-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1h2C8.1,19.5,8.5,19,8.5,18.5z M15,18.5v-8c0-0.6-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1h2C14.6,19.5,15,19,15,18.5z"},
    {color: '#FFCE00', path: "M20.5,19.5h-2c-0.6,0-1-0.4-1-1v-13c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1v13C21.5,19,21.1,19.5,20.5,19.5z"},
];
export const ICON_PASSWORD_NOTIFICATIONS = "M12,24c-0.5,0-1.1-0.2-1.6-0.5c-0.4-0.3-0.9-0.6-1.1-1.2c-0.2-0.5,0.1-1.1,0.6-1.3c0.5-0.2,1.1,0.1,1.3,0.6c0,0,0.2,0.1,0.3,0.2C11.8,22,12,22,12.2,22c0.3-0.1,0.5-0.2,0.7-0.5c0.3-0.5,0.9-0.6,1.4-0.1s0.6,0.9,0.3,1.4c-0.4,0.7-1.1,1.2-1.9,1.4C12.4,24,12.2,24,12,24z M22,19H2c-0.6,0-1-0.4-1-1s0.4-1,1-1c1.1,0,2-0.9,2-2v-5c0-4.5,3.5-8,8-8s8,3.5,8,8v5c0,1.1,0.9,2,2,2c0.6,0,1,0.4,1,1S22.6,19,22,19z M5.5,17h13c-0.3-0.6-0.5-1.3-0.5-2v-5c0-3.4-2.6-6-6-6s-6,2.6-6,6v5C6,15.7,5.8,16.4,5.5,17z M11.9,0c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S10.8,0,11.9,0z";
export const ICON_STATUS = [
    {path: "M21,23H3c-1.6,0-3-1.3-3-3V4c0-1.6,1.3-3,3-3h18c1.6,0,3,1.3,3,3v16C24,21.6,22.6,23,21,23z M3,3C2.4,3,2,3.4,2,4v16c0,0.6,0.5,1,1,1h18c0.6,0,1-0.5,1-1V4c0-0.6-0.5-1-1-1H3z"},
    {color: '#61CBB5', path: "M11.3,18.1c-0.4,0-0.8-0.2-0.9-0.6l-2.3-5.8l-0.7,1.2c-0.2,0.3-0.5,0.5-0.9,0.5l0,0H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h2l1.5-2.5c0.3-0.5,0.9-0.6,1.4-0.3C9.1,8.7,9.3,9,9.3,9.3l2,5l3-7.8C14.5,6,15,5.8,15.6,6c0.4,0.1,0.6,0.5,0.6,0.8l1.9,4.6H20c0.6,0,1,0.4,1,1s-0.4,1-1,1h-2.6c-0.1,0-0.1,0-0.2,0s-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1-0.1-0.1-0.1-0.2l-1.3-3.1l-2.9,7.5c0,0.4-0.3,0.7-0.6,0.8C11.5,18.1,11.4,18.1,11.3,18.1z"},
];
export const ICON_ACOUNT_MANAGER = [
    {path: "M8.2,2.3c-2.6,0.2-4.7,2.4-5,4.9C2.9,9.5,4,11.5,5.5,12.7C2.3,14,0,17.1,0,20.9c0,0.4,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-3.1,1.9-5.6,4.5-6.7c0.9-0.3,1.3-1.3,1-2.1c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.2-0.3-0.3-0.4C5.8,10.6,5,9.4,5,8c0-2.2,1.8-4,4-4c0,0,0.5-0.1,0.5-0.8C9.6,2.3,8.6,2.2,8.2,2.3"},
    {color: '#00B7D9', path: "M18.3,12.7c1.5-1,2.4-2.7,2.4-4.7c0-3.2-2.5-5.8-5.8-5.8S9.3,4.8,9.3,8c0,1.9,1,3.6,2.4,4.7c-3.2,1.4-5.4,4.4-5.4,8.2c0,0.5,0.4,0.9,0.9,0.9c0.9,0,15.9,0,15.9,0c0.5,0,0.9-0.3,0.9-0.9C23.8,17.2,21.5,14,18.3,12.7z M11,8c0-2.3,1.7-4,4-4s4,1.7,4,4s-1.7,4-4,4C12.8,12,11,10.2,11,8z M8.1,19.8c0.5-3.4,3.5-6,7-6s6.4,2.6,7,6H8.1z"},
];
export const ICON_DLP_SETUP = [
    {path: "M12,24c-0.1,0-0.2,0-0.3-0.1C11.3,23.8,2,20.3,2,12V4.3c0-0.4,0.3-0.8,0.7-0.9l9-3.3c0.2-0.1,0.5-0.1,0.7,0	l9,3.3c0.4,0.1,0.7,0.5,0.7,0.9V12c0,8.3-9.3,11.8-9.7,11.9C12.2,24,12.1,24,12,24z M4,5v7c0,6.1,6.4,9.2,8,9.9c1.6-0.7,8-3.8,8-9.9	V5l-8-2.9L4,5z"},
    {color: '#F04A00', path: "M11.1,16c-0.3,0-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.3,2.3l5.3-5.3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-6,6C11.6,15.9,11.4,16,11.1,16z"},
];
export const ICON_RESOURCE = [
    {primary: true, path: "M16,24h-2c-0.6,0-1-0.4-1-1v-2c0-0.3,0.1-0.5,0.3-0.7l7-7c0.4-0.4,1-0.4,1.4,0l2,2c0.4,0.4,0.4,1,0,1.4l-7,7C16.5,23.9,16.3,24,16,24z M15,22h0.6l6-6L21,15.4l-6,6V22z"},
    {path: "M22.9,6.5H4.8C4.2,6.5,3.7,6,3.7,5.4s0.5-1.1,1.1-1.1h18.1c0.6,0,1.1,0.5,1.1,1.1S23.5,6.5,22.9,6.5z M1.1,4.3c0.6,0,1.1,0.5,1.1,1.1S1.7,6.5,1.1,6.5S0,6,0,5.4S0.5,4.3,1.1,4.3z M22.9,10.9H4.8c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1h18.1c0.6,0,1.1,0.5,1.1,1.1S23.5,10.9,22.9,10.9z M1.1,8.7c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1S0,10.4,0,9.8S0.5,8.7,1.1,8.7z M11.9,15.3H4.8c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1h7.1c0.6,0,1.1,0.5,1.1,1.1S12.5,15.3,11.9,15.3z M1.1,13.1c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1S0,14.8,0,14.2S0.5,13.1,1.1,13.1z M11.9,19.7H4.8c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1h7.1c0.6,0,1.1,0.5,1.1,1.1S12.5,19.7,11.9,19.7z M1.1,17.5c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1S0,19.2,0,18.6S0.5,17.5,1.1,17.5z"},    
];
export const ICON_SYSTEM_SETTING = [
    {color: '#DB6BC4', path: "M23.4,16.2h-0.8c-0.1-0.7-0.5-1.4-0.9-1.9l0.5-0.5c0.1-0.1,0.1-0.6,0-0.8l-0.7-0.8c-0.1-0.1-0.6-0.1-0.8,0L20,12.5c-0.5-0.4-1.2-0.6-1.9-0.8v-0.6c0-0.4-0.4-0.6-0.6-0.6h-1.2c-0.4-0.1-0.6,0.2-0.6,0.6V12c-0.8,0.1-1.5,0.4-2,0.7l-0.4-0.5c-0.4-0.4-0.6-0.2-0.9,0l-0.5,0.5c-0.1,0.1-0.2,0.5,0,0.7l0.5,0.5c-0.5,0.6-0.7,1.4-0.8,1.9h-0.8c-0.2,0-0.6,0.4-0.6,0.6v1.2c0,0.4,0.4,0.6,0.6,0.6h0.8c0.1,0.8,0.5,1.4,0.9,1.9l-0.5,0.5c-0.1,0.1-0.1,0.6,0,0.8l0.7,0.9c0.1,0.1,0.6,0.1,0.8,0l0.5-0.5c0.6,0.5,1.4,0.8,1.9,0.9v0.8c0,0.4,0.4,0.6,0.6,0.6h1.2c0.4,0,0.6-0.4,0.6-0.6v-0.8c0.8-0.1,1.4-0.5,1.9-0.9l0.5,0.5c0.1,0.1,0.6,0.1,0.8,0l0.8-0.8c0.1-0.1,0.1-0.6,0-0.8l-0.7-0.4c0.5-0.6,0.8-1.4,0.9-1.9h0.8c0.4,0,0.6-0.4,0.6-0.6v-1.2C24,16.5,23.6,16.2,23.4,16.2z M17.1,19.7c-1.2,0-2.3-1.1-2.3-2.3s1.1-2.3,2.3-2.3s2.3,1.1,2.3,2.3C19.6,18.6,18.4,19.7,17.1,19.7z"},
    {path: "M8.3,22.7H5.7c-0.6,0-1-0.4-1-1s0.4-1,1-1h2.6c0.6,0,1,0.4,1,1S8.9,22.7,8.3,22.7z M8.3,19.3H3c-1.7,0-3-1.3-3-3v-12c0-1.7,1.3-3,3-3h18c1.7,0,3,1.3,3,3v6c0,0.6-0.4,1-1,1s-1-0.4-1-1v-6c0-0.6-0.4-1-1-1H3c-0.6,0-1,0.4-1,1v12c0,0.6,0.4,1,1,1h5.3c0.6,0,1,0.4,1,1S8.9,19.3,8.3,19.3z"},    
];
export const ICON_MAINTENANCE = [
    {path: "M12.9,16.4l5.8,5.8c1,1,2.8,1.1,3.8,0c0.9-1,0.8-2.4-0.1-3.4l-6.1-6.1L12.9,16.4z M10.6,8.9L7.3,5.5L6.4,3.2C6.3,2.9,6.1,2.7,5.9,2.6L3.2,1.2C2.8,1,2.4,1.1,2.1,1.4l-0.9,1C1,2.6,0.9,3,1.1,3.3L2.4,6c0.1,0.3,0.4,0.5,0.7,0.6l2.3,0.7l3.4,3.4	L10.6,8.9z"},
    {color: '#F56B4F', path: "M23.1,3l-3.2,3.2c-0.3,0.3-0.6,0.4-1,0.4s-0.7-0.1-1-0.4c-0.6-0.6-0.6-1.4,0-2L21.1,1c-0.6-0.3-1.3-0.5-2-0.5c-2.4,0-4.5,2-4.5,4.5c0,0.7,0.2,1.4,0.5,2l-3.1,3.1c-0.5,0.5-0.9,0.9-1.4,1.4l-0.8,0.8c-0.1,0.1-0.3,0.3-0.4,0.4l-0.9,0.9C7.6,14.4,6.9,15,6.9,15c-0.6-0.3-1.3-0.5-2-0.5c-2.5,0-4.5,2-4.5,4.5c0,0.7,0.2,1.4,0.5,2l3.2-3.2c0.3-0.3,0.6-0.4,1-0.4c0.4,0,0.7,0.1,1,0.4c0.6,0.6,0.6,1.4,0,2L2.9,23c0.6,0.3,1.3,0.5,2,0.5c2.4,0,4.5-2,4.5-4.5c0-0.7-0.2-1.4-0.5-2l3.1-3.1c0.5-0.5,0.9-0.9,1.4-1.4l0.8-0.8c0.1-0.1,0.3-0.3,0.4-0.4l0.9-0.9C16.4,9.6,17.1,9,17.1,9c0.6,0.3,1.3,0.5,2,0.5c2.5,0,4.5-2,4.5-4.5C23.6,4.3,23.4,3.5,23.1,3z"},
];
export const ICON_SCAN_AND_LOGS = [
    {path: "M18.5,24h-13c-1.7,0-3-1.3-3-3V5c0-1.7,1.3-3,3-3h1c0-1.2,0.8-2,2-2h7c1.2,0,2,0.8,2,2h1c1.7,0,3,1.3,3,3v16C21.5,22.7,20.2,24,18.5,24z M5.5,4c-0.6,0-1,0.4-1,1v16c0,0.6,0.4,1,1,1h13c0.6,0,1-0.4,1-1V5c0-0.6-0.4-1-1-1h-1c0,1.2-0.8,2-2,2h-7c-1.2,0-2-0.8-2-2H5.5z M8.5,2v2h7V2C9.5,2,8.5,2,8.5,2z"},
    {color: '#4CBD7F', path: "M15.5,10.5h-8c-0.6,0-1-0.4-1-1s0.4-1,1-1h8c0.6,0,1,0.4,1,1S16.1,10.5,15.5,10.5z M14,13.5c0-0.6-0.4-1-1-1H7.5c-0.6,0-1,0.4-1,1s0.4,1,1,1H13C13.6,14.5,14,14.1,14,13.5z"},
]
export const ICON_HELP = "M12,14c-0.6,0-1-0.4-1-1v-1c0-0.6,0.4-1,1-1c0.6,0,1-0.4,1-1s-0.4-1-1-1s-1,0.4-1,1c0,0.6-0.4,1-1,1s-1-0.4-1-1c0-1.7,1.3-3,3-3s3,1.3,3,3c0,1.3-0.8,2.4-2,2.8V13C13,13.6,12.6,14,12,14z M11,16c0,0.6,0.4,1,1,1s1-0.4,1-1s-0.4-1-1-1S11,15.4,11,16z M3.2,22L8,21.2c1.2,0.5,2.6,0.8,4,0.8c0,0,0,0,0,0c5.5,0,10-4.5,10-10c0-5.5-4.5-10-10-10C6.5,2,2,6.5,2,12c0,1.4,0.3,2.7,0.8,4L2,20.8c-0.1,0.3,0.1,0.6,0.3,0.9C2.5,21.9,2.7,22,3,22C3.1,22,3.1,22,3.2,22z M8,19.2l-3.8,0.6L4.8,16c0-0.2,0-0.4-0.1-0.6C4.3,14.4,4,13.2,4,12c0-4.4,3.6-8,8-8s8,3.6,8,8s-3.6,8-8,8c0,0,0,0,0,0c-1.2,0-2.3-0.3-3.4-0.8c-0.1-0.1-0.3-0.1-0.4-0.1C8.1,19.1,8,19.1,8,19.2z";
export const ICON_HELP_MAIN_MENU = [
    {path: "M1.1,24c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.6-0.3-0.9l1-6C0.4,15.2,0,13.6,0,12C0,5.4,5.4,0,12,0s12,5.4,12,12s-5.4,12-12,12c-1.6,0-3.2-0.4-4.8-1.1L1.1,24C1.2,24,1.2,24,1.1,24z M7.4,20.9c0.1,0,0.3,0,0.4,0.1c1.4,0.7,2.9,1,4.3,1c5.5,0,10-4.5,10-10s-4.5-10-10-10s-10,4.5-10,10c0,1.4,0.3,2.8,1,4.3c0.1,0.2,0.1,0.4,0.1,0.6l-0.9,4.9l4.9-0.9C7.3,20.9,7.3,20.9,7.4,20.9z"},
    {primary: true, path: "M12,15.6c-0.6,0-1-0.4-1-1v-2.1c0-0.6,0.4-1,1-1c1,0,1.7-0.7,1.7-1.7S13,8.1,12,8.1s-1.7,0.7-1.7,1.7c0,0.6-0.4,1-1,1s-1-0.4-1-1c0-2,1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7c0,1.7-1.1,3.1-2.7,3.6v1.2C13,15.2,12.6,15.6,12,15.6z M11,17.8c0,0.6,0.4,1,1,1s1-0.4,1-1s-0.4-1-1-1S11,17.3,11,17.8z"},
];

// Events
export const ICON_ALL_EVENTS = "M8,11H5c-1.7,0-3-1.3-3-3V5c0-1.7,1.3-3,3-3h3c1.7,0,3,1.3,3,3v3C11,9.7,9.7,11,8,11z M5,4C4.4,4,4,4.4,4,5v3c0,0.6,0.4,1,1,1h3c0.6,0,1-0.4,1-1V5c0-0.6-0.4-1-1-1H5z M8,22H5c-1.7,0-3-1.3-3-3v-3c0-1.7,1.3-3,3-3h3c1.7,0,3,1.3,3,3v3C11,20.7,9.7,22,8,22z M5,15c-0.6,0-1,0.4-1,1v3c0,0.6,0.4,1,1,1h3c0.6,0,1-0.4,1-1v-3c0-0.6-0.4-1-1-1H5z M19,11h-3c-1.7,0-3-1.3-3-3V5c0-1.7,1.3-3,3-3h3c1.7,0,3,1.3,3,3v3C22,9.7,20.7,11,19,11z M16,4c-0.6,0-1,0.4-1,1v3c0,0.6,0.4,1,1,1h3c0.6,0,1-0.4,1-1V5c0-0.6-0.4-1-1-1H16z M19,22h-3c-1.7,0-3-1.3-3-3v-3c0-1.7,1.3-3,3-3h3c1.7,0,3,1.3,3,3v3C22,20.7,20.7,22,19,22z M16,15c-0.6,0-1,0.4-1,1v3c0,0.6,0.4,1,1,1h3c0.6,0,1-0.4,1-1v-3c0-0.6-0.4-1-1-1H16z";
export const ICON_NETWORK = "M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z M21.1,7.8 c-0.6,0.8-1.2,1.5-1.9,2.2c-1.7-1.5-3.6-2.7-5.7-3.5c0.8-1.3,1.8-2.4,2.9-3.4C18.5,4.1,20.1,5.7,21.1,7.8z M17.7,11.4c-2.2,1.9-4.8,3.4-7.6,4.3c0.3-2.6,1.1-5.2,2.3-7.5C14.4,8.9,16.1,10,17.7,11.4z M12,2c0.7,0,1.5,0.1,2.2,0.3c-1,1.1-1.9,2.3-2.7,3.5C9.7,5.3,7.9,5,6,5C5.6,5,5.2,5,4.8,5.1C6.7,3.1,9.3,2,12,2z M3.3,17C2.5,15.5,2,13.7,2,12c0-1.3,0.3-2.5,0.7-3.7c0.8,3.1,2.4,5.9,4.6,8.2C6,16.8,4.6,16.9,3.3,17z M4.8,18.9c1.1-0.1,2.1-0.3,3.2-0.5c0,1,0.1,1.9,0.2,2.9 C6.9,20.7,5.8,19.9,4.8,18.9z M4.5,7.1C5,7,5.5,7,6,7c1.5,0,3,0.2,4.5,0.6c-1.1,2.2-1.9,4.6-2.2,7C6.4,12.5,5.1,9.9,4.5,7.1z M12,22 c-0.5,0-1.1-0.1-1.6-0.1c-0.2-0.9-0.3-1.9-0.3-2.8c1.4,1.1,3,2,4.7,2.6C13.9,21.9,12.9,22,12,22z M17.4,20.4c-2.2-0.6-4.3-1.6-6.1-3 c2.9-1.1,5.6-2.7,7.9-4.7c0.8,0.9,1.5,1.8,2.1,2.8C20.6,17.5,19.2,19.3,17.4,20.4z M20.6,11.4c0.4-0.4,0.8-0.8,1.2-1.3c0.1,0.6,0.2,1.3,0.2,1.9v1C21.5,12.4,21.1,11.9,20.6,11.4z";
export const ICON_DISCOVERY = "M9,11.3C8,10.5,6.8,10,5.5,10c-0.5,0-0.9,0.1-1.3,0.2l-3.3,2.4C0.3,13.4,0,14.4,0,15.5c0,3,2.5,5.5,5.5,5.5s5.5-2.5,5.5-5.5c0-0.2,0-0.4,0-0.5 M5.5,19C3.6,19,2,17.4,2,15.5S3.6,12,5.5,12S9,13.6,9,15.5S7.4,19,5.5,19z M13,15c0,0.2,0,0.4,0,0.5c0,3,2.5,5.5,5.5,5.5s5.5-2.5,5.5-5.5c0-1.1-0.3-2.1-0.9-2.9l-3.3-2.4C19.4,10.1,19,10,18.5,10 c-1.3,0-2.5,0.5-3.5,1.3 M18.5,19c-1.9,0-3.5-1.6-3.5-3.5s1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5S20.4,19,18.5,19z M15,11.3V6.5C15,5.7,15.7,5,16.5,5c0.4,0,0.9,0.2,1.2,0.6l2.1,4.6l3.3,2.4l0,0l-3.7-8c0-0.1-0.1-0.1-0.1-0.2l0,0l-0.1-0.1l0,0C18.5,3.5,17.5,3,16.5,3c-1.3,0-2.4,0.7-3,1.7C13.1,4.3,12.6,4,12,4s-1.1,0.3-1.5,0.7C9.9,3.7,8.8,3,7.5,3c-1,0-2,0.5-2.7,1.2l0,0L4.7,4.3l0,0c0,0.1-0.1,0.1-0.1,0.2l-3.7,8.1l3.3-2.4l2.2-4.7C6.6,5.2,7.1,5,7.5,5C8.3,5,9,5.7,9,6.5v4.8l2,3.7v-0.3 c0.3,0.2,0.6,0.3,1,0.1s0.7-0.1,1-0.3V15 M13,11.3c-0.3-0.2-0.6-0.3-1-0.1s-0.7,0.1-1,0.3V7.7C11.3,7.9,11.6,8,12,8s0.7-0.1,1-0.3V11.3z";
export const ICON_CLOUD = "M3.8,8.6C1.5,9.7,0,12,0,14.5C0,18.1,2.9,21,6.5,21h12c3,0,5.5-2.5,5.5-5.5s-2.3-5.4-5.3-5.5c-0.9-3.5-4.1-6-7.7-6C7.9,4,5.1,5.8,3.8,8.6 M18,12.1c2.1-0.3,3.9,1.4,3.9,3.5c0.1,1.8-1.5,3.4-3.4,3.4h-12C4,19,2,17,2,14.5c0-1.8,1.2-3.5,2.9-4.2c0.2-0.1,0.4-0.3,0.5-0.5C6.3,7.6,8.5,6,11,6c3,0,5.5,2.2,5.9,5.2C17,11.9,17.4,12.1,18,12.1 M18,12.1";
export const ICON_END_POINT = "M22,3c0-1.7-1.3-3-3-3H5C3.3,0,2,1.3,2,3v7c0,0.8,0.3,1.5,0.8,2C2.3,12.5,2,13.2,2,14v7c0,1.7,1.3,3,3,3h14c1.7,0,3-1.3,3-3v-7c0-0.8-0.3-1.5-0.8-2c0.5-0.5,0.8-1.2,0.8-2V3z M4,3c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1v7c0,0.6-0.4,1-1,1H5c-0.6,0-1-0.4-1-1V3z M20,21c0,0.6-0.4,1-1,1H5c-0.6,0-1-0.4-1-1v-7c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1V21z M10,18h4 M14,19h-4 c-0.6,0-1-0.4-1-1s0.4-1,1-1h4c0.6,0,1,0.4,1,1S14.6,19,14,19z M17,18h1 M18,19h-1c-0.6,0-1-0.4-1-1s0.4-1,1-1h1c0.6,0,1,0.4,1,1S18.6,19,18,19z M6,18h1 M7,19H6c-0.6,0-1-0.4-1-1s0.4-1,1-1h1c0.6,0,1,0.4,1,1S7.6,19,7,19z M6,14c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1s-1-0.4-1-1C5,14.4,5.4,14,6,14z M9,14c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1C8,14.4,8.4,14,9,14z M12,14c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1s-1-0.4-1-1C11,14.4,11.4,14,12,14z M18,14c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1s-1-0.4-1-1C17,14.4,17.4,14,18,14z M15,14c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1s-1-0.4-1-1C14,14.4,14.4,14,15,14z";

// Events -> Discovery
export const ICON_DISCOVERY_LOCAL = "M8,13H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h4c0.6,0,1,0.4,1,1S8.6,13,8,13z M21,23c0-0.6-0.4-1-1-1h-4c-0.6,0-1,0.4-1,1s0.4,1,1,1h4C20.6,24,21,23.6,21,23z M20.5,8.9C21,8.6,21.2,8,21,7.6c-1.2-2.3-3.2-4.1-5.6-5 c-0.5-0.2-1.1,0.1-1.3,0.6s0.1,1.1,0.6,1.3c2,0.7,3.6,2.1,4.5,4C19.3,8.8,19.7,9,20.1,9C20.2,9,20.4,9,20.5,8.9z M11,21c0.1-0.5-0.2-1.1-0.8-1.2c-2.4-0.5-4.3-2.1-5.4-4.2c-0.2-0.5-0.8-0.7-1.3-0.5C3,15.4,2.8,16,3,16.4c1.3,2.7,3.8,4.6,6.7,5.3c0.1,0,0.2,0,0.2,0C10.5,21.8,10.9,21.5,11,21z M12,7V3c0-1.7-1.3-3-3-3H3C1.3,0,0,1.3,0,3v4c0,1.7,1.3,3,3,3h6C10.7,10,12,8.7,12,7z M9,2c0.6,0,1,0.4,1,1v4c0,0.6-0.4,1-1,1H3C2.4,8,2,7.6,2,7V3c0-0.6,0.4-1,1-1H9z M24,17.5v-4c0-1.7-1.3-3-3-3h-6c-1.7,0-3,1.3-3,3v4c0,1.7,1.3,3,3,3h6C22.7,20.5,24,19.2,24,17.5z M21,12.5c0.6,0,1,0.4,1,1v4c0,0.6-0.4,1-1,1h-6c-0.6,0-1-0.4-1-1v-4c0-0.6,0.4-1,1-1H21z";
export const ICON_DISCOVERY_FILE_SHARE = "M7,17h3v1c0,0.6,0.4,1,1,1s1-0.4,1-1v-1h4c0.8,0,1.5-0.4,2.1-0.9S19,14.8,19,14V6c0-0.3-0.1-0.5-0.3-0.7l-5-5C13.5,0.1,13.3,0,13,0H7C6.2,0,5.4,0.3,4.9,0.9C4.3,1.4,4,2.2,4,3v11c0,0.8,0.3,1.6,0.9,2.1C5.4,16.7,6.2,17,7,17z M15.3,5H14V3.4L15.3,5z M6,3c0-0.3,0.1-0.5,0.3-0.7S6.7,2,7,2h5v4c0,0.6,0.4,1,1,1h4v7c0,0.3-0.1,0.5-0.3,0.7S16.3,15,16,15H7c-0.3,0-0.5-0.1-0.7-0.3S6,14.3,6,14V3z M21,21l-6-0.1c-0.5,0-1,0.4-1,1s0.4,1,1,1l6,0.1c0.5,0,1-0.4,1-1C22,21.5,21.6,21,21,21z M11,20c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S9.9,20,11,20z M7,21H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h4c0.6,0,1-0.4,1-1S7.6,21,7,21z";
export const ICON_DISCOVERY_OUTLOOCK = "M8,12c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2S8,10.9,8,12z M12,1v22L0,21V3L12,1z M10,12c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4C8.2,16,10,14.2,10,12z M24,7.9l-7.4,5C16.4,12.9,16.2,13,16,13c-0.2,0-0.4-0.1-0.6-0.2l-1.4-1V20h8.5c0.4,0,0.8-0.2,1.1-0.4c0.3-0.3,0.4-0.7,0.4-1.1v-13V7.9z M14,9.5l2,1.3l8-5.3c0-0.4-0.2-0.8-0.4-1C23.3,4.2,22.9,4,22.5,4H14V9.5z";
export const ICON_DISCOVERY_SHARE_POINT = "M21.5,9.5c-0.1,0-0.1,0-0.2,0c-0.6-1.6-1.8-3-3.3-3.9c0-0.1,0-0.1,0-0.2C18,4.1,16.9,3,15.5,3C15,3,14.4,3.2,14,3.5v4C14.4,7.8,15,8,15.5,8c0.8,0,1.5-0.4,1.9-0.9c1.2,0.7,2,1.8,2.5,3C19.4,10.5,19,11.2,19,12s0.4,1.5,0.9,1.9c-0.4,1.3-1.3,2.3-2.5,3C17,16.4,16.3,16,15.5,16c-0.5,0-1.1,0.2-1.5,0.5v4c0.4,0.3,1,0.5,1.5,0.5c1.4,0,2.5-1.1,2.5-2.5c0-0.1,0-0.1,0-0.2c1.6-0.9,2.7-2.2,3.3-3.9c0.1,0,0.1,0,0.2,0c1.4,0,2.5-1.1,2.5-2.5S22.9,9.5,21.5,9.5z M12,1L0,3v18l12,2V1z M6.7,11C9,11.6,9,13.1,9,13.8c0,0.9-0.4,1.7-1,2.3c-0.7,0.6-1.4,1-2.5,1c-0.9,0-1.7-0.3-2.5-0.7V14c0.6,0.6,1.4,0.9,2.2,1c0.3,0,0.7-0.1,0.9-0.3c0.2-0.2,0.4-0.5,0.4-0.8c0-0.2-0.1-0.5-0.3-0.7c-0.3-0.3-0.7-0.5-1-0.7c-0.6-0.3-1.2-0.7-1.6-1.1C3.2,11,3,10.4,3,9.9c0-0.8,0.4-1.5,0.9-2C4.6,7.3,5.4,7,6.3,7C7,7,7.7,7.2,8.4,7.5v2.3C7.9,9.3,7.4,9,6.5,8.9c-0.2,0,0,0,0,0c-1.1-0.1-1.3,0.8-1.2,1C5.4,10.6,5.8,10.8,6.7,11";
export const ICON_DISCOVERY_EXCHANGE = "M0,3l12-2v22L0,21V3z M9,7H3v10h6v-2H5v-2h3v-2H5V9h4V7z M14,7V4h8.5c0.4,0,0.8,0.2,1.1,0.4C23.8,4.7,24,5.1,24,5.5v13c0,0.4-0.2,0.8-0.4,1.1c-0.3,0.3-0.7,0.4-1.1,0.4H14v-3h1c0.3,0,0.5-0.1,0.7-0.3l2.4-3.5c0.1-0.1,0-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0L14.6,15H14v-0.6l0.7-0.7L14,13v-1.1l1.8,1.2c0.1,0.1,0.2,0,0.3,0c0.1-0.1,0.1-0.2,0-0.3L14,9.6V9h0.6l0.7,0.7l1.4-1.4l-1-1C15.5,7.1,15.3,7,15,7H14z M21,17c0.6,0,1-0.4,1-1v-2c0-0.3-0.1-0.5-0.3-0.7l-3.5-2.4c-0.1-0.1-0.2,0-0.3,0c-0.1,0.1-0.1,0.2,0,0.3l2.1,3.2V15h-0.6l-0.7-0.7l-1.4,1.4l1,1c0.2,0.2,0.4,0.3,0.7,0.3H21z M21,7h-2c-0.3,0-0.5,0.1-0.7,0.3l-2.4,3.5c-0.1,0.1,0,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0L19.4,9H20v0.6l-0.7,0.7l1.4,1.4l1-1c0.2-0.2,0.3-0.4,0.3-0.7V8C22,7.4,21.6,7,21,7z";
export const ICON_DISCOVERY_DATABASES = "M12,1C7.2,1,2,2.3,2,5v14c0,2.7,5.2,4,10,4s10-1.3,10-4V5C22,2.3,16.8,1,12,1z M20,19c0,0.5-2.8,2-8,2c-5.2,0-8-1.5-8-2v-4.5c1.9,1,5,1.5,8,1.5s6.1-0.5,8-1.5V19z M20,12c0,0.5-2.8,2-8,2c-5.2,0-8-1.5-8-2V7.5C5.9,8.5,9,9,12,9s6.1-0.5,8-1.5V12z M12,7C6.8,7,4,5.5,4,5s2.8-2,8-2c5.2,0,8,1.5,8,2S17.2,7,12,7z";
export const ICON_DISCOVERY_MONITORING = "M16,22H8c-0.6,0-1-0.4-1-1s0.4-1,1-1h8c0.6,0,1,0.4,1,1S16.6,22,16,22z M12.9,14.4l1.7-3.4H17c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3c-0.4,0-0.7,0.2-0.9,0.6l-0.9,1.7L11,6.7C10.8,6.3,10.5,6,10.1,6c-0.4,0-0.8,0.2-1,0.6l-1,2.5L7.9,8.7C7.8,8.2,7.2,7.9,6.7,8.1C6.2,8.2,5.9,8.8,6.1,9.3l1,3C7.2,12.7,7.6,13,8,13c0.4,0,0.8-0.2,1-0.6l0.9-2.3l1.2,4.2c0.1,0.4,0.5,0.7,0.9,0.7c0,0,0.1,0,0.1,0C12.4,15,12.7,14.8,12.9,14.4z M20,19H4c-1.7,0-3-1.3-3-3V5c0-1.7,1.3-3,3-3h16c1.7,0,3,1.3,3,3v11C23,17.7,21.7,19,20,19z M4,4C3.4,4,3,4.4,3,5v11c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1V5c0-0.6-0.4-1-1-1H4z";

// DLP Setup 
export const ICON_DLP_INSPECTOR = "M20.4,4.1l-8-3c-0.2-0.1-0.5-0.1-0.7,0l-8,3C3.3,4.2,3,4.6,3,5v7c0,7.6,8.3,10.8,8.6,10.9c0.1,0,0.2,0.1,0.4,0.1s0.2,0,0.4-0.1C12.7,22.8,21,19.6,21,12V5C21,4.6,20.7,4.2,20.4,4.1z M13,20.4v-17l6,2.3V12C19,16.5,15.1,19.3,13,20.4L13,20.4 M11,20.4L11,20.4c-2.1-1.2-6-3.9-6-8.4V5.7l6-2.3V20.4";
export const ICON_DLP_DISCOVERY = "M19.7,22h-3.8c-1.7,0-3-1.3-3-3v-4h-2v4c0,1.7-1.3,3-3,3H4c-0.8,0-1.6-0.4-2.2-1c-0.6-0.6-0.9-1.4-0.8-2.3 c0.1-1.3,0.2-3.2,0.4-5.2c0.1-1.9,0.2-3.7,0.3-4.8c0.1-1.3,1-2.3,2.1-2.6V5c0-0.8,0.3-1.6,0.9-2.1C5.3,2.3,6,2,6.8,2h1C8.6,2,9.4,2.3,10,2.9c0.6,0.6,0.9,1.3,0.9,2.1h2c0-0.8,0.3-1.6,0.9-2.1C14.3,2.3,15,2,15.8,2h1c0.8,0,1.6,0.3,2.1,0.9 c0.6,0.6,0.9,1.3,0.9,2.1v1.1c1.2,0.3,2,1.4,2.1,2.6c0.1,1.2,0.2,3.1,0.3,5c0.1,1.9,0.3,3.8,0.3,5c0.1,0.8-0.2,1.7-0.8,2.3 C21.3,21.7,20.5,22,19.7,22z M9.8,13h4c0.6,0,1,0.4,1,1v5c0,0.6,0.4,1,1,1h3.8c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.3-0.8 c-0.1-1.3-0.2-3.1-0.3-5c-0.1-1.9-0.3-3.8-0.3-5c0-0.5-0.5-0.9-1-0.9h-5.1c-0.6,0-1-0.4-1-1h-2c0,0.6-0.4,1-1,1H4.7 c-0.5,0-1,0.4-1,0.9c-0.1,1.2-0.2,3-0.3,4.8c-0.1,1.9-0.3,3.9-0.4,5.2c0,0.3,0.1,0.6,0.3,0.8C3.5,19.9,3.7,20,4,20h3.8c0.6,0,1-0.4,1-1v-5C8.8,13.4,9.3,13,9.8,13z M14.8,6h3V5c0-0.3-0.1-0.5-0.3-0.7C17.4,4.1,17.1,4,16.8,4h-1c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7V6z M5.8,6h3V5c0-0.3-0.1-0.5-0.3-0.7C8.4,4.1,8.1,4,7.8,4h-1C6.6,4,6.3,4.1,6.1,4.3 C5.9,4.5,5.8,4.7,5.8,5V6z";
export const ICON_DLP_END_POINT = "M9,19H8c-0.6,0-1-0.4-1-1s0.4-1,1-1h1c0.6,0,1,0.4,1,1S9.6,19,9,19z M6,18c0-0.6-0.4-1-1-1H4c-0.6,0-1,0.4-1,1s0.4,1,1,1h1C5.6,19,6,18.6,6,18z M3,15c0,0.6,0.4,1,1,1s1-0.4,1-1s-0.4-1-1-1S3,14.4,3,15z M6,15c0,0.6,0.4,1,1,1s1-0.4,1-1s-0.4-1-1-1S6,14.4,6,15z M0.8,12C0.3,12.5,0,13.2,0,14v7c0,1.7,1.3,3,3,3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1H3c-0.6,0-1-0.4-1-1v-7c0-0.6,0.4-1,1-1 M3,11h6c0.6,0,1,0.4,1,1s-0.4,1-1,1H3 M3,13 M0.8,12C0.3,11.5,0,10.8,0,10V3c0-1.7,1.3-3,3-3h14c1.7,0,3,1.3,3,3v4c0,0.6-0.4,1-1,1s-1-0.4-1-1V3c0-0.6-0.4-1-1-1H3C2.4,2,2,2.4,2,3v7c0,0.6,0.4,1,1,1v2 M18.3,23.9c0.2-0.1,5.7-2.1,5.7-6.9v-4c0-0.4-0.2-0.8-0.6-0.9l-5-2c-0.2-0.1-0.5-0.1-0.7,0l-5,2C12.2,12.2,12,12.6,12,13v4c0,4.8,5.4,6.9,5.7,6.9c0.1,0,0.2,0.1,0.3,0.1S18.2,24,18.3,23.9z M18,12.1l4,1.6V17c0,2.9-3,4.5-4,4.9c-1-0.5-4-2.1-4-4.9v-3.3L18,12.1z";
export const ICON_DLP_IRM = "M14,23c-6.6,0-8.2-3.1-10-6.5c-0.4-0.8-0.9-1.6-1.4-2.4c-0.4-0.4-0.6-1-0.6-1.6v-0.1c0-0.7,0.3-1.3,0.7-1.8c1-1,2.6-1,3.5,0L7,11.5v-6C7,4.1,8.1,3,9.5,3C9.7,3,9.9,3,10,3.1C10.2,1.9,11.3,1,12.5,1c0.9,0,1.7,0.5,2.1,1.2C14.9,2.1,15.2,2,15.5,2C16.9,2,18,3.1,18,4.5v0.6C18.2,5,18.3,5,18.5,5C19.9,5,21,6.1,21,7.5V16C21,19.9,17.9,23,14,23z M4,12.4c0,0.2,0.2,0.4,0.3,0.6c0,0-0.1,0,0,0c0.6,0.9,1,1.8,1.5,2.6C7.4,18.7,8.6,21,14,21c2.8,0,5-2.2,5-5V7.5C19,7.2,18.8,7,18.5,7S18,7.2,18,7.5c0,0.6-0.4,1-1,1s-1-0.4-1-1v-3C16,4.2,15.8,4,15.5,4S15,4.2,15,4.5c0,0.6-0.4,1-1,1s-1-0.4-1-1v-1 C13,3.2,12.8,3,12.5,3S12,3.2,12,3.5v2c0,0.6-0.4,1-1,1s-1-0.4-1-1C10,5.2,9.8,5,9.5,5S9,5.2,9,5.5V14c0,0.4-0.2,0.8-0.6,0.9C8,15.1,7.6,15,7.3,14.7l-2.4-2.6c-0.2-0.2-0.5-0.2-0.7,0C4.1,12.2,4,12.3,4,12.4z";
export const ICON_DLP_POLICY_MANAGMENT = "M16,24h-2c-0.6,0-1-0.4-1-1v-2c0-0.3,0.1-0.5,0.3-0.7l7-7c0.4-0.4,1-0.4,1.4,0l2,2c0.4,0.4,0.4,1,0,1.4l-7,7C16.5,23.9,16.3,24,16,24z M15,22h0.6l6-6L21,15.4l-6,6V22z M10.9,21.5c0.3-0.5,0.1-1.1-0.4-1.4C8.4,18.9,5,16.3,5,12V5.7l7-2.6l7,2.6V10c0,0.6,0.4,1,1,1s1-0.4,1-1V5c0-0.4-0.3-0.8-0.6-0.9l-8-3c-0.2-0.1-0.5-0.1-0.7,0l-8,3C3.3,4.2,3,4.6,3,5v7c0,5.3,4.1,8.4,6.5,9.9C9.7,22,9.8,22,10,22C10.3,22,10.7,21.8,10.9,21.5z";
export const ICON_DLP_INSPECTION = "M12.3,20c-0.1,0-0.2,0-0.3,0c-4.9,0.1-9.4-3-10.9-7.7c-0.1-0.2-0.1-0.4,0-0.6C2.6,7,7.1,3.8,12,4 c4.8-0.2,9.4,3,10.9,7.7c0.1,0.2,0.1,0.4,0,0.6C21.4,16.9,17.1,20,12.3,20z M12,18c4,0.1,7.6-2.3,9-6c-1.4-3.7-5-6.1-8.9-6 c-4-0.1-7.6,2.3-9,6C4.4,15.7,8,18.1,12,18z M12,7c-2.8,0-5,2.2-5,5s2.2,5,5,5s5-2.2,5-5S14.8,7,12,7z M11,13c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S12.1,13,11,13z";
export const ICON_DLP_GENERAL = "M13,23h-2c-1.1,0-2-0.9-2-2v-0.5c-0.3-0.1-0.6-0.2-0.9-0.4l-0.4,0.4c-0.8,0.8-2,0.8-2.8,0l-1.4-1.4c-0.8-0.8-0.8-2,0-2.8l0.4-0.4c-0.1-0.3-0.3-0.6-0.4-0.9H3c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h0.5c0.1-0.3,0.2-0.6,0.4-0.9L3.5,7.7c-0.8-0.8-0.8-2,0-2.8l1.4-1.4c0.8-0.8,2-0.8,2.8,0l0.4,0.4C8.4,3.7,8.7,3.6,9,3.5V3c0-1.1,0.9-2,2-2h2c1.1,0,2,0.9,2,2v0.5c0.3,0.1,0.6,0.2,0.9,0.4l0.4-0.4c0.8-0.8,2-0.8,2.8,0l1.4,1.4c0.8,0.8,0.8,2,0,2.8l-0.4,0.4c0.1,0.3,0.3,0.6,0.4,0.9H21c1.1,0,2,0.9,2,2v2c0,1.1-0.9,2-2,2h-0.5c-0.1,0.3-0.2,0.6-0.4,0.9l0.4,0.4c0.8,0.8,0.8,2,0,2.8l-1.4,1.4c-0.8,0.8-2,0.8-2.8,0 l-0.4-0.4c-0.3,0.1-0.6,0.3-0.9,0.4V21C15,22.1,14.1,23,13,23z M6,7.4c0.2,0.3,0.2,0.7,0,1C5.6,9,5.4,9.6,5.2,10.2 c-0.1,0.4-0.5,0.7-1,0.7H3v2h1.2c0.5,0,0.9,0.3,1,0.8C5.4,14.4,5.6,15,6,15.5c0.2,0.3,0.2,0.7,0,1c-0.1,0.1-0.9,1-1,1.1l0,0L6.4,19 l0.9-0.9c0.1-0.1,0.4-0.2,0.6-0.2s0.4,0,0.5,0.1c0.6,0.3,1.2,0.6,1.8,0.8c0.4,0.1,0.8,0.5,0.8,1V21h2v-1.3c0-0.5,0.3-0.9,0.8-1 c0.6-0.2,1.2-0.4,1.8-0.7c0.4-0.2,0.9-0.2,1.2,0.2l0.9,0.9l1.4-1.4l0,0c-0.1-0.1,0,0-1-1c-0.2-0.3-0.2-0.7,0-1c0.3-0.6,0.6-1.2,0.8-1.8c0.1-0.4,0.5-0.8,1-0.8H21v-2h-1.2c-0.5,0-0.9-0.3-1-0.8C18.6,9.6,18.4,9,18,8.5c-0.2-0.3-0.2-0.7,0-1 c0.1-0.1,0.9-1,1-1.1l0,0L17.6,5l-0.9,0.9C16.5,6.1,16,6.2,15.6,6c-0.6-0.3-1.2-0.6-1.8-0.8c-0.4-0.1-0.8-0.5-0.8-1V3h-2v1.3c0,0.5-0.3,0.9-0.8,1C9.6,5.4,9,5.6,8.4,6C8.1,6.2,7.7,6.2,7.3,5.9c-0.1,0-0.9-0.9-1-1l0,0L4.9,6.3 M12,17c-2.8,0-5-2.2-5-5 s2.2-5,5-5s5,2.2,5,5S14.8,17,12,17z M12,9c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S13.7,9,12,9z";

//Logo
export const ICON_HIDE_LOGO = "M7.4,12l4.3-4.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-5,5c-0.4,0.4-0.4,1,0,1.4l5,5c0.2,0.2,0.4,0.3,0.7,0.1s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L7.4,12z M14.4,12l4.3-4.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-5,5c-0.4,0.4-0.4,1,0,1.4l5,5c0.2,0.2,0.4,0.3,0.7,0.1s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L14.4,12z";
export const ICON_UNHIDE_LOGO = "M11.7,11.3l-5-5c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4L9.6,12l-4.3,4.3c-0.4,0.4-0.4,1,0,1.4C5.5,17.9,5.7,18,6,18s0.5-0.1,0.7-0.3l5-5C12.1,12.3,12.1,11.7,11.7,11.3z M18.7,11.3l-5-5c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l4.3,4.3l-4.3,4.3c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.4,0.3,0.7,0.1s0.5-0.1,0.7-0.3l5-5C19.1,12.3,19.1,11.7,18.7,11.3z";

//INFO
export const ICON_INFO = "M13.8,4.1c1,0,1.6,0.7,1.6,1.5c0,1-0.9,2-2.1,2c-1,0-1.6-0.6-1.6-1.6C11.7,5.3,12.5,4.1,13.8,4.1z M10.6,19.9c-0.9,0-1.4-0.5-0.9-2.8l1-4c0.2-0.7,0.2-0.9,0-0.9c-0.3,0-1.3,0.5-2,0.9l-0.4-0.7c2-1.7,4.3-2.8,5.4-2.8c0.9,0,1,1,0.5,2.6l-1.1,4.2c-0.2,0.7-0.1,1,0.1,1c0.3,0,1-0.3,1.9-0.9l0.5,0.7C13.6,19.1,11.4,19.9,10.6,19.9z";

//Toolbar buttons
export const ICON_INSPECTOR_TOOLBAR = [
    {path: "M11.5,23.5C11.4,23.5,11.4,23.5,11.5,23.5C4.9,23.5,0,18.5,0,12C0,5.3,5,0,11.4,0c0,0,0,0,0,0c5.2,0,9.8,3.4,11.3,8.4c0,0,0,0.1,0,0.2c0.1,0.4,0.2,0.9,0.3,1.3c0.1,0.4-0.2,0.8-0.6,0.9c-0.4,0.1-0.8-0.2-0.9-0.6c0-0.2-0.1-0.4-0.1-0.7c-5,0.2-10.6,3.1-13.2,7.2c0.7,1.8,1.9,3.7,3.7,5.7c0,0,0,0,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2l0,0c0,0,0,0,0,0l0,0l0,0c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.2c0,0,0,0,0,0h0c0,0,0,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0.1-0.2,0.1C11.7,23.5,11.6,23.5,11.5,23.5C11.5,23.5,11.5,23.5,11.5,23.5L11.5,23.5C11.5,23.5,11.5,23.5,11.5,23.5L11.5,23.5C11.5,23.5,11.5,23.5,11.5,23.5z M6.8,20.9c0.9,0.5,1.9,0.8,2.9,0.9c-1-1.2-1.7-2.4-2.3-3.6C7.1,19.1,6.9,20,6.8,20.9z M1.6,10.3c-0.1,0.5-0.1,1.1-0.1,1.7c0,3.4,1.5,6.3,3.9,8.1c0.2-1.3,0.6-2.5,1.2-3.6c-1.1-3.1-1-5.8-0.4-8.1C4.7,8.6,3.1,9.3,1.6,10.3z M7.8,8.1C7.2,10,7,12.3,7.7,14.8c1.8-2.4,4.6-4.3,7.6-5.5C13.5,8.6,10.8,7.9,7.8,8.1z M9,6.5c3.7,0,6.9,1.3,8.4,2C18.6,8.2,19.8,8,21,7.9c-1.5-3.7-5.1-6.3-9.2-6.4c-0.1,0.2-0.3,0.4-0.5,0.7c-0.9,1.1-2,2.6-2.9,4.4C8.6,6.6,8.8,6.5,9,6.5z M9.8,1.6C6.2,2.2,3.3,4.8,2.1,8.2c1.5-0.8,3.1-1.3,4.6-1.5C7.6,4.6,8.9,2.9,9.8,1.6z"},
    {primary: true, path: "M23.8,13.1l-5-1.9c-0.1,0-0.1,0-0.2,0l-5,1.9c-0.1,0-0.2,0.2-0.2,0.3v4.3c0,4.4,4.8,6.3,5,6.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.2-0.1,5-2,5-6.3v-4.3C24,13.2,23.9,13.1,23.8,13.1z M22.5,17.6c0,2.8-2.8,4.4-3.7,4.8l0,0l0,0l0,0v-5.2H15v-3.1l3.8-1.4v4.5h3.7V17.6z"},
];
export const ICON_DISCOVERY_TOOLBAR = [
    {primary: true, path: "M23.8,17.8l-4.6-2.9c-0.2-0.1-0.4-0.1-0.5,0.1l-0.1,0.3l-0.9-0.5c1.3-2.7,0.4-6-2.2-7.6c-2.8-1.7-6.4-0.9-8.1,1.9s-0.9,6.4,1.9,8.1c2.6,1.6,6,0.9,7.8-1.5l0.9,0.5l-0.2,0.4c-0.1,0.2-0.1,0.4,0.1,0.5l4.6,2.9c0.2,0.1,0.4,0.1,0.5-0.1l1-1.7C24.1,18,23.9,17.9,23.8,17.8z M10,16.1C7.9,14.8,7.2,12,8.5,9.9s4.1-2.8,6.2-1.5s2.8,4.1,1.5,6.2S12.1,17.3,10,16.1z"},
    {path: "M14.8,24h-12C1.3,24,0,22.8,0,21.3V2.8C0,1.3,1.3,0,2.8,0h12c1.5,0,2.8,1.2,2.8,2.8v2.8c0,0.4-0.3,0.8-0.8,0.8S16,6,16,5.6V2.8c0-0.7-0.6-1.3-1.3-1.3h-12C2,1.5,1.4,2.1,1.4,2.8v18.5c0,0.7,0.6,1.3,1.3,1.3h12c0.7,0,1.3-0.6,1.3-1.3v-2.4c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v2.4C17.5,22.8,16.3,24,14.8,24z M8.1,19H3.9c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h4.2c0.4,0,0.8,0.3,0.8,0.8S8.5,19,8.1,19z M5.1,15H4c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h1.1c0.4,0,0.8,0.3,0.8,0.8S5.5,15,5.1,15z M5.1,11.8H4c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h1.1c0.4,0.1,0.7,0.4,0.7,0.8S5.5,11.8,5.1,11.8z M6.2,8.5H4c-0.4,0-0.8-0.3-0.8-0.8S3.6,7,4,7h2.2C6.6,7,7,7.3,7,7.7S6.6,8.5,6.2,8.5z M13.5,5.1H4c-0.4,0-0.8-0.3-0.8-0.8S3.6,3.6,4,3.6h9.5c0.4,0,0.8,0.3,0.8,0.8S13.9,5.1,13.5,5.1z M13,15.3h-2.7c-0.6,0-1-0.4-1-1s0.4-1,1-1H13c0.6,0,1,0.4,1,1S13.6,15.3,13,15.3z M13,12h-2.7c-0.6,0-1-0.4-1-1s0.4-1,1-1H13c0.6,0,1,0.4,1,1S13.6,12,13,12z"},
];
export const ICON_EP_TOOLBAR = [
    {primary: true, path: "M19.3,17c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S18.6,17,19.3,17z M19.3,14c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S18.6,14,19.3,14z M21.3,22.8h-4c-1.5,0-2.8-1.2-2.8-2.8V4c0-1.5,1.2-2.8,2.8-2.8h4C22.8,1.3,24,2.5,24,4v16C24,21.5,22.8,22.8,21.3,22.8z M17.3,2.8C16.6,2.8,16,3.3,16,4v16c0,0.7,0.6,1.3,1.3,1.3h4c0.7,0,1.3-0.6,1.3-1.3V4c0-0.7-0.6-1.3-1.3-1.3H17.3z"},
    {path: "M10.8,22.8h-6C4.3,22.8,4,22.4,4,22s0.3-0.8,0.8-0.8h6c0.4,0,0.8,0.3,0.8,0.8S11.2,22.8,10.8,22.8z M12.8,19.8h-10C1.2,19.8,0,18.5,0,17v-7c0-1.5,1.2-2.8,2.8-2.8h10c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8h-10c-0.7,0-1.3,0.6-1.3,1.3v7c0,0.7,0.6,1.3,1.3,1.3h10c0.4,0,0.8,0.3,0.8,0.8S13.2,19.8,12.8,19.8z"},
];