import { API_URL } from './config';
import { fetching } from './utils';

interface RefreshTokenInterface {
	auth: { login: string, password: string },
	onSuccess?: (message: any) => void,
	onError?: (error: any) => void
}

export const getAuthToken = (props: RefreshTokenInterface) => {
	const { auth, onSuccess, onError } = props;
	fetching({
		url: API_URL + '/api/auth/login',
		options: {
			method: 'POST',
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(auth),
		},
		onSuccess,
		onError,
	}, false);
};

export const getUserInfo = (props: { onSuccess?: (message: any) => void, onError?: (err: any) => void }) => {
	const { onSuccess, onError } = props;
	fetching({
		url: API_URL + '/api/auth/userinfo',
		onSuccess,
		onError,
	});
};

export const getServerTimezone = (props: { onSuccess?: (message: any) => void, onError?: (err: any) => void }) => {
	const { onSuccess, onError } = props;
	fetching({
		url: API_URL + '/api/auth/timezone',
		onSuccess,
		onError,
	});
};
