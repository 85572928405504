import React from "react";
import {ButtonProps} from "@mui/material";
import {GtbSimpleButton, GtbSimpleIconButton, GtbGroupedButton, GtbGroupedIconButton} from './skins'
import {CN} from "../../utils";

interface GtbButtonProps extends ButtonProps {
    skin?: string;
    classes?: object,
}

const setSkin = (skin?: string) => {
    switch (skin) {
        case 'simple':
            return GtbSimpleButton;
        case 'simpleIcon':
            return GtbSimpleIconButton;
        case 'grouped':
            return GtbGroupedButton;
        case 'groupedIcon':
            return GtbGroupedIconButton;
        default:
            return GtbSimpleButton;
    }
};

export const GtbButton: React.FC<GtbButtonProps> = ({skin, classes = {}, children, ...rest}) => {
    const Button = setSkin(skin)
    return (
            <Button
                    classes={classes}
                    className={CN(['gtb-button', `gtb-${skin}-button`], {
                        '_disabled': false,
                        '_enabled': true,
                    })}
                    disableRipple={true}
                    {...rest}
            >
                {children}
            </Button>
    );
};

export default GtbButton;
