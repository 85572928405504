export const HIDE_MESSAGE = '[MESSAGE] CLOSE';
export const SHOW_MESSAGE = '[MESSAGE] SHOW';
export const MESSAGE_ERROR = 'error';
export const MESSAGE_INFO = 'info';
export const MESSAGE_SUCCESS = 'success';
export const MESSAGE_TIMER = 'timer';

export function hideMessage () {
	return {
		type: HIDE_MESSAGE,
	};
};

export const showMessage = (options: any) => {
	return {
		type: SHOW_MESSAGE,
		options,
	};
};
