export * from './icons/Action';
export * from './icons/Authorization';
export * from './icons/Checkboxes';
export * from './icons/Events';
export * from './icons/Help';
export * from './icons/Menu';
export * from './icons/File_Icons';
export * from './icons/DLP_Setup/DLP_Setup';
export * from './icons/DLP_Setup/APP_CONTROL';
export * from './icons/DLP_Setup/FILE_TYPES';
export * from './icons/DLP_Setup/EP_Protector';
export * from './icons/DLP_Setup/GENERAL';
export * from './icons/DLP_Setup/Inspections';
export * from './icons/DLP_Setup/Inspector';
export * from './icons/DLP_Setup/LDAP_Objects';
export * from './icons/DLP_Setup/Actions';
export * from './icons/DLP_Setup/Enforcement_Action';
export * from './icons/DLP_Setup/Alert';
export * from './icons/DLP_Setup/Other';
export * from './icons/DLP_Setup/Table_Columns';
export * from './icons/DLP_Setup/Device_Types';
export * from './icons/DLP_Setup/Table_Icons';
export * from './icons/DLP_Setup/Discovery_File_Shares';
//export * from './icons/DLP_Setup/Server';
export * from './icons/DLP_Setup/Scan_Status';
export * from './icons/DLP_Setup/Scan_Settings_Categories';
export * from './icons/DLP_Setup/Outlook_Discovery';
export * from './icons/System_Settings/System_Settings';
export * from './icons/System_Settings/Licenses';
export * from './icons/System_Settings/Network_status';
export * from './icons/System_Settings/Customize_Notifications';
export * from './icons/DLP_Setup/Cloud';
export * from './icons/Languages';
export * from './icons/DLP_Setup/Watermarking';
export * from './icons/DLP_Setup/Local_Scan';
export * from './icons/DLP_Setup/Exceptions';
export * from './icons/DLP_Setup/Classification';
export * from './icons/DLP_Setup/Policy_Management';
export * from './icons/Dashboard';
export * from './icons/OS';
export * from './icons/Logs';
export * from './icons/DLP_Setup/IRM';
export const ICON_DEFAULT = "";