import React from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {Text as Field} from '@gtb'

const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    root: {
        background: 'transparent',
        backgroundColor: 'transparent',
        height: '100%',
        width: '100%',
    },
}));

interface TextProps {
    classes?: unknown,
    value?: unknown,
    autoComplete?: 'string',
    clearable?: boolean,
    multiline?: unknown,
    [x: string]: any,
}

const Password: React.FC<TextProps> = (props) => {
    const {
        classes,
        value,
        autoComplete,
        clearable,
        multiline,
        ...rest
    } = props;
    const styles = useStyles(props);

    return (
            <Field
                    classes={{
                        root: styles.root,
                    }}
                    type={'password'}
                    autoComplete={'new-password'}
                    value={(value !== undefined && value !== null) ? value : ''}
                    clearable={clearable}
                    {...rest}
            />
    );
};

export default Password;
