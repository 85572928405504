import React from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import _ from 'lodash';
import {CN} from '@gtb/utils';
import {ComboSelect, ComboGroupSelect} from '@gtb';
import {
    Input,
    Text,
    Number,
    Password,
    ChooseFile,
    Custom,
    SimpleText,
    DateView,
    TH,
    Checkbox,
    Size
} from './components';

const useStyles = makeStyles(({typography, palette}: CustomTheme) => ({
    root: {
        ...typography.inputField,
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexWrap: 'wrap',
        borderStyle: 'solid',
        borderColor: palette.background.main,
        borderWidth: 0,
        padding: 0,
        '&:hover .info': {
            opacity: 0.5,
        },
    },
    disabled: {},
}));

type SetupFieldSwitchType = unknown | undefined | 'input' | 'text';

export const chooseFieldRender = (type: SetupFieldSwitchType) => {
    switch (type) {
        case 'custom':
            return Custom;
        case 'simple-text':
            return SimpleText;
        case 'date-view':
            return DateView;
        case 'input':
            return Input;
        case 'text':
            return Text;
        case 'number':
            return Number;
        case 'choose-file':
            return ChooseFile;
        case 'select':
            return ComboSelect;
        case 'combo-select':
            return ComboSelect;
        case 'combo-group':
            return ComboGroupSelect;
        case 'th':
            return TH;
        case 'size':
            return Size;
        case 'checkbox':
            return Checkbox;
        case 'password':
        case 'new-password':
            return Password;
        default:
            return SimpleText;
    }
};

interface InputProps {
    [key: string]: unknown
}

interface FieldProps {
    input: InputProps,
    className?: any,
    template?: any,
    id?: any,
    disabled?: any,
    minimizedView?: any,
    classes?: any
}

const SetupField: React.FC<FieldProps> = (props) => {
    const stl = useStyles(props);
    const {
        input,
        classes,
        className,
        template,
        id,
        disabled,
        minimizedView,
    } = props;


    if (_.isArray(input)) {
        return (
                <div
                        id={id}
                        className={CN([stl.root, className], {
                            [stl.disabled]: disabled,
                            minimizedView: minimizedView
                        })}
                        style={{...template}}
                >
                    {input.map((i, count) => {
                        const {type, width, fieldStyle = {}, disabled, memo, memoSettings, actions, ...rest} = i;
                        const InputField = _.isArray(memo)
                                ? chooseFieldRender(type)// Memo(chooseFieldRender(type), memo, memoSettings)
                                : chooseFieldRender(type);

                        const NewInput = (
                                <div
                                        key={id + '_' + count}
                                        {...(input.id ? {id: id + '_' + count} : {})}
                                        style={{
                                            width: width || '100%',
                                            position: actions
                                                    ? 'relative'
                                                    : 'inherit',
                                            ...fieldStyle,
                                        }}
                                        className={CN(i.className, {[stl.disabled]: i.disabled})}
                                >
                                    <InputField {...rest} />
                                    {actions}
                                </div>
                        );
                        return i.hasOwnProperty('enabled')
                                ? i.enabled && NewInput
                                : NewInput;
                    })}
                </div>
        );
    } else {
        const {type, width, actions, memo, memoSettings, ...rest} = input;
        const InputField = _.isArray(memo)
                ? chooseFieldRender(type) // Memo(chooseFieldRender(type), memo, memoSettings)
                : chooseFieldRender(type);

        const NewInput = (
                <div
                        id={input.id || id}
                        className={CN([stl.root], {
                            [`${input.className}`]: !!input.className,
                            [stl.disabled]: disabled || input.disabled,
                            minimizedView: minimizedView
                        })}
                        style={{
                            ...template,
                            width,
                            position: actions
                                    ? 'relative'
                                    : 'inherit',
                        }}
                >
                    <InputField {...rest} />
                </div>
        );
        return input.hasOwnProperty('enabled')
                ? input.enabled
                        ? NewInput
                        : null
                : NewInput;
    }
};

export default SetupField;
