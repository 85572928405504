import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import NoMatch from "./common/components/404";
import routes from './routes';

const App: React.FC = () => {
    return (
            <BrowserRouter>
                <Routes>
                    {routes.map(r => {
                        return <Route key={'route-key' + r.path} path={r.path} element={r.component}/>
                    })}
                    <Route path="*" element={<NoMatch/>}/>
                </Routes>
            </BrowserRouter>
    );
};

export default App;
