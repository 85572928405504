// import Package from '../../package.json';
import {
	HTTP_PROTOCOL,
	APP_DEV,
} from './apiConfig';

declare global {
	interface HTMLElementEventMap {
		'value-changed': CustomEvent;
	}
	interface Window {
		WEB_ROOT: string,
		API_ROOT: string,
		DEBUG: boolean,
	}
}

window.WEB_ROOT = '';
window.API_ROOT = '';

if (process.env.NODE_ENV === 'development') {
	window.API_ROOT = HTTP_PROTOCOL + document.location.host.replace(':5000', '') + '/' + APP_DEV;
	window.WEB_ROOT = '';
} else {
	window.API_ROOT = HTTP_PROTOCOL + document.location.host + '/' + APP_DEV;
	// window.WEB_ROOT = Package.homepage;
}
window.DEBUG = process.env.NODE_ENV === 'development';

// const IP_CC = '192.168.88.55'; // Localhost
// const IP_CC = '192.168.0.205'; // Roma
// const IP_CC = '192.168.0.204'; // Roma
// const IP_CC = '192.168.0.174'; // Zimin
// const IP_CC = '192.168.13.171'; // Zimin
// const IP_CC = '192.168.5.5'; // Roma
const IP_CC = '192.168.5.4'; // Roma
// const IP_CC = '192.168.5.3'; // Roma
// const IP_CC = '192.168.9.35'; // Alexandr Korol
// const IP_CC = '192.168.1.21'; // My virtual CC in office
// const IP_CC = '192.168.1.22'; // My virtual CC in office
// const IP_CC = '192.168.9.2'; // Alex
// const IP_CC = '192.168.66.123'; // Alex

// const IP_CC = process.env.REACT_APP_IP_CC;
if (IP_CC) {
	window.API_ROOT = HTTP_PROTOCOL + IP_CC + '/' + APP_DEV + '/old';
}
