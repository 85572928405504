import React from 'react';
import {CN} from '@gtb/utils';
import useStyles from './ComboSelect.styles';
import {useTheme} from '@mui/styles';
import {CustomTheme} from '@gtb/components/Theme/interfaces';
import CreatableSelect from 'react-select/creatable';
import {isInArray} from '@gtb/utils';
import _ from 'lodash';
import {formatComboSelectValue} from './utils';


import Control from './components/Control';
import Menu from './components/Menu';
import MultiValue from './components/MultiValue';
import MultiValueIcons from './components/MultiValueIcons';
import NoOptionsMessage from './components/NoOptionsMessage';
import OptionWithIcon from './components/OptionWithIcon';
import Placeholder from './components/Placeholder';
import SingleValue from './components/SingleValue';
import ValueContainer from './components/ValueContainer';
import DropdownIndicator from './components/DropdownIndicator';

const ComboSelect: React.FC<{ [x: string]: any }> = (props) => {
    const stl = useStyles(props);
    const theme: CustomTheme = useTheme();
    const {
        classes,
        options,
        value,
        skip,
        isMulti,
        hideSelectedOptions,
        placeholder,
        formatCreateLabel,
        noOptionsMessage,
        isClearable,
        isSearchable,
        isDisabled,
        showOneOption = false,
        label,
        onlyIcons = false,
        ...rest
    } = props;

    const handleChange = (value: any) => {
        if (props.onChange) {
            props.onChange(value);
        }
    };

    const components = {
        Control,
        Menu,
        MultiValue: isMulti
                ? MultiValueIcons
                : MultiValue,
        NoOptionsMessage,
        Option: OptionWithIcon,
        Placeholder,
        SingleValue,
        ValueContainer,
        DropdownIndicator: options && options.length > 0
                ? DropdownIndicator
                : '',
    };

    const selectStyles = {
        input: (base: any) => ({
            ...base,
            color: theme.palette.text.primary,
            position: 'relative',
            '& input': {
                gridArea: '1 / 2 / auto / auto',
                font: 'inherit',
                fontSize: '1.6rem!important',
                fontWeight: 400,
                flexShrink: 1,
                position: 'relative',
                '&:focus': {
                    position: 'absolute',
                    display: 'block',
                    top: 0,
                    left: 0,
                    right: 0,
                    minHeight: theme.spacing(7),
                    flexShrink: 0,
                    gridArea: 'unset!important',
                },
            },
        }),
        menuList: (base: any) => ({
            ...base,
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
            borderRadius: `0px 0px 0.7rem 0.7rem`,
            cursor: 'pointer!important',
        }),
        clearIndicator: (base: any) => ({
            ...base,
            cursor: 'pointer',
        }),
        menuPortal: (base: any) => ({...base, zIndex: 9999}),
    };


    return (
            <div
                    className={CN(stl.root, {
                        _isDisabled: isDisabled,
                        _isSingle: (!showOneOption && options && options.length < 2),
                    })}
                    onClick={e => e.stopPropagation()}
            >
                <CreatableSelect
                        // @ts-ignore
                        classes={stl}
                        styles={selectStyles}
                        textFieldProps={{
                            InputLabelProps: {
                                shrink: true,
                            },
                        }}
                        label={label || false}
                        isClearable={isClearable || false}
                        isSearchable={isSearchable || false}
                        isDisabled={isDisabled || false}
                        isOptionDisabled={option => option.disabled === true}
                        options={options || []}
                        tabSelectsValue={false}
                        // @ts-ignore
                        components={components}
                        closeMenuOnSelect={!isMulti}
                        menuShouldBlockScroll={true}
                        hideSelectedOptions={hideSelectedOptions || false}
                        value={formatComboSelectValue(value, options)}
                        onChange={handleChange.bind(this)}
                        placeholder={placeholder || 'Select value'}
                        noOptionsMessage={
                            noOptionsMessage ||
                            (({inputValue}) => {
                                if (
                                        value && _.isArray(value) &&
                                        isInArray(
                                                value.map(e => e.value),
                                                inputValue,
                                        )
                                ) {
                                    return 'This value is already added';
                                } else {
                                    return false;
                                }
                            })
                        }
                        formatCreateLabel={
                            formatCreateLabel ||
                            (inputValue => 'Add' + ' ' + inputValue)
                        }
                        menuPortalTarget={document.body}
                        menuPosition={'absolute'}
                        menuPlacement={'auto'}
                        isMulti={isMulti || false}
                        onlyIcons={onlyIcons}
                        {...rest}
                />
            </div>
    );
};


export default ComboSelect;
