import React, {
    useEffect,
    useState,
} from 'react';
import {CN} from '@gtb/utils';
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import Text from '@gtb/components/Fields/Text/Text';
import {alpha as fade} from '@mui/material/styles';

const useStyles = makeStyles(({spacing, palette, transitions}: CustomTheme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        width: '100%',
        height: '100%',
        '& input': {
            '&::-webkit-inner-spin-button': {
                appearance: 'none!important',
            },
        },
        '&:hover': {
            '& .plus, & .minus': {
                opacity: 1,
            },
        },
        '&.dimension': {
            backgroundColor: fade(palette.background.main, 0.5),
            borderRadius: '0.7rem',
            height: spacing(5),
            margin: [[0, 0]],
            paddingLeft: spacing(1),
            paddingRight: spacing(1),
            '& $plus': {
                opacity: 1,
                right: spacing(1),
            },
            '& $minus': {
                opacity: 1,
                right: spacing(1),
            },
            '.minimizedView &': {
                height: spacing(4),
            },
        },
    },
    dimension: {
        whiteSpace: 'nowrap',
        padding: spacing(1.1666, 3, 0.84444, 0),
    },
    textRoot: {
        backgroundColor: 'transparent',
    },
    text: {
        padding: 0,
        '.dimension &': {
            paddingLeft: spacing(1),
        },
        '& input': {
            padding: spacing(2.333333, 3, 2.16666),
        },
    },
    plus: {
        opacity: 1,
        cursor: 'pointer',
        width: spacing(1),
        height: spacing(1),
        position: 'absolute',
        bottom: `calc(50% + ${spacing(0.33333)})`,
        right: spacing(3),
        '&:before': {
            display: 'block',
            content: '""',
            cursor: 'pointer',
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: 0,
            height: 0,
            borderStyle: 'solid',
            transition: `all 0.1s ${transitions.easing.easeIn}`,
            borderWidth: [[0, 4, 5, 4]],
            borderColor: `transparent transparent ${palette.text.secondary}`,
        },
        '&:hover': {
            '&:before': {
                borderColor: `transparent transparent ${palette.action.active}`,
            },
        },
    },
    minus: {
        opacity: 1,
        cursor: 'pointer',
        width: spacing(1),
        height: spacing(1),
        position: 'absolute',
        top: `calc(50% + ${spacing(0.3333)})`,
        right: spacing(3),
        transition: 'all 0.1 ease',
        '&:before': {
            display: 'block',
            content: '""',
            cursor: 'pointer',
            position: 'absolute',
            top: 0,
            right: 0,
            width: 0,
            height: 0,
            borderStyle: 'solid',
            transition: `all 0.1s ${transitions.easing.easeIn}`,
            borderWidth: [[5, 4, 0, 4]],
            borderColor: `${palette.text.secondary} transparent transparent`,
        },
        '&:hover': {
            '&:before': {
                borderColor: `${palette.action.active} transparent transparent`,
            },
        },
    },
    noFocus: {
        backgroundColor: 'transparent!important',
    },
}));

const Input: React.FC<{ [x: string]: any }> = (props) => {

    const stl = useStyles(props);
    const {
        classes,
        value,
        min = 0,
        max = 999999999,
        dimension = false,
        step = 1,
        validation = () => {
            return false;
        },
        fullWidth,

        onChange,
        changeOnKeyEnter,
        ...rest
    } = props;

    const [stateValue, setValue] = useState(value || '');
    const [exceed, setExceed] = useState<string | boolean | null>(null);

    useEffect(() => {
        if (value !== stateValue) {
            setValue(value);
        }
    }, [value]);

    const onChangeHandler = (event: any) => {
        const formattedValue = !isNaN(parseFloat(event.target.value))
                ? parseFloat(event.target.value)
                : '';
        const error = defaultValidation(formattedValue);
        if (!error) {
            setValue(formattedValue);
            onChange(formattedValue);
        }
        if (typeof formattedValue === 'string' && formattedValue.length > 0) {
            setValue(formattedValue);
            if (!error) {
                onChange(min);
            }
        }
    };

    const errorHandler = (status: any) => {
        const errorValue = !status
                ? exceed === 'min'
                        ? min
                        : max
                : status;
        setValue(errorValue);
        onChange(errorValue);
    };

    const onEnter = (event: any) => {
        event.target.blur();
        const error = defaultValidation(stateValue);
        if (error) {
            errorHandler(false);
        } else {
            onChangeHandler(event);
        }
    };

    const increase = (step: any) => {
        const value = stateValue && !isNaN(parseFloat(stateValue))
                ? parseFloat(stateValue) + step
                : min > 0
                        ? min + step
                        : step;
        const error = defaultValidation(value);
        // console.log('error', error, stateValue)
        if (!error) {
            setValue(value);
            if (!error) {
                onChange(value)
            }
        } else {
            setValue(min);
        }
    };

    const onBlurHandler = (e: any) => {
        defaultValidation(e.target.value, errorHandler);
    };

    const onValidationError = (message: any) => {
        console.log(message);
    };

    const defaultValidation = (value: any, callback = (x: any) => {
    }) => {
        const formattedValue = !isNaN(parseFloat(value))
                ? parseFloat(value)
                : value;
        let error = false;
        if (max != null && formattedValue > max) {
            error = true;
            onValidationError('Value should be lower than ' + max + ' ' + value);
            setExceed('max');
            callback(max);
        }
        if (min != null && min > formattedValue) {
            error = true;
            onValidationError('Value should be bigger than ' + min + ' ' + value);
            setExceed('min');
            callback(min);
        }
        //case set keyboard value
        setValue(formattedValue);

        return error;
    };

    return <div className={CN(stl.root, {dimension: dimension})}>
        <Text
                type={'number'}
                value={stateValue}
                fullWidth={fullWidth
                        ? fullWidth
                        : true}
                onChange={event => onChangeHandler(event)}
                onBlur={e => onBlurHandler(e)}
                fastChange={true}
                changeOnKeyEnter={changeOnKeyEnter
                        ? changeOnKeyEnter
                        : event => onEnter(event)}
                classes={{root: stl.textRoot, text: stl.text, focused: dimension && stl.noFocus}}
                {...rest}
        />
        {dimension && <div className={stl.dimension}>{dimension}</div>}
        <div onDoubleClick={e => e.stopPropagation()} className={CN([stl.plus, 'plus'])}
             onClick={(e) => {
                 e.stopPropagation();
                 increase(step);
             }}/>
        <div onDoubleClick={e => e.stopPropagation()} className={CN([stl.minus, 'minus'])}
             onClick={(e) => {
                 e.stopPropagation();
                 increase(-step);
             }}/>
    </div>
};

export default Input;
