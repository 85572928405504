import {
	applyMiddleware,
	compose,
	createStore,
} from 'redux';
import thunk from 'redux-thunk';
import {
	persistStore,
	REHYDRATE,
} from 'redux-persist';
import reducers from './reducers';

const composeEnhancers =
	// @ts-ignore
	typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		// @ts-ignore
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const middlewares = [thunk];

if (process.env.NODE_ENV === `development`) {
	const { createLogger } = require(`redux-logger`);
	middlewares.push(createLogger({
		collapsed: true,
		duration: true,
		// @ts-ignore
		predicate: (getState, action) => action.type !== REHYDRATE,
	}));
}

const enhancer = composeEnhancers(
	applyMiddleware(...middlewares),
);

export const store = createStore(reducers, enhancer);
export const persistor = persistStore(store);

export default store;
