import React from 'react';
import {
    getSMTP,
    updateSMTP,
    testSMTP,
} from 'api';
import _ from 'lodash';
import {LoadingModal as Loading} from '@gtb';
import {hideModal, MESSAGE_ERROR, MESSAGE_SUCCESS, MODAL_SKIN_SYSTEM_MESSAGE, showMessage, showModal} from '../layout';

export const SMTP_GET_DATA = '[SMTP] GET DATA';
export const SMTP_SUCCESS_GET_DATA = '[SMTP] SUCCESS GET DATA';
export const SMTP_ERROR_GET_DATA = '[SMTP] ERROR GET DATA';

export const SMTP_UPDATE_DATA = '[SMTP] UPDATE DATA';
export const SMTP_SUCCESS_UPDATE_DATA = '[SMTP] SUCCESS UPDATE DATA';
export const SMTP_ERROR_UPDATE_DATA = '[SMTP] ERROR UPDATE DATA';

export const getSettingsData = (onSuccess?: (message: unknown) => void, onError?: (error: unknown) => void) => {
    return (dispatch: any, getState: () => { [x: string]: any }) => {
        dispatch({type: SMTP_GET_DATA});
        getSMTP((result) => {
            // console.log('GET CUSTOMERS', result);
            dispatch({type: SMTP_SUCCESS_GET_DATA, data: result.data});
            if (_.isFunction(onSuccess)) {
                onSuccess(result);
            }
        }, (err) => {
            dispatch({type: SMTP_ERROR_GET_DATA});
            if (_.isFunction(onError)) {
                onError(err);
            }
        });
    };
};

export const updateSettingsData = (data: { [x: string]: any }, onSuccess?: (message: unknown) => void, onError?: (error: unknown) => void) => {
    return (dispatch: any, getState: () => { [x: string]: any }) => {
        dispatch({type: SMTP_UPDATE_DATA});
        updateSMTP(data, (result) => {
            dispatch(getSettingsData());
            dispatch({type: SMTP_SUCCESS_UPDATE_DATA, data: result.data});
            if (_.isFunction(onSuccess)) {
                onSuccess(result);
            }
            dispatch(showMessage({
                message: 'SMTP was updated',
                type: MESSAGE_SUCCESS,
            }));
        }, (err: any) => {
            dispatch({type: SMTP_ERROR_UPDATE_DATA});
            if (_.isFunction(onError)) {
                onError(err);
            }
            dispatch(showMessage({
                message: 'SMTP wasn`t updated',
                type: MESSAGE_ERROR,
            }));
        });
    };
};


export const testSMTPSettingsData = (data: { [x: string]: any }, onSuccess?: (message: unknown) => void, onError?: (error: unknown) => void) => {
    return (dispatch: any, getState: () => { [x: string]: any }) => {
        dispatch({type: SMTP_UPDATE_DATA});
        dispatch(showModal({
            width: '78rem',
            height: 'auto',
            title: 'Test connection',
            content: (<Loading message={'Connecting...'}/>),
            skin: MODAL_SKIN_SYSTEM_MESSAGE,
        }));
        testSMTP(data, (result) => {
            dispatch(hideModal());
            dispatch(getSettingsData());
            dispatch({type: SMTP_SUCCESS_UPDATE_DATA, data: result.data});
            if (_.isFunction(onSuccess)) {
                onSuccess(result);
            }
            dispatch(showMessage({
                message: 'Successfully connected to the server',
                type: MESSAGE_SUCCESS,
            }));
        }, (err: any) => {
            dispatch(hideModal());
            dispatch({type: SMTP_ERROR_UPDATE_DATA});
            if (_.isFunction(onError)) {
                onError(err);
            }
            dispatch(showMessage({
                message: 'Error connection to the server',
                type: MESSAGE_ERROR,
            }));
        });
    };
};
