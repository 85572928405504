import React from 'react';
import {CN} from '@gtb/utils'
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";

const useStyles = makeStyles(({spacing, typography, palette}: CustomTheme) => ({
    root: {
        ...typography.tableGrid,
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '100%',
        padding: spacing(2.16666, 3, 1.8444444),
        '.bold &': {
            fontWeight: 600,
            color: palette.text.primary,
        },
        '&.centered': {
            textAlign: 'center',
        },
        '&.minimizedView': {
            padding: spacing(0.84444, 0),
        },
    },
}));

// const bytesToSize = (bytes, dynamicFormat = false) => {
// 	if (dynamicFormat) {
// 		let sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB' ];
// 		if (bytes == 0) return '0 Byte';
// 		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
// 		return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
// 	} else {
// 		const rezult = Math.round(bytes / (1024 * 1024) * 100) / 100;
// 		return (rezult + '').replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,');
// 	}
// };

const bytesToSize = (bytes: number, dynamicFormat = false) => {
    if (dynamicFormat) {
        let sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        // eslint-disable-next-line eqeqeq
        if (bytes == 0) return '0 B';
        // @ts-ignore
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        const result = (bytes / Math.pow(1024, i)).toFixed(2);

        // eslint-disable-next-line eqeqeq
        // @ts-ignore
        return (parseInt(result) != result)
                ? result + ' ' + sizes[i]
                : Math.round(parseInt(result)) + ' ' + sizes[i];
    } else {
        const rezult = Math.round(bytes / (1024 * 1024) * 100) / 100;
        return (rezult + '').replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,');
    }
};

const Size: React.FC<{ [x: string]: any }> = (props) => {
    const stl = useStyles(props);
    const {
        classes,
        value,
        dynamicFormat,
        className,
    } = props;
    return (
            <div className={CN([stl.root, className ? className : ''])}>
                {
                    typeof value !== 'undefined' && value !== null
                            ? (
                                    typeof value === 'number' || parseInt(value)
                                            ? bytesToSize(value, dynamicFormat)
                                            : value
                            )
                            : ''
                }
            </div>
    );
}

export default Size;
