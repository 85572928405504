import React from 'react';
import Icon, * as Icons from '@gtb/components/Icons';
import {customersDelete, MESSAGE_ERROR, showMessage, showModal} from 'store/actions';
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {Button} from "@gtb";
import ConfirmationModal from "apps/common/components/layout/ConfirmationModal";


const Delete: React.FC = ({children,}) => {

    // @ts-ignore
    const {select, data} = useSelector((state) => state.customers);
    const dispatch = useDispatch();

    const onDelete = () => {
        // @ts-ignore
        dispatch(customersDelete(select));
    };

    const options = {
        width: '695px',
        height: 'auto',
        title: _.isArray(select) && select.length > 1 ? 'Delete customers' : 'Delete customer',
        content: <ConfirmationModal
                message={_.isArray(select) && select.length > 1
                        ? 'Do you want to delete these customers?'
                        : 'Do you want to delete this customer?'
                }
                handleYes={() => {
                    onDelete();
                }}
        />
    };

    return (
            <Button
                    onClick={() => {
                        if (_.isArray(select) && _.isArray(data) && select.length === 1 && data.length > 0) {
                            dispatch(showModal(options));
                        } else {
                            dispatch(showMessage({
                                message: 'Please select one item!',
                                type: MESSAGE_ERROR,
                            }));
                        }
                    }}
                    skin={'grouped'}
            >
                <Icon view={Icons.ICON_DELETE}/>{'Delete'}
            </Button>
    );
};

export default Delete;
