import React from 'react';
import {Button} from '@gtb';
import Icon, * as Icons from '@gtb/components/Icons';
import {MESSAGE_ERROR, showMessage, showModal} from 'store/actions';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import EditCustomer from './EditCustomer';
import _ from 'lodash';


const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    title: {
        ...typography.h1,
        marginTop: 22,
    },
}));

const Edit: React.FC = (props) => {

    const stl = useStyles(props);
    // @ts-ignore
    const {select, focused, data} = useSelector((state) => state.customers);
    const dispatch = useDispatch();

    return (
            <Button
                    skin={'grouped'}
                    onClick={() => {
                        if (_.isArray(select) && _.isArray(focused) && (select.length === 1 || (select.length < 2 && focused.length === 1)) && _.isArray(data) && data.length > 0) {
                            const current = _.isArray(select) && select.length === 1 ? select[0] : focused[0]
                            const fields = data.filter((e: any) => e._id === current)[0]
                            dispatch(showModal({
                                width: '105rem',
                                height: 'auto',
                                maxHeight: 'auto',
                                title: <div className={stl.title}>{'Edit customer'}</div>,
                                content: <EditCustomer fields={fields}/>,
                            }));
                        } else {
                            dispatch(showMessage({
                                message: 'Please select one item!',
                                type: MESSAGE_ERROR,
                            }));
                        }
                    }}
            >
                <Icon view={Icons.ICON_EDIT}/>Edit
            </Button>
    );
};

export default Edit;
