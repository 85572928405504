import React, {useState, useEffect} from 'react';
import {
    Dialog,
    DialogContent,
} from '@mui/material';
import {IconButton} from '@gtb';
import {darken} from '@mui/material/styles';
import {Close as CloseIcon} from '@mui/icons-material';
import {Scroll} from '@gtb';
import useStyles from './Modal.styles';
import {CustomTheme} from '@gtb/interfaces';
import {useTheme} from '@mui/styles';
import {spacingToNumber} from "../Theme/utils";
import Move from './Move';
import {MODAL_SKIN_DEFAULT, MODAL_SKIN_WHITE, MODAL_SKIN_SYSTEM_MESSAGE} from 'store/actions';

const Modal: React.FC<{ [x: string]: any }> = (props) => {
    const stl = useStyles(props);
    const theme: CustomTheme = useTheme();
    const {
        classes,
        options,
        open,
        hideModal,
        hideMessage,
        hideMessages,
        scroll,
        styles,
        index,
    } = props;

    const currentOptions = options[index];

    const {
        width,
        height,
        maxHeight,
        disableBackdropClick = true,
        disableRestoreFocus = true,
    } = currentOptions;
    const systemMode = currentOptions.skin === MODAL_SKIN_SYSTEM_MESSAGE;

    const [p, setPosition] = useState([0, 0]);
    const [sP, setStartPosition] = useState(null);
    useEffect(() => {
        if (open === true) {
            setPosition([0, 0]);
        }
    }, [open]);

    // @ts-ignore
    const startDrag = (poss) => {
        // @ts-ignore
        setStartPosition(p);
        // console.log('Start poss', poss);
    };

    // @ts-ignore
    const onMove = (diff, poss) => {
        if (sP) {
            // @ts-ignore
            const [sX, sY] = sP;
            const [X, Y] = diff;
            const position = [(sX - X), (sY - Y)];
            setPosition(position);
            // `console.log`('position', position);
        }
    };

    const endDrag = () => {
        setStartPosition(null);
    };


    // @ts-ignore
    return (
            <Dialog
                    style={{
                        // TODO: don't delete it already has been changed twice
                        // filter: (open - 1) > index
                        // 	? 'blur(4px) opacity(0.9)'
                        // 	: 'blur(0px) opacity(1)',
                    }}
                    className={currentOptions.skin === MODAL_SKIN_WHITE
                            ? 'white'
                            : 'default'}
                    maxWidth={false}
                    classes={{
                        root: stl.root,
                        paper: stl.paper,
                    }}
                    PaperProps={{
                        style: {
                            ...styles,
                            width: width
                                    ? width
                                    : '100%',
                            height: height
                                    ? height
                                    : 'auto',
                            maxHeight: maxHeight
                                    ? maxHeight
                                    : 'inherit',
                            paddingTop: currentOptions.title
                                    ? spacingToNumber(theme.spacing(3))
                                    : spacingToNumber(theme.spacing(8.5)),
                            transform: `translate(${p[0]}px, ${p[1]}px)`,
                            transition: 'all 0s ease',
                            ...(index > 0 && index < 3
                                            ? {background: darken(theme.palette.background.main, index / 20)}
                                            : {}
                            ),
                        },
                    }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    BackdropProps={{invisible: true}}
                    // disableBackdropClick={disableBackdropClick}
                    disablePortal={true}
                    open={open > index}
                    scroll={'body'}
                    onClose={
                        !systemMode
                                ? hideModal
                                : () => {
                                }
                    }
                    disableRestoreFocus={disableRestoreFocus}  //made to lose focus of tooltip on modal close
            >
                <DialogContent
                        data-testid={'Modal'}
                        classes={{root: stl.content}}
                >
                    {/*// @ts-ignore */}
                    <Move
                            onStartDrag={startDrag}
                            onMove={onMove}
                            onEndDrag={endDrag}
                    />
                    {
                        !systemMode && (
                                <IconButton className={stl.close} aria-label="Delete" onClick={() => hideModal()}>
                                    <CloseIcon className={stl.closeIco}/>
                                </IconButton>
                        )
                    }
                    <h1
                            className={stl.title}
                            style={{textAlign: systemMode ? 'center' : 'left'}}>
                        {currentOptions.title}
                    </h1>
                    {
                        // @ts-ignore
                        scroll ? (<Scroll
                                        //  className={classes.scroll}
                                >
                                    {currentOptions.content}
                                </Scroll>
                        ) : null
                    }
                    {
                        !scroll && currentOptions.content
                    }
                </DialogContent>
            </Dialog>
    );
}

export default Modal;
