import React from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {Grow} from "@mui/material";
import Icon, * as Icons from '@gtb/components/Icons';

const useStyles = makeStyles(({typography, palette, spacing}: CustomTheme) => ({
    message: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        ...typography.p,
        color: palette.action.active6,
        paddingTop: spacing(2),
        textAlign: 'left',
        lineHeight: spacing(3),
        marginBottom: spacing(-2),
        '& svg': {
            marginRight: spacing(1.5),
            transform: 'translateY(-3px)',
        },
        '& svg path': {
            fill: palette.action.active6,
        },
    },
}));

interface AuthMessageProps extends React.PropsWithChildren<any> {
    [x: string]: any
}

const AuthMessage: React.FC<AuthMessageProps> = (props) => {
    const stl = useStyles(props);
    const {message} = props;

    return message ? (
            <Grow in={true} timeout={300}>
                <div className={stl.message}>
                    <Icon view={Icons.ICON_OUCH}/>
                    {
                        React.isValidElement(message)
                                ? <div>{message}</div>
                                : <div dangerouslySetInnerHTML={{__html: message}}/>
                    }
                </div>
            </Grow>
    ) : null;
};

export default AuthMessage;
