import React from 'react';
// import PropTypes from 'prop-types';
import {TextField} from '@mui/material';
import inputComponent from './inputComponent';

const Control = props => {
	return (
		<TextField
			fullWidth
			InputProps={{
				inputComponent,
				inputProps: {
					className: props.selectProps.classes.input,
					inputRef: props.innerRef,
					children: props.children,
					...props.innerProps,
				},
			}}
			{...props.selectProps.textFieldProps}
		/>
	);
};

export default Control;
