import _ from 'lodash';

export const formatUrlAddressField = (address: string | string[]) => {
	return _.isArray(address) ? address : address.replace(/\s/g, '').split(',');
};

export const normalizeUrl = (url: string) => {
	if (typeof url !== 'string') {
		return '#'
	}
	if (/(http(s?)):\/\//i.test(url)) {
		return url
	}
	return 'https://' + url;
}

export const validURL = (str: string): boolean => {
	let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
		'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
	return _.isString(str) ? !!pattern.test(str) : false;
};

interface UrlAddressFieldValidationInterface {
	field: string | string[],
	onSuccess?: () => void,
	onError?: () => void
}

export const urlAddressFieldValidation = (props: UrlAddressFieldValidationInterface) => {
	const {
		field,
		onSuccess,
		onError,
	} = props;

	let valid;
	if (_.isArray(field)) {
		valid = field.filter((u: string) => validURL(u)).length === field.length;
	} else {
		valid = validURL(field);
	}
	if (valid) {
		if (_.isFunction(onSuccess)) {
			onSuccess();
		}
	} else {
		if (_.isFunction(onError)) {
			onError();
		}
	}
};

export const emailValidation = (emailAddress: string) => {
	let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	if (typeof emailAddress == 'string' && emailAddress.match(regexEmail)) {
		return true;
	} else {
		return false;
	}
};
