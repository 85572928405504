import React from 'react';
import {Paper} from '@mui/material';

const Menu = props => {
	// console.log(props.children);
	return (
		<Paper
			data-testid={'Menu'}
			square
			className={props.selectProps.classes.paper}
			{...props.innerProps}
		>
			{props.children}
		</Paper>
	);
};

export default Menu;
