import React from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {ChooseFile} from "@gtb";

const useStyles = makeStyles(({}: CustomTheme) => ({
    root: {
        display: 'block',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    chooseFile: {
        borderRadius: 0,
    },
    input: {},
    text: {},
}));

const ChooseFileField: React.FC<{ [x: string]: any }> = (props) => {

    const stl = useStyles(props);
    const {
        classes, ...rest
    } = props;

    return (
            <div className={stl.root}>
                <ChooseFile classes={{
                    root: stl.chooseFile,
                    input: stl.input,
                    text: stl.text,
                }} {...rest} />
            </div>
    )
}

export default ChooseFileField;
