export const columns = [
	{
		type: 'th',
		slug: '_id',
		width: 50,
		className: 'middle idMiddle',
		sortable: false,
		hidable: false,
		filters: ['number'],
		resizable: false,
		movable: false,
		description: '#',
		content: '#',
	},
	{
		type: 'th',
		slug: 'companyName',
		className: 'smallPadding',
		width: 200,
		hidable: false,
		sortable: false,
		filters: ['keywords'],
		resizable: false,
		movable: false,
		description: 'Company Name',
		content: 'Company Name',
	},
	{
		type: 'th',
		slug: 'fqdn',
		className: 'smallPadding',
		width: 200,
		hidable: false,
		sortable: false,
		filters: ['keywords'],
		resizable: false,
		movable: false,
		description: 'FQDN',
		content: 'FQDN',
	},
	{
		type: 'th',
		slug: 'users',
		className: 'smallPadding centered',
		width: 100,
		hidable: false,
		sortable: false,
		filters: ['keywords'],
		resizable: false,
		movable: false,
		description: 'Users',
		content: 'Users',
	},
	{
		type: 'th',
		slug: 'licenseExpirationDate',
		className: 'smallPadding centered',
		width: 200,
		hidable: false,
		sortable: false,
		filters: ['keywords'],
		resizable: false,
		movable: false,
		description: 'License expiration date',
		content: 'License expiration date',
	},
	{
		type: 'th',
		slug: 'companyUrl',
		className: 'smallPadding',
		width: 300,
		hidable: false,
		sortable: false,
		filters: ['keywords'],
		resizable: false,
		movable: false,
		description: 'Company website',
		content: 'Company website',
	},
	{
		type: 'th',
		slug: 'createdAt',
		className: 'smallPadding centered',
		width: 200,
		hidable: false,
		sortable: false,
		filters: ['keywords'],
		resizable: false,
		movable: false,
		description: 'Created',
		content: 'Created',
	},
	// {
	// 	type: 'th',
	// 	slug: 'updatedAt',
	// 	className: 'smallPadding centered',
	// 	width: 150,
	// 	hidable: false,
	// 	sortable: false,
	// 	filters: ['keywords'],
	// 	resizable: false,
	// 	movable: false,
	// 	description: 'Updated',
	// 	content: 'Updated',
	// },
	{
		type: 'th',
		slug: 'description',
		className: 'smallPadding',
		hidable: false,
		sortable: false,
		filters: ['keywords'],
		resizable: false,
		movable: false,
		description: 'Description',
		content: 'Description',
	},
];

export default columns;
