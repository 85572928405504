import { API_URL } from './config';
import { downloadFile, fetching } from './utils';

export const startBackup = (
	id: string,
	data: { [x: string]: any },
	onSuccess?: (message?: any) => void,
	onError?: (error?: any) => void,
) => {
	fetching({
		url: API_URL + `/api/backup/${id}`,
		options: {
			method: 'POST',
			headers: {
				accept: '*/*',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		},
		onSuccess,
		onError,
	});
};

export const setBackupOptions = (
	id: string,
	data: { [x: string]: any },
	onSuccess?: (message: any) => void,
	onError?: (error: any) => void,
) => {
	fetching({
		url: API_URL + `/api/backup/${id}`,
		options: {
			method: 'PUT',
			headers: {
				accept: '*/*',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		},
		onSuccess,
		onError,
	});
};

export const removeBackup = (
	id: string,
	onSuccess?: (message: any) => void,
	onError?: (error: any) => void,
) => {
	fetching({
		url: API_URL + `/api/backup/${id}`,
		options: {
			method: 'DELETE',
			headers: {
				accept: '*/*',
				'Content-Type': 'application/json',
			},
		},
		onSuccess,
		onError,
	});
};

export const getBackupStatus = (
	id: string,
	onSuccess?: (message: any) => void,
	onError?: (error: any) => void,
) => {
	fetching({
		url: API_URL + `/api/backup/${id}`,
		options: {
			method: 'GET',
			headers: {
				accept: '*/*',
				'Content-Type': 'application/json',
			},
		},
		onSuccess,
		onError,
	});
};

export const getAllBackupsStatus = (
	onSuccess?: (message: any) => void,
	onError?: (error: any) => void,
) => {
	fetching({
		url: API_URL + `/api/backup`,
		options: {
			method: 'GET',
			headers: {
				accept: '*/*',
				'Content-Type': 'application/json',
			},
		},
		onSuccess,
		onError,
	});
};

export const downloadBackup = (
	id: string,
	onSuccess?: () => void,
	onError?: (error: any) => void,
) => {
	downloadFile({
		url: API_URL + `/api/backup/${id}/file`,
		options: { method: 'GET', headers: { accept: '*/*' } },
		defaultFileName: 'backup',
		onSuccess,
		onError,
	});
};


export const restoreBackup = (
	id: string,
	files: any,
	onSuccess?: (message: any) => void,
	onError?: (error: any) => void,
) => {
	const formData = new FormData();
	if (files && files.length > 0) {
		formData.append('file', files[0], files[0].name);
	}
	fetching({
		url: API_URL + `/api/backup/${id}/restore`,
		options: {
			method: 'POST',
			headers: {
				accept: '*/*',
			},
			body: formData,
		},
		onSuccess,
		onError,
	});
};
