import React from 'react';
import {makeStyles} from "@mui/styles";
import {CustomTheme} from "@gtb/components/Theme/interfaces";
import moment from "moment-timezone";

const useStyles = makeStyles(({palette, spacing}: CustomTheme) => ({
    root: {
        paddingTop: spacing(2.16666),
        paddingBottom: spacing(1.8444444),
    },
    notLicensed: {
        color: palette.action.active5,
        paddingTop: spacing(2.16666),
        paddingBottom: spacing(1.8444444),
    },
    unlimitedLicense: {
        color: palette.action.active,
        paddingTop: spacing(2.16666),
        paddingBottom: spacing(1.8444444),
    }
}));

interface LicenseInterface {
    value: string | number | Date,
    min?: string | Date,
    max?: string | Date,
    minLabel?: string | JSX.Element,
    maxLabel?: string | JSX.Element,
    emptyLabel?: string | JSX.Element,
    classes?: any,
}


const License: React.FC<LicenseInterface> = (props) => {
    const stl = useStyles(props);
    const {
        classes,
        value,
        min = 'Jan 1 2000',
        max = 'Dec 30 2099',
        minLabel = <span className={stl.notLicensed}>Not Licensed</span>,
        maxLabel = <span className={stl.unlimitedLicense}>License is unlimited</span>,
        emptyLabel = <span className={stl.notLicensed}>Not Licensed</span>,
    } = props;

    const parsedDate: moment.Moment = moment(new Date(value));
    const minDate = min ? moment(new Date(min)) : false;
    const maxDate = max ? moment(new Date(max)) : false;

    if (!value || !parsedDate.isValid()) {
        return <span className={stl.root}>{emptyLabel}</span>;
    }
    if (maxDate && maxDate.isValid() && parsedDate.isAfter(maxDate)) {
        return <span className={stl.root}>{maxLabel}</span>;
    }
    if (minDate && minDate.isValid() && parsedDate.isBefore(minDate)) {
        return <span className={stl.root}>{minLabel}</span>;
    }
    return <span className={stl.root}>{value}</span>;
}

export default License;
