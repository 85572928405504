import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Layout from './layout/Layout';
import NotAuthorized from './NotAuthorized'
import Breadcrumbs from 'apps/common/components/Breadcrumbs';
import {useLocation} from "react-router-dom";
import MainMenu from 'apps/common/components/layout/MainMenu';
import routes from 'apps/routes';
import {Preloader} from "@gtb";

interface Page {
    isLoading?: boolean,
    path?: string[],
    auth?: { user?: string, session?: string },
    toolbar?: JSX.Element,
    classes?: any
}

const Page: React.FC<React.PropsWithChildren<Page>> = (props) => {

    const {
        isLoading = false,
        toolbar,
        children,
        path,
        auth,
        classes
    } = props;
    const navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        if (!(auth && auth.session)) {
            navigate('/login');
        }
    }, [JSON.stringify(auth)])

    return auth && auth.session ? (
            <Preloader isLoading={isLoading} fullPage={true}>
                <Layout
                        toolbar={toolbar}
                        top={<Breadcrumbs path={path ? path : [location.pathname]}/>}
                        navbar={<MainMenu nav={routes}/>}
                        classes={classes}
                >
                    {children}
                </Layout>
            </Preloader>
    ) : (<NotAuthorized/>);
};

Page.defaultProps = {
    auth: {session: 'abracadabra'}
};

export default Page;
