import React from 'react';
import {makeStyles} from '@mui/styles';
import {CustomTheme} from "@gtb/interfaces";
import {Button, ButtonProps} from "@mui/material";

const useSimpleIconButtonStyles = makeStyles(({typography, spacing, palette, transitions, shadows,}: CustomTheme) => ({
    root: {
        ...typography.button,
        minWidth: spacing(7),
        height: spacing(7),
        padding: `${spacing(2)}px ${spacing(3)}px ${spacing(2)}px`,
        margin: 0,
        textTransform: 'inherit',
        textDecoration: 'none!important',
        textAlign: 'center',
        outline: 0,
        backgroundColor: palette.common.white,
        borderRadius: '0.7rem',
        transition: `all 0.1s ${transitions.easing.easeIn}`,
        '&:hover': {
            backgroundColor: palette.action.hover,
            boxShadow: shadows[1],
            textDecoration: 'none!important',
        },
        '&:active': {
            backgroundColor: palette.common.white,
            boxShadow: shadows[3],
        },
        '& svg': {
            margin: 0,
            '& path': {},
        },
        '&.pressed': {
            backgroundColor: palette.action.selected,
        },
        '.minimizedView > &': {
            height: spacing(5.5),
            padding: [[0, spacing(3)]],
        },
        '&.softDisabled': {
            pointerEvents: 'none!important',
        },
    },
    label: {},
    disabled: {
        opacity: 1,
        color: 'inherit !important',
    },
}));

interface GtbButtonProps extends ButtonProps {
    classes?: object,
}

export const GtbSimpleIconButton: React.FC<GtbButtonProps> = ({classes = {}, ...rest}) => {
    const stl = useSimpleIconButtonStyles({classes});
    return (
            <Button classes={stl} {...rest} />
    );
};

export default GtbSimpleIconButton;
